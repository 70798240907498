import React from "react";
import DataTable from "react-data-table-component";
import { downloadCSV, Export } from "../shared/exportButton";
import tableCustomStyles from "../shared/tableStyle";
import Dropdown from "./Dropdown";
import reportTableCustomStyle from "./ReportStyleCustomStyle";

const tabledata = [
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543210,
    listed: 3,
    location: "Pimpri",
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543211,
    listed: 3,
    location: "Pimpri",
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543212,
    listed: 3,
    location: "Pimpri",
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543213,
    listed: 3,
    location: "Pimpri",
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543214,
    listed: 3,
    location: "Pimpri",
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543215,
    listed: 3,
    location: "Pimpri",
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543216,
    listed: 3,
    location: "Pimpri",
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543217,
    listed: 3,
    location: "Pimpri",
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543218,
    listed: 3,
    location: "Pimpri",
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543219,
    listed: 3,
    location: "Pimpri",
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543220,
    listed: 3,
    location: "Pimpri",
    city: "Pune",
  },
];

const PropertyShared = ({ reportType, setReportType }) => {
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(tabledata)} />,
    []
  );

  const columns = [
    {
      name: "PUID",
      selector: (row) => row.id,
      // style:{
      //     borderRight: "1px solid #ECECEC",
      // }
    },
    {
      name: "Shared By",
      selector: (row) => row.sharedby,
      width: "300px",
      // cell:(row) => <span className='flex gap-[8px]'>{row.buyer} {row.isInterested && interestedClip}</span>,
    },
    {
      name: "Contact Info",
      selector: (row) => row.mobile,
      // cell:(row) => <span className='flex gap-[8px]'>{row.seller} {row.isFetchedInfo && fetchedClip}</span>,
    },
    {
      name: "Shared To",
      selector: (row) => row.sharedto,
      // cell:(row) => <span>{row.bid !== "-" ? `₹${row.bid}`: row.bid}</span>
    },
    {
      name: "Contact Info",
      selector: (row) => row.sharedContact,
      // cell:(row) => <span>{row.expsell !== "-" ? `₹${row.expsell}`: row.expsell}</span>,
    },
  ];

  return (
    <DataTable
      actions={actionsMemo}
      columns={columns}
      data={tabledata}
      responsive
      striped
      highlightOnHover
      noDataComponent={
        <span className="px-[16px] py-[20px]">{`No data found`}</span>
      }
      pagination
      customStyles={{
        ...reportTableCustomStyle,
        headCells: {
          style: {
            padding: "20px 16px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#666666",
            borderRight: "none",
          },
        },
      }}
      subHeader
      subHeaderAlign="left"
      subHeaderComponent={
        <Dropdown reportType={reportType} setReportType={setReportType} />
      }
    />
  );
};

export default PropertyShared;
