import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getUserBids,
  getUserInterested,
  getUserListings,
  getUserSaved,
  registerProfile,
  updateProfile,
} from "../apis/apiClient";
import PlotCard from "../components/BuyPlot/PlotCard";
import Footer from "../components/Footer";
import HomeHeader from "../components/Header/HomeHeader";
import ProfileDetails from "../components/Profile/ProfileDetails";
import { updateUser } from "../reducers/profile";
import { Oval } from "react-loader-spinner";
import { showToast } from "../utils/showToast";

const Profile = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("listing");
  const [plotData, setPlotData] = useState([]);
  const [editData, setEditData] = useState({
    mobile_number: "",
    email: "",
    dob: "",
    taluka: "",
    district: "",
    aadhaar_number: "",
  });

  const profile = useSelector((state) => state.profile);

  console.log(profile.userData.notifications)


  let { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!profile.isLoggedIn) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    setSelectedTab(state ? state.selectedTab : "listing");
  }, [state]);

  useEffect(() => {
    if (profile.userData?.id) {
      const getListing = async () => {
        try {
          const response = await getUserListings(profile.userData?.id);
          setIsLoading(false);
          if (response.status === "success") {
            setPlotData(response.listings.results);
          } else {
            showToast("Something Went wrong", "error");
          }
        } catch (e) {
          showToast("Something Went wrong", "error");

          console.log(e);
        }
      };
      const getBids = async () => {
        try {
          const response = await getUserBids(profile.userData?.id);
          setIsLoading(false);
          if (response.status === "success") {
            setPlotData(response.bids.results);
          } else {
            showToast("Something Went wrong", "error");
          }
        } catch (e) {
          showToast("Something Went wrong", "error");
          console.log(e);
        }
      };
      const getSaved = async () => {
        try {
          const response = await getUserSaved(profile.userData?.id);
          setIsLoading(false);
          if (response.status === "success") {
            setPlotData(response.saved.results);
          } else {
            showToast("Something Went wrong", "error");
          }
        } catch (e) {
          showToast("Something Went wrong", "error");
          console.log(e);
        }
      };
      const getInterested = async () => {
        try {
          const response = await getUserInterested(profile.userData?.id);
          setIsLoading(false);
          if (response.status === "success") {
            setPlotData(response.interested.results);
          } else {
            showToast("Something Went wrong", "error");
          }
        } catch (e) {
          showToast("Something Went wrong", "error");

          console.log(e);
        }
      };
      setIsLoading(true);
      if (selectedTab === "listing") {
        getListing();
      } else if (selectedTab === "bids") {
        getBids();
      } else if (selectedTab === "saved") {
        getSaved();
      } else if (selectedTab === "interested") {
        getInterested();
      }
    }
  }, [selectedTab, profile]);

  useEffect(() => {
    setEditData({
      mobile_number: profile.userData?.mobile_number,
      email: profile.userData?.email,
      dob: profile.userData?.dob,
      taluka: profile.userData?.taluka,
      district: profile.userData?.district,
      aadhaar_number: profile.userData?.aadhaar_number,
    });
  }, [profile]);

  const handleUpdateProfile = async () => {
    try {
      const response = await updateProfile(profile.userData.id, editData);
      if (response.status === "success") {
        dispatch(updateUser({ ...profile.userData, ...editData }));
        setIsEditModalOpen(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="bg-white mx-auto max-w-screen-xl px-[24px]">
        <HomeHeader isHome={false} />
        <div className="mt-[40px]">
          <ProfileDetails
            setIsEditModalOpen={setIsEditModalOpen}
            setIsVerifyModalOpen={setIsVerifyModalOpen}
            isOwnProfile={true}
            data={profile.userData}
          />
        </div>
        <div className="mt-[48px] border-b-[2px] border-[#D9D9D9] px-[36px] flex gap-[48px] overflow-x-scroll">
          <p
            onClick={() => {
              setSelectedTab("listing");
            }}
            className={`${
              selectedTab === "listing"
                ? "border-b-[3px] border-[#0259DB] text-[#1C1C1C]"
                : "text-[#666666]"
            } font-medium text-[16px] cursor-pointer w-fit p-[12px] whitespace-nowrap`}
          >
            My listings
          </p>
          <p
            onClick={() => {
              setSelectedTab("bids");
            }}
            className={`${
              selectedTab === "bids"
                ? "border-b-[3px] border-[#0259DB] text-[#1C1C1C]"
                : "text-[#666666]"
            } font-medium text-[16px] cursor-pointer w-fit p-[12px] whitespace-nowrap`}
          >
            My Offers
          </p>
          <p
            onClick={() => {
              setSelectedTab("interested");
            }}
            className={`${
              selectedTab === "interested"
                ? "border-b-[3px] border-[#0259DB] text-[#1C1C1C]"
                : "text-[#666666]"
            } font-medium text-[16px] cursor-pointer w-fit p-[12px] whitespace-nowrap`}
          >
            Liked Properties
          </p>
          <p
            onClick={() => {
              setSelectedTab("saved");
            }}
            className={`${
              selectedTab === "saved"
                ? "border-b-[3px] border-[#0259DB] text-[#1C1C1C]"
                : "text-[#666666]"
            } font-medium text-[16px] cursor-pointer w-fit p-[12px] whitespace-nowrap`}
          >
            Saved Properties
          </p>
        </div>

        {isLoading ? (
          <div className="h-[320px] flex items-center justify-center">
            <Oval
              height={52}
              width={52}
              color="#0259DB"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#0259DB"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : plotData.length === 0 ? (
          <div className="flex items-center justify-center w-full h-[320px]">
            {selectedTab === "listing" && (
              <div className="flex flex-col items-center">
                <h2 className="text-[24px] font-semibold text-[#2B2B2B] text-center">
                  You have not listed any property yet.
                </h2>
                <p className="mt-[16px] text-[16px] text-[#2B2B2B] text-center">
                  Start listing your property on rempo to get authentic
                  customers and great deals
                </p>
                <button
                  onClick={() => {
                    navigate("/sellplot");
                  }}
                  className="bg-[#0259DB] py-[12px] text-center border-[#0259DB] border-[1px] shadow-md rounded-regular w-full max-w-[300px] mt-[24px] text-white text-[16px] font-medium"
                >
                  List your property
                </button>
              </div>
            )}{" "}
            {selectedTab === "bids" && (
              <div className="flex flex-col items-center">
                <h2 className="text-[24px] font-semibold text-[#2B2B2B] text-center">
                  Your Offers will show up here
                </h2>
                <p className="mt-[16px] text-[16px] text-[#2B2B2B] text-center">
                  Place your perfect Offer on properties to crack the great deal
                </p>
                <button
                  onClick={() => {
                    navigate("/buyplot");
                  }}
                  className="bg-[#0259DB] py-[12px] text-center border-[#0259DB] border-[1px] shadow-md rounded-regular w-full max-w-[300px] mt-[24px] text-white text-[16px] font-medium"
                >
                  Browse Properties
                </button>
              </div>
            )}
            {selectedTab === "interested" && (
              <div className="flex flex-col items-center">
                <h2 className="text-[24px] font-semibold text-[#2B2B2B] text-center">
                  Liked properties will show up here
                </h2>
                <p className="mt-[16px] text-[16px] text-[#2B2B2B] text-center">
                  Browse from multiple listed properties to find your Liked one
                </p>
                <button
                  onClick={() => {
                    navigate("/buyplot");
                  }}
                  className="bg-[#0259DB] py-[12px] text-center border-[#0259DB] border-[1px] shadow-md rounded-regular w-full max-w-[300px] mt-[24px] text-white text-[16px] font-medium"
                >
                  Browse Properties
                </button>
              </div>
            )}
            {selectedTab === "saved" && (
              <div className="flex flex-col items-center">
                <h2 className="text-[24px] font-semibold text-[#2B2B2B] text-center">
                  Saved properties will show up here
                </h2>
                <p className="mt-[16px] text-[16px] text-[#2B2B2B] text-center">
                  Browse from multiple listed properties to find your Liked one
                </p>
                <button
                  onClick={() => {
                    navigate("/buyplot");
                  }}
                  className="bg-[#0259DB] py-[12px] text-center border-[#0259DB] border-[1px] shadow-md rounded-regular w-full max-w-[300px] mt-[24px] text-white text-[16px] font-medium"
                >
                  Browse Properties
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="">
            <InfiniteScroll
              className="mt-[24px] grid grid-cols-1 lg:grid-cols-2 gap-[32px]"
              dataLength={plotData.length}
              next={() => {
                // setTimeout(() => {
                //   setPlotData((prevState) => [...prevState, ...prevState]);
                // }, 2000);
              }}
              hasMore={false}
              loader={
                <div className="w-full pb-[50px] sm:w-[calc(200%+32px)] flex flex-col justify-center items-center text-[16px] font-medium gap-[16px]">
                  <Icon
                    icon="material-symbols:autorenew"
                    className="animate-spin"
                    color="#666666"
                    width={24}
                    height={24}
                  />
                  <p>Loading properties</p>
                </div>
              }
              scrollThreshold={1}
            >
              {plotData.map((data, index) => (
                <PlotCard data={data} keyIndex={index} key={index} />
              ))}
            </InfiniteScroll>
          </div>
        )}
      </div>

      <div
        className={`fixed z-50 overflow-y-auto top-0 w-full left-0 ${
          isVerifyModalOpen ? "" : "hidden"
        }`}
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => {
              setIsVerifyModalOpen(false);
            }}
          >
            <div className="absolute inset-0 bg-[#00000099] opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[700px] sm:w-full p-[24px]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex justify-between items-center">
              <div className="flex gap-[12px] items-center">
                <h3 className="text-[#000E14] text-[20px] font-bold">
                  User KYC
                </h3>{" "}
                <Icon
                  icon="material-symbols:check-circle-outline"
                  width={24}
                  height={24}
                  color="#58B455"
                />
              </div>
              <button
                onClick={() => {
                  setIsVerifyModalOpen(false);
                }}
              >
                <Icon
                  icon="material-symbols:close"
                  width={24}
                  height={24}
                  color="#000"
                />
              </button>
            </div>
            <p className="mt-[8px] text-[#606060] text-[16px] font-semibold w-[90%]">
              You are going to mark this user as verified. This will mark user
              as KYC is completed
            </p>
            <div className="mt-[36px] flex justify-end gap-[16px]">
              <button
                className="py-[8px] px-[16px] border-[1px] border-[#0259DB] text-[#0259DB] rounded-regular text-[16px] text-medium"
                onClick={() => {
                  setIsVerifyModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button className="py-[8px] px-[16px] border-[1px] border-[#0259DB] text-[#fff] bg-[#0259DB] rounded-regular text-[16px] text-medium">
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`fixed z-50 overflow-y-auto top-0 w-full left-0 ${
          isEditModalOpen ? "" : "hidden"
        }`}
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => {
              setIsEditModalOpen(false);
            }}
          >
            <div className="absolute inset-0 bg-[#00000099] opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[700px] sm:w-full p-[24px]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex justify-between items-center">
              <div className="flex gap-[12px] items-center">
                <h3 className="text-[#000E14] text-[20px] font-bold">
                  Edit Profile
                </h3>{" "}
                <Icon
                  icon="material-symbols:fact-check-outline"
                  width={24}
                  height={24}
                  color="#0259DB"
                />
              </div>
              <button
                onClick={() => {
                  setIsEditModalOpen(false);
                }}
              >
                <Icon
                  icon="material-symbols:close"
                  width={24}
                  height={24}
                  color="#000"
                />
              </button>
            </div>

            
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[28px] mt-[28px]">
              <div className="flex flex-col w-full">
                <label
                  htmlFor="mobile"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Mobile Number
                </label>
                <input
                  value={editData.mobile_number}
                  onChange={(e) => {
                    setEditData((prevState) => ({
                      ...prevState,
                      mobile_number: e.target.value,
                    }));
                  }}
                  placeholder="Mobile"
                  id="mobile"
                  type="text"
                  className="mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[10px] text-[16px] text-[#667085]"
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  htmlFor="email"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Email
                </label>
                <input
                  value={editData.email}
                  onChange={(e) => {
                    setEditData((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                  }}
                  placeholder="Email Address"
                  id="email"
                  type="text"
                  className="mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[10px] text-[16px] text-[#667085]"
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  htmlFor="dob"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Date of birth
                </label>
                <input
                  value={editData.dob}
                  onChange={(e) => {
                    setEditData((prevState) => ({
                      ...prevState,
                      dob: e.target.value,
                    }));
                  }}
                  placeholder="DD/MM/YYYY"
                  id="dob"
                  type="date"
                  className="mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[10px] text-[16px] text-[#667085]"
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  htmlFor="taluka"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Taluka
                </label>
                <input
                  value={editData.taluka}
                  onChange={(e) => {
                    setEditData((prevState) => ({
                      ...prevState,
                      taluka: e.target.value,
                    }));
                  }}
                  placeholder="Taluka"
                  id="taluka"
                  type="text"
                  className="mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[10px] text-[16px] text-[#667085]"
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  htmlFor="district"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  District
                </label>
                <input
                  value={editData.district}
                  onChange={(e) => {
                    setEditData((prevState) => ({
                      ...prevState,
                      district: e.target.value,
                    }));
                  }}
                  id="district"
                  type="text"
                  className="mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[10px] text-[16px] text-[#667085]"
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  htmlFor="aadhar"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Aadhaar
                </label>
                <input
                  value={editData.aadhaar_number}
                  onKeyUp={(e) => {
                    var value = e.target.value;
                    value = value
                      .replace(/\D/g, "")
                      .split(/(?:([\d]{4}))/g)
                      .filter((s) => s.length > 0)
                      .join("-");
                    e.target.value = value;
                  }}
                  onChange={(e) => {
                    setEditData((prevState) => ({
                      ...prevState,
                      aadhaar_number: e.target.value,
                    }));
                  }}
                  maxLength={19}
                  placeholder="1234-5678-9876"
                  id="aadhar"
                  type="text"
                  className="mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[10px] text-[16px] text-[#667085]"
                />
              </div>
            </div>
            <div className="mt-[36px] flex justify-end gap-[16px]">
              <button
                className="py-[8px] px-[16px] border-[1px] border-[#0259DB] text-[#0259DB] rounded-regular text-[16px] text-medium"
                onClick={() => {
                  setIsEditModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateProfile}
                className="py-[8px] px-[16px] border-[1px] border-[#0259DB] text-[#fff] bg-[#0259DB] rounded-regular text-[16px] text-medium"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
