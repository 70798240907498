import React from "react";
import OTPInput from "otp-input-react";
import { Oval } from "react-loader-spinner";

const OTPVerification = ({
  OTP,
  setIsOTPError,
  setOTP,
  isOTPError,
  username,
  setStep,
  remainingSeconds,
  handleVerifyOTP,
  isVerifyLoading,
  handleSendOTP,
}) => {
  return (
    <div>
      <h1 className="text-[24px] md:text-[40px] font-bold text-center">
        You are almost there
      </h1>
      <h5 className="text-[16px] md:text-[18px] text-secondaryText mt-[16px] text-center font-medium">
        Please enter the 4 digit OTP that we just sent on
        <br />{" "}
        {username?.slice(0, 2) + username?.slice(2).replace(/.(?=...)/g, "*")}
      </h5>
      <div className="mt-[40px] flex flex-col justify-center items-center">
        <div>
          <OTPInput
            value={OTP}
            onChange={(otp) => {
              setIsOTPError(false);
              setOTP(otp);
            }}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
            inputClassName={`border-[1px] p-[12px] rounded-regular !mr-[5px] ml-[5px] !w-[52px] !h-[48px] text-inputText outline-none ${
              isOTPError ? "border-errorColor" : "border-inputBorder"
            }`}
            className="justify-center p-[10px]"
          />
          {isOTPError ? (
            <p className="text-errorColor text-[14px] mx-[20px]">
              Please enter a valid OTP
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="mt-[32px] text-labelText text-[14px] font-medium flex justify-between">
        <button
          className="text-primaryColor bg-none"
          onClick={() => {
            setStep(0);
          }}
        >
          Edit {isNaN(username) ? "Email Address" : "Phone Number"}
        </button>
        <button
          onClick={handleSendOTP}
          className="text-primaryColor bg-none"
          disabled={remainingSeconds > 0}
        >
          {remainingSeconds === 0
            ? "Resend OTP"
            : `Resend OTP in ${remainingSeconds} sec`}
        </button>
      </div>
      <button
        disabled={isVerifyLoading}
        onClick={handleVerifyOTP}
        className="w-full mt-[36px] bg-primaryColor p-[12px] rounded-regular text-white font-medium text-[16px] flex items-center justify-center gap-[12px]"
      >
        <Oval
          height={20}
          width={20}
          color="#fff"
          wrapperStyle={{}}
          wrapperClass=""
          visible={isVerifyLoading}
          ariaLabel="oval-loading"
          secondaryColor="#f8f8f8f"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        Verify
      </button>
    </div>
  );
};

export default OTPVerification;
