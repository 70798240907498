import React, { useEffect, useState } from "react";
import MetricCard from "../components/Dashboard/MetricCard";
import Header from "../components/Header/AdminHeader";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../components/shared/tableStyle";
import ExpandedRows from "../components/ExpandedRows";
import { Icon } from "@iconify/react";
import { fetchedClip, interestedClip } from "../components/shared/Clips";
import { getAllBids, getAnalytics } from "../apis/apiClient";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlass, ThreeDots } from "react-loader-spinner";

const Dashboard = () => {
  const [logsData, setLogsData] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const [isBidsLoading, setIsBidsLoading] = useState(true);
  const [isAnalyticsLoading, setIsAnalyticsLoading] = useState(true);

  const profile = useSelector((state) => state.profile);

  const columns = [
    {
      name: "PUID",
      selector: (row) => row.plot?.puid,
      width: "200px",
    },
    {
      name: "Buyer’s Info",
      selector: (row) => row.buyer,
      cell: (row) => (
        <span className="flex gap-[8px]">
          {row.user?.name} {row.isInterested && interestedClip}
        </span>
      ),
      width: "250px",
    },
    {
      name: "Seller info",
      selector: (row) => row.plot?.listed_by?.name,
      cell: (row) => (
        <span className="flex gap-[8px]">
          {row.plot?.listed_by?.name} {row.isFetchedInfo && fetchedClip}
        </span>
      ),
      width: "250px",
    },
    {
      name: "Offer Submitted",
      selector: (row) => row.amaount,
      cell: (row) => (
        <span>{row.amount !== "-" ? `₹${row.amount}` : row.amount}</span>
      ),
    },
    {
      name: "Expected Selling",
      selector: (row) => row.plot,
      cell: (row) => <span>{`₹${row.plot?.rate?.rate}`}</span>,
    },
    {
      title: "",
      dataIndex: "",
      key: "expand",
      width: 1,
      cell: (row) => (
        <button
          id={`expandButton-${row.id}`}
          className={`transition ease-in-out delay-10`}
          onClick={() => {
            document
              .querySelectorAll(`[data-testid="expander-button-${row.id}"]`)[0]
              .click();
          }}
        >
          <Icon width="24px" icon="material-symbols:keyboard-arrow-down" />
        </button>
      ),
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.isLoggedIn) {
      navigate("/login", { state: { navigateTo: "/dashboard" } });
    }

    const fetchLogsData = async () => {
      try {
        setIsBidsLoading(true);
        const response = await getAllBids();
        setIsBidsLoading(false);

        if (response.status === "success") {
          setLogsData(response.bids?.splice(0, 5));
        } else {
          console.log(response);
        }

        setIsAnalyticsLoading(true);
        const anaResponse = await getAnalytics();
        setIsAnalyticsLoading(false);

        if (anaResponse.status === "success") {
          setCardsData(anaResponse.result);
        } else {
          console.log(anaResponse);
          setIsAnalyticsLoading(false);
        }
      } catch (e) {
        console.log(e);
        setIsBidsLoading(false);
      }
    };

    fetchLogsData();
  }, []);

  return (
    <div className="bg-[#F9FCFF] min-h-screen">
      <Header />
      <div className="mx-auto max-w-screen-xl pt-[40px] pb-[64px] px-[24px]">
        <h3 className="text-[24px] font-semibold text-[#333333]">
          Welcome back, {profile.userData?.name}
        </h3>
        <h6 className="mt-[12px] text-[14px] text-[#747C7F]">
          Have a look at every interaction and activities of REMPO users
        </h6>
        {isAnalyticsLoading ? (
          <div className="w-full min-h-[400px] flex justify-center items-center">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#0259DB"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
            {/* <MagnifyingGlass
              visible={isAnalyticsLoading }
              height="80"
              width="80"
              ariaLabel="MagnifyingGlass-loading"
              wrapperStyle={{}}
              wrapperClass="MagnifyingGlass-wrapper"
              glassColor="#c0efff"
              color="#367DE7"
            /> */}
          </div>
        ) : (
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[32px] mt-[18px]">
            {cardsData.map((cardData, i) => (
              <MetricCard key={`metric__${i}`} data={cardData} />
            ))}
          </div>
        )}

        <section>
          <h3 className="text-[#333333] font-semibold text-[20px] my-[25px]">
            Logs
          </h3>
          <DataTable
            columns={columns}
            data={logsData}
            responsive
            striped
            highlightOnHover
            noDataComponent={
              <span className="px-[16px] py-[20px]">{`No data found`}</span>
            }
            expandableRows={{
              expandedRowRender: (record) => <p style={{ margin: 0 }}>H</p>,
            }}
            expandableRowsComponent={ExpandedRows}
            // pagination
            customStyles={tableCustomStyles}
            onRowExpandToggled={(isExpanded, data) => {
              if (isExpanded) {
                document
                  .getElementById(`expandButton-${data.id}`)
                  .classList.add("rotate-180");
              } else {
                document
                  .getElementById(`expandButton-${data.id}`)
                  .classList.remove("rotate-180");
              }
            }}
            // conditionalRowStyles={[
            //     {
            //         when: row => {console.log(row); return true},
            //         style: {
            //         backgroundColor: 'green',
            //         color: 'white',
            //         '&:hover': {
            //             cursor: 'pointer',
            //         },
            //         },
            //     }]}
          />
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
