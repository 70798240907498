import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Popup from "../Popup";
import { logoutUser } from "../../reducers/profile";
import { useNavigate } from "react-router-dom";
import { handleUserDelete } from "../../apis/apiClient";
import { showToast } from '../../utils/showToast'

const ProfileDetails = ({
  setIsEditModalOpen,
  setIsVerifyModalOpen,
  isOwnProfile,
  data,
}) => {
  const [isShareOpen, setIsShareOpen] = useState(false);
  useEffect(() => {
    document.getElementsByTagName("body")[0].addEventListener("click", (e) => {
      if (e.target.tagName === "path" || e.target.tagName === "svg") {
        return false;
      }
      setIsShareOpen(false);
    });
    return () => {
      document
        .getElementsByTagName("body")[0]
        .removeEventListener("click", () => {});
    };
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [popup, setPopup] = useState({
    show: false,
    id: null,
  });

  function handleDelete(){
    setPopup({
      show: true,
      id:data._id,
    });
  }

  const handleLogout = () => {
    localStorage.removeItem("userData");
    dispatch(logoutUser());
    navigate("/login");
  };
  const handleDeleteTrue = async () => {
    if (popup.show && popup.id) {
      try {
      let response = await handleUserDelete(data._id)
      handleLogout()
      showToast(response.message, 'success')
      setPopup({
        show: false,
        id: null,
      });
    } catch (error) {
      console.error('Error deleting user:', error);
    }
    }
  }

  const handleDeleteFalse = () => {
    
    setPopup({
      show: false,
      id: null,
    });
  };

  if (popup.show) return(<Popup
    handleDeleteTrue={handleDeleteTrue}
    handleDeleteFalse={handleDeleteFalse}
  />)

  return (
    <div className="flex gap-[24px]">
      {isOwnProfile && (
        <div
          className="relative inline-flex items-center justify-center min-w-[60px] h-[60px] overflow-hidden bg-[#EAF2FF] border-[1px] border-[#B3CDF4] rounded-full"
          type="button"
          data-dropdown-toggle="userDropdown"
          data-dropdown-placement="bottom-start"
        >
          <span className="font-bold text-[32px] text-primaryColor text-uppercase">
            {data.name?.split("")[0]}
          </span>
        </div>
      )}
      <div className="flex w-full justify-between items-start flex-col md:flex-row">
        <div className="w-[90%]">
          <div className="flex gap-[12px] items-center">
            <h1 className="text-[32px] font-semibold text-[#000E14]">
              {data.name}
            </h1>
            {data.isBlocked && (
              <Icon color="red" icon="material-symbols:block" width={24} />
            )}
          </div>
          <div className="mt-[12px] grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-[20px]">
            <div>
              <h3 className="text-[#666666] text-[12px]">Mobile Number</h3>
              <p className="mt-[4px] text-[16px] font-medium">
                {" "}
                {data.mobile_number}
              </p>
            </div>
            <div>
              <h3 className="text-[#666666] text-[12px]">Email</h3>
              <p className="mt-[4px] text-[16px] font-medium">{data.email}</p>
            </div>{" "}
            <div>
              <h3 className="text-[#666666] text-[12px]">Date of birth</h3>
              <p className="mt-[4px] text-[16px] font-medium"> {data.dob}</p>
            </div>{" "}
            <div>
              <h3 className="text-[#666666] text-[12px]">Taluka</h3>
              <p className="mt-[4px] text-[16px] font-medium"> {data.taluka}</p>
            </div>{" "}
            <div>
              <h3 className="text-[#666666] text-[12px]">City</h3>
              <p className="mt-[4px] text-[16px] font-medium">
                {" "}
                {data.district}
              </p>
            </div>{" "}
            <div>
              <h3 className="text-[#666666] text-[12px]">Aadhaar</h3>
              {isOwnProfile ? (
                // <div className="w-full py-[4px] px-[8px] bg-[#CCDEF8] text-[#0259DB] text-[12px] font-medium rounded-[16px] mt-[4px]">
                //   Verify your account to boost user’s confidence in your
                //   profile.
                // </div>
                <p className="mt-[4px] text-[16px] font-medium flex gap-[12px]">
                  {data.aadhaar_number && data.aadhaar_number !== ""
                    ? data.aadhaar_number
                    : "-"}
                </p>
              ) : (
                <p className="mt-[4px] text-[16px] font-medium flex gap-[12px]">
                  {data.aadhaar_number}
                  {/* {!data.isVerified && (
                    <button
                      onClick={() => {
                        setIsVerifyModalOpen(true);
                      }}
                      className="text-primaryColor"
                    >
                      verify
                    </button>
                  )} */}
                </p>
              )}
            </div>
            {/* <div>
              <h3 className="text-[#666666] text-[12px]">Referral</h3>
              <p className="mt-[4px] text-[16px] font-medium flex gap-[12px]">
                {data.referral_code}
                {isOwnProfile && (
                  <div className="relative">
                    <button
                      className="cursor-pointer text-[#000]"
                      onClick={() => {
                        setIsShareOpen((prevState) => !prevState);
                      }}
                    >
                      <Icon icon="material-symbols:share" />
                    </button>

                    <div
                      id="dropdown"
                      className={`z-10  bg-[#efefef] rounded-regular divide-y gap-[8px] divide-gray-100 px-[12px] py-[8px] shadow-xl absolute flex transition duration-100 ease-in-out origin-top-left ${
                        isShareOpen ? "scale-100" : "scale-0"
                      }`}
                    >
                      <FacebookShareButton
                        url={`http://localhost:3000/login?referral=${data.referral_code}`}
                        quote="Join Rempo with this referral"
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>

                      <TwitterShareButton
                        url={`http://localhost:3000/login?referral=${data.referral_code}`}
                        title="Join Rempo with this referral"
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>

                      <TelegramShareButton
                        url={`http://localhost:3000/login?referral=${data.referral_code}`}
                        title="Join Rempo with this referral"
                      >
                        <TelegramIcon size={32} round />
                      </TelegramShareButton>
                      <WhatsappShareButton
                        url={`http://localhost:3000/login?referral=${data.referral_code}`}
                        title="Join Rempo with this referral"
                        separator=": "
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>

                      <EmailShareButton
                        url={`http://localhost:3000/login?referral=${data.referral_code}`}
                        subject="Join Rempo with this referral"
                        body="body"
                        className="Demo__some-network__share-button"
                      >
                        <EmailIcon size={32} round />
                      </EmailShareButton>
                    </div>
                  </div>
                )}
              </p>
            </div> */}
          </div>
          {!isOwnProfile && (
            <>
              <div className="mt-[24px]">
                <h6 className="text-[16px] font-medium text-[#000E14] before:w-[40px] before:border-b-[2px] before:border-[#0247AF] before:absolute before:bottom-0 before:left-0 relative">
                  Seller Stats
                </h6>
                <div className="mt-[12px] grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-[24px]">
                  <div className="p-[12px] bg-[#ECF3FF66] shadow-md rounded-regular border-b-[2px] border-primaryColor">
                    <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
                      {data.listings?.length}
                    </h3>
                    <p className="text-[14px] font-medium text-[#5E6A72] text-center">
                      Total Listings
                    </p>
                  </div>
                  <div className="p-[12px] bg-[#ECF3FF66] shadow-md rounded-regular border-b-[2px] border-primaryColor">
                    <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
                      {data?.sellerStats?.interested}
                    </h3>
                    <p className="text-[14px] font-medium text-[#5E6A72] text-center">
                      Liked Properties
                    </p>
                  </div>
                  <div className="p-[12px] bg-[#ECF3FF66] shadow-md rounded-regular border-b-[2px] border-primaryColor">
                    <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
                      {data?.sellerStats?.saved}
                    </h3>
                    <p className="text-[14px] font-medium text-[#5E6A72] text-center">
                      Saved
                    </p>
                  </div>
                  <div className="p-[12px] bg-[#ECF3FF66] shadow-md rounded-regular border-b-[2px] border-primaryColor">
                    <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
                      {data.contacts_fetched?.length}
                    </h3>
                    <p className="text-[14px] font-medium text-[#5E6A72] text-center">
                      Fetched Contact
                    </p>
                  </div>
                  <div className="p-[12px] bg-[#ECF3FF66] shadow-md rounded-regular border-b-[2px] border-primaryColor">
                    <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
                      {data?.sellerStats?.bids}
                    </h3>
                    <p className="text-[14px] font-medium text-[#5E6A72] text-center">
                      Total Offers
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-[24px]">
                <h6 className="text-[16px] font-medium text-[#000E14] before:w-[40px] before:border-b-[2px] before:border-[#0247AF] before:absolute before:bottom-0 before:left-0 relative">
                  Buyer Stats
                </h6>
                <div className="mt-[12px] grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-[24px]">
                  <div className="p-[12px] bg-[#ECF3FF66] shadow-md rounded-regular border-b-[2px] border-primaryColor">
                    <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
                      {data.interested?.length}
                    </h3>
                    <p className="text-[14px] font-medium text-[#5E6A72] text-center">
                      Liked Properties
                    </p>
                  </div>
                  <div className="p-[12px] bg-[#ECF3FF66] shadow-md rounded-regular border-b-[2px] border-primaryColor">
                    <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
                      {data.saved?.length}
                    </h3>
                    <p className="text-[14px] font-medium text-[#5E6A72] text-center">
                      Saved
                    </p>
                  </div>
                  <div className="p-[12px] bg-[#ECF3FF66] shadow-md rounded-regular border-b-[2px] border-primaryColor">
                    <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
                      {data.bids?.length}
                    </h3>
                    <p className="text-[14px] font-medium text-[#5E6A72] text-center">
                      Total Offers
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col md:gap-4">
          <button
            className="w-[140px] text-center justify-center flex gap-[10px] py-[10px] px-[26px] rounded-regular border-[1px] border-[#666666] text-[16px] text-[#666666] items-center mt-[24px] md:mt-0"
            onClick={() => {
              setIsEditModalOpen(true);
            }}
          >
            Edit Profile
          </button>
          <button
            className="w-[140px] text-center justify-center flex gap-[10px] py-[10px] px-[26px] rounded-regular border-[1px] border-[#666666] text-[16px] text-[#666666] items-center mt-[24px] md:mt-0 whitespace-nowrap"
            onClick={() => {
              handleDelete();
            }}
          >
            Delete Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
