import { Transition } from "@headlessui/react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/AdminHeader";
import EndUser from "../components/Reports/EndUsers";
import Fees from "../components/Reports/Fees";
import Listings from "../components/Reports/Listings";
import Listings2 from "../components/Reports/Listings2";
import Bidded from "../components/Reports/ModalSidebar/Bidded";
import BidHistory from "../components/Reports/ModalSidebar/BidHistory";
import Interested from "../components/Reports/ModalSidebar/Interested";
import PropertyListed from "../components/Reports/ModalSidebar/PropertiesListed";
import PropertyShared from "../components/Reports/PropertyShared";
import PropertyStats from "../components/Reports/PropertyStats";
import Referral from "../components/Reports/Referral";
import Subscription from "../components/Reports/Subscription";

const Reports = () => {
  const [reportType, setReportType] = useState("listings");
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const ref = useRef();

  const profile = useSelector((state) => state.profile);

  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.isLoggedIn) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [reportType]);

  return (
    <>
      <div className={`bg-[#F9FCFF] min-h-screen`}>
        <Header />
        <div
          className={`mx-auto max-w-screen-xl pt-[40px] pb-[64px] px-[24px]`}
        >
          <h1 className="text-[32px] font-semibold text-[#000E14]">Reports</h1>
          <section className="relative">
            {reportType === "fees" && (
              <Fees reportType={reportType} setReportType={setReportType} />
            )}
            {reportType === "listings" && (
              <Listings reportType={reportType} setReportType={setReportType} />
            )}
            {reportType === "property" && (
              <Listings2
                reportType={reportType}
                setReportType={setReportType}
                // setIsOpen={setIsOpen}
              />
            )}
            {reportType === "propertyshared" && (
              <PropertyShared
                reportType={reportType}
                setReportType={setReportType}
              />
            )}
            {reportType === "propertystats" && (
              <PropertyStats
                reportType={reportType}
                setReportType={setReportType}
              />
            )}
            {reportType === "subscription" && (
              <Subscription
                reportType={reportType}
                setReportType={setReportType}
              />
            )}
            {reportType === "enduser" && (
              <EndUser
                reportType={reportType}
                setReportType={setReportType}
                setIsOpen={setIsOpen}
                setModalData={setModalData}
              />
            )}
            {reportType === "referral" && (
              <Referral reportType={reportType} setReportType={setReportType} />
            )}
          </section>
        </div>
      </div>
      {isOpen && (
        <div
          className="w-full h-full bg-[#ffffffa8] absolute top-0 z-50"
          onClick={() => {
            setIsOpen(false);
          }}
        ></div>
      )}
      <Transition
        show={isOpen ? true : false}
        ref={ref}
        className={`z-50 fixed top-0 ${
          isOpen === "bidhistory" ? "md:right-[360px]" : "md:right-[560px]"
        } w-full md:w-auto`}
        enter="transition ease-out duration-100 transform"
        enterFrom={`-translate-y-[360px] md:-translate-y-0 ${
          isOpen === "bidhistory"
            ? "md:-translate-x-[-360px]"
            : "md:-translate-x-[-560px]"
        }`}
        enterTo="-translate-y-0 md:-translate-x-full"
        leave="transition ease-in duration-75 transform"
        leaveFrom="-translate-y-0 md:-translate-x-full"
        leaveTo={`-translate-y-[360px] md:-translate-y-0 ${
          isOpen === "bidhistory"
            ? "md:-translate-x-[-360px]"
            : "md:-translate-x-[-560px]"
        }`}
        // enter="transition ease-out duration-100 transform"
        // enterFrom={`-translate-y-[256px] md:-translate-y-0 md:-translate-x-[-256px]`}
        // enterTo="-translate-y-0 md:-translate-x-full"
        // leave="transition ease-in duration-75 transform"
        // leaveFrom="-translate-y-0 md:-translate-x-full"
        // leaveTo={`-translate-y-[256px] md:-translate-y-0 md:-translate-x-[256px]`}
      >
        {(ref) => (
          <div
            ref={ref}
            className={`fixed bg-[#fff] w-full ${
              isOpen === "bidhistory" ? "md:w-[360px]" : "md:w-[560px]"
            } h-screen end-0 px-[24px] md:px-[32px] pt-[24px] flex flex-col shadow-lg`}
          >
            {isOpen === "bidhistory" && (
              <BidHistory setIsOpen={setIsOpen} modalData={modalData} />
            )}
            {isOpen === "propertieslisted" && (
              <PropertyListed setIsOpen={setIsOpen} modalData={modalData} />
            )}
            {isOpen === "interested" && (
              <Interested setIsOpen={setIsOpen} modalData={modalData} />
            )}
            {isOpen === "bidded" && (
              <Bidded setIsOpen={setIsOpen} modalData={modalData} />
            )}
          </div>
        )}
      </Transition>
    </>
  );
};

export default Reports;
