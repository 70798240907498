import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../../shared/tableStyle";
import { convertArea, getUnitLabel, units } from "../../../utils/units";
import { getFormattedDate } from "../../../utils/date";
import { numDifferentiation } from "../../../utils/sharedFunc";

const PropertyListed = ({ setIsOpen, modalData }) => {
  return (
    <>
      <div className="flex justify-between w-full items-center">
        <h5 className="text-[20px] font-medium">Properties Listed</h5>
        <button
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <Icon width="24px" icon="material-symbols:close" />
        </button>
      </div>
      <h6 className="text-[16px] text-[#667085] mt-[8px] mb-[24px]">
        {modalData.length} properties listed
      </h6>
      <DataTable
        columns={[
          {
            name: "PUID",
            selector: (row) => row.puid,
            // cell: (row) => <span>1234567890</span>,
          },
          {
            name: "Price",
            selector: (row) =>
              row.rate?.lumpsum !== ""
                ? row.rate?.lumpsum
                : row.rate?.from &&
                  row.rate?.from !== "" &&
                  row.rate?.to &&
                  row.rate?.to !== ""
                ? `${numDifferentiation(
                    Number(row.rate.from)
                  )} - ${numDifferentiation(Number(row.rate.to))}`
                : `${row.rate.rate} / ${getUnitLabel(row.rate.unit)}`,
            // cell: (row) => <span>₹1200</span>,
          },
          {
            name: "Date",
            selector: (row) => getFormattedDate(row.createdAt),
            // cell: (row) => <span>05/04/2023</span>,
          },
          {
            name: "Area",
            width: "150px",
            selector: (row) => row.area,
            cell: (row) => (
              <div className="flex gap-[8px] break-normal items-center">
                <span id={`${row.puid}`}>
                  {convertArea(row.area, "ft2", row.area_unit)}
                </span>
                <select
                  onChange={(e) => {
                    console.log(
                      (document.getElementById(row.puid).innerHTML =
                        convertArea(row.area, "ft2", e.target.value)),
                      e.target.value
                    );
                  }}
                  className=" py-[10px] outline-none text-[14px] font-medium text-[#666666] w-full bg-transparent border-none outline-none"
                  defaultValue={row.area_unit}
                >
                  {units.map((unit) => (
                    <option value={unit.value}>{unit.label}</option>
                  ))}
                  {/* <option value="ft2">ft2</option>
                  <option value="m2">m2</option>
                  <option value="g">Guntha</option>
                  <option value="ac">Acre</option>
                  <option value="ha">Hectare</option> */}
                </select>
              </div>
            ),
          },
        ]}
        data={modalData}
        noDataComponent={
          <span className="px-[16px] py-[20px]">{`No data found`}</span>
        }
        customStyles={{
          ...tableCustomStyles,
          headRow: {
            style: {
              padding: "0",
            },
          },
          rows: {
            style: {
              fontSize: "14px",
              fontWeight: "500",
              color: "#484848",
              backgroundColor: "#FAFAFA",
              // padding: "16px",
              "&:not(:last-of-type)": {
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                borderBottomColor: "#ECECEC",
              },
            },
          },
        }}
      />
    </>
  );
};

export default PropertyListed;
