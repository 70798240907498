import React from "react";
import mission1 from "../../assets/svg/mission/1.svg";
import mission2 from "../../assets/svg/mission/2.svg";
import mission3 from "../../assets/svg/mission/3.svg";
import mission4 from "../../assets/svg/mission/4.svg";
import mission5 from "../../assets/svg/mission/5.svg";

const Mission = () => {
  return (
    <section className="mt-[64px] bg-missionBg" id="ourmission">
      <div className="mx-auto max-w-screen-xl px-[24px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[16px] pb-[64px]">
        <div className="h-[200px] rounded-regular flex items-center">
          <h1 className="text-[36px] md:text-[48px] font-bold text-headingSecondary">
            Our Mission
          </h1>
        </div>
        <div className="h-[200px] p-[28px] rounded-regular bg-primaryColor shadow-md">
          <div className="flex items-center">
            <img alt="mission" src={mission1} />
            <h3 className="ml-[16px] font-bold text-[18px] md:text-[24px] text-disabledColor missionheading">
              Connecting Markets
            </h3>
          </div>
          <p className="mt-[30px] text-[16px] md:text-[20px] font-medium text-disabledColorLight missiondetails">
            We are leveraging technology to connect local properties to a global
            audience for transparent dealing.
          </p>
        </div>
        <div className="h-[200px] p-[28px] rounded-regular bg-primaryColor shadow-md">
          <div className="flex items-center">
            <img alt="mission" src={mission2} />
            <h3 className="ml-[16px] font-bold text-[18px] md:text-[24px] text-disabledColor missionheading">
              Get Recognized
            </h3>
          </div>
          <p className="mt-[30px] text-[16px] md:text-[20px] font-medium text-disabledColorLight missiondetails">
            We help land developers get recognized in the real-estate
            marketplace.
          </p>
        </div>
        <div className="h-[200px] p-[28px] rounded-regular bg-primaryColor shadow-md">
          <div className="flex items-center">
            <img alt="mission" src={mission3} />
            <h3 className="ml-[16px] font-bold text-[18px] md:text-[24px] text-disabledColor missionheading">
              Right Networking
            </h3>
          </div>
          <p className="mt-[30px] text-[16px] md:text-[20px] font-medium text-disabledColorLight missiondetails">
            We help you connect with the right audience to build a stronger real
            estate business
          </p>
        </div>
        <div className="h-[200px] p-[28px] rounded-regular bg-primaryColor shadow-md">
          <div className="flex items-center">
            <img alt="mission" src={mission4} />
            <h3 className="ml-[16px] font-bold text-[18px] md:text-[24px] text-disabledColor missionheading">
              Trust & Legacy
            </h3>
          </div>
          <p className="mt-[30px] text-[16px] md:text-[20px] font-medium text-disabledColorLight missiondetails">
            Our legacy elicits trust in the real estate market and as land
            developers, you can leverage it.
          </p>
        </div>
        <div className="h-[200px] p-[28px] rounded-regular bg-primaryColor shadow-md">
          <div className="flex items-center">
            <img alt="mission" src={mission5} />
            <h3 className="ml-[16px] font-bold text-[18px] md:text-[24px] text-disabledColor missionheading">
              Employment Creation
            </h3>
          </div>
          <p className="mt-[30px] text-[16px] md:text-[20px] font-medium text-disabledColorLight missiondetails">
            We aim to create jobs in the local real estate industry by
            harnessing local knowledge and expertise.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Mission;
