import { Icon } from "@iconify/react";
import React from "react";
import { baseURL } from "../../constants";

const ImageModal = ({
  isImageModalOpen,
  setIsImageModalOpen,
  plotDetails,
  setIsLightBoxOpen,
  setPhotoIndex,
}) => {
  return (
    <div
      className={`fixed z-50 overflow-y-auto top-0 w-full left-0 ${
        isImageModalOpen ? "" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => {
            setIsImageModalOpen(false);
          }}
        >
          <div className="absolute inset-0 bg-[#1C1C1C] opacity-90" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[800px] max-h-[90vh] overflow-y-scroll sm:w-full p-[24px]"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-between items-center">
            <div className="flex gap-[12px] items-center">
              <h3 className="text-[#000E14] text-[24px] font-semibold">
                Photos from Seller
              </h3>
            </div>
            <button
              onClick={() => {
                setIsImageModalOpen(false);
              }}
            >
              <Icon
                icon="material-symbols:close"
                width={24}
                height={24}
                color="#000"
              />
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-[16px] py-[24px]">
            {plotDetails?.images?.map((image) => (
              <img
                onClick={() => {
                  setPhotoIndex(1);
                  setIsLightBoxOpen(true);
                  setIsImageModalOpen(false);
                }}
                src={`${baseURL}/${image.path}`}
                alt="plotimg"
                className="rounded-regular w-full h-[135px] object-cover"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
