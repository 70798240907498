import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import InterestedRow from "./InterestedRow";

const InterestedModal = ({
  isInterestedModalOpen,
  setIsInterestedModalOpen,
  interested,
  plotId,
}) => {
  return (
    <div
      className={`fixed z-50 overflow-y-auto top-0 w-full left-0 ${
        !isInterestedModalOpen && "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => {
            setIsInterestedModalOpen(false);
          }}
        >
          <div className="absolute inset-0 bg-[#1C1C1C] opacity-90" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[900px] sm:w-full p-[32px]"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-between items-center">
            <div className="flex gap-[12px] items-center">
              <h3 className="text-[#000E14] text-[24px] font-semibold">
                Interested
              </h3>
            </div>
            <button
              onClick={() => {
                setIsInterestedModalOpen(false);
              }}
            >
              <Icon
                icon="material-symbols:close"
                width={24}
                height={24}
                color="#000"
              />
            </button>
          </div>
          <div className="mt-[12px] flex flex-col gap-[12px] h-full max-h-[70vh] overflow-y-scroll ">
            {interested?.map((data, i) => (
              <InterestedRow
                data={{ ...data, plot: plotId }}
                key={`key__${i}bid`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestedModal;
