const interestedClip = (
  <span className="rounded-regular bg-[#EFF8EE] px-[8px] py-[4px] text-[#58B455] font-medium text-[10px]">
    Interested
  </span>
);

const fetchedClip = (
  <span className="rounded-regular bg-[#FAEBEC] px-[8px] py-[4px] text-[#C62F39] font-medium text-[10px]">
    Fetched Info
  </span>
);

export { interestedClip, fetchedClip };
