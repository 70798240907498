import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";
import { baseURL } from "../../constants";

const ImageSlider = ({ setIsImageModalOpen, data, videos }) => {
  const [imageUrl, setImageUrl] = useState(``);
  const [videoUrl, setVideoUrl] = useState(``);

  useEffect(() => {
    if (data) {
      if (videos?.length > 0) {
        setImageUrl(``);
        setVideoUrl(videos[0]);
      } else {
        setVideoUrl(``);
        setImageUrl(
          data[0]?.path
            ? `${baseURL}/${data[0]?.path}`
            : require("../../assets/images/plots/placeholder.jpg")
        );
      }
    }
  }, [data, videos]);

  return (
    <div className="w-full flex flex-col sm:flex-row gap-[16px] sm:h-[510px]">
      {videoUrl !== "" && (
        <YouTube
          className="rounded-regular w-full sm:max-w-[calc(100%-115px)] h-full object-cover"
          videoId={videoUrl
            ?.replace("https://youtu.be/", "")
            .replace("https://www.youtube.com/watch?v=", "")}
          opts={{
            height: "100%",
            width: "100%",
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 1,
              muted: 1,
            },
          }}
        />
      )}
      {videoUrl === "" && (
        <img
          src={imageUrl}
          alt="activeImg"
          className="rounded-regular w-full sm:max-w-[calc(100%-115px)] h-full object-cover"
        />
      )}
      <div className="flex flex-row sm:flex-col justify-between">
        {[...videos, ...data]?.slice(0, 5)?.map((image, i) => {
          return i === 4 && [...videos, ...data].length > 5 ? (
            <div
              onClick={() => {
                setIsImageModalOpen(true);
              }}
              className={`border-[3px] ${
                imageUrl === `${baseURL}/${image.path}`
                  ? "border-[#367DE7]"
                  : "border-transparent"
              } rounded-regular p-[2px] cursor-pointer relative`}
            >
              <img
                src={`${baseURL}/${image.path}`}
                alt="activeImg"
                className="rounded-regular w-[85px] h-[85px] object-cover"
              />
              <div className="bg-[#000E14] absolute w-full h-full top-0 left-0 rounded-regular bg-opacity-70 flex justify-center items-center text-white text-[16px] font-medium">
                + {data?.length - 5}
              </div>
            </div>
          ) : (
            <div
              className={`border-[3px] ${
                imageUrl === `${baseURL}/${image.path}` || videoUrl === image
                  ? "border-[#367DE7]"
                  : "border-transparent"
              } rounded-regular p-[2px] cursor-pointer`}
            >
              <img
                onClick={(e) => {
                  if (image.path) {
                    setImageUrl(e.target.src);
                    setVideoUrl(``);
                  } else {
                    setImageUrl(``);
                    setVideoUrl(image);
                  }
                }}
                src={
                  image.path
                    ? `${baseURL}/${image.path}`
                    : `https://img.youtube.com/vi/${image
                        ?.replace("https://youtu.be/", "")
                        ?.replace(
                          "https://www.youtube.com/watch?v=",
                          ""
                        )}/0.jpg`
                }
                alt="activeImg"
                className="rounded-regular w-[85px] h-[85px] object-cover"
              />
            </div>
          );
        })}
        {[...videos, ...data] &&
          [...videos, ...data].length < 6 &&
          [...Array(6 - [...videos, ...data].length)].map((e, i) => (
            <div className="h-[85px]"></div>
          ))}

        {/* <div
          onClick={() => {
            setIsImageModalOpen(true);
          }}
          className={`border-[3px] ${
            imageUrl.replaceAll("http://localhost:3000", "") === plot2
              ? "border-[#367DE7]"
              : "border-transparent"
          } rounded-regular p-[2px] cursor-pointer relative`}
        >
          <img
            onClick={(e) => {
              setImageUrl(e.target.src);
            }}
            src={plot2}
            alt="activeImg"
            className="rounded-regular w-[85px] h-[85px] object-cover"
          />
          <div className="bg-[#000E14] absolute w-full h-full top-0 left-0 rounded-regular bg-opacity-70 flex justify-center items-center text-white text-[16px] font-medium">
            +12
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ImageSlider;
