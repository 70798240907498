import React, { useState } from "react";
import { convertArea, units } from "../../utils/units";
import { VscDash } from "react-icons/vsc";

const Rate = ({ plotDetails, setPlotDetails }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [rateInfo, setRateInfo] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
    setPlotDetails((prevState) => {
      let rate = { ...prevState.rate };
      rate["selectedOption"] = option;
      if (prevState.rate.selectedOption !== option) {
        rate["rate"] = "";
        rate["lumpsum"] = "";
        rate["from"] = "";
        rate["to"] = "";
        rate["noquote"] = false;
      }
      return { ...prevState, rate };
    });
  };

  return (
    <section className="mt-[32px]">
      <div className="flex items-center gap-[12px]">
        <div className="flex items-center justify-between">
          <h4 className="text-2xl text-black font-medium mr-4">
            Property Rate
          </h4>
          <div className="relative group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 mt-1 cursor-pointer transition-transform transform-gpu group-hover:scale-110 text-gray-500 hover:text-gray-800"
              title="Select Property Rate"
              onMouseOver={() => {
                setRateInfo(true);
              }}
              onMouseLeave={() => {
                setRateInfo(false);
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
            <div
              className={`absolute ${
                rateInfo ? "" : "hidden"
              } top-full left-1/2 transform -translate-x-1/2 bg-white text-gray-800 shadow-md py-4 px-6 rounded-md opacity-0 transition-opacity duration-300 group-hover:opacity-100 w-64 z-20`}
            >
              <p className="text-lg font-semibold mb-2">
                Select Property Rate:
              </p>
              <ul className="list-none pl-3 text-sm">
                <li className="mb-1 flex items-center">
                  <VscDash /> Selling Rate per unit
                </li>
                <li className="mb-1 flex items-center">
                  <VscDash /> Lump sum rate of Property
                </li>
                <li className="mb-1 flex items-center">
                  <VscDash /> Price range for Property
                </li>
                <li className="mb-1 flex items-center">
                  <VscDash /> Give price on request
                </li>
              </ul>
              <p className="text-sm mt-2 italic text-gray-600">
                Please select only one option.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <label
          htmlFor="select"
          className="block text-sm font-medium text-gray-700"
        >
          Please select one option
          <span className="text-[#C62F39]"> *</span>
        </label>
        <div className="relative mt-1">
          <div className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] flex ">
            <input
              value={selectedOption || "Please Select a Property Rate"}
              readOnly
              className="w-full rounded-regular px-4 text-sm text-gray-900 focus:outline-none"
              type="text"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            />
            <button
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="flex items-center justify-center px-2 text-sm text-gray-800 hover:bg-gray-50"
            >
              <svg
                className="w-6 h-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 24"
              >
                <path d="M6 9l6 6 6-6"></path>
              </svg>
            </button>
          </div>
          {dropdownOpen && (
            <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg border border-gray-200">
              {[
                "Selling Rate per unit",
                "Lump sum rate of Property",
                "Price range for Property",
                "Give price on request",
              ].map((option, index) => (
                <div key={index} className="cursor-pointer hover:bg-gray-100">
                  <div
                    className="px-4 py-2 text-sm text-gray-900"
                    onClick={() => handleOptionSelect(option)}
                  >
                    {option}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {selectedOption === "Selling Rate per unit" && (
        <div className="flex mt-[24px]">
          <div className="flex flex-col w-full">
            <label
              htmlFor="rate"
              className="text-[#344054] text-[14px] font-medium"
            >
              Expected Selling Rate
              <span className="text-[#C62F39]"> *</span>
            </label>
            <input
              disabled={
                plotDetails.rate.noquote === "true" ||
                plotDetails.rate.noquote === true ||
                plotDetails.rate.from !== "" ||
                plotDetails.rate.to !== ""
              }
              value={plotDetails.rate.rate}
              onChange={(e) => {
                e.target.classList.remove("!border-[#C62F39]");
                var lumpsum = "";
                if (plotDetails.area !== "") {
                  lumpsum =
                    convertArea(
                      e.target.value,
                      plotDetails.area_unit,
                      plotDetails.rate?.unit
                    ) * plotDetails.area;
                }

                setPlotDetails((prevState) => {
                  let rate = { ...prevState.rate };
                  rate["rate"] = e.target.value;

                  rate["noquote"] = false;
                  rate["from"] = "";
                  rate["to"] = "";
                  rate["lumpsum"] = lumpsum === 0 ? "" : lumpsum;
                  return { ...prevState, rate };
                });
              }}
              placeholder="Enter your expected selling rate here e.g. 2000"
              id="rate"
              type="number"
              className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed"
            />
          </div>
          <div className="flex items-end gap-[16px]">
            <div className="py-[12px] px-[4px] text-[16px]">/</div>
            <div className="flex flex-col">
              <label
                htmlFor="rateunit"
                className="text-[#344054] text-[14px] font-medium"
              >
                Area Unit
                <span className="text-[#C62F39]"> *</span>
              </label>
              <select
                disabled={
                  plotDetails.rate.noquote === "true" ||
                  plotDetails.rate.noquote === true ||
                  plotDetails.rate.from !== "" ||
                  plotDetails.rate.to !== ""
                }
                value={plotDetails.rate.unit}
                onChange={(e) => {
                  e.target.classList.remove("!border-[#C62F39]");

                  var lumpsum = "";
                  if (plotDetails.area !== "") {
                    lumpsum =
                      convertArea(
                        plotDetails.rate?.rate,
                        plotDetails.area_unit,
                        e.target.value
                      ) * plotDetails.area;
                  }

                  setPlotDetails((prevState) => {
                    let rate = { ...prevState.rate };
                    rate["unit"] = e.target.value;
                    rate["lumpsum"] = lumpsum === 0 ? "" : lumpsum;
                    rate["noquote"] = false;
                    rate["from"] = "";
                    rate["to"] = "";
                    return { ...prevState, rate };
                  });
                }}
                placeholder="Agricultural/commercial/Residential"
                id="rateunit"
                type="number"
                className={`max-w-[180px] mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]`}
              >
                {units.map((unit) => (
                  <option value={unit.value}>{unit.label}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      {selectedOption === "Lump sum rate of Property" && (
        <div className="flex flex-col w-full mt-[24px]">
          <label
            htmlFor="lumpsum"
            className="text-[#344054] text-[14px] font-medium"
          >
            Expected Selling Price on Lump Sum Basis
            <span className="text-[#C62F39]"> *</span>
          </label>
          <input
            disabled={
              plotDetails.rate.noquote === "true" ||
              plotDetails.rate.noquote === true ||
              plotDetails.rate.from !== "" ||
              plotDetails.rate.to !== ""
            }
            value={plotDetails.rate.lumpsum}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");
              var lumpsum = "";
              if (plotDetails.area !== "") {
                lumpsum =
                  convertArea(
                    e.target.value,
                    plotDetails.rate.unit,
                    plotDetails.area_unit
                  ) / plotDetails.area;
              }

              setPlotDetails((prevState) => {
                let rate = { ...prevState.rate };
                rate["rate"] =
                  lumpsum === 0
                    ? ""
                    : isNaN(Number(lumpsum))
                    ? ""
                    : Number(lumpsum)?.toFixed(2);
                rate["lumpsum"] = e.target.value;
                rate["noquote"] = false;
                rate["from"] = "";
                rate["to"] = "";
                return { ...prevState, rate };
              });
            }}
            placeholder="Expected Selling Price"
            id="lumpsum"
            type="number"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed"
          />
        </div>
      )}

      {selectedOption === "Price range for Property" && (
        <>
          <h6 className=" mt-[24px] text-[#344054] text-[14px] font-medium">
            Property Selling Rate Range
          </h6>
          <div className="relative">
            <div className="relative  mt-[10px]">
              <div className="flex justify-evenly gap-[8px] w-full">
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="lumpsum"
                    className="text-[#344054] text-[14px] font-medium"
                  >
                    Min Price
                    <span className="text-[#C62F39]"> *</span>
                  </label>
                  <input
                    disabled={
                      plotDetails.rate.noquote === "true" ||
                      plotDetails.rate.noquote === true ||
                      plotDetails.rate.lumpsum !== ""
                    }
                    value={plotDetails.rate.from}
                    onChange={(e) => {
                      e.target.classList.remove("!border-[#C62F39]");

                      setPlotDetails((prevState) => {
                        let rate = { ...prevState.rate };

                        rate["rate"] = "";
                        rate["unit"] = "ft2";
                        rate["lumpsum"] = "";
                        rate["noquote"] = false;
                        rate["from"] = e.target.value;
                        return { ...prevState, rate };
                      });
                    }}
                    placeholder="Min Price"
                    id="lumpsum"
                    type="number"
                    className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="lumpsum"
                    className="text-[#344054] text-[14px] font-medium"
                  >
                    Max Price
                    <span className="text-[#C62F39]"> *</span>
                  </label>
                  <input
                    disabled={
                      plotDetails.rate.noquote === "true" ||
                      plotDetails.rate.noquote === true ||
                      plotDetails.rate.lumpsum !== ""
                    }
                    value={plotDetails.rate.to}
                    onChange={(e) => {
                      e.target.classList.remove("!border-[#C62F39]");

                      setPlotDetails((prevState) => {
                        let rate = { ...prevState.rate };
                        rate["rate"] = "";
                        rate["unit"] = "ft2";
                        rate["lumpsum"] = "";
                        rate["noquote"] = false;
                        rate["to"] = e.target.value;
                        return { ...prevState, rate };
                      });
                    }}
                    placeholder="Max Price"
                    id="lumpsum"
                    type="number"
                    className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {selectedOption === "Give price on request" && (
        <>
          <h6 className="mt-[24px] text-[#344054] text-[14px] font-medium">
            Give Price on Buyers request
          </h6>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-[16px] mt-[24px]">
            <div className="flex w-full items-center">
              <input
                disabled={
                  plotDetails.rate.lumpsum !== "" ||
                  plotDetails.rate.from !== "" ||
                  plotDetails.rate.to !== ""
                }
                checked={
                  plotDetails.rate?.noquote === true ||
                  plotDetails.rate?.noquote === "true"
                }
                onChange={() => {
                  setPlotDetails((prevState) => {
                    let rate = { ...prevState.rate };

                    rate["rate"] = "";
                    rate["unit"] = "ft2";
                    rate["lumpsum"] = "";
                    rate["from"] = "";
                    rate["to"] = "";
                    rate["noquote"] = !rate.noquote;
                    return { ...prevState, rate };
                  });
                }}
                type="checkbox"
                id="noquote"
                className="cursor-pointer disabled:!cursor-not-allowed"
              />{" "}
              <label
                htmlFor="noquote"
                className={`ml-[11px] text-[14px] text-labelText font-medium cursor-pointer ${
                  (plotDetails.rate.lumpsum !== "" ||
                    plotDetails.rate.from !== "" ||
                    plotDetails.rate.to !== "") &&
                  "!cursor-not-allowed"
                }`}
              >
                Give Price on Buyers request
              </label>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Rate;
