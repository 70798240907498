const tableCustomStyles = {
                        tableWrapper: {
                            style: {
                                backgroundColor: "#fff",
                                border: "1px solid #ECECEC",
                                borderRadius: "8px",
                                overflow: 'hidden',
                            },
                        
                        },
                         header: {
                            style: {
                                flexDirection:"row-reverse",
                                paddingRight: 0,
                                paddingLeft: 0,
                                zIndex: 2,
                                width:"60%",
                                background:"transparent",
                                'div': {
                                    justifyContent:"flex-start"
                                },
                                "@media screen and (max-width: 768px)":{
                                    width:"100%",
                                }
                            },
                        },
                         subHeader: {
                                style: {
                                    position:"absolute",
                                    top: 0,
                                    padding: "28px 0",
                                    minHeight: "56px",
                                    zIndex: 1,
                                    background:"transparent",
                                    "@media screen and (max-width: 768px)":{
                                        position: "relative"
                                    }
                                },
                            },
                        headRow:{
                            style:{
                            padding:"20px 16px"
                            }
                        },
                        headCells: {
                            style: {
                                fontSize: '14px',
                                fontWeight: '500',
                                color:"#666666",
                            },
                        },
                        expanderCell: {
                            style: {
                                display:"none"
                            },
                        },
                        pagination: {
                            style: {
                                background:"transparent"
                            }
                        },
                        rows: {
                            style: {
                                fontSize: '14px',
                                fontWeight: '500',
                                color:"#484848",
                                backgroundColor:"#fff",
                                padding: "16px",
                                '&:not(:last-of-type)': {
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: '1px',
                                    borderBottomColor: "#ECECEC",
                                },
                            },
                            stripedStyle: {
                                backgroundColor: "#FAFAFA",
                                 color:"#484848",
                            },
                        },
                        
                        
                    }



                    export default tableCustomStyles