const Export = ({ onExport }) => (
  <div className="flex gap-[24px] my-[28px]">
    <button
      onClick={(e) => onExport(e.target.value)}
      className="w-[140px] text-center justify-center flex gap-[10px] py-[10px] px-[26px] rounded-regular border-[1px] border-[#0259DB] text-[16px] text-[#0259DB] items-center font-medium hover:bg-[#0259DB] hover:text-white"
    >
      Export
    </button>
  </div>
);

// const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(tabledata)} />, []);

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

export { downloadCSV, Export };
