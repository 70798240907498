import tableCustomStyles from "../shared/tableStyle";

const reportTableCustomStyle = {...tableCustomStyles,
                            rows: {
                                style: {
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color:"#484848",
                                    backgroundColor:"#fff",
                                    padding: 0,
                                    '&:not(:last-of-type)': {
                                        borderBottomStyle: 'solid',
                                        borderBottomWidth: '1px',
                                        borderBottomColor: "#ECECEC",
                                    },
                                },
                                stripedStyle: {
                                    backgroundColor: "#FAFAFA",
                                    color:"#484848",
                                },
                            }, 
                            cells: {
                                style: {
                                    padding: '16px',
                                    wordBreak: 'break-word',
                                },
                                
                            },
                            headRow:{
                                style:{
                                padding: 0,
                                }
                            },
                            headCells: {
                                style: {
                                    padding: "20px 16px",
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color:"#666666",
                                    '&:first-of-type':{
                                        borderRight: "1px solid #ECECEC",
                                    }
                                },
                            },
                             header: {
                                style: {
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                    zIndex: 1,
                                    background:"transparent",
                                },
                            },
                            subHeader: {
                                style: {
                                        width:"40%",
                                        position:"absolute",
                                        top: 0,
                                        padding: "28px 0",
                                        minHeight: "56px",
                                        zIndex: 2,
                                        background:"transparent",
                                         "@media screen and (max-width: 768px)":{
                                            width:"100%",
                                            position:"relative"
                                        }
                                    },
                                },
                    }



                    export default reportTableCustomStyle