import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import Header from "../components/Header/AdminHeader";
import { fetchedClip, interestedClip } from "../components/shared/Clips";
import tableCustomStyles from "../components/shared/tableStyle";
import ExpandedRows from "../components/UserDetails/ExpandedRow";
import ProfileDetails from "../components/Profile/ProfileDetails";
import {
  getUser,
  getUserBids,
  getUserInterested,
  getUserListings,
  getUserSaved,
  updateProfile,
  verifyProfile,
} from "../apis/apiClient";
import { downloadCSV } from "../utils/downloadCSV";
import { useSelector } from "react-redux";
import { getUnitLabel } from "../utils/units";
import { showToast } from "../utils/showToast";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="px-[12px] py-[10px] border-[1px] border-[#D0D5DD] bg-white rounded-regular flex items-center w-full max-w-[350px] mr-[16px]">
      <Icon
        icon="material-symbols:search"
        color="#858585"
        width="28"
        height="28"
      />
      <input
        id="search"
        type="text"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        className="outline-none text-[16px] font-medium text-[#858585] ml-[12px] w-full"
      />
    </div>
    <button
      className="rounded-regular bg-[#0259DB] py-[10px] px-[24px] text-[16px] text-white font-medium mt-[8px] md:mt-0"
      type="button"
      onClick={onClear}
    >
      Search
    </button>
  </>
);

const tableData = [
  {
    id: 9876543210,
    buyer: "Balaji Sankaran",
    seller: "Kalyani Hayre",
    bid: 3250,
    interested_count: 12,
    bid_count: 12,
    views_count: 123,
    district: "Ratnagiri",
    isInterested: true,
    isFetchedInfo: false,
    expsell: 3000,
  },
  {
    id: 9876543211,
    buyer: "Narmada Chokshi",
    seller: "Raju Chahal",
    bid: 3250,
    interested_count: 64,
    bid_count: 14,
    views_count: 364,
    district: "Nashik",
    isInterested: false,
    isFetchedInfo: true,
    expsell: 3000,
  },
  {
    id: 9876543212,
    buyer: "Narendra Singh",
    seller: "K. L.  Mishra",
    bid: 3250,
    interested_count: 45,
    bid_count: 22,
    views_count: 987,
    district: "Mumbai",
    isInterested: true,
    isFetchedInfo: true,
    expsell: 3000,
  },
  {
    id: 9876543213,
    buyer: "Devika Singhal",
    seller: "Prabhat Hari",
    bid: 3250,
    interested_count: 12,
    bid_count: 25,
    views_count: 324,
    district: "Pune",
    isInterested: true,
    isFetchedInfo: false,
    expsell: 3000,
  },
  {
    id: 9876543215,
    buyer: "Balaji Sankaran",
    seller: "Kalyani Hayre",
    bid: 3250,
    interested_count: 34,
    bid_count: 13,
    views_count: 758,
    district: "Nagpur",
    isInterested: true,
    isFetchedInfo: false,
    expsell: 3000,
  },
  {
    id: 9876543216,
    buyer: "Narmada Chokshi",
    seller: "Raju Chahal",
    bid: 3250,
    interested_count: 12,
    bid_count: 55,
    views_count: 546,
    district: "Nanded",
    isInterested: false,
    isFetchedInfo: true,
    expsell: 3000,
  },
  {
    id: 9876543217,
    buyer: "Narendra Singh",
    seller: "K. L.  Mishra",
    bid: 3250,
    interested_count: 83,
    bid_count: 72,
    views_count: 235,
    district: "Nagpur",
    isInterested: true,
    isFetchedInfo: true,
    expsell: 3000,
  },
  {
    id: 9876543218,
    buyer: "Devika Singhal",
    seller: "Prabhat Hari",
    bid: 3250,
    interested_count: 65,
    bid_count: 67,
    views_count: 112,
    district: "Nashik",
    isInterested: true,
    isFetchedInfo: false,
    expsell: 3000,
  },
];

const UserDetails = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState({});
  const [tableData, setTableData] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("listing");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [filteredItems, setFilteredItems] = useState(tableData);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [isDataVerifyLoading, setIsDataVerifyLoading] = useState(true);
  const [editData, setEditData] = useState({
    mobile_number: "",
    email: "",
    dob: "",
    taluka: "",
    district: "",
    aadhaar_number: "",
  });

  const profile = useSelector((state) => state.profile);

  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.isLoggedIn) {
      navigate("/login");
    }
    if (window.location.hash.includes("edituser")) {
      setIsEditModalOpen(true);
    }
    if (window.location.hash.includes("verifyuser")) {
      setIsVerifyModalOpen(true);
    }
  }, []);

  useEffect(() => {
    const getUsersData = async () => {
      try {
        setIsUserDataLoading(true);
        const response = await getUser(userId);
        setIsUserDataLoading(false);
        setUserData(response);
        setEditData({
          mobile_number: response.mobile_number,
          email: response.email,
          dob: response.dob,
          taluka: response.taluka,
          district: response.district,
          aadhaar_number: response.aadhaar_number,
        });
        // setTableData(response.results);
        // setFilteredItems(response.results);
      } catch (e) {
        console.log(e);
        setIsUserDataLoading(false);
      }
    };
    getUsersData();
  }, [userId]);

  useEffect(() => {
    const getListing = async () => {
      const response = await getUserListings(userId);
      setTableData(response.listings.results);
    };
    const getBids = async () => {
      const response = await getUserBids(userId);
      setTableData(response.bids.results);
    };
    const getSaved = async () => {
      const response = await getUserSaved(userId);
      setTableData(response.saved.results);
    };
    const getInterested = async () => {
      const response = await getUserInterested(userId);
      setTableData(response.interested.results);
    };
    if (selectedTab === "listing") {
      getListing();
    } else if (selectedTab === "bids") {
      getBids();
    } else if (selectedTab === "saved") {
      getSaved();
    } else if (selectedTab === "interested") {
      getInterested();
    }
  }, [selectedTab, userId]);

  useEffect(() => {
    setFilteredItems(tableData);
  }, [tableData]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText !== "") {
        setFilteredItems((prevState) => {
          return tableData.filter(
            (item) =>
              (item.puid &&
                item.puid
                  .toString()
                  .toLowerCase()
                  .includes(filterText.toLowerCase())) ||
              (item.location?.district &&
                item.location?.district
                  .toLowerCase()
                  .includes(filterText.toLowerCase())) ||
              (item.buyer &&
                item.buyer.toLowerCase().includes(filterText.toLowerCase()))
          );
        });
      } else {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        setFilteredItems(tableData);
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => {
          setFilterText(e.target.value);
          if (e.target.value === "") {
            setFilteredItems(tableData);
          }
        }}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "PUID",
      selector: (row) => row.puid,
      cell: (row) => (
        <Link
          to={`/plot/${row.puid}`}
          className="text-[14px] font-semibold text-primaryColor cursor-pointer"
        >
          {row.puid}
        </Link>
      ),
      width: "150px",
      style: {
        borderRight: "1px solid #ECECEC",
      },
    },
    {
      name: "Interested Count",
      selector: (row) => row.interested,
      cell: (row) =>
        row.interested === undefined
          ? row.plots.reduce(function (a, b) {
              return a + b?.interested?.length;
            }, 0)
          : row.interested?.length,
    },
    {
      name: "Number of BIDs",
      selector: (row) => row.bids,
      cell: (row) =>
        row.bids === undefined
          ? row.plots.reduce(function (a, b) {
              return a + b?.bids?.length;
            }, 0)
          : row.bids?.length,
    },
    {
      name: "Total Views",
      selector: (row) => row.views,
      cell: (row) =>
        row.views === undefined
          ? row.plots.reduce(function (a, b) {
              return a + b?.views?.length;
            }, 0)
          : row.views?.length,
    },
    {
      name: "District",
      selector: (row) => row.location?.district,
    },
    {
      name: "Price",
      selector: (row) => row.rate,
      cell: (row) =>
        row.type === "bulk"
          ? "-"
          : `${row.rate?.rate} per ${getUnitLabel(row.rate?.unit)}`,
    },
    {
      name: "Expiry Date",
      selector: (row) => row.expiry,
      cell: (row) => "12/03/2023",
    },
    {
      title: "",
      dataIndex: "",
      key: "expand",
      width: 1,
      cell: (row) => (
        <button
          id={`expandButton-${row._id}`}
          className={`transition ease-in-out delay-10`}
          onClick={() => {
            document
              .querySelectorAll(`[data-testid="expander-button-${row._id}"]`)[0]
              ?.click();
          }}
        >
          <Icon width="24px" icon="material-symbols:keyboard-arrow-down" />
        </button>
      ),
    },
  ];

  const Export = ({ onExport }) => (
    <div className="flex gap-[24px] my-[28px]">
      <button
        onClick={(e) => onExport(e.target.value)}
        className="w-[140px] text-center justify-center flex gap-[10px] py-[10px] px-[26px] rounded-regular border-[1px] border-[#0259DB] text-[16px] text-[#0259DB] items-center"
      >
        Export
      </button>
      <button className="w-[140px] text-center justify-center flex gap-[10px] py-[10px] px-[26px] rounded-regular border-[1px] bg-primaryColor border-[#D0D5DD] text-[16px] text-white items-center">
        Add Plot
      </button>
    </div>
  );

  const actionsMemo = React.useMemo(
    () => (
      <Export
        onExport={() =>
          downloadCSV(tableData, `Rempo - ${selectedTab} Report.csv`)
        }
      />
    ),
    [tableData, selectedTab]
  );

  const handleUpdateProfile = async () => {
    try {
      const response = await updateProfile(userId, editData);
      if (response.status === "success") {
        setUserData((prevState) => ({ ...prevState, ...editData }));
        setIsEditModalOpen(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="bg-[#F9FCFF] min-h-screen">
      <Header />
      <div className="mx-auto max-w-screen-xl pt-[40px] pb-[64px] px-[24px] xl:px-0">
        <ProfileDetails
          setIsEditModalOpen={setIsEditModalOpen}
          setIsVerifyModalOpen={setIsVerifyModalOpen}
          isOwnProfile={false}
          data={userData}
        />

        <div className="mt-[36px] border-b-[2px] border-[#D9D9D9] px-[36px] flex gap-[48px] overflow-x-scroll">
          <p
            onClick={() => {
              setSelectedTab("listing");
            }}
            className={`${
              selectedTab === "listing"
                ? "border-b-[3px] border-[#0259DB] text-[#1C1C1C]"
                : "text-[#666666]"
            } font-medium text-[16px] cursor-pointer w-fit p-[12px] whitespace-nowrap`}
          >
            Listed Properties
          </p>

          <p
            onClick={() => {
              setSelectedTab("interested");
            }}
            className={`${
              selectedTab === "interested"
                ? "border-b-[3px] border-[#0259DB] text-[#1C1C1C]"
                : "text-[#666666]"
            } font-medium text-[16px] cursor-pointer w-fit p-[12px] whitespace-nowrap`}
          >
            Liked Properties
          </p>
          <p
            onClick={() => {
              setSelectedTab("saved");
            }}
            className={`${
              selectedTab === "saved"
                ? "border-b-[3px] border-[#0259DB] text-[#1C1C1C]"
                : "text-[#666666]"
            } font-medium text-[16px] cursor-pointer w-fit p-[12px] whitespace-nowrap`}
          >
            Saved
          </p>
          <p
            onClick={() => {
              setSelectedTab("bids");
            }}
            className={`${
              selectedTab === "bids"
                ? "border-b-[3px] border-[#0259DB] text-[#1C1C1C]"
                : "text-[#666666]"
            } font-medium text-[16px] cursor-pointer w-fit p-[12px] whitespace-nowrap`}
          >
            Offered
          </p>
        </div>
        {isUserDataLoading ? (
          <div className="h-[320px] flex items-center justify-center">
            <Oval
              height={52}
              width={52}
              color="#0259DB"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#0259DB"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <section className="relative mt-[32px]">
            {/* <div className='my-[28px]'></div> */}
            <DataTable
              keyField="_id"
              actions={actionsMemo}
              columns={columns}
              data={filteredItems}
              responsive
              striped
              highlightOnHover
              noDataComponent={
                <span className="px-[16px] py-[20px]">{`No data found`}</span>
              }
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              customStyles={{
                ...tableCustomStyles,
                headRow: {
                  style: {
                    padding: 0,
                  },
                },
                headCells: {
                  style: {
                    padding: "20px 16px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#666666",
                    "&:nth-child(2)": {
                      borderRight: "1px solid #ECECEC",
                    },
                  },
                },
                rows: {
                  style: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#484848",
                    backgroundColor: "#fff",
                    padding: 0,
                    "&:not(:last-of-type)": {
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1px",
                      borderBottomColor: "#ECECEC",
                    },
                  },
                  stripedStyle: {
                    backgroundColor: "#FAFAFA",
                    color: "#484848",
                  },
                },
                cells: {
                  style: {
                    padding: "16px",
                    wordBreak: "break-word",
                  },
                },
                header: {
                  style: {
                    paddingRight: 0,
                    paddingLeft: 0,
                    zIndex: 1,
                    background: "transparent",
                  },
                },
                subHeader: {
                  style: {
                    width: "40%",
                    position: "absolute",
                    top: 0,
                    padding: "28px 0",
                    minHeight: "56px",
                    zIndex: 2,
                    background: "transparent",
                    justifyContent: "flex-start",
                    "@media screen and (max-width: 768px)": {
                      width: "100%",
                      position: "relative",
                    },
                  },
                },
              }}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              expandableRows={{
                expandedRowRender: (record) => <ExpandedRows />,
              }}
              expandableRowsComponent={ExpandedRows}
              onRowExpandToggled={(isExpanded, data) => {
                if (isExpanded) {
                  document
                    .getElementById(`expandButton-${data._id}`)
                    .classList.add("rotate-180");
                } else {
                  document
                    .getElementById(`expandButton-${data._id}`)
                    .classList.remove("rotate-180");
                }
              }}
            />
          </section>
        )}
      </div>

      <div
        className={`fixed z-50 overflow-y-auto top-0 w-full left-0 ${
          isVerifyModalOpen ? "" : "hidden"
        }`}
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => {
              setIsVerifyModalOpen(false);
            }}
          >
            <div className="absolute inset-0 bg-[#00000099] opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[700px] sm:w-full p-[24px]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex justify-between items-center">
              <div className="flex gap-[12px] items-center">
                <h3 className="text-[#000E14] text-[20px] font-bold">
                  User KYC
                </h3>{" "}
                <Icon
                  icon="material-symbols:check-circle-outline"
                  width={24}
                  height={24}
                  color="#58B455"
                />
              </div>
              <button
                onClick={() => {
                  setIsVerifyModalOpen(false);
                }}
              >
                <Icon
                  icon="material-symbols:close"
                  width={24}
                  height={24}
                  color="#000"
                />
              </button>
            </div>
            <p className="mt-[8px] text-[#606060] text-[16px] font-semibold w-[90%]">
              You are going to mark this user as verified. This will mark user
              as KYC is completed
            </p>
            <div className="mt-[36px] flex justify-end gap-[16px]">
              <button
                className="py-[8px] px-[16px] border-[1px] border-[#0259DB] text-[#0259DB] rounded-regular text-[16px] text-medium"
                onClick={() => {
                  setIsVerifyModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={async (e) => {
                  try {
                    const response = await verifyProfile(userData._id);
                    if (response.status === "success") {
                      setUserData((prevState) => ({
                        ...prevState,
                        isVerified: true,
                      }));
                      showToast(`${userData.name} is now verified!`, "success");
                      setIsVerifyModalOpen(false);
                    } else {
                      showToast(
                        "Some error occurred, plase try again later.",
                        "error"
                      );
                    }
                  } catch (e) {
                    showToast(
                      "Some error occurred, plase try again later.",
                      "error"
                    );
                    console.log(e);
                  }
                }}
                className="py-[8px] px-[16px] border-[1px] border-[#0259DB] text-[#fff] bg-[#0259DB] rounded-regular text-[16px] text-medium"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`fixed z-50 overflow-y-auto top-0 w-full left-0 ${
          isEditModalOpen ? "" : "hidden"
        }`}
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => {
              setIsEditModalOpen(false);
            }}
          >
            <div className="absolute inset-0 bg-[#00000099] opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[700px] sm:w-full p-[24px]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex justify-between items-center">
              <div className="flex gap-[12px] items-center">
                <h3 className="text-[#000E14] text-[20px] font-bold">
                  Edit Profile
                </h3>{" "}
                <Icon
                  icon="material-symbols:fact-check-outline"
                  width={24}
                  height={24}
                  color="#0259DB"
                />
              </div>
              <button
                onClick={() => {
                  setIsEditModalOpen(false);
                }}
              >
                <Icon
                  icon="material-symbols:close"
                  width={24}
                  height={24}
                  color="#000"
                />
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[28px] mt-[28px]">
              <div className="flex flex-col w-full">
                <label
                  for="mobile"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Mobile Number
                </label>
                <input
                  value={editData.mobile_number}
                  onChange={(e) => {
                    setEditData((prevState) => ({
                      ...prevState,
                      mobile_number: e.target.value,
                    }));
                  }}
                  placeholder="9876543210"
                  id="mobile"
                  type="text"
                  className="mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[10px] text-[16px] text-[#667085]"
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  for="email"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Email
                </label>
                <input
                  value={editData.email}
                  onChange={(e) => {
                    setEditData((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                  }}
                  placeholder="user@email.com"
                  id="email"
                  type="text"
                  className="mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[10px] text-[16px] text-[#667085]"
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  for="dob"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Date of birth
                </label>
                <input
                  value={editData.dob}
                  onChange={(e) => {
                    setEditData((prevState) => ({
                      ...prevState,
                      dob: e.target.value,
                    }));
                  }}
                  placeholder="03/07/1967"
                  id="dob"
                  type="date"
                  className="mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[10px] text-[16px] text-[#667085]"
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  for="taluka"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Taluka
                </label>
                <input
                  value={editData.taluka}
                  onChange={(e) => {
                    setEditData((prevState) => ({
                      ...prevState,
                      taluka: e.target.value,
                    }));
                  }}
                  placeholder="Haveli"
                  id="taluka"
                  type="text"
                  className="mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[10px] text-[16px] text-[#667085]"
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  for="city"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  City
                </label>
                <input
                  value={editData.district}
                  onChange={(e) => {
                    setEditData((prevState) => ({
                      ...prevState,
                      district: e.target.value,
                    }));
                  }}
                  placeholder="Pune"
                  id="city"
                  type="text"
                  className="mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[10px] text-[16px] text-[#667085]"
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  for="aadhar"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Aadhaar
                </label>
                <input
                  onKeyUp={(e) => {
                    var value = e.target.value;
                    value = value
                      .replace(/\D/g, "")
                      .split(/(?:([\d]{4}))/g)
                      .filter((s) => s.length > 0)
                      .join("-");
                    e.target.value = value;
                  }}
                  maxLength={19}
                  value={editData.aadhaar_number}
                  onChange={(e) => {
                    setEditData((prevState) => ({
                      ...prevState,
                      aadhaar_number: e.target.value,
                    }));
                  }}
                  placeholder="1234-5678-9876"
                  id="aadhar"
                  type="text"
                  className="mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[10px] text-[16px] text-[#667085]"
                />
              </div>
            </div>
            <div className="mt-[36px] flex justify-end gap-[16px]">
              <button
                className="py-[8px] px-[16px] border-[1px] border-[#0259DB] text-[#0259DB] rounded-regular text-[16px] text-medium"
                onClick={() => {
                  setIsEditModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateProfile}
                className="py-[8px] px-[16px] border-[1px] border-[#0259DB] text-[#fff] bg-[#0259DB] rounded-regular text-[16px] text-medium"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
