// import React, { useEffect, useRef } from "react";

// function Editor({ onChange, editorLoaded, name, value, isVisible }) {
//   const editorRef = useRef();
//   const { CKEditor, ClassicEditor } = editorRef.current || {};

//   useEffect(() => {
//     editorRef.current = {
//       CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
//       ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
//     };
//   }, []);

//   return (
//     <div className={`my-[32px] ${!isVisible && "hidden"}`}>
//       {editorLoaded && (
//         <CKEditor
//           type=""
//           name={name}
//           editor={ClassicEditor}
//           config={{
//             ckfinder: {
//               // Upload the images to the server using the CKFinder QuickUpload command
//               // You have to change this address to your server that has the ckfinder php connector
//               uploadUrl: "", //Enter your upload url
//             },
//             // toolbar: [
//             //   "heading",
//             //   "|",
//             //   "bold",
//             //   "italic",
//             //   "blockQuote",
//             //   "link",
//             //   "numberedList",
//             //   "bulletedList",
//             //   "imageUpload",
//             //   "insertTable",
//             //   "tableColumn",
//             //   "tableRow",
//             //   "mergeTableCells",
//             //   "mediaEmbed",
//             //   "|",
//             //   "undo",
//             //   "redo",
//             // ],
//           }}
//           data={value}
//           onChange={(event, editor) => {
//             const data = editor.getData();
//             // console.log({ event, editor, data })
//             onChange(data);
//           }}
//         />
//       )}
//     </div>
//   );
// }

// export default Editor;

import React, { useEffect } from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { baseURL } from "../../constants";

const CustomEditor = ({ value, onChange, isVisible }) => {
  return (
    <div className={`${!isVisible && "hidden"} my-[32px]`}>
      <CKEditor
        type=""
        editor={Editor}
        config={{
          ckfinder: {
            uploadUrl: `${baseURL}/v1/library/uploads`,
          },
          toolbar: [
            "bold",
            "italic",
            "underline",
            {
              label: "Text More",
              items: [
                "heading",
                "fontColor",
                "highlight",
                "fontFamily",
                "fontSize",
                "fontBackgroundColor",
                "superscript",
                "subscript",
                "strikethrough",
              ],
            },
            "|",
            "alignment",
            "numberedList",
            "bulletedList",
            {
              label: "Paragraph More",
              items: ["outdent", "indent"],
            },
            "|",
            "link",
            "imageUpload",
            "insertTable",
            "emoji",
            "|",
            "undo",
            "redo",
          ],
        }}
        data={value}
        onChange={(event, editor) => {
          onChange(editor.getData());
        }}
      />
    </div>
  );
};

export default CustomEditor;
