import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { showToast } from "../utils/showToast";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhoneNum, setUserPhoneNum] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userComment, setUserComment] = useState("");

  const handleSubscribe = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmail("");
      showToast("You have subscribed for updates", "success");
    } else {
      setError(true);
    }
  };
  const handleContactUs = () => {
    setIsContactModalOpen(true);
  };
  return (
    <>
      <footer className="bg-primaryColor px-[48px] pt-[54px] mt-[64px]">
        <div className="mx-auto max-w-screen-xl flex justify-between flex-wrap flex-col lg:flex-row">
          <div className="py-[20px] lg:py-0">
            <h6 className="text-white font-bold text-[16px]">
              Subscribe for updates
            </h6>
            {/* <p className="mt-[4px] text-white text-[12px]">
            Join our weekly mailing list
          </p> */}
            <div className="mt-[24px] flex gap-[8px] flex-col sm:flex-row">
              <input
                value={email}
                onChange={(e) => {
                  setError(false);
                  setEmail(e.target.value);
                }}
                className={`px-[12px] py-[10px] rounded-[4px] outline-none`}
                placeholder="Enter your email"
              />
              <button
                onClick={handleSubscribe}
                className="rounded-[4px] text-primaryColor bg-white text-[12px] px-[32px] py-[12px] font-medium"
              >
                SUBSCRIBE
              </button>
            </div>
            {error && (
              <p className="text-[12px] font-[500] text-[#ff9ea4] mt-[8px]">
                Please enter valid email address
              </p>
            )}

            <div className="flex gap-[24px] mt-[16px]">
              <Icon icon="mdi:twitter" color="#fff" width={20} />
              <a href="https://www.facebook.com/profile.php?id=100093018129499">
                <Icon icon="uil:facebook-f" color="#fff" width={20} />
              </a>
              <a href="https://www.instagram.com/rempoventures/">
                <Icon icon="mdi:instagram" color="#fff" width={20} />
              </a>
            </div>
          </div>
          <div className="py-[20px] lg:py-0">
            <p className="text-white font-semibold text-[16px] uppercase">
              Get to know us
            </p>
            <div className="mt-[14px] gap-[8px] flex flex-col">
              <a href="#ourteam" className="text-[14px] text-white">
                Our team
              </a>
              <a href="#ourmission" className="text-[14px] text-white">
                Our Mission
              </a>
              <a href="#aboutus" className="text-[14px] text-white">
                About Us
              </a>
              <p
                className="text-[14px] text-white cursor-pointer"
                onClick={handleContactUs}
              >
                Contact Us
              </p>
            </div>
          </div>
          <div className="py-[20px] lg:py-0">
            <p className="text-white font-semibold text-[16px] uppercase">
              Disclosure
            </p>
            <div className="mt-[14px] gap-[8px] flex flex-col">
              <Link
                to="/terms&conditions"
                state={{
                  selectedTab: "Disclosure",
                  selectedDisclosure: "Terms & condition",
                }}
                className="text-[14px] text-white"
              >
                Terms & Condition
              </Link>
              <Link to="/privacy" className="text-[14px] text-white">
                Privacy policy
              </Link>
              <Link to="/cancellationpolicy" className="text-[14px] text-white">
                Cancellation Policy
              </Link>
            </div>
          </div>

          <div className="py-[20px] lg:py-0">
            <p className="text-white font-semibold text-[16px] uppercase">
              Resources
            </p>
            <div className="mt-[14px] gap-[8px] flex flex-col">
              {/* <Link
                className="text-[14px] text-white"
                to="/resources"
                state={{
                  selectedTab: "Ready reckoner rate",
                }}
              >
                Ready reckoner rate
              </Link> */}

              
                 {/* <Link
                to="/subscription"
                state={{
                  selectedTab: "Articles",
                }}
                className="text-[14px] text-white"
              >
                Subscription
              </Link> */}
              <Link
                to="/resources"
                state={{
                  selectedTab: "Articles",
                }}
                className="text-[14px] text-white"
              >
                Blog
              </Link>
              <Link
                to="/resources"
                state={{
                  selectedTab: "Gallery",
                }}
                className="text-[14px] text-white"
              >
                Gallery
              </Link>
              <Link
                to="/resources"
                state={{
                  selectedTab: "FAQ",
                }}
                className="text-[14px] text-white"
              >
                FAQ
              </Link>
              {/* <Link
                to="/resources"
                state={{
                  selectedTab: "Information",
                }}
                className="text-[14px] text-white"
              >
                Information
              </Link>
              <Link
                to="/resources"
                state={{
                  selectedTab: "FAQ",
                }}
                className="text-[14px] text-white"
              >
                FAQ
              </Link> */}
            </div>
          </div>

          <div className="h-[160px] w-[2px] bg-white hidden lg:block"></div>

          <div className="py-[20px] lg:py-0">
            <p className="text-white font-semibold text-[16px] uppercase">
              Mail us
            </p>
            <div className="mt-[14px] gap-[8px] flex flex-col text-[14px] text-white">
              {/* <a
                href="mailto:admin@rempo.in"
                className="text-[14px] text-white"
              > */}
              admin@rempo.in <br /> Rempo Ventures Pvt. Ltd. <br />
              Sr No. 7 & 8, Office No. 304,
              <br />
              3rd Floor, Elite Premio,
              <br />
              Balewadi, Pune, 411045
              {/* <br />
                State Name, India */}
              {/* </a> */}
            </div>
          </div>
        </div>

        <div className="mt-[94px] border-t-[1px] border-white">
          <div className="mx-auto max-w-screen-xl flex justify-between p-[24px]">
            <p className="text-[14px] text-white">
              ©2023 - Rempo | All right reserved
            </p>
          </div>
        </div>
      </footer>
      <div
        className={`fixed z-50 overflow-y-auto top-0 w-full left-0 ${
          !isContactModalOpen && "hidden"
        }`}
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => {
              setIsContactModalOpen(false);
            }}
          >
            <div className="absolute inset-0 bg-[#1C1C1C] opacity-90" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            ;
          </span>
          <div
            className="inline-block align-center   text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[500px] sm:w-full p-[32px]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex max-h-[80vh] overflow-y-scroll no-scrollbar">
              <div className="relative w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-white-700">
                  <div className="px-6 py-6 lg:px-8">
                    <div className="flex justify-between items-center">
                      <h3 className="text-[#000000] text-[24px] font-semibold">
                        Contact Us<span className="text-[#C62F39]"> *</span>
                      </h3>
                      <div onClick={() => setIsContactModalOpen(false)} className="cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    </div>
                    <form className="mt-[32px] space-y-6" action="#">
                      <div>
                        <label
                          for="name"
                          className="text-[#344054] text-[14px] font-medium"
                        >
                          Your Name<span className="text-[#C62F39]"> *</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          onChange={(e) => setName(e.target.value)}
                          className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
                          placeholder="Your Name"
                          required
                        />
                      </div>
                      <div>
                        <label
                          for="email"
                          className="text-[#344054] text-[14px] font-medium"
                        >
                          Your email<span className="text-[#C62F39]"> *</span>
                        </label>
                        <input
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          name="email"
                          id="email"
                          className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
                          placeholder="name@company.com"
                          required
                        />
                      </div>
                      <div>
                        <label
                          for="phonenum"
                          className="text-[#344054] text-[14px] font-medium"
                        >
                          Phone Number<span className="text-[#C62F39]"> *</span>
                        </label>
                        <input
                          pattern="[0-9]*"
                          maxLength={10}
                          name="phonenum"
                          onChange={(e) => setUserPhoneNum(e.target.value)}
                          id="phonenum"
                          placeholder="Your Phone Number"
                          className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
                          required
                        />
                      </div>
                      <div>
                        <label
                          for="city"
                          className="text-[#344054] text-[14px] font-medium"
                        >
                          City Name<span className="text-[#C62F39]"> *</span>
                        </label>
                        <input
                          type="text"
                          name="city"
                          onChange={(e) => setUserCity(e.target.value)}
                          id="city"
                          placeholder="Your City Name"
                          className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
                          required
                        />
                      </div>
                      <div>
                        <label
                          for="comment"
                          className="text-[#344054] text-[14px] font-medium"
                        >
                          Comment<span className="text-[#C62F39]"> *</span>
                        </label>
                        <textarea
                          type="text"
                          name="comment"
                          onChange={(e) => setUserComment(e.target.value)}
                          id="comment"
                          placeholder="Your Comment"
                          className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
                          required
                        />
                        <div className="text-[#344054] text-[12px] font-medium">
                          All <span className="text-[#C62F39]"> *</span> fields
                          are mandatory
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
