import axios from "axios";
import Cookies from "universal-cookie";
import { baseURL } from "../constants";


const cookies = new Cookies();

const client = axios.create({
  baseURL: baseURL,
  responseType: "json",
  headers: {
    Accept: "application/json",
    Authorization: cookies.get("token"),
  },
});

export const getArticleBySlug = async(name, slug)=>{
  return new Promise(async (resolve, reject) => {
    try {
      console.log(name,slug)
      const response = await client.get(`v1/library/data/${name}/${slug}`, {
      });
      resolve(response.data);
    }catch(err){
      reject(err);
    }
  });
}

// export const getPayment = async () => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await client.get("/about", {
//         // You can include additional configuration options here if needed
//       });
//       resolve(response.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const PaymentHDFC = async (amount,redirect_url, cancel_url) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await client.post(`ccavRequestHandler`, {
//         amount,
//         redirect_url,
//         cancel_url,
//       });
//       resolve(response.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

export const PaymentHDFC = async (amount, redirect_url, cancel_url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new URLSearchParams();
      formData.append('amount', amount);
      formData.append('redirect_url', redirect_url);
      formData.append('cancel_url', cancel_url);

      const response = await client.post('ccavRequestHandler', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};




export const updateToken = (token) => {
  client.defaults.headers.Authorization = `Bearer ${token}`;
};

// Login
export const login = async (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post("v1/auth/login/", body);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const sendOTP = async (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post("v1/auth/sendOTP/", body);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const handleUserDelete = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.delete(`v1/users/delete/${id}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const verifyOTP = async (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post("v1/auth/verifyOTP/", body);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const registerProfile = async (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post("v1/users/register/", body);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateProfile = async (id, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.patch(`v1/users/${id}`, body);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteProfile = async (id, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.delete(`v1/users/${id}`, body);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const blockProfile = async (id, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(`v1/users/${id}`, body);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const verifyProfile = async (id, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(`v1/users/verify/${id}`, body);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getOwnProfile = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get("v1/users/profile/");
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getUsers = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get("v1/users/");
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getUser = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(`v1/users/${userId}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const listNewPlot = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post("v1/plots/", data);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const draftPlot = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post("v1/plots/draft", data);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updatePlot = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.put("v1/plots/", data);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchPlots = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(
        `v1/plots?size=${data.size}&category=${data.type}&price=${data.price}&location=${data.location}&sortBy=createdAt:desc&page=${data.page}&limit=${data.limit}`
      );
      console.log("Recieved from Server",response)
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPlotByID = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(`v1/plots/${data.plotId}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deletePlotByID = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.delete(`v1/plots/${data.plotId}`);
      let deletedPlot = await response.data
      console.log("Delete Plot",deletedPlot)
      const { addNotification } = await import("../reducers/notification");
      const store = (await import("../store/store")).default;
      
      const deletedNotification = {
        type: "deleted",
        plot: deletedPlot,
      };
      store.dispatch(addNotification(deletedNotification));
      resolve(response.data);
      
    } catch (error) {
      reject(error);
    }
  });
};

export const getUserListings = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(`v1/users/listings/${userId}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// export const getBidsByUser = async (userId) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await client.get(`v1/users/:${userId}/bids`);
//       resolve(response.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const getSavedByUser = async (userId) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await client.get(`v1/users/:${userId}/saved`);
//       resolve(response.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const getInterestedByUser = async (userId) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await client.get(`v1/users/:${userId}/interested`);
//       resolve(response.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const getListingsByUser = async (userId) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await client.get(`v1/users/:${userId}/listings`);
//       resolve(response.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

export const getUserBids = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(`v1/users/bids/${userId}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllBids = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(`v1/bid`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getUserSaved = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(`v1/users/saved/${userId}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getUserInterested = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(`v1/users/interested/${userId}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchContact = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(
        `v1/users/fetchcontact/${data.userId}?plotId=${data.plotId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const placeBid = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(`v1/bid/${data.plotId}/`, {
        amount: data.bidAmount,
        unit: data.bidUnit,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const interested = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(
        `v1/plots/${data.plotId}/interested`,
        {}
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const saved = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(`v1/plots/${data.plotId}/saved`, {});
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getBids = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(`v1/bid/`, data);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getNotifications = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(`v1/users/notifications/${data}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getLibrary = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(`v1/library/data/${data.name}/`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateLibrary = async (data) => {
  delete data.createdAt;
  delete data.updatedAt;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(`v1/library/data/${data.name}/`, data);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateGallery = async (data) => {
  delete data.createdAt;
  delete data.updatedAt;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(`v1/library/gallery/`, data);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSubscriptions = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(`v1/subscriptions/`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateSubscriptions = async (data) => {
  delete data.createdAt;
  delete data.updatedAt;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(`v1/subscriptions/`, data);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAnalytics = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(`v1/analytics`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
