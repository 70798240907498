import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deletePlotByID, interested, placeBid } from "../../apis/apiClient";
import { updateUser } from "../../reducers/profile";
import { showToast } from "../../utils/showToast";
import { units } from "../../utils/units";
import { Oval } from "react-loader-spinner";

const ActionButtons = ({ plotDetails, selectedPlot }) => {
  const [isBidding, setIsBidding] = useState(false);
  const [bidUnit, setBidUnit] = useState("m2");
  const [bidAmount, setBidAmount] = useState("");
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);

  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const handlePlaceBid = async () => {
    try {
      const response = await placeBid({
        plotId: plotDetails.id,
        bidAmount,
        bidUnit,
      });
      if (response.status === "success") {
        showToast(
          `Great! Your Bid of ₹${bidAmount} on ${plotDetails.puid} Placed Successfully`,
          "success"
        );
        const preUserData = profile.userData;
        dispatch(
          updateUser({
            ...preUserData,
            bids: [...preUserData.bids, plotDetails.id],
          })
        );
        setIsBidding(false);
        setBidAmount("");
      } else {
        console.log(response.status);
        showToast(`Some error occurred, please try again later`, "error");
      }
    } catch (e) {
      showToast(`Some error occurred, please try again later`, "error");
      console.log(e);
    }
  };

  const handleInterested = async () => {
    try {
      const response = await interested({
        plotId: plotDetails.id,
      });
      if (response.status === "success") {
        if (profile.userData?.interested.includes(plotDetails.id)) {
          showToast(
            `Removed your interest from plot ${plotDetails.puid}`,
            "success"
          );

          const preUserData = profile.userData;
          const interestedArr = [...preUserData.interested];

          const index = interestedArr?.indexOf(plotDetails.id);
          if (index > -1) {
            // only splice array when item is found
            interestedArr.splice(index, 1); // 2nd parameter means remove one item only
          }

          dispatch(
            updateUser({
              ...preUserData,
              interested: [...interestedArr],
            })
          );
        } else {
          showToast(
            `Added your interest in plot ${plotDetails.puid}`,
            "success"
          );
          const preUserData = profile.userData;
          dispatch(
            updateUser({
              ...preUserData,
              interested: [...preUserData.interested, plotDetails.id],
            })
          );
        }
      } else {
        showToast(`Some error occurred, please try again later`, "error");
      }
    } catch (e) {
      showToast(`Some error occurred, please try again later`, "error");
      console.log(e);
    }
  };

  return isBidding ? (
    <div className="mt-[24px] flex justify-evenly items-center gap-[16px] flex-col sm:flex-row">
      <div className="w-full rounded-regular border-[#C7C7C7] border-[1px] flex">
        <input
          value={bidAmount}
          onChange={(e) => {
            setBidAmount(e.target.value);
          }}
          type="number"
          placeholder="Enter Amount in ₹"
          className="outline-none rounded-l-regular p-[12px] w-full"
        />
        <select
          value={bidUnit}
          onChange={(e) => {
            setBidUnit(e.target.value);
          }}
          className=" py-[12px] px-[18px] outline-none text-[14px] font-medium text-[#666666] border-none bg-[#ECECEC] w-[130px] rounded-r-regular"
          defaultValue="ft2"
        >
          <option value="lumpsum">Lumpsum</option>
          {units.map((unit) => (
            <option value={unit.value}>Per {unit.label}</option>
          ))}
        </select>
      </div>
      <div className="min-w-fit w-full sm:w-auto flex items-center gap-[16px]">
        <button
          className="min-w-fit w-full sm:w-auto py-[12px] px-[32px] border-[1px] border-[#0259DB] bg-[#0259DB] text-white rounded-regular text-[16px] text-medium"
          onClick={handlePlaceBid}
        >
          {plotDetails.type === "single"
            ? "Place an Offer"
            : `Place an Offer on  ${selectedPlot.label?.replace("No ", "")}`}
        </button>
        <Icon
          icon="material-symbols:cancel"
          className="min-w-[32px] cursor-pointer block sm:hidden"
          width={24}
          color="#838383"
          onClick={() => {
            setIsBidding(false);
          }}
        />
      </div>
      <Icon
        icon="material-symbols:cancel"
        className="min-w-[32px] cursor-pointer hidden sm:block"
        width={24}
        color="#838383"
        onClick={() => {
          setIsBidding(false);
        }}
      />
    </div>
  ) : plotDetails.listed_by?.id === profile.userData?.id ||
    profile.userData?.role === "admin" ? (
    <div className="mt-[24px] flex justify-evenly items-center gap-[16px]">
      <button
        onClick={() => {
          navigate(`/editplot/${plotDetails.puid}`);
        }}
        className="p-[12px] border-[1px] border-[#0259DB] bg-[#0259DB] text-white rounded-regular text-[16px] text-medium w-full"
      >
        Edit Listing
      </button>
      <button
        disabled={isVerifyLoading}
        onClick={async () => {
          setIsVerifyLoading(true);
          const response = await deletePlotByID({ plotId: plotDetails.puid });
          if (response) {
            setIsVerifyLoading(false);
          }
          navigate("/profile", {
            state: {
              selectedTab: "listing",
            },
          });
        }}
        className="p-[12px] border-[1px] border-[#0259DB] text-[#0259DB] rounded-regular text-[16px] text-medium w-full flex justify-center items-center gap-4"
      >
        <Oval
          height={20}
          width={20}
          color="#0259db"
          wrapperStyle={{}}
          wrapperClass=""
          visible={isVerifyLoading}
          ariaLabel="oval-loading"
          secondaryColor="#f8f8f8f"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        Delete Listing
      </button>
    </div>
  ) : (
    <div className="mt-[24px] flex justify-evenly items-center gap-[16px]">
      <button
        className={`p-[12px] border-[1px] rounded-regular text-[16px] text-medium w-full ${
          plotDetails.type === "bulk" && !plotDetails.area
            ? "bg-[#88B8FF] text-white border-[#88B8FF] cursor-not-allowed"
            : "bg-[#0259DB] text-white border-[#0259DB]"
        }`}
        disabled={plotDetails.type === "bulk" && !plotDetails.area}
        onClick={() => {
          setIsBidding(true);
        }}
      >
        {profile.userData?.bids?.includes(plotDetails.id)
          ? "Re-Offer"
          : plotDetails.type === "bulk"
          ? !plotDetails.area
            ? "Place an Offer"
            : `Place an Offer on ${selectedPlot.label?.replace("No ", "")}`
          : "Place an Offer"}
      </button>

      <button
        disabled={plotDetails.type === "bulk" && !plotDetails.area}
        onClick={handleInterested}
        className={`p-[12px] border-[1px] text-[#0259DB] rounded-regular text-[16px] text-medium w-full ${
          plotDetails.type === "bulk" && !plotDetails.area
            ? "cursor-not-allowed border-[#88B8FF]"
            : "border-[#0259DB]"
        }`}
      >
        {plotDetails.type === "bulk"
          ? !plotDetails.area
            ? profile.userData?.interested?.includes(plotDetails.id)
              ? "Not Interested"
              : "Interested"
            : profile.userData?.interested?.includes(plotDetails.id)
            ? `Not Interested ${selectedPlot.label?.replace("No ", "")}`
            : `Interested ${selectedPlot.label?.replace("No ", "")}`
          : profile.userData?.interested?.includes(plotDetails.id)
          ? "Not Interested"
          : "Interested"}
      </button>
    </div>
  );
};

export default ActionButtons;
