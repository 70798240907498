import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { downloadCSV, Export } from "../shared/exportButton";
import tableCustomStyles from "../shared/tableStyle";
import Dropdown from "./Dropdown";
import reportTableCustomStyle from "./ReportStyleCustomStyle";
import { fetchPlots } from "../../apis/apiClient";
import { fuzzyMatch } from "../../utils/sharedFunc";

// const tabledata = [
//   {
//     userinfo: "Balaji Sankaran",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543210,
//     listed: 3,
//     location: "Pimpri",
//     city: "Pune",
//   },
//   {
//     userinfo: "Balaji Sankaran",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543211,
//     listed: 3,
//     location: "Pimpri",
//     city: "Pune",
//   },
//   {
//     userinfo: "Balaji Sankaran",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543212,
//     listed: 3,
//     location: "Pimpri",
//     city: "Pune",
//   },
//   {
//     userinfo: "Balaji Sankaran",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543213,
//     listed: 3,
//     location: "Pimpri",
//     city: "Pune",
//   },
//   {
//     userinfo: "Balaji Sankaran",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543214,
//     listed: 3,
//     location: "Pimpri",
//     city: "Pune",
//   },
//   {
//     userinfo: "Balaji Sankaran",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543215,
//     listed: 3,
//     location: "Pimpri",
//     city: "Pune",
//   },
//   {
//     userinfo: "Balaji Sankaran",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543216,
//     listed: 3,
//     location: "Pimpri",
//     city: "Pune",
//   },
//   {
//     userinfo: "Balaji Sankaran",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543217,
//     listed: 3,
//     location: "Pimpri",
//     city: "Pune",
//   },
//   {
//     userinfo: "Balaji Sankaran",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543218,
//     listed: 3,
//     location: "Pimpri",
//     city: "Pune",
//   },
//   {
//     userinfo: "Balaji Sankaran",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543219,
//     listed: 3,
//     location: "Pimpri",
//     city: "Pune",
//   },
//   {
//     userinfo: "Balaji Sankaran",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543220,
//     listed: 3,
//     location: "Pimpri",
//     city: "Pune",
//   },
// ];

const Listings = ({ reportType, setReportType }) => {
  const [tableData, setTableData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const [search, setSearch] = useState("");
  useEffect(() => {
    setFilteredItems((prevState) => {
      return tableData.filter((item) => {
        return Object.entries(item)
          ?.map((val) => {
            if (
              val[0] === "listed_by" ||
              val[0] === "puid" ||
              val[0] === "location"
            ) {
              if (val[0] === "location") {
                return (
                  fuzzyMatch(
                    search?.toLowerCase(),
                    item?.location?.village?.toLowerCase()
                  ) ||
                  fuzzyMatch(
                    search?.toLowerCase(),
                    item?.location?.district?.toLowerCase()
                  )
                );
              } else if (val[0] === "listed_by") {
                return (
                  fuzzyMatch(
                    search?.toLowerCase(),
                    item?.listed_by?.[0]?.name.toString()?.toLowerCase()
                  ) ||
                  fuzzyMatch(
                    search?.toLowerCase(),
                    item?.listed_by?.[0]?.email.toString()?.toLowerCase()
                  ) ||
                  fuzzyMatch(
                    search?.toLowerCase(),
                    item?.listed_by?.[0]?.mobile_number
                      .toString()
                      ?.toLowerCase()
                  )
                );
              }
              return fuzzyMatch(
                search?.toLowerCase(),
                val[1]?.toString()?.toLowerCase()
              );
            } else {
              return false;
            }
          })
          .includes(true);
      });
    });
  }, [search]);

  const fetchPlotsbyFilter = async (isFirstLoad) => {
    const data = {
      location: "",
      price: "",
      size: "",
      type: "",
      page: 0,
      limit: Number.MAX_SAFE_INTEGER,
    };
    try {
      const response = await fetchPlots(data);
      setTableData(response.results);
      setFilteredItems(response.results);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchPlotsbyFilter();
  }, []);
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(tableData)} />,
    [tableData]
  );

  const columns = [
    {
      name: "User Info",
      selector: (row) => row.listed_by?.[0]?.name,
      style: {
        borderRight: "1px solid #ECECEC",
      },
    },
    {
      name: "Email",
      selector: (row) => row.listed_by?.[0]?.email,
      width: "300px",
      // cell:(row) => <span className='flex gap-[8px]'>{row.buyer} {row.isInterested && interestedClip}</span>,
    },
    {
      name: "Mobile number",
      selector: (row) => row.listed_by?.[0]?.mobile_number,
      // cell:(row) => <span className='flex gap-[8px]'>{row.seller} {row.isFetchedInfo && fetchedClip}</span>,
    },
    {
      name: "PUID",
      selector: (row) => row.puid,
      // cell:(row) => <span>{row.bid !== "-" ? `₹${row.bid}`: row.bid}</span>
    },
    {
      name: "Properties Listed",
      selector: (row) => row?.plots,
      cell: (row) => (
        <span>{row?.plots?.length > 1 ? row?.plots?.length : 1}</span>
      ),
    },
    {
      name: "Location",
      selector: (row) => row.location?.village,
      // cell:(row) => <span>{row.fees !== "-" ? `₹${row.fees}`: row.fees}</span>,
    },
    {
      name: "City",
      selector: (row) => row.location?.district,
      // cell:(row) => <span>{row.discount !== "-" ? `₹${row.discount}`: row.discount}</span>,
    },
  ];

  return (
    <DataTable
      actions={actionsMemo}
      columns={columns}
      data={filteredItems}
      responsive
      striped
      highlightOnHover
      noDataComponent={
        <span className="px-[16px] py-[20px]">{`No data found`}</span>
      }
      pagination
      customStyles={reportTableCustomStyle}
      subHeader
      subHeaderAlign="left"
      subHeaderComponent={
        <Dropdown
          reportType={reportType}
          setReportType={setReportType}
          search={search}
          setSearch={setSearch}
        />
      }
    />
  );
};

export default Listings;
