import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { downloadCSV, Export } from "../shared/exportButton";
import tableCustomStyles from "../shared/tableStyle";
import Dropdown from "./Dropdown";
import reportTableCustomStyle from "./ReportStyleCustomStyle";
import { deleteProfile, fetchPlots, getUsers } from "../../apis/apiClient";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { fuzzyMatch } from "../../utils/sharedFunc";

// const tabledata = [
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543210,
//     listed: 3,
//     views: 234,
//     bids: 4,
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543211,
//     listed: 3,
//     views: 2344,
//     bids: 8,
//     location: "Pimpri",
//     area: 243,
//     city: "Nashik",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543212,
//     listed: 3,
//     views: 65,
//     bids: 22,
//     location: "Pimpri",
//     area: 243,
//     city: "Kokan",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543213,
//     listed: 3,
//     views: 876,
//     bids: 12,
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543214,
//     listed: 3,
//     views: 2345,
//     bids: 2,
//     location: "Pimpri",
//     area: 243,
//     city: "Vidharbha",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543215,
//     listed: 3,
//     views: 2344,
//     bids: "-",
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543216,
//     listed: 3,
//     views: 555,
//     bids: "-",
//     location: "Pimpri",
//     area: 243,
//     city: "Kokan",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543217,
//     listed: 3,
//     views: 10,
//     bids: 41,
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543218,
//     listed: 3,
//     views: 10,
//     bids: 4,
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543219,
//     listed: 3,
//     views: 10,
//     bids: 4,
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     id: 9876543220,
//     listed: 3,
//     views: 10,
//     bids: 4,
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
// ];

const PropertyStats = ({ reportType, setReportType }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [filteredItems, setFilteredItems] = useState([]);

  const [search, setSearch] = useState("");
  useEffect(() => {
    setFilteredItems((prevState) => {
      return tableData.filter((item) => {
        return Object.entries(item)
          ?.map((val) => {
            if (
              val[0] === "name" ||
              val[0] === "email" ||
              val[0] === "mobile_number" ||
              val[0] === "district"
            ) {
              return fuzzyMatch(
                search?.toLowerCase(),
                val[1]?.toString()?.toLowerCase()
              );
            } else {
              return false;
            }
          })
          .includes(true);
      });
    });
  }, [search]);

  const fetchPlotsbyFilter = async (isFirstLoad) => {
    // const data = {
    //   location: "",
    //   price: "",
    //   size: "",
    //   type: "",
    //   page: 0,
    //   limit: Number.MAX_SAFE_INTEGER,
    // };
    try {
      const response = await getUsers();
      setTableData(response.users);
      setFilteredItems(response.users);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchPlotsbyFilter();
  }, []);

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(tableData)} />,
    [tableData]
  );

  const columns = [
    // {
    //   name: "PUID",
    //   selector: (row) => row.puid,
    // },
    // {
    //   name: "Number of views",
    //   selector: (row) => row.views,
    //   cell: (row) => <span>{row.views?.length}</span>,
    // },
    // {
    //   name: "Number of offers",
    //   selector: (row) => row.bids,
    //   cell: (row) => <span>{row.bids?.length}</span>,
    // },
    // {
    //   name: "Property location",
    //   selector: (row) => row.location?.district,
    // },
    // {
    //   name: "Area",
    //   selector: (row) => row.location?.village,
    // },
    // User name, email, phone no, city. options:Edit, delete.
    {
      name: "User name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      // cell: (row) => <span>{row.views?.length}</span>,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobile_number,
      // cell: (row) => <span>{row.bids?.length}</span>,
    },
    {
      name: "City",
      selector: (row) => row.district,
    },
    {
      name: "",
      selector: (row) => row.name,
      cell: (row, index) => {
        return (
          <div className="flex gap-[16px]">
            <Link to={`/users/${row.id}#edituser`}>
              <Icon width="16px" icon="material-symbols:edit" />
            </Link>
            <button
              className="cursor-pointer"
              onClick={() => {
                setDeleteModalOpen(true);
                setSelectedRow({ data: row, index });
              }}
            >
              <Icon width="16px" icon="material-symbols:delete" />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        actions={actionsMemo}
        columns={columns}
        data={filteredItems}
        responsive
        striped
        highlightOnHover
        noDataComponent={
          <span className="px-[16px] py-[20px]">{`No data found`}</span>
        }
        pagination
        customStyles={{
          ...reportTableCustomStyle,
          headCells: {
            style: {
              padding: "20px 16px",
              fontSize: "14px",
              fontWeight: "500",
              color: "#666666",
              borderRight: "none",
            },
          },
        }}
        subHeader
        subHeaderAlign="left"
        subHeaderComponent={
          <Dropdown
            reportType={reportType}
            setReportType={setReportType}
            search={search}
            setSearch={setSearch}
          />
        }
      />
      <div
        className={`fixed z-50 overflow-y-auto top-0 w-full left-0 ${
          deleteModalOpen ? "" : "hidden"
        }`}
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => {
              setDeleteModalOpen(false);
            }}
          >
            <div className="absolute inset-0 bg-[#00000099] opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[700px] sm:w-full p-[24px]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex justify-between items-center">
              <div className="flex gap-[12px] items-center"></div>
              <button
                onClick={() => {
                  setDeleteModalOpen(false);
                }}
              >
                <Icon
                  icon="material-symbols:close"
                  width={20}
                  height={20}
                  color="#000"
                />
              </button>
            </div>
            <h6 className="text-[#000E14] text-[20px]">
              Are you sure you want to delete{" "}
              <span className="font-bold">{selectedRow.data?.name}</span>
            </h6>
            <div className="mt-[36px] flex justify-end gap-[16px]">
              <button
                className="py-[8px] px-[16px] border-[1px] border-[#0259DB] text-[#0259DB] rounded-regular text-[16px] text-medium"
                onClick={() => {
                  setDeleteModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={async () => {
                  try {
                    await deleteProfile(selectedRow?.data?.id);
                    const response = await getUsers();

                    setTableData(response.users);
                    setFilteredItems(response.users);
                    setDeleteModalOpen(false);
                  } catch (error) {
                    console.error(
                      "Error occurred while deleting profile or fetching users:",
                      error
                    );
                  }
                }}
                className="py-[8px] px-[16px] border-[1px] border-[#0259DB] text-[#fff] bg-[#0259DB] rounded-regular text-[16px] text-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyStats;
