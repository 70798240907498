import { baseURL } from "../constants";

export async function getFileFromURL(fileDict) {
  console.log(fileDict);
  const response = await fetch(`${baseURL}/${fileDict?.path}`);
  const data = await response.blob();
  return new File([data], fileDict?.originalname, {
    type: data.type,
  });
}
