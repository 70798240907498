import React from 'react'

const ExpandedRows = ({ data, someTitleProp }) => {
    return (
        <div className='mx-[149px] px-[16px] py-[16px] flex border-b-[1px] border-l-[1px] border-[#ECECEC]'>
            <div className='w-[250px] flex flex-col gap-[10px]'>
                <div>
                    <p className='text-[10px] font-medium text-[#838383] mb-[4px]'>Contact info</p>
                    <p className='text-[12px] font-medium text-[#484848]'>9876543210</p>
                </div>
                <div>
                    <p className='text-[10px] font-medium text-[#838383] mb-[4px]'>Email</p>
                    <p className='text-[12px] font-medium text-[#484848]'>gujarrishikesh1996@gmail.com</p>
                </div>
            </div>
            <div className='w-[250px] flex flex-col gap-[10px]'>
                <div>
                    <p className='text-[10px] font-medium text-[#838383] mb-[4px]'>Contact info</p>
                    <p className='text-[12px] font-medium text-[#484848]'>9876543210</p>
                </div>
                <div>
                    <p className='text-[10px] font-medium text-[#838383] mb-[4px]'>Email</p>
                    <p className='text-[12px] font-medium text-[#484848]'>gujarrishikesh1996@gmail.com</p>
                </div>
            </div>
        </div>
    );

}

export default ExpandedRows