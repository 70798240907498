import { Icon } from "@iconify/react";

import React, { useEffect, useState } from "react";
import CustomEditor from "./Editor";
import { useSelector } from "react-redux";
import { updateGallery, updateLibrary } from "../../apis/apiClient";
import { showToast } from "../../utils/showToast";

const htmlDecode = (content) => {
  let e = document.createElement("div");
  e.innerHTML = content;
  return e.childNodes.length === 0
    ? ""
    : e.childNodes[0].nodeValue
    ? e.childNodes[0].nodeValue
    : e.outerHTML;
};

const AddNew = ({
  setIsAddNewOpen,
  selectedTab,
  articlesData,
  setArticlesData,
}) => {
  const [isEditting, setIsEditting] = useState(true);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [meta, setMeta] = useState("");


  const [galleryImages, setGalleryImages] = useState([]);

  const [articleContent, setArticleContent] = useState(``);
  const [articleContentMain, setArticleContentMain] = useState(articleContent);
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const updateData = async () => {
    if (selectedTab === "Gallery") {
      const formdata = new FormData();
      // formdata.append("data");
      const oldData = [...articlesData?.data];
      oldData.push({ name: title, data: galleryImages });
      oldData?.map((gallery, index) => {
        formdata.append(`data[${index}][name]`, gallery.name);
        formdata.append(
          `data[${index}][id]`,
          String(Math.floor(100000000 + Math.random() * 900000000))
        );

        gallery.data?.map((img, i) => {
          if (img.path) {
            Object.entries(img)?.map((ent) => {
              formdata.append(`data[${index}][data][${i}][${ent[0]}]`, ent[1]);
            });
          } else {
            formdata.append(`data[${index}][data][${i}]`, img);
          }
        });
      });
      for (var pair of formdata.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      const response = await updateGallery(formdata);
      console.log(response);
      setArticlesData((prevState) => ({
        ...prevState,
        data: [...prevState.data, response.data],
      }));
      setArticleContentMain(articleContent);
      setIsEditting(false);
      setIsAddNewOpen(false);

      return;
    }
    const oldData = [...articlesData?.data];
    oldData.push({ name: title, data: articleContent, slug: slug, meta: meta || ''});
    const response = await updateLibrary({
      ...articlesData,
      id: String(Math.floor(100000000 + Math.random() * 900000000)),
      data: [...oldData],
    });
    console.log(response);
    setArticlesData((prevState) => ({
      ...prevState,
      id: String(Math.floor(100000000 + Math.random() * 900000000)),
      data: [...oldData],
    }));
    setArticleContentMain(articleContent);
    setIsEditting(false);
    setIsAddNewOpen(false);
  };

  return (
    <div className="mt-[32px]">
      <div className="flex justify-between items-center">
        {profile.isLoggedIn &&
          profile.userData.role === "admin" &&
          !isEditting && (
            <div className="flex gap-[16px]">
              <button
                onClick={() => {
                  setIsEditting(true);
                }}
                className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] bg-[#0259DB] rounded-regular text-[16px] font-medium text-[#fff] flex items-center gap-[12px]"
                // onClick={() => {
                //   setIsBanksModalOpen(false);
                // }}
              >
                <Icon icon="mdi:pencil-outline" width={20} />
                Edit
              </button>
              <button
                onClick={() => {
                  // setArticleContent(articleContentMain);
                  // setIsEditting(false);
                }}
                className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] rounded-regular text-[16px] font-medium text-[#0259DB] flex items-center gap-[12px]"
              >
                Delete
              </button>
            </div>
          )}
      </div>
      {isEditting ? (
        <>
          <input
            type="text"
            placeholder="Title"
            className="text-[16px] text-[#000E14] font-medium py-[12px] px-[16px] w-full rounded-regular border-[1px] border-[#D0D5DD] outline-none"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          ></input>
          {(selectedTab === "FAQ" || selectedTab === "Articles") && (
            <input
              type="text"
              placeholder="Slug Url"
              className="text-[16px] text-[#000E14] font-medium py-[12px] px-[16px] w-full rounded-regular border-[1px] border-[#D0D5DD] outline-none mt-2"
              value={slug}
              onChange={(e) => {
                setSlug(e.target.value);
              }}
            ></input>
          )}
             {selectedTab === "Articles" && (
            <input
              type="text"
              placeholder="Meta Tag Description"
              className="text-[16px] text-[#000E14] font-medium py-[12px] px-[16px] w-full rounded-regular border-[1px] border-[#D0D5DD] outline-none mt-2"
              value={meta}
              onChange={(e) => {
                setMeta(e.target.value);
              }}
            ></input>
          )}
        </>
      ) : (
        <>
          <h5 className="text-[24px] text-[#000E14] font-medium mt-[20px]">
            {title}
          </h5>
          <h5 className="text-[24px] text-[#000E14] font-medium mt-[20px]">
            {slug}
          </h5>
        </>
      )}

      {selectedTab === "Gallery" ? (
        <div className="flex flex-col w-full mt-[24px]">
          <label className="text-[#344054] text-[14px] font-medium">
            Upload Photos
          </label>
          <div className="flex flex-wrap items-center mt-[8px] gap-[16px]">
            <label className="w-[140px] h-[140px] bg-[#F6F6F6] flex flex-col justify-center items-center px-[16px] py-[12px] rounded-regular border-dashed border-[1px] border-[#367DE7] cursor-pointer text-[#838383] gap-[8px] text-[16px]">
              <Icon
                icon="material-symbols:photo-camera-outline"
                width={24}
                color="#838383"
              />
              Upload Photo
              <input
                multiple
                accept="image/*"
                type="file"
                className="hidden"
                onChange={(e) => {
                  setGalleryImages((prevState) => [
                    ...prevState,
                    ...e.target.files,
                  ]);
                  // setPlotDetails((prevState) => {
                  //   let images = prevState.images;
                  //   let tempArray = [...images];
                  //   tempArray.push(...e.target.files);
                  //   return { ...prevState, images: [...tempArray] };
                  // });
                  // setImages((prevState) => [...prevState, ...e.target.files]);
                }}
              />
            </label>
            {galleryImages.map((image, index) => (
              <div className="w-[140px] h-[140px] rounded-regular relative">
                <button
                  className="absolute right-[8px] top-[8px]"
                  onClick={() => {
                    setGalleryImages((prevState) => {
                      let tempArray = [...prevState];
                      tempArray.splice(index, 1);
                      return [...tempArray];
                    });
                  }}
                >
                  <Icon icon="mdi:close-circle" width={20} color="#fff" />
                </button>
                <img
                  src={URL.createObjectURL(image)}
                  alt=""
                  className="w-full h-full cover rounded-regular"
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <CustomEditor
          isVisible={isEditting}
          name="article"
          onChange={(data) => {
            setArticleContent(data);
          }}
          editorLoaded={editorLoaded}
          value={articleContent}
        />
      )}
      <div
        dangerouslySetInnerHTML={{ __html: articleContentMain }}
        className={`mt-[32px] ${isEditting && "hidden"}`}
      ></div>

      {isEditting && (
        <div className="flex gap-[16px] my-[24px] justify-end">
          <button
            onClick={() => {
              setArticleContent(articleContentMain);
              setIsEditting(false);
              setIsAddNewOpen(false);
            }}
            className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] rounded-regular text-[16px] font-medium text-[#0259DB] flex items-center gap-[12px]"
          >
            Cancel
          </button>
          <button
            onClick={updateData}
            className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] bg-[#0259DB] rounded-regular text-[16px] font-medium text-[#fff] flex items-center gap-[12px]"
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default AddNew;
