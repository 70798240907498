import { Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { fetchedClip, interestedClip } from "../shared/Clips";
import { downloadCSV, Export } from "../shared/exportButton";
import tableCustomStyles from "../shared/tableStyle";
import Dropdown from "./Dropdown";
import ExpandedRows from "./ExpandedRows";
import reportTableCustomStyle from "./ReportStyleCustomStyle";
import { deletePlotByID, fetchPlots } from "../../apis/apiClient";
import { getFormattedDate } from "../../utils/date";
import { fuzzyMatch } from "../../utils/sharedFunc";

const Listings2 = ({ reportType, setReportType, setIsOpen }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  const [search, setSearch] = useState("");
  useEffect(() => {
    setFilteredItems((prevState) => {
      return tableData.filter((item) => {
        return Object.entries(item)
          ?.map((val) => {
            if (
              val[0] === "puid" ||
              val[0] === "title" ||
              val[0] === "listed_by"
            ) {
              if (val[0] === "listed_by") {
                return fuzzyMatch(
                  search?.toLowerCase(),
                  item.listed_by?.[0]?.name?.toLowerCase()
                );
              }
              return fuzzyMatch(
                search?.toLowerCase(),
                val[1]?.toString()?.toLowerCase()
              );
            } else {
              return false;
            }
          })
          .includes(true);
      });
    });
  }, [search]);

  const fetchPlotsbyFilter = async (isFirstLoad) => {
    const data = {
      location: "",
      price: "",
      size: "",
      type: "",
      page: 0,
      limit: Number.MAX_SAFE_INTEGER,
    };
    try {
      const response = await fetchPlots(data);
      setTableData(response.results);
      setFilteredItems(response.results);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchPlotsbyFilter();
  }, []);
  const actionsMemo = React.useMemo(
    () => (
      <Export
        onExport={() =>
          downloadCSV(
            tableData.map((data) => ({
              PUID: data.puid,
              Title: data.title,
              Owner: data.listed_by?.[0]?.name,
              Date: getFormattedDate(data.createdAt),
            }))
          )
        }
      />
    ),
    [tableData]
  );
  // PUID, Title, Owner name, listed date.
  const columns = [
    {
      name: "PUID",
      selector: (row) => row.puid,
      // cell:(row) => <span>{row.bid !== "-" ? `₹${row.bid}`: row.bid}</span>
      // width: "150px",
      style: {
        borderRight: "1px solid #ECECEC",
      },
    },
    {
      name: "Title",
      selector: (row) => row.title,
      // width: "250px",
      // cell: (row) => (
      //   <span className="flex gap-[8px]">
      //     {row.buyer} {interestedClip}
      //   </span>
      // ),
    },
    {
      name: "Owner name",
      selector: (row) => row.listed_by?.[0]?.name,
      // width: "250px",
      // cell: (row) => (
      //   <span className="flex gap-[8px]">
      //     {row.seller} {fetchedClip}
      //   </span>
      // ),
    },
    {
      name: "Listed Date",
      selector: (row) => getFormattedDate(row.createdAt),
      // width: "200px",
    },
    {
      name: "",
      selector: (row) => row.name,
      cell: (row, index) => (
        <div className="flex gap-[16px]">
          <Link to={`/editplot/${row.puid}`}>
            <Icon width="16px" icon="material-symbols:edit" />
          </Link>
          <button
            className="cursor-pointer"
            onClick={() => {
              setSelectedRow({ data: row, index });
              setDeleteModalOpen(true);
            }}
          >
            <Icon width="16px" icon="material-symbols:delete" />
          </button>
        </div>
      ),
    },
    // {
    //   name: "Offer Amount",
    //   selector: (row) => row.bid,
    //   cell: (row) => (
    //     <span
    //       className="text-[#0259DB] cursor-pointer"
    //       onClick={() => openModal(row)}
    //     >
    //       {row.bid !== "-" ? `₹${row.bid}` : row.bid}
    //     </span>
    //   ),
    // },
    // {
    //   name: "Offer Date",
    //   selector: (row) => row.biddedOn,
    //   // cell:(row) => <span>{row.fees !== "-" ? `₹${row.fees}`: row.fees}</span>,
    // },
    // {
    //   title: "",
    //   dataIndex: "",
    //   key: "expand",
    //   width: 1,
    //   cell: (row) => (
    //     <button
    //       id={`expandButton-${row.id}`}
    //       className={`transition ease-in-out delay-10`}
    //       onClick={() => {
    //         document
    //           .querySelectorAll(`[data-testid="expander-button-${row.id}"]`)[0]
    //           .click();
    //       }}
    //     >
    //       <Icon width="24px" icon="material-symbols:keyboard-arrow-down" />
    //     </button>
    //   ),
    // },
  ];

  const openModal = (data) => {
    setIsOpen("bidhistory");
  };

  return (
    <>
      <DataTable
        actions={actionsMemo}
        columns={columns}
        data={filteredItems}
        responsive
        striped
        highlightOnHover
        noDataComponent={
          <span className="px-[16px] py-[20px]">{`No data found`}</span>
        }
        pagination
        customStyles={{
          ...reportTableCustomStyle,
          headCells: {
            style: {
              padding: "20px 16px",
              fontSize: "14px",
              fontWeight: "500",
              color: "#666666",
              "&:nth-child(2)": {
                borderRight: "1px solid #ECECEC",
              },
            },
          },
        }}
        onRowExpandToggled={(isExpanded, data) => {
          if (isExpanded) {
            document
              .getElementById(`expandButton-${data.id}`)
              .classList.add("rotate-180");
          } else {
            document
              .getElementById(`expandButton-${data.id}`)
              .classList.remove("rotate-180");
          }
        }}
        expandableRows={{
          expandedRowRender: (record) => <p style={{ margin: 0 }}>H</p>,
        }}
        expandableRowsComponent={ExpandedRows}
        subHeader
        subHeaderAlign="left"
        subHeaderComponent={
          <Dropdown
            reportType={reportType}
            setReportType={setReportType}
            search={search}
            setSearch={setSearch}
          />
        }
      />
      <div
        className={`fixed z-50 overflow-y-auto top-0 w-full left-0 ${
          deleteModalOpen ? "" : "hidden"
        }`}
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => {
              setDeleteModalOpen(false);
            }}
          >
            <div className="absolute inset-0 bg-[#00000099] opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[700px] sm:w-full p-[24px]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex justify-between items-center">
              <div className="flex gap-[12px] items-center"></div>
              <button
                onClick={() => {
                  setDeleteModalOpen(false);
                }}
              >
                <Icon
                  icon="material-symbols:close"
                  width={20}
                  height={20}
                  color="#000"
                />
              </button>
            </div>
            <h6 className="text-[#000E14] text-[20px]">
              Are you sure you want to delete{" "}
              <span className="font-bold">{selectedRow.data?.title}</span>
            </h6>
            <div className="mt-[36px] flex justify-end gap-[16px]">
              <button
                className="py-[8px] px-[16px] border-[1px] border-[#0259DB] text-[#0259DB] rounded-regular text-[16px] text-medium"
                onClick={() => {
                  setDeleteModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={async () => {
                  try {
                    await deletePlotByID({
                      plotId: selectedRow?.data?.puid,
                    });

                    const data = {
                      location: "",
                      price: "",
                      size: "",
                      type: "",
                      page: 0,
                      limit: Number.MAX_SAFE_INTEGER,
                    };
                    const response = await fetchPlots(data);
                    setTableData(response.results);
                    setFilteredItems(response.results);
                  } catch (error) {
                    console.error("Error occurred while deleting plot:", error);
                  }

                  setDeleteModalOpen(false);
                }}
                className="py-[8px] px-[16px] border-[1px] border-[#0259DB] text-[#fff] bg-[#0259DB] rounded-regular text-[16px] text-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listings2;
