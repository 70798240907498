import React from "react";
import { useSelector } from "react-redux";

const Highlights = ({ plotDetails, selectedPlot }) => {
  const profile = useSelector((state) => state.profile);

  return (
    <div className="mt-[32px]">
      <div className="flex justify-between">
        <h3 className="text-[24px] text-[#000E14] font-semibold">
          Property Highlights {selectedPlot.value.area && selectedPlot.label}
        </h3>
        {/* {(plotDetails.listed_by === profile.userData?.id ||
          profile.userData?.role === "admin") && (
          <button className="text-[16px] text-primaryColor font-semibold">
            Edit Details
          </button>
        )} */}
      </div>
      <div className="shadow-md px-[24px] py-[12px] w-full rounded-regular mt-[12px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-[16px] bg-white">
        {plotDetails.highlights?.map((highlight) => (
          <div className="w-full py-[8px] px-[16px] border-[1px] border-[#D9D9D9] rounded-regular">
            <h5 className="text-[16px] text-[#000E14] font-medium whitespace-nowrap overflow-hidden text-ellipsis">
              {highlight}
            </h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Highlights;
