export const RTONumbers = [
  {
    state: "Andaman and Nicobar Islands",
    code: "AN",
    codes: {
      "Port Blair": "01",
      "Car Nicobar": "02",
    },
  },
  {
    state: "Andhra Pradesh",
    code: "AP",
    codes: {
      Secunderabad: "10",
      Malakpet: "11",
      Kishanbagh: "12",
      Tolichowki: "13",
      Karimnagar: "15",
      Vijayawada: "18",
      Khammam: "20",
      Kurnool: "21",
      Mahbubnagar: "22",
      Medak: "23",
      Nalgonda: "24",
      Nizamabad: "25",
      Nellore: "26",
      Ongole: "27",
      Attapur: "28",
      "Uppal Kalan": "29",
      Srikakulam: "30",
      Visakhapatnam: "33",
      Vizianagaram: "34",
      Warangal: "36",
      Eluru: "37",
      Adilabad: "01",
      Anantapur: "02",
      Chittoor: "03",
      Kadapa: "04",
      Kakinada: "05",
      Guntur: "07",
      Khairtabad: "09",
    },
  },
  {
    state: "Arunachal Pradesh",
    code: "AR",
    codes: {
      Anini: "10",
      Tezu: "11",
      Changlang: "12",
      Khonsa: "13",
      Yingkiong: "14",
      Koloriang: "15",
      Roing: "16",
      Hawai: "17",
      Namsai: "20",
      Itanagar: "02",
      Tawang: "03",
      Bomdila: "04",
      Seppa: "05",
      Ziro: "06",
      Daporijo: "07",
      Along: "08",
      Pasighat: "09",
    },
  },
  {
    state: "Assam",
    code: "AS",
    codes: {},
  },
  {
    state: "Bihar",
    code: "BR",
    codes: {
      Bhagalpur: "10",
      Purnia: "11",
      Hazaribagh: "13",
      Ranchi: "14",
      Daltonganj: "15",
      Jamshedpur: "16",
      Dhanbad: "17",
      Chaibasa: "18",
      Saharsa: "19",
      Bokaro: "20",
      "Bihar Sharif": "21",
      Bettiah: "22",
      Giridih: "23",
      Dehri: "24",
      Jehanabad: "25",
      Aurangabad: "26",
      Nawada: "27",
      Gopalganj: "28",
      Siwan: "29",
      Sitamarhi: "30",
      Hajipur: "31",
      Madhubani: "32",
      Samastipur: "33",
      Khagaria: "34",
      Sahibganj: "35",
      Godda: "36",
      Kishanganj: "37",
      Araria: "38",
      Katihar: "39",
      Deoghar: "40",
      Gumla: "41",
      Lohardaga: "42",
      Madhepura: "43",
      Buxar: "44",
      Bhabua: "45",
      Jamui: "46",
      Koderma: "47",
      Supaul: "50",
      Banka: "51",
      Sheikhpura: "52",
      Lakhisarai: "53",
      Sheohar: "55",
      Arwal: "56",
      Sasaram: "57",
      Patna: "01",
      Gaya: "02",
      Arrah: "03",
      Chhapra: "04",
      Motihari: "05",
      Muzaffarpur: "06",
      Darbhanga: "07",
      Munger: "08",
      Begusarai: "09",
    },
  },
  {
    state: "Chhattisgarh",
    code: "CG",
    codes: {
      Bilaspur: "10",
      Janjgir: "11",
      Korba: "12",
      Raigarh: "13",
      "Jashpur Nagar": "14",
      Ambikapur: "15",
      Baikunthpur: "16",
      Jagdalpur: "17",
      Dantewada: "18",
      Kanker: "19",
      Bijapur: "20",
      Narayanpur: "21",
      "Baloda Bazar": "22",
      Gariaband: "23",
      Balod: "24",
      Bemetara: "25",
      Sukma: "26",
      Kondagaon: "27",
      Mungeli: "28",
      Surajpur: "29",
      Balrampur: "30",
      "Governor of Chhattisgarh": "01",
      "Government of Chhattisgarh": "02",
      "Chhattisgarh Police": "03",
      Raipur: "04",
      Dhamtari: "05",
      Mahasamund: "06",
      Durg: "07",
      Rajnandgaon: "08",
      Kawardha: "09",
    },
  },
  {
    state: "Chandigarh",
    code: "CH",
    codes: {
      Chandigarh: "04",
    },
  },
  {
    state: "Daman and Diu",
    code: "DD",
    codes: {
      "Diu Diu district": "02",
      Daman: "03",
    },
  },
  {
    state: "Delhi",
    code: "DL",
    codes: {
      "Raja Garden": "10",
      Rohini: "11",
      "Vasant Vihar": "12",
      "Surajmal Vihar": "13",
      Sonipat: "14",
      Gurgaon: "15",
      Faridabad: "16",
      Noida: "30",
      Ghaziabad: "18",
      "Civil Lines": "01",
      "Indraprastha Depot": "02",
      "Sheikh Sarai": "03",
      Janakpuri: "04",
      "Loni Road": "05",
      "Sarai Kale Khan, Delhi": "06",
      "Mayur Vihar": "07",
      "Wazir Pur": "08",
      "Dwarka, Delhi": "09",
    },
  },
  {
    state: "Dadra and Nagar Haveli",
    code: "DN",
    codes: {
      Silvassa: "09",
    },
  },
  {
    state: "Goa",
    code: "GA",
    codes: {
      Panajim: "7",
      Margao: "8",
      Mapusa: "3",
      Bicholim: "4",
      Ponda: "5",
      "Vasco da Gama": "6",
      Quepem: "9",
      Canacona: "10",
      Pernem: "11",
      Dharbandora: "12",
    },
  },
  {
    state: "Gujarat",
    code: "GJ",
    codes: {
      "Ahmedabad City(West)": "1",
      Mehsana: "2",
      Rajkot: "3",
      Bhavnagar: "4",
      "Surat city": "5",
      "Vadodara city": "6",
      Nadiad: "7",
      Palanpur: "8",
      Himmatnagar: "9",
      Jamnagar: "10",
      Junagadh: "11",
      Bhuj: "12",
      Surendranagar: "13",
      Rajula: "14",
      Valsad: "15",
      Bharuch: "16",
      Godhra: "17",
      Gandhinagar: "18",
      Bardoli: "19",
      Dahod: "20",
      Navsari: "21",
      Rajpipla: "22",
      Anand: "23",
      Patan: "24",
      Porbandar: "25",
      Vyara: "26",
      "Ahmedabad(East), Vastral": "27",
      "Surat rural": "28",
      "Vadodara rural": "29",
      Waghai: "30",
      Modasa: "31",
      Veraval: "32",
      Botad: "33",
      "Chhota Udaipur": "34",
      Lunawada: "35",
      Morbi: "36",
      Khambhaliya: "37",
      Bavla: "38",
    },
  },
  {
    state: "Haryana",
    code: "HR",
    codes: {
      Ambala: "37",
      Jagadhri: "58",
      Panchkula: "68",
      Naraingarh: "4",
      Karnal: "45",
      Panipat: "67",
      Thanesar: "65",
      Kaithal: "64",
      Guhla: "9",
      Sonipat: "86",
      Gohana: "11",
      Rohtak: "46",
      Bahadurgarh: "13",
      Jhajjar: "63",
      Meham: "15",
      Bhiwani: "61",
      Siwani: "17",
      Loharu: "18",
      "Charkhi Dadri": "19",
      Hisar: "39",
      Hansi: "21",
      Fatehabad: "62",
      Tohana: "23",
      Sirsa: "57",
      "Mandi Dabwali": "25",
      Gurgaon: "72",
      Nuh: "74",
      "Ferozepur Jhirka": "28",
      Ballabgarh: "29",
      Palwal: "73",
      Jind: "56",
      Narwana: "32",
      Safidon: "33",
      Mahendragarh: "34",
      Narnaul: "66",
      Rewari: "47",
      Faridabad: "51",
      Assandh: "40",
      Pehowa: "41",
      Ganaur: "42",
      Kosli: "43",
      Ellenabad: "44",
      Tosham: "48",
      Kalka: "49",
      Hodal: "50",
      Hathin: "52",
      Adampur: "53",
      Barara: "54",
      Ratia: "59",
      Samalkha: "60",
      Chandigarh: "70",
      Bilaspur: "71",
      Indri: "75",
      Pataudi: "76",
      Beri: "77",
      "Shahabad Markanda": "78",
      Kharkhoda: "79",
      Barwala: "80",
      Bawal: "81",
      Kalayat: "83",
      CANTT: "85",
      Kundli: "88",
    },
  },
  {
    state: "Himachal Pradesh",
    code: "HP",
    codes: {
      Rohru: "10",
      Arki: "11",
      Nalagarh: "12",
      Kandaghat: "13",
      Solan: "64",
      Parwanoo: "15",
      Rajgarh: "16",
      "Paonta Sahib": "17",
      Nahan: "71",
      Amb: "19",
      Una: "72",
      "Barsar, Hamirpur": "21",
      Hamirpur: "67",
      Ghumarwin: "23",
      Bilaspur: "24",
      "Reckong Peo": "25",
      Nichar: "26",
      Poo: "27",
      Sarkaghat: "28",
      Jogindernagar: "29",
      Karsog: "30",
      Sundernagar: "31",
      "Gohar, Mandi": "32",
      Mandi: "05",
      Kullu: "66",
      "Anni, Kullu": "35",
      Dehra: "36",
      Palampur: "37",
      Nurpur: "38",
      Dharamshala: "39",
      Kangra: "40",
      Kaza: "41",
      Keylong: "42",
      Udaipur: "43",
      Churah: "44",
      Pangi: "45",
      Bharmour: "46",
      Dalhousie: "47",
      Chamba: "73",
      Banjar: "49",
      Shimla: "50",
      Shimla: "63",
      Baijnath: "53",
      Jawali: "54",
      "Nadaun / Hamirpur": "55",
      Jaisinghpur: "56",
      Chowari: "57",
      Manali: "58",
      Dharmsala: "68",
      Bilaspur: "70",
      "Bhoranj, Hamirpur": "74",
      "Paddhar, Mandi": "76",
      "Dodra Kawar, Shimla": "77",
      "Bangana, Una": "78",
      "Sangrah, Sirmaur": "79",
      "Haroli, Una": "80",
      "Salooni (Dist. Chamba)": "81",
      "Mandi (rural)": "82",
      "Jawalaji, Kangra": "83",
      "Sujanpur Tihra, Hamirpur": "84",
      "Shillai, Sirmaur": "85",
      "Dharampur Distt Mandi": "86",
      Statewide: "02",
      "Shimla – Urban": "03",
      Dharamsala: "04",
      Rampur: "06",
      Shimla: "07",
      Chaupal: "08",
      Theog: "09",
    },
  },
  {
    state: "Jharkhand",
    code: "JH",
    codes: {
      Dhanbad: "10",
      Giridih: "11",
      Koderma: "12",
      Chatra: "13",
      Garhwa: "14",
      Deoghar: "15",
      Pakur: "16",
      Godda: "17",
      Sahibganj: "18",
      Latehar: "19",
      Simdega: "20",
      Jamtara: "21",
      "Saraikela-khrshawan": "22",
      Khunti: "23",
      Ramgarh: "24",
      "Ranchi / Khunti": "01",
      "Hazaribagh / Ramgarh": "02",
      Daltonganj: "03",
      Dumka: "04",
      Jamshedpur: "05",
      Chaibasa: "06",
      Gumla: "07",
      Lohardaga: "08",
      Bokaro: "09",
    },
  },
  {
    state: "Jammu and Kashmir",
    code: "JK",
    codes: {
      Leh: "10",
      Rajouri: "11",
      Poonch: "12",
      Pulwama: "13",
      Udhampur: "14",
      Bandipora: "15",
      Ganderbal: "16",
      Kishtwar: "17",
      Kulgam: "18",
      Ramban: "19",
      Reasi: "20",
      Samba: "21",
      Shopian: "22",
      Srinagar: "01",
      Jammu: "02",
      Anantnag: "03",
      Budgam: "04",
      Baramulla: "05",
      Doda: "06",
      Kargil: "07",
      Kathua: "08",
      Kupwara: "09",
    },
  },
  {
    state: "Karnataka",
    code: "KA",
    codes: {
      Chamarajanagar: "10",
      Mandya: "11",
      Madikeri: "12",
      Hassan: "13",
      Shivamogga: "14",
      Sagara: "15",
      Chitradurga: "16",
      Davangere: "17",
      Chikkamagaluru: "18",
      Mangaluru: "19",
      Udupi: "20",
      Puttur: "21",
      Belagavi: "22",
      Chikkodi: "23",
      Bailhongal: "24",
      Dharwad: "25",
      Gadag: "26",
      Haveri: "27",
      Bijapur: "28",
      Bagalkote: "29",
      Karwar: "30",
      Sirsi: "31",
      "Gulbarga s": "32",
      Yadgir: "33",
      Ballari: "34",
      Hospet: "35",
      Raichur: "36",
      Koppal: "37",
      Bidar: "38",
      Bhalki: "39",
      Chikkaballapur: "40",
      "Bengaluru Western Suburbs": "41",
      Ramanagaram: "42",
      Devanahalli: "43",
      Tiptur: "44",
      Hunsur: "45",
      Sakleshpur: "46",
      Honnavar: "47",
      Jamkhandi: "48",
      Gokak: "49",
      "Bengaluru Northern Suburbs: Yelahanka": "50",
      "Bengaluru Southern Suburbs: Electronics City": "51",
      "Nelamangala, Bengaluru Rural District": "52",
      "Bengaluru Eastern Suburbs: Krishnarajapuram": "53",
      Nagamangala: "54",
      "Mysuru East": "55",
      Basavakalyan: "56",
      "Shantinagar, Bengaluru Urban District": "57",
      "Marathahalli, Bengaluru Urban District": "61",
      "Surathkal, Mangaluru": "62",
      Hubli: "63",
      Madhugiri: "64",
      Dandeli: "65",
      Tarikere: "66",
      Sedam: "67",
      Chintamani: "68",
      Ranebennuru: "69",
      Bantwal: "70",
      "Bengaluru Central, Koramangala": "01",
      "Bengaluru West, Rajajinagar": "02",
      "Bengaluru East, Indiranagar": "03",
      "Bengaluru North, Yeshwanthpur": "04",
      "Bengaluru South, Jayanagar": "05",
      Tumakuru: "06",
      Kolar: "07",
      "Kolar Gold Fields": "08",
      "Mysuru West": "09",
    },
  },
  {
    state: "Kerala",
    code: "KL",
    codes: {
      Malappuram: "10",
      Kozhikode: "11",
      Wayanad: "12",
      Kannur: "13",
      Kasargod: "14",
      "Kerala State Road Transport Corporation buses": "15",
      Attingal: "16",
      Muvattupuzha: "17",
      Vatakara: "18",
      Parassala: "19",
      Neyyattinkara: "20",
      Nedumangad: "21",
      "Kazhakkoottam / Thiruvananthapuram": "22",
      Karunagapally: "23",
      Kottarakara: "24",
      Punalur: "25",
      Adoor: "26",
      Thiruvalla: "27",
      Mallappally: "28",
      Kayamkulam: "29",
      Chengannur: "30",
      Mavelikara: "31",
      Cherthala: "32",
      Changanassery: "33",
      Kanjirappally: "34",
      Pala: "35",
      Vaikom: "36",
      Vandiperiyar: "37",
      Thodupuzha: "38",
      Thripunithura: "39",
      Perumbavoor: "40",
      Aluva: "41",
      "North Paravur": "42",
      Mattancherry: "43",
      Kothamangalam: "44",
      Irinjalakuda: "45",
      Guruvayur: "46",
      Kodungallur: "47",
      Wadakkancherry: "48",
      Alathur: "49",
      Mannarghat: "50",
      Ottappalam: "51",
      Pattambi: "52",
      Perinthalmanna: "53",
      Ponnani: "54",
      Tirur: "55",
      Koyilandy: "56",
      Koduvally: "57",
      Thalassery: "58",
      Thaliparamba: "59",
      Kanhangad: "60",
      Kunnathur: "61",
      Ranni: "62",
      Angamaly: "63",
      Chalakkudy: "64",
      Thirurangadi: "65",
      Kuttanadu: "66",
      Uzhavoor: "67",
      Devikulam: "68",
      Nedumkandam: "69",
      Chittur: "70",
      Nilambur: "71",
      Mananthavady: "72",
      "Sulthan Bathery": "73",
      Thiruvananthapuram: "01",
      Kollam: "02",
      Pathanamthitta: "03",
      Alappuzha: "04",
      Kottayam: "05",
      Idukki: "06",
      Ernakulam: "07",
      Thrissur: "08",
      Palakkad: "09",
    },
  },
  {
    state: "Lakshadweep",
    code: "LD",
    codes: {
      Kavaratti: "01",
      Agatti: "02",
      Amini: "03",
      Androth: "04",
      Kadmat: "05",
      Kiltan: "06",
      Chetlat: "07",
      Kalpeni: "08",
      Minicoy: "09",
    },
  },
  {
    state: "Maharashtra",
    code: "MH",
    codes: {
      "Sangli-Miraj-Kupwad": "10",
      Satara: "11",
      Pune: "12",
      Solapur: "13",
      "Pimpri-Chinchwad": "14",
      Nashik: "15",
      Ahmednagar: "16",
      "Ahmednagar District (North)": "17",
      Dhule: "18",
      Jalgaon: "19",
      Aurangabad: "20",
      Jalna: "21",
      Parbhani: "22",
      Beed: "23",
      Latur: "24",
      Osmanabad: "25",
      Nanded: "26",
      Amravati: "27",
      Buldhana: "28",
      Yavatmal: "29",
      Akola: "30",
      Nagpur: "31",
      Wardha: "32",
      Gadchiroli: "33",
      Chandrapur: "34",
      Gondia: "35",
      Bhandara: "36",
      Washim: "37",
      Hingoli: "38",
      Nandurbar: "39",
      "Nagpur (Rural)": "40",
      Malegaon: "41",
      Baramati: "42",
      "Navi Mumbai": "43",
      Ambejogai: "44",
      Akluj: "45",
      "Panvel also MSRTC buses are registered here": "46",
      "Mumbai North": "47",
      Virar: "48",
      "Nagpur East": "49",
      "Karad (Satara Rural)": "50",
      "Nashik Rural": "51",
      "[Kanwati)": "52",
      "Nanded South": "53",
      "Pune South": "54",
      "Mumbai (South)": "01",
      "Mumbai (West)": "02",
      "Mumbai (East)": "03",
      "Thane.": "04",
      "Kalyan-Dombivali, Ulhasnagar": "05",
      Raigad: "06",
      Sindhudurg: "07",
      Ratnagiri: "08",
      Kolhapur: "09",
    },
  },
  {
    state: "Meghalaya",
    code: "ML",
    codes: {
      "Ri-Bhoi": "10",
      "ML government vehicles": "01",
      "ML police vehicles": "02",
      Vehicles: "03",
      "Jaintia Hills": "04",
      Shillong: "05",
      "West Khasi Hills": "06",
      "East Garo Hills": "07",
      "West Garo Hills(Tura)": "08",
      "South Garo Hills": "09",
    },
  },
  {
    state: "Manipur",
    code: "MN",
    codes: {
      "Imphal West": "01",
      Churachandpur: "02",
      Kangpokpi: "03",
      Thoubal: "04",
      Bishnupur: "05",
      "Imphal East": "06",
      Ukhrul: "07",
    },
  },
  {
    state: "Madhya Pradesh",
    code: "MP",
    codes: {
      "Kahrgone (west Nimar)": "10",
      Dhar: "11",
      "Khandwa (East Nimar)": "12",
      Ujjain: "13",
      Mandsaur: "14",
      Sagar: "15",
      Chhatarpur: "16",
      Rewa: "17",
      Shahdol: "18",
      Satna: "19",
      Jabalpur: "20",
      Katni: "21",
      Seoni: "22",
      Raipur: "23",
      Durg: "24",
      "Jagdalpur (not in use)": "25",
      "Bilaspur (not in use)": "26",
      "Ambikapur (not in use)": "27",
      Chhindwara: "28",
      "Rajnandgaon(not in use)": "29",
      Bhind: "30",
      Sheopur: "31",
      Datia: "32",
      Shivpuri: "33",
      Damoh: "34",
      Panna: "35",
      Tikamgarh: "36",
      Sehore: "37",
      Raisen: "38",
      Rajgarh: "39",
      Vidisha: "40",
      Dewas: "41",
      Shajapur: "42",
      Ratlam: "43",
      Neemuch: "44",
      Jhabua: "45",
      Badwani: "46",
      Harda: "47",
      Betul: "48",
      Narsinghpur: "49",
      Balaghat: "50",
      Mandla: "51",
      Dindori: "52",
      Sidhi: "53",
      Umaria: "54",
      Anuppur: "65",
      "Singrauli (Waidhan)": "66",
      Ashoknagar: "67",
      Burhanpur: "68",
      Alirajpur: "69",
      "Agar / Malwa": "70",
      "State Governors Vehicle": "01",
      "MP Government Vehicles": "02",
      "MP Police Vehicle": "03",
      Bhopal: "04",
      Hoshangabad: "05",
      Morena: "06",
      Gwalior: "07",
      Guna: "08",
      Indore: "09",
    },
  },
  {
    state: "Mizoram",
    code: "MZ",
    codes: {
      Aizawl: "01",
      Lunglei: "02",
      Saiha: "03",
      Champhai: "04",
      Kolasib: "05",
      Serchhip: "06",
      Lawngtlai: "07",
      Mamit: "08",
    },
  },
  {
    state: "Nagaland",
    code: "NL",
    codes: {
      Kohima: "01",
      Mokokchung: "02",
      Tuensang: "03",
      Mon: "04",
      Wokha: "05",
      Zunheboto: "06",
      Dimapur: "07",
      Phek: "08",
    },
  },
  {
    state: "Odisha",
    code: "OD",
    codes: {
      Koraput: "10",
      Mayurbhanj: "11",
      "Phulabani Khandamal": "12",
      Puri: "13",
      Rourkela: "14",
      Sambalpur: "15",
      Sundergarh: "16",
      Baragarh: "17",
      Rayagada: "18",
      Angul: "19",
      Gajapati: "20",
      Jagatsinghpur: "21",
      Bhadrak: "22",
      Jharsuguda: "23",
      Nabarangpur: "24",
      Nayagarh: "25",
      Nuapada: "26",
      Boudh: "27",
      Debagarh: "28",
      Kendrapara: "29",
      Malkangiri: "30",
      Subarnapur: "31",
      Bhanjanagar: "32",
      "Bhubaneswar-II": "33",
      Jajpur: "34",
      Talcher: "35",
      Balasore: "01",
      "Bhubaneswar-I": "02",
      Bolangir: "03",
      Chandikhol: "04",
      Cuttack: "05",
      Dhenkanal: "06",
      Ganjam: "07",
      Kalahandi: "08",
      Keonjhar: "09",
    },
  },
  {
    state: "Punjab",
    code: "PB",
    codes: {
      Ludhiana: "10",
      "Patiala (city)": "11",
      Ropar: "12",
      Sangrur: "13",
      Ajnala: "14",
      Abohar: "15",
      "Anandpur Sahib": "16",
      "Baba Bakala": "17",
      Batala: "18",
      Barnala: "19",
      Balachaur: "20",
      Dasuya: "21",
      Fazilka: "22",
      "Fatehgarh Sahib": "23",
      Garhshankar: "24",
      Jagraon: "25",
      Khanna: "26",
      Kharar: "27",
      Malerkotla: "28",
      Moga: "29",
      Muktsar: "30",
      Mansa: "31",
      Nawanshahar: "32",
      Nakodar: "33",
      "Nabha (Patiala South West)": "34",
      Pathankot: "35",
      Phagwara: "36",
      Phillaur: "37",
      Patti: "38",
      "Rajpura (Patiala North)": "39",
      "Rampura Phul": "40",
      "Sultanpur Lodhi": "41",
      "Samana (Patiala East)": "42",
      Samrala: "43",
      Sunam: "44",
      "Talwandi Sabo": "45",
      "Tarn Taran": "46",
      Zira: "47",
      "Amloh (Patiala West)": "48",
      Khamanon: "49",
      Budhlada: "50",
      "Jhunir / Sardulgarh": "51",
      "Bassi Pathana": "52",
      Malout: "53",
      Mukerian: "54",
      Payal: "55",
      Raikot: "56",
      Bhulath: "57",
      "Dera Baba Nanak": "58",
      Dhuri: "59",
      Gidderbaha: "60",
      Jalalabad: "61",
      Jaitu: "62",
      "Khadoor Sahib": "63",
      Moonak: "64",
      Mohali: "65",
      "Nihal Singh Wala": "66",
      Shahkot: "67",
      "Dhar Kalan (District Pathankot)": "68",
      "Bagha Purana": "69",
      "Dera Bassi": "70",
      "Chamkaur Sahib": "71",
      "Pattran (Patiala East)": "72",
      "Tappa Mandi": "73",
      Nangal: "74",
      Lehragaga: "75",
      Ahmedgarh: "76",
      "Guru Har Sahai": "77",
      "Chandigarh taxi vehicles from Punjab": "01",
      Amritsar: "02",
      Bathinda: "03",
      Faridkot: "04",
      Ferozepur: "05",
      Gurdaspur: "06",
      Hoshiarpur: "07",
      Jalandhar: "08",
      Kapurthala: "09",
    },
  },
  {
    state: "Puducherry",
    code: "PY",
    codes: {
      Puducherry: "01",
      Karaikal: "02",
      Mahe: "03",
      Yanam: "04",
      Oulgaret: "05",
    },
  },
  {
    state: "Rajasthan",
    code: "RJ",
    codes: {
      Churu: "10",
      Dholpur: "11",
      Dungarpur: "12",
      "Sri Ganganagar": "13",
      "Jaipur South": "14",
      Jaisalmer: "15",
      Jalore: "16",
      Jhalawar: "17",
      Jhunjhunu: "18",
      Jodhpur: "19",
      Kota: "20",
      Nagaur: "21",
      Pali: "22",
      Sikar: "23",
      Sirohi: "24",
      "Sawai Madhopur": "25",
      Tonk: "26",
      "UDAIPUR-1": "27",
      Baran: "28",
      Dausa: "29",
      Rajsamand: "30",
      Hanumangarh: "31",
      "Kotputli (Jaipur)": "32",
      "Ramganj Mandi (Kota)": "33",
      Karauli: "34",
      Pratapgarh: "35",
      "Beawar (Ajmer)": "36",
      "Didwana (Nagaur)": "37",
      "Abu Road (Sirohi)": "38",
      "Balotra (Barmer)": "39",
      "Bhiwadi (Alwar)": "40",
      Rambaag: "41",
      "Kishangarh (Ajmer)": "42",
      "Phalodi (Jodhpur)": "43",
      "Sujangarh (Churu)": "44",
      "Jaipur North": "45",
      "Bhinmal (Jalore)": "46",
      "Dudu (Jaipur)": "47",
      "Kekri (Ajmer)": "48",
      "Nohar (Hanumangarh)": "49",
      "Nokha (Bikaner)": "50",
      "Shahpura (Bhilwara)": "51",
      "Shahpura (Jaipur)": "59",
      Ajmer: "01",
      Alwar: "02",
      Banswara: "03",
      Barmer: "04",
      Bharatpur: "05",
      Bhilwara: "06",
      Bikaner: "07",
      Bundi: "08",
      Chittaurgarh: "09",
    },
  },
  {
    state: "Sikkim",
    code: "SK",
    codes: {},
  },
  {
    state: "Tamil Nadu",
    code: "TN",
    codes: {
      "Chennai South West (Virugambakkam)": "10",
      "Chennai Suburban South (Tambaram)": "11",
      "Chennai Suburban (Poonamallee)": "12",
      "Chennai Suburban North Central (Ambattur)": "13",
      "Chennai Suburban South East Sholinganallur": "14",
      Ulundurpet: "15",
      Tindivanam: "16",
      "Chennai Suburban North (Red Hills": "18",
      Chengalpattu: "19",
      Tiruvallur: "20",
      Kanchipuram: "21",
      "Chennai Alandur": "22",
      Vellore: "23",
      Krishnagiri: "24",
      "Thiruvannamalai District": "25",
      "Salem District (Not In Use)": "27",
      "Namakkal North": "28",
      Dharmapuri: "29",
      "Salem West": "30",
      Cuddalore: "31",
      Villupuram: "32",
      "Erode East": "33",
      Thiruchengode: "34",
      Gobichettipalayam: "36",
      "Coimbatore South": "37",
      "Coimbatore North": "38",
      Tirupur: "39",
      Mettupalayam: "40",
      Pollachi: "41",
      "Tirupur South": "42",
      Ooty: "43",
      "Tiruchirapalli East": "81",
      Perambalur: "46",
      Karur: "47",
      Srirangam: "48",
      Thanjavur: "49",
      Tiruvarur: "50",
      Nagapattinam: "51",
      Sankagiri: "52",
      "Salem East": "54",
      Pudukottai: "55",
      Perundurai: "56",
      Dindigul: "57",
      "Madurai South": "58",
      "Madurai North": "59",
      Theni: "60",
      Ariyalur: "61",
      "Sivaganga district": "63",
      "Madurai Central": "64",
      Ramanathapuram: "65",
      "Coimbatore Central": "66",
      Virudhunagar: "67",
      Kumbakonam: "68",
      Thoothukudi: "69",
      Hosur: "70",
      Tirunelveli: "72",
      Ranipet: "73",
      Nagercoil: "74",
      Marthandam: "75",
      Tenkasi: "76",
      Attur: "77",
      Dharapuram: "78",
      Sankarankovil: "79",
      Mayiladuthurai: "82",
      Vaniyambadi: "83",
      Srivilliputhur: "84",
      "Chennai Manapakkam": "85",
      "Erode West": "86",
      Sriperumbudur: "87",
      "Namakkal South": "88",
      "Salem South": "90",
      Chidambaram: "91",
      Thiruchendur: "92",
      Mettur: "93",
      Palani: "94",
      Sivakasi: "95",
      Kovilpatti: "96",
      "Coimbatore – West": "99",
      "Chennai Central": "01",
      "Chennai Anna Nagar": "02",
      "Chennai Tondiarpet": "03",
      "Chennai Royapuram": "04",
      "Chennai Kolathur": "05",
      "Chennai Mandavelli": "06",
      "Chennai Tiruvanmiyur": "07",
      "Chennai West & South East (K. K. Nagar)": "09",
      Kallakurichi: "15Z",
      Gingee: "16Z",
      Gummidipoondi: "18Y",
      Thiruthani: "20X",
      Gudiyatham: "23T",
      Omalur: "30W",
      Panruti: "31Z",
      Bhavani: "36W",
      Sathyamangalam: "36Z",
      Sulur: "37Z",
      Avinashi: "39Z",
      Valparai: "41W",
      Kangayam: "42Y",
      Manaparai: "45Z",
      Lalgudi: "48X",
      Thuraiyur: "48Z",
      Mannargudi: "50Z",
      Karaikudi: "63Z",
      Aruppukottai: "67W",
      Arakkonam: "73Z",
      Valapadi: "77Z",
      Udumalpet: "78Z",
      Sirkazhi: "82Z",
      Ambur: "83Z",
      "Paramathi Velur": "88Z",
      Neyveli: "91Y",
      Virudhachalam: "91Z",
    },
  },
  {
    state: "Tripura",
    code: "TR",
    codes: {
      Agartala: "01",
      Kailasahar: "02",
      Udaipur: "03",
      Ambassa: "04",
      Dharmanagar: "05",
      Khowai: "06",
      Sepahijala: "07",
      Santirbazar: "08",
    },
  },
  {
    state: "Telangana",
    code: "TS",
    codes: {
      "Hyderabad North (Secunderabad)": "10",
      "Hyderabad East (Malakpet)": "11",
      "Hyderabad South (Kishanbagh)": "12",
      "Hyderabad West (Tolichowki)": "13",
      "Hyderabad (reserved)": "14",
      Sangareddy: "15",
      Nizamabad: "16",
      Kamareddy: "17",
      Nirmal: "18",
      Mancherial: "19",
      "Asifabad (Komaram Bheem)": "20",
      Jagtial: "21",
      Peddapalli: "22",
      Sircilla: "23",
      "Warangal (rural)": "24",
      Jayashankar: "25",
      Mahabubabad: "26",
      Jangaon: "27",
      Bhadradri: "28",
      Suryapet: "29",
      Yadadri: "30",
      Nagarkurnool: "31",
      Wanaparthy: "32",
      Gadwal: "33",
      Vikarabad: "34",
      Medak: "35",
      Siddipet: "36",
      Adilabad: "01",
      Karimnagar: "02",
      Warangal: "03",
      Khammam: "04",
      Nalgonda: "05",
      Mahbubnagar: "06",
      "Ranga Reddy": "07",
      Medchal: "08",
      "Hyderabad Central (Khairtabad)": "09",
    },
  },
  {
    state: "Uttarakhand",
    code: "UK",
    codes: {
      Uttarkashi: "10",
      "Chamoli Gopeshwar": "11",
      Pauri: "12",
      Rudraprayag: "13",
      Rishikesh: "14",
      Kotdwar: "15",
      Vikasnagar: "16",
      Roorkee: "17",
      Kashipur: "18",
      Almora: "01",
      Bageshwar: "02",
      Champawat: "03",
      Nainital: "04",
      Pithoragarh: "05",
      Rudrapur: "06",
      Dehradun: "07",
      Haridwar: "08",
      Tehri: "09",
    },
  },
  {
    state: "Uttar Pradesh",
    code: "UP",
    codes: {
      Saharanpur: "11",
      Muzaffarnagar: "12",
      Bulandshahar: "13",
      Ghaziabad: "14",
      Meerut: "15",
      "Gautam Budh Nagar/Noida": "16",
      Baghpat: "17",
      Shamli: "19",
      Bijnor: "20",
      Moradabad: "21",
      Rampur: "22",
      Amroha: "23",
      Badaun: "24",
      Bareilly: "25",
      Pilibhit: "26",
      Shahjahanpur: "27",
      Ayodhya: "28",
      Hardoi: "30",
      "Lakhimpur Kheri": "31",
      Lucknow: "32",
      Raebareli: "33",
      Sitapur: "34",
      Unnao: "35",
      Amethi: "36",
      Hapur: "37",
      "Sambhal district": "38",
      Bahraich: "40",
      Barabanki: "41",
      Faizabad: "42",
      Gonda: "43",
      Sultanpur: "44",
      "Ambedkar Nagar": "45",
      Shravasti: "46",
      Balrampur: "47",
      Azamgarh: "50",
      Basti: "51",
      Deoria: "52",
      Gorakhpur: "53",
      Mau: "54",
      "Siddharth Nagar": "55",
      Maharajganj: "56",
      Kushinagar: "57",
      "Sant Kabir Nagar": "58",
      Ballia: "60",
      Ghazipur: "61",
      Jaunpur: "62",
      Mirzapur: "63",
      Sonbhadra: "64",
      Varanasi: "65",
      Bhadohi: "66",
      Chandauli: "67",
      Allahabad: "70",
      Fatehpur: "71",
      Pratapgarh: "72",
      Kaushambi: "73",
      Kannauj: "74",
      Etawah: "75",
      Farrukhabad: "76",
      "Kanpur Dehat (rural)": "77",
      "Kanpur Nagar (urban)": "78",
      Auraiya: "79",
      Agra: "80",
      Aligarh: "81",
      Etah: "82",
      Firozabad: "83",
      Mainpuri: "84",
      Mathura: "85",
      Hathras: "86",
      Kasganj: "87",
      Banda: "90",
      Hamirpur: "91",
      Jalaun: "92",
      Jhansi: "93",
      Lalitpur: "94",
      Mahoba: "95",
      Chitrakoot: "96",
    },
  },
  {
    state: "West Bengal",
    code: "WB",
    codes: {
      "Kolkata New Town": "09",
      Howrah: "12",
      Uluberia: "14",
      "Hugli-Chuchura": "16",
      "Hooghly Srirampur": "17",
      "Hooghly Srirampur": "18",
      Alipore: "19",
      Alipore: "20",
      Barrackpore: "24",
      Barasat: "26",
      Bangaon: "28",
      Tamluk: "30",
      Contai: "32",
      Midnapur: "34",
      Midnapur: "36",
      Asansol: "38",
      Durgapur: "40",
      Burdwan: "42",
      Kalna: "43",
      Asansol: "45",
      Kalna: "46",
      Bolpur: "48",
      Jhargram: "50",
      Krishnanagar: "52",
      Birbhum: "54",
      Purulia: "56",
      Murshidabad: "58",
      Raiganj: "60",
      Balurghat: "62",
      "Cooch Behar": "64",
      Malda: "66",
      Bankura: "68",
      Alipurduar: "70",
      Jalpaiguri: "72",
      Siliguri: "74",
      Darjeeling: "76",
      Darjeeling: "77",
      Kalimpong: "79",
      Raghunathpur: "82",
      "Mathabhanga RTO": "86",
      Bishnupur: "88",
      Kalyani: "90",
      Islampur: "92",
      "Jangipur, West Bengal": "94",
      Baruipur: "96",
      Kolkata: "02",
      "Kolkata Beltala": "04",
      "Kolkata Kasba": "06",
      "Kolkata Salt Lake": "08",
    },
  },
];
