import convert from "convert-units";

export const units = [
  {
    label: "Square Feet",
    value: "ft2",
  },
  {
    label: "Square Meter",
    value: "m2",
  },
  {
    label: "Guntha",
    value: "g",
  },
  {
    label: "Acre",
    value: "ac",
  },
  {
    label: "Hectare",
    value: "ha",
  },
];

export const lengthunits = [
  {
    label: "Feet",
    value: "ft",
  },
  {
    label: "Meter",
    value: "m",
  },
  {
    label: "Yard",
    value: "y",
  },
];

export const getUnitLabel = (unit) => {
  switch (unit) {
    case "ft2":
      return "Square Feet";
    case "m2":
      return "Square Meter";
    case "g":
      return "Guntha";
    case "ac":
      return "Acre";
    case "ha":
      return "Hectare";
    case "ft":
      return "Feet";
    case "m":
      return "Meter";
    case "y":
      return "Yard";
    default:
      return "Square Feet";
  }
};


export const convertArea = (value, fromunit, tounit) => {
  try {
    console.log(value, fromunit, tounit);
    
    if (!value || !fromunit || !tounit) {
      return 0;
    }

    if (fromunit === tounit) {
      return value;
    }

    if (tounit === "g") {
      value = Math.round(Number(value) * 0.000918273 * 100) / 100;
      tounit = "ft2";
    }

    if (fromunit === "g") {
      value = Math.round(Number(value) * 1089 * 100) / 100;
      fromunit = "ft2";
    }

    const convertedValue = convert(value).from(fromunit).to(tounit);
    if (isNaN(convertedValue)) {
      console.error(`Conversion failed for ${value} ${fromunit} to ${tounit}`);
      return 0;
    }

    return Math.round(convertedValue * 100) / 100;
  } catch (error) {
    console.error("Error converting area:", error.message);
    return 0; // Handle the error by returning a default value or handle it according to your application's needs
  }
};


export const convertLength = (value, fromunit, tounit) => {
  if (value && fromunit && tounit) {
    if (tounit === "y") {
      value = Math.round((Number(value) / 3) * 100) / 100;
      tounit = "ft";
    } else if (fromunit === "y") {
      value = Math.round(Number(value) * 3 * 100) / 100;
      fromunit = "ft";
    }
    return Math.round(convert(value).from(fromunit).to(tounit) * 100) / 100;
  } else {
    return 0;
  }
};
