import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchContact } from "../../../apis/apiClient";
import { getUnitLabel } from "../../../utils/units";

const BidRow = ({ data }) => {
  const [showContact, setShowContact] = useState(false);
  const [contact, setContact] = useState("");

  const profile = useSelector((state) => state.profile);

  const handleTextCopy = () => {
    navigator.clipboard.writeText(contact);
  };

  const handleFetchContact = async () => {
    const response = await fetchContact({
      userId: data.user?.id,
      plotId: data.plot,
    });
    setContact(response.contact);
    setShowContact(true);
  };

  // useEffect(() => {
  //   if (profile.userData?.contacts_fetched?.includes(data.user?.id)) {
  //     handleFetchContact();
  //   } else {
  //     setShowContact(false);
  //   }
  // }, [data, profile]);

  return (
    <div className="shadow-md px-[16px] py-[24px] rounded-regular flex">
      <h6 className="text-[#000E14] font-medium text-[16px] flex-1">
        {data.user?.name}
      </h6>
      <p>
        ₹{data.amount}/{getUnitLabel(data.unit)}
      </p>
      {showContact ? (
        <div className="flex-1 text-[#000E14] text-[16px] flex items-center justify-end gap-[14px]">
          <p>{contact}</p>
          <Icon
            icon="material-symbols:content-copy-outline"
            width={22}
            className="cursor-pointer"
            onClick={handleTextCopy}
          />
        </div>
      ) : (
        <button
          className="flex-1 text-[#0259DB] font-medium text-[16px] text-end"
          onClick={handleFetchContact}
        >
          Fetch Contact
        </button>
      )}
    </div>
  );
};

export default BidRow;
