import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import HomeHeader from "../components/Header/HomeHeader";
import About from "../components/Home/About";
import Mission from "../components/Home/Mission";
import Team from "../components/Home/Team";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.hash === "#aboutus") {
      document.getElementById("aboutus").scrollIntoView();
    }
    if (window.location.hash === "#ourteam") {
      document.getElementById("ourteam").scrollIntoView();
    }
  }, []);

  return (
    <div className="bg-[#8A8A8A0A]">
      <div className="bg-homeBanner bg-no-repeat bg-cover bg-bottom h-[700px] w-full">
        <HomeHeader isHome={true} />
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div class="text-center">
            <h1 class="text-heading-black font-bold text-4xl md:text-5xl px-4 md:px-0 mb-2">
              Buy or Sell Plots in Maharashtra
              <span class="text-primary block text-3xl font-bold py-4">
                10,000+ Options | <span class="text-rempo">Rempo</span>
              </span>
            </h1>
          </div>

          <h4 class="mt-6 font-medium text-lg md:text-xl text-center text-heading-gray lg:px-56 md:px-10 px-8">
            Explore 10,000+ plots for sale in Maharashtra. Find the perfect plot
            for your needs. Buy or sell land hassle-free with our expert
            guidance.
          </h4>

          <div className="flex mt-[24px] md:mt-[52px] gap-[24px] flex-col sm:flex-row">
            <button
              className="w-[180px] bg-primaryColor border-[2px] border-primaryColor p-[12px] rounded-regular text-white font-medium text-[16px]"
              onClick={() => {
                navigate("buyplot");
              }}
            >
              Buy a Plot
            </button>
            <button
              className="w-[180px] bg-transparent border-[2px] border-primaryColor p-[12px] rounded-regular text-primaryColor font-medium text-[16px]"
              onClick={() => {
                navigate("sellplot");
              }}
            >
              Sell a Plot
            </button>
          </div>
        </div>
      </div>
      <About />
      <Mission />
      <Team />
      <Footer />
    </div>
  );
};

export default Home;
