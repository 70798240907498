import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getFormattedDate } from "../../utils/date";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FcCancel } from "react-icons/fc";
import { FaUserCog } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { MdOutlineLocalOffer } from "react-icons/md";


const HeaderDropdown = ({ handleLogout }) => {
  const profile = useSelector((state) => state.profile);
  const notificationsValue = useSelector((state) => state.notificationList.list);



  return (
    <div className="flex items-center gap-[24px]">
      <Link to="/profile" state={{ selectedTab: "saved" }}>
        <Icon
          icon="material-symbols:bookmark-outline-sharp"
          width="26"
          height="26"
          color="#000"
        />
      </Link>

      <div className="group inline-block relative">
        <button>
          <Icon icon="mdi:bell-outline" width="26" height="26" color="#000" />
          <div className="group-hover:scale-100 transform scale-0 absolute transition duration-100 ease-in-out origin-top-right right-[-4px] pt-[12px] z-50">
            <ul className="list-none bg-white border rounded-regular px-[20px] py-[12px] w-[424px] z-50 before:absolute before:right-[10px] before:w-[14px] before:h-[14px] before:bg-white before:top-[7px] before:rotate-45">
              {profile.userData?.notifications?.map((notification) => (
                <li className="flex gap-[16px] items-start mb-[24px]">
                  {console.log("yes", notification)}


                  

                  {notification.type === "listings" && (
                    <div className="min-w-[48px] min-h-[48px] bg-[#EFF8EE] flex justify-center items-center rounded-full">
                      <Icon
                        icon="material-symbols:post-add"
                        color="#027A48"
                        width={20}
                      />
                    </div>
                  )}
                  {notification.type === "interested" && (
                    <div className="min-w-[48px] min-h-[48px] bg-[#FAEBEC] flex justify-center items-center rounded-full">
                      <Icon
                        icon="mdi:favourite-border"
                        color="#C62F39"
                        width={20}
                      />
                    </div>
                  )}
                  {notification.type === "bid" && (
                    <div className="min-w-[48px] min-h-[48px] bg-[#CCDEF8] flex justify-center items-center rounded-full">
                      <Icon
                        icon="material-symbols:trending-up"
                        color="#0259DB"
                        width={20}
                      />
                    </div>
                  )}
                  <div>

                    {notification.type === "listings" && (
                      <h6 className="text-[#000E14] text-[14px] text-left">
                        Your Plot in{" "}
                        {notification?.plot && (
                          <a
                            href={`https://rempo.in/plot/${notification?.plot?.puid}`}
                            className="font-bold text-[#212B36]"
                          >
                            {notification?.plot?.location?.village} -{" "}
                            {notification?.plot?.puid}{" "}
                          </a>
                        )}
                        {notification?.scheme && (
                          <span className="font-bold text-[#212B36]">
                            {notification?.scheme?.location?.village} -{" "}
                            {notification?.scheme?.puid}{" "}
                          </span>
                        )}
                        has been posted.
                      </h6>
                    )}
                    {notification.type === "interested" && (
                      <h6 className="text-[#000E14] text-[14px] text-left">
                        1 people are Interested in your Listing for Plot in{" "}
                        <span className="font-bold text-[#212B36]">
                          {notification.plot?.location?.village} -{" "}
                          {notification.plot?.puid}
                        </span>
                        .
                      </h6>
                    )}
                    {notification.type === "bid" && (
                      <h6 className="text-[#000E14] text-[14px] text-left">
                        <span className="font-bold text-[#212B36]">
                          You have new bid{" "}
                        </span>
                        on your plot in{" "}
                        <span className="font-bold text-[#212B36]">
                          {notification.plot?.location?.village} -{" "}
                          {notification.plot?.puid}
                        </span>
                        .
                      </h6>
                    )}
                    <div className="flex mt-[4px] gap-[4px] items-center">
                      <Icon
                        icon="mdi:clock-time-three-outline"
                        color="#919EAB"
                        width={16}
                      />
                      <p className="text-[#919EAB] text-[12px]">
                        {getFormattedDate(notification?.createdAt, {
                          withMonthName: true,
                        })}
                      </p>
                    </div>
                  </div>
                </li>
              ))}




{notificationsValue?.map((notification) =>(<>
  {notification.type === "kycUpdate" && (
                <div
                key={notification.id}
                className="flex gap-4 items-start mb-1 p-4 rounded-lg"
                >
                  <div className="flex-shrink-0">
                    <GoVerified className="text-green-400 w-6 h-6" />
                  </div>
                  <div>
                    <p className="text-[#000E14] text-sm">
                    {notification.message}
                    </p>
                  </div>
                </div>
              )}

{notification.type === "offerPlaced" && (
                <div
                key={notification.id}
                className="flex gap-4 items-start mb-1 p-4 rounded-lg"
                >
                  <div className="flex-shrink-0">
                    <MdOutlineLocalOffer className="text-green-300 w-6 h-6" />
                  </div>
                  <div>
                    <p className="text-[#000E14] text-sm">
                    {notification.message}
                    </p>
                  </div>
                </div>
              )}

  {notification.type === "adminUpdate" && (
                <div
                key={notification.id}
                className="flex gap-4 items-start mb-1 p-4 rounded-lg"
                >
                  <div className="flex-shrink-0">
                    <FaUserCog className="text-blue-400 w-6 h-6" />
                  </div>
                  <div>
                    <p className="text-[#000E14] text-sm">
                    {notification.message}
                    </p>
                  </div>
                </div>
              )}

              {notification.type === "deleted" && (
                <div
                key={notification.id}
                className="flex gap-4 items-start mb-1 p-4 rounded-lg"
                >
                  <div className="flex-shrink-0">
                    <FcCancel className="text-red-400 w-6 h-6" />
                  </div>
                  <div>
                    <p className="text-[#000E14] text-sm">
                    Your listing <span className="font-bold">{notification?.plot?.plot?.title} - {notification?.plot?.plot?.puid}</span> has been removed from REMPO.
                    </p>
                  </div>
                </div>
              )}
              </>))}






{profile.userData?.notifications?.map((notification) => (
      <li className="flex gap-[16px] items-start mb-[24px]">
                  {notification?.plot?.availability === 'sold' && (
                    <div className="min-w-[48px] min-h-[48px] bg-[#EFF8EE] flex justify-center items-center rounded-full">
                      <FaMoneyBillTrendUp color="#027A48"
                        size={20} />
                    </div>
                  )}
                  <div>
                  {notification?.plot?.availability === 'sold' && (
                      <Link to={`/plot/${notification?.plot?.puid}`} className="text-[#000E14] text-[14px] text-left">
                        <span className="font-semibold text-[#212B36]">
                        Great news!{" "}Your listing has been marked as sold on REMPO.{" "}
                            

                        </span>
                        Visit the link to edit the details.{" "}
                      </Link>
                    )}
                  </div>
      </li>


            ))}
              {profile.isLoggedIn && (
                <li className="flex gap-3 bg-green-100 items-start mb-6 p-4 rounded-lg">
                  <div className="flex-shrink-0">
                    <FiCheckCircle className="text-green-500 w-6 h-6" />
                  </div>
                    You have signed into your account successfully.
                </li>
              )}

              {!profile.userData.isRegistered && (
                <li className="flex gap-4 items-start mb-6 bg-yellow-100 p-4 rounded-lg">
                  <div className="flex-shrink-0">
                    <FiAlertCircle className="text-yellow-500 w-6 h-6" />
                  </div>
                  <Link to="/registration" className="text-[#000E14] text-sm">
                    Some information is missing. Please fill in the mandatory
                    fields to complete your sign-up.
                  </Link>
                </li>
              )}

              <li className="border-t-[1px] border-[#919EAB3D] mx-[-20px] pt-[12px]">
                <Link
                  to="/notifications"
                  className="w-full text-center text-[14px] text-[#0259DB] font-bold"
                >
                  View all
                </Link>
              </li>
            </ul>
          </div>
        </button>
      </div>

      <div className="group inline-block relative">
        <button>
          <div
            className="relative inline-flex items-center justify-center w-[44px] h-[44px] overflow-hidden bg-[#EAF2FF] border-[1px] border-[#013583] rounded-full"
            type="button"
            data-dropdown-toggle="userDropdown"
            data-dropdown-placement="bottom-start"
          >
            <span className="font-bold text-[20px] text-primaryColor text-uppercase">
              {profile.userData.name?.split("")[0]}
            </span>
          </div>

          <ul className="list-none bg-white border rounded-regular transform scale-0 group-hover:scale-100 absolute transition duration-100 ease-in-out origin-top-right p-[8px] w-[220px] right-0 z-50 before:absolute before:right-[10px] before:w-[14px] before:h-[14px] before:bg-white before:top-[-7px] before:rotate-45">
            <li className="px-[34px] py-[16px] cursor-default border-b-[2px] border-[#919EAB3D] ml-[-8px] mr-[-8px] mb-[8px]">
              <h6 className="text-[#000E14] font-semibold text-[16px] text-start">
                {profile.userData.name}
              </h6>
            </li>
            <li className="">
              <Link
                className="rounded-t py-[12px] px-[32px] block whitespace-no-wrap text-[16px] font-medium text-[#333333] hover:bg-[#919EAB14] text-left"
                to="/profile"
                state={{ selectedTab: "listing" }}
              >
                Profile
              </Link>
            </li>
            <li className="">
              <Link
                className="py-[12px] px-[32px] block whitespace-no-wrap text-[16px] font-medium text-[#333333] hover:bg-[#919EAB14] text-left"
                to="/profile"
                state={{ selectedTab: "listing" }}
              >
                My Listings
              </Link>
            </li>
            <li className="">
              <Link
                className="rounded-b py-[12px] px-[32px] block whitespace-no-wrap text-[16px] font-medium text-[#333333] hover:bg-[#919EAB14] text-left"
                to="/profile"
                state={{ selectedTab: "bids" }}
              >
                My Offers
              </Link>
            </li>
            <li className="">
              <Link
                className="rounded-t py-[12px] px-[32px] block whitespace-no-wrap text-[16px] font-medium text-[#333333] hover:bg-[#919EAB14] text-left"
                to="/profile"
                state={{ selectedTab: "interested" }}
              >
                Liked Properties
              </Link>
            </li>
            <li className="mb-[8px]">
              <Link
                className="py-[12px] px-[32px] block whitespace-no-wrap text-[16px] font-medium text-[#333333] hover:bg-[#919EAB14] text-left"
                to="/profile"
                state={{ selectedTab: "saved" }}
              >
                Saved Properties
              </Link>
            </li>
            <li className="border-t-[2px] border-[#919EAB3D] p-[8px] pb-0 ml-[-8px] mr-[-8px]">
              <div
                onClick={handleLogout}
                className="py-[12px] px-[32px] block whitespace-no-wrap text-[16px] font-medium text-[#C62F39] hover:bg-[#919EAB14] text-left w-full"
              >
                Logout
              </div>
            </li>
          </ul>
        </button>
      </div>
    </div>
  );
};

export default HeaderDropdown;
