import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import marker from "../../assets/images/mapmarker.png";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";

const iconPerson = new L.Icon({
  iconUrl: marker,
  iconRetinaUrl: marker,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(35, 45),
  className: "border-none",
});

const LocationDetails = ({ plotDetails }) => {
  useEffect(() => {
    if (window.location.hash === "#map") {
      document.getElementById("map").scrollIntoView();
    }
  }, [plotDetails]);
  return (
    <div className="mt-[32px]" id="map">
      <div className="flex justify-between">
        <h3 className="text-[24px] text-[#000E14] font-semibold">
          View on Map -{" "}
          <span className="text-[#838383]"> Explore Neighborhood </span>{" "}
        </h3>
      </div>
      <div className="shadow-md p-[24px] w-full rounded-regular mt-[12px] h-[400px] w-full">
        <div className="w-full h-full z-0">
          <GoogleMapReact
            options={(maps) => {
              return {
                mapTypeControl: true,
                // mapTypeId: maps.MapTypeId.SATELLITE,
                mapTypeControlOptions: {
                  style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                  position: maps.ControlPosition.BOTTOM_CENTER,
                  mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID,
                  ],
                },

                zoomControl: true,
                clickableIcons: false,
              };
            }}
            bootstrapURLKeys={{
              key: "AIzaSyCfjqexH-qoWRG2Rip7S_t7CCMENGg-YVU",
            }}
            defaultCenter={{
              lat:
                plotDetails?.location?.latlng &&
                !isNaN(Number(plotDetails?.location?.latlng[0]))
                  ? Number(plotDetails?.location?.latlng[0])
                  : 20.5937,
              lng:
                plotDetails?.location?.latlng &&
                !isNaN(Number(plotDetails?.location?.latlng[1]))
                  ? Number(plotDetails?.location?.latlng[1])
                  : 78.9629,
            }}
            defaultZoom={plotDetails?.location?.latlng ? 11 : 5}
          >
            {plotDetails?.location?.latlng &&
              !isNaN(Number(plotDetails?.location?.latlng[0])) && (
                <div
                  lat={Number(plotDetails?.location?.latlng[0])}
                  lng={Number(plotDetails?.location?.latlng[1])}
                  className="mt-[-45px] ml-[-8px]"
                >
                  <img src={marker} alt="mapmarker" className="w-[25px]" />
                </div>
              )}
          </GoogleMapReact>
        </div>
        {/* <MapContainer
          center={[
            plotDetails?.location?.latlng
              ? Number(plotDetails?.location?.latlng[0])
              : 20.5937,
            plotDetails?.location?.latlng
              ? Number(plotDetails?.location?.latlng[1])
              : 78.9629,
          ]}
          zoom={10}
          scrollWheelZoom={false}
          className="w-full h-full z-0"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            icon={iconPerson}
            position={[
              plotDetails?.location?.latlng
                ? Number(plotDetails?.location?.latlng[0])
                : 20.5937,
              plotDetails?.location?.latlng
                ? Number(plotDetails?.location?.latlng[1])
                : 78.9629,
            ]}
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer> */}
      </div>
    </div>
  );
};

export default LocationDetails;
