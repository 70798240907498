import React, { useEffect, useState } from "react";
import logo from "../../assets/svg/logo.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import HeaderDropdown from "./HeaderDropdown";
import { logoutUser } from "../../reducers/profile";

const Header = ({ isRegistration, showResourceSelector }) => {
  const [registration, setRegistration] = useState(
    isRegistration ? true : false
  );
  const profile = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("userData");
    dispatch(logoutUser());
    navigate("/login");
  };

  return (
    <div className="relative bg-white px-[24px] shadow-md max-h-[72px]">
      <div className="mx-auto max-w-7xl">
        <div className="flex items-center pt-[20px] pb-[8px] justify-between space-x-10">
          <div className="flex justify-start">
            <Link to="/" className="flex items-center">
              <img className="w-[96px]" src={logo} alt="" />
            </Link>
          </div>
          {profile.isLoggedIn && !registration && (
            <>
              <div
                className="hidden justify-between items-center w-full lg:flex ml-[40px]"
                id="mobile-menu-2"
              >
                <ul className="list-none flex flex-row font-medium gap-[20px] mt-[14px]">
                  <li>
                    <NavLink
                      to="/dashboard"
                      className={({ isActive }) =>
                        isActive
                          ? "block px-[10px] text-primaryColor border-b-[2px] border-primaryColor pb-[14px]"
                          : "block px-[10px] text-[#3E3E3E]"
                      }
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/logs"
                      className={({ isActive }) =>
                        isActive
                          ? "block px-[10px] text-primaryColor border-b-[2px] border-primaryColor pb-[14px]"
                          : "block px-[10px] text-[#3E3E3E]"
                      }
                    >
                      Logs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/users"
                      className={({ isActive }) =>
                        isActive
                          ? "block px-[10px] text-primaryColor border-b-[2px] border-primaryColor pb-[14px]"
                          : "block px-[10px] text-[#3E3E3E]"
                      }
                    >
                      User Management
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/reports"
                      className={({ isActive }) =>
                        isActive
                          ? "block px-[10px] text-primaryColor border-b-[2px] border-primaryColor pb-[14px]"
                          : "block px-[10px] text-[#3E3E3E]"
                      }
                    >
                      Reports
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/resources"
                      className={({ isActive }) =>
                        (isActive || showResourceSelector)
                          ? "block px-[10px] text-primaryColor border-b-[2px] border-primaryColor pb-[14px]"
                          : "block px-[10px] text-[#3E3E3E]"
                      }
                    >
                      Resources
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      to="/subscription"
                      className={({ isActive }) =>
                        isActive
                          ? "block px-[10px] text-primaryColor border-b-[2px] border-primaryColor pb-[14px]"
                          : "block px-[10px] text-[#3E3E3E]"
                      }
                    >
                      Subscription
                    </NavLink>
                  </li> */}
                </ul>
              </div>
              <HeaderDropdown handleLogout={handleLogout} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
