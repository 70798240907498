import { showToast } from "./showToast";

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  console.log(array);
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      if (
        typeof item[key] === "string" ||
        typeof item[key] === "number" ||
        typeof item[key] === "boolean"
      ) {
        result += item[key];
      } else if (Array.isArray(item[key])) {
        result += item[key].length;
      } else {
      }

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(array, name) {
  if (array.length > 0) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = name;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  } else {
    showToast("Data Not available", "error");
  }
}
