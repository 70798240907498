import React from "react";

const Dropdown = ({ reportType, setReportType, search, setSearch }) => {
  return (
    <div className="flex flex-col md:flex-row gap-[12px]">
      <div className="border-[1px] pr-[12px] border-[#D0D5DD] bg-white rounded-regular flex flex-row-reverse items-center w-full md:max-w-[350px] mr-[16px]">
        <select
          value={reportType}
          onChange={(e) => {
            setReportType(e.target.value);
          }}
          className="px-[12px] py-[10px] outline-none text-[16px] font-medium text-[#858585] ml-[12px] w-full bg-transparent"
        >
          {/* <option value="fees">Fees collection report</option> */}
          <option value="listings">New Listings report</option>
          <option value="property">Property Report</option>
          {/* <option value="propertyshared">Property shared details report</option> */}
          <option value="propertystats">Users report</option>
          {/* <option value="subscription">Subscription report</option> */}
          <option value="enduser">End user master data report</option>
          {/* <option value="referral">Referral benefits report</option> */}
        </select>
      </div>
      <div className="border-[1px] pr-[12px] border-[#D0D5DD] bg-white rounded-regular flex flex-row-reverse items-center w-full md:max-w-[350px] mr-[16px]">
        <input
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="Search"
          type="text"
          className="px-[12px] py-[10px] outline-none text-[16px] font-medium text-[#858585] ml-[12px] w-full bg-transparent"
        />
      </div>
    </div>
  );
};

export default Dropdown;
