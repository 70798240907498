import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import CustomEditor from "./Editor";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getArticleBySlug, getLibrary } from "../../apis/apiClient";
import HomeHeader from "../Header/HomeHeader";
import AdminHeader from "../Header/AdminHeader";
import ResourceSelector from "./ResourceSelector";
import { updateLibrary } from "../../apis/apiClient";

const htmlDecode = (content) => {
  let e = document.createElement("div");
  e.innerHTML = content;
  return e.childNodes.length === 0
    ? ""
    : e.childNodes[0].nodeValue
    ? e.childNodes[0].nodeValue
    : e.outerHTML;
};

const FAQDetails = () => {
  const navigate = useNavigate()
  const [articleData, setArticleData] = useState({});
  const [isEditting, setIsEditting] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");

  const [articleContent, setArticleContent] = useState("");
  const [articleContentMain, setArticleContentMain] = useState("");
  const [articlesData, setArticlesData] = useState({});


  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    const fetchLibraryData = async () => {
      try{
        setIsDataLoading(true);
        const response = await getLibrary({ name: "FAQ" });
        setIsDataLoading(false);
        setArticlesData(response);
      }catch(err){
        setIsDataLoading(false)
        console.log(err)
      }
    };
    fetchLibraryData();
  }, []);


  const profile = useSelector((state) => state.profile);
  const articleSlug = useParams();

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        setIsDataLoading(true);
        const data = await getArticleBySlug("FAQ", articleSlug.articleName);
        setArticleData(data);
        setTitle(data?.name);
        setSlug(data?.slug)
        setArticleContent(htmlDecode(data?.data));
        setArticleContentMain(htmlDecode(data?.data));
        setEditorLoaded(true);
        setIsDataLoading(false)
      } catch (error) {
        setIsDataLoading(false)
        console.error("Error fetching article data:", error);
      }
    };
    fetchArticleData();
  }, [articleSlug.articleName]);


  const updateData = async (data) => {
    const oldData = [...articlesData.data];

    oldData[articleData?.index] = data;

    try {
      await updateLibrary({
        name: articlesData.name,
        data: [...oldData],
      });
      setIsEditting(false);
      navigate('/faqs');
    } catch (err) {
      console.log(err);
      setIsEditting(false);
    }
  };

  const deleteData = async () => {
    const oldData = [...articlesData?.data];

    const newData = oldData?.filter((article, i) => i !== articleData?.index); 

    try {
      await updateLibrary({
        name: "FAQ",
        data: newData,
      });
      navigate('/faqs');
    } catch (error) {
      console.error("Error deleting article data:", error);
    }
  };
  
  
  
  

  return (
    <>
      <div>
        {
          <>
            {profile.isLoggedIn && profile.userData.role === "admin" ? (
              <AdminHeader showResourceSelector={true}/>
            ) : (
              <HomeHeader isHome={false} showResourceSelector={true}/>
            )}
          </>
        }
        <div className="bg-white mx-auto max-w-screen-xl px-[24px] mt-[24px]">
          <ResourceSelector selectedTab={"FAQ"} />

          <div className="mt-[32px]">
            <div className="flex justify-between md:items-center flex-col gap-[20px] md;gap-0 md:flex-row">
              {!isEditting && (
                <div className="flex gap-[12px] items-center">
                  <Icon
                    icon="mdi:file-document-outline"
                    width={32}
                    color="#0259DB"
                    className="min-w-[32px] my-[12px]"
                  />
                  <h1 className="text-[32px] text-[#000E14] font-semibold">
                    {articleData?.name}
                  </h1>
                </div>
              )}
              {profile.isLoggedIn &&  
                profile.userData.role === "admin" &&
                !isEditting && (
                  <div className="flex gap-[16px]">
                    <button
                      onClick={() => {
                        setIsEditting(true);
                      }}
                      className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] bg-[#0259DB] rounded-regular text-[16px] font-medium text-[#fff] flex items-center gap-[12px]"
                    >
                      <Icon icon="mdi:pencil-outline" width={20} />
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        // deleteData(articleData?.index);
                        deleteData();
                      }}
                      className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] rounded-regular text-[16px] font-medium text-[#0259DB] flex items-center gap-[12px]"
                    >
                      Delete
                    </button>
                  </div>
                )}
            </div>
            {isEditting ? (
              <>
              <input
                type="text"
                className="text-[16px] text-[#000E14] font-medium py-[12px] px-[16px] w-full rounded-regular border-[1px] border-[#D0D5DD] outline-none"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                ></input>
              
                <input
                  type="text"
                  placeholder="Slug Url"
                  className="text-[16px] text-[#000E14] font-medium py-[12px] px-[16px] w-full rounded-regular border-[1px] border-[#D0D5DD] outline-none mt-2"
                  value={slug}
                  onChange={(e) => {
                    setSlug(e.target.value);
                  }}
                  ></input>
                  </>
    
            ) : (
              <h5 className="text-[24px] text-[#000E14] font-medium mt-[20px]">
                {title}
              </h5>
            )}

            <CustomEditor
              isVisible={isEditting}
              name="article"
              onChange={(data) => {
                setArticleContent(data);
              }}
              editorLoaded={editorLoaded}
              value={articleContent}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: articleContentMain.replaceAll(
                  "<a ",
                  "<a target='_blank'"
                ),
              }}
              className={`mt-[32px] ${isEditting && "hidden"}`}
            ></div>

            {isEditting && (
              <div className="flex gap-[16px] my-[24px] justify-end">
                <button
                  onClick={() => {
                    setArticleContent(articleContentMain);
                    setIsEditting(false);
                  }}
                  className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] rounded-regular text-[16px] font-medium text-[#0259DB] flex items-center gap-[12px]"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    updateData({name:title, data: articleContent, slug: slug });
                    setArticleContentMain(articleContent);
                    setIsEditting(false);
                  }}
                  className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] bg-[#0259DB] rounded-regular text-[16px] font-medium text-[#fff] flex items-center gap-[12px]"
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQDetails;