import React, { useEffect, useState } from "react";
import Header from "../components/Header/AdminHeader";
import googleLogo from "../assets/svg/google.svg";
import OTPVerification from "../components/OTPVerification";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../reducers/profile";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

import { login, sendOTP, verifyOTP } from "../apis/apiClient";
import { Oval } from "react-loader-spinner";
import { showToast } from "../utils/showToast";

const Login = () => {
  const [isUsernameError, setIsUsernameError] = useState(false);
  const { state } = useLocation();

  const [isRemembered, setIsRemembered] = useState(localStorage.getItem('loginData') ? true : false);
  const [haveReferral, setHaveReferral] = useState(false);

  const [isOTPError, setIsOTPError] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [username, setUsername] = useState(localStorage.getItem('loginData') ? localStorage.getItem('loginData') : "");
  const [referral, setReferral] = useState("");

  const [step, setStep] = useState(0);
  const [OTP, setOTP] = useState("");
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);

  const profile = useSelector((state) => state.profile);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (profile.userData.isFirstTime) {
      navigate("/registration");
    } else if (profile.isLoggedIn) {
      navigate("/");
    }
  }, [profile]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("referral")) {
      setHaveReferral(true);
      setReferral(searchParams.get("referral"));
    }
  }, []);

  useEffect(() => {
    const secondsInterval = setInterval(() => {
      if (remainingSeconds > 0) {
        setRemainingSeconds((prevState) => prevState - 1);
      }
    }, 1000);
    return () => {
      clearInterval(secondsInterval);
    };
  }, [remainingSeconds]);

  const handleSendOTP = async () => {
    if (username) {
      if (isNaN(username)) {
        if (username.includes("@") && username.includes(".")) {
          if (isRemembered) {
            localStorage.setItem('loginData', username);
          }else{
            localStorage.removeItem('loginData');
          }
          
          await sendOTPFunc({ email: username, username });
        } else {
          setIsUsernameError("Please enter a valid email address");
          return;
        }
      } else {
        if (username.length === 10) {
          if (isRemembered) {
            localStorage.setItem('loginData', username);
          }else{
            localStorage.removeItem('loginData');
          }
          await sendOTPFunc({ mobile_number: username, username });
        } else {
          setIsUsernameError("Please enter a valid phone number");
          return;
        }
      }
    } else {
      setIsUsernameError("Please enter a valid phone number or email address");
      return;
    }

    // if (username === "admin@gmail.com" || username === "user@gmail.com") {
    //   setStatus(username.replace("@gmail.com", ""));
    //   setStep(1);
    //   setRemainingSeconds(10);
    // } else {
    //   setIsUsernameError("User not found please try again");
    // }
  };

  const sendOTPFunc = async (data) => {
    if (haveReferral) {
      data.referred_by = referral;
    }
    try {
      setIsLoginLoading(true);
      const response = await sendOTP(data);
      setIsLoginLoading(false);
      if (response.status === "success") {
        setRemainingSeconds(30);
        setStep(1);
      } else {
        setIsUsernameError(response.message);
      }
    } catch (e) {
      setIsLoginLoading(false);
      console.log(e);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      if (OTP !== "" || OTP.length < 4) {
        setIsVerifyLoading(true);

        const response = await login({
          to: username,
          code: Number(OTP),
          username,
        });
        setIsVerifyLoading(false);

        if (response.status === "success") {
          if (response.user.isBlocked) {
            showToast(
              "You have been blocked by admin, please contact support for more info.",
              "error"
            );
            return;
          }
          if (isNaN(username)) {
            dispatch(
              loginUser({
                ...response.user,
                tokens: { ...response.tokens },
                isFirstTime: response.isFirstTime,
              })
            );
          } else {
            dispatch(
              loginUser({
                ...response.user,
                tokens: { ...response.tokens },
                isFirstTime: response.isFirstTime,
              })
            );
          }
          if (response.isFirstTime) {
            navigate("/registration");
          } else {
            if (state?.navigateTo) {
              window.location.pathname = state?.navigateTo;
            } else {
              // navigate("/");
              // window.location.reload();
              window.location.pathname = "/";
            }
          }
        } else {
          setIsOTPError(true);
        }
      } else {
        setIsOTPError(true);
      }
    } catch (e) {
      setIsVerifyLoading(false);
      console.log(e);
    }
  };

  const loginUsingGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // const userInfo = await axios
      //   .get("https://www.googleapis.com/oauth2/v3/userinfo", {
      //     headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      //   })
      //   .then((res) => res.data);

      const response = await login({
        isGoogle: tokenResponse.access_token,
      });
      if (response.status === "success") {
        if (isNaN(username)) {
          dispatch(
            loginUser({
              ...response.user,
              tokens: { ...response.tokens },
              isFirstTime: response.isFirstTime,
            })
          );
        } else {
          dispatch(
            loginUser({
              ...response.user,
              tokens: { ...response.tokens },
              isFirstTime: response.isFirstTime,
            })
          );
        }
        if (response.isFirstTime) {
          navigate("/registration");
        } else {
          if (state?.navigateTo) {
            window.location.pathname = state?.navigateTo;
          } else {
            // navigate("/");
            // window.location.reload();
            window.location.pathname = "/";
          }
        }
      } else {
      }
      // dispatch(
      //   loginUser({
      //     name: userInfo.name,
      //     email: userInfo.email,
      //   })
      // );
      // navigate("/registration");
    },
  });

  return (
    <div className="bg-[#F9FCFF] min-h-screen">
      <Header />
      <div className="w-full flex justify-center items-center h-[calc(100vh-80px)]">
        <div className="max-w-[700px] mx-[24px]">
          {step === 0 ? (
            <>
              <h1 className="text-[24px] md:text-[40px] font-bold text-center">
                Log in to your account
              </h1>
              <h5 className="text-[16px] md:text-[18px] text-secondaryText mt-[16px] text-center font-medium">
                Welcome! Please enter your details.
              </h5>
              <div className="mt-[40px]">
                <div>
                  <label
                    htmlFor="usernamenumber"
                    className="text-labelText text-[14px] font-medium"
                  >
                    Phone number or Email ID
                  </label>
                  <input
                    id="usernamenumber"
                    value={username}
                    onChange={(e) => {
                      setIsUsernameError(false);
                      setUsername(e.target.value);
                    }}
                    type="text"
                    className={`w-full bg-white px-[16px] py-[10px] border-[1px] outline-none rounded-regular text-inputText my-[8px] text-[16px] ${
                      isUsernameError
                        ? "border-errorColor"
                        : "border-inputBorder"
                    }`}
                  />
                </div>
                {isUsernameError && (
                  <p className="text-errorColor text-[14px] mb-[8px]">
                    {isUsernameError}
                  </p>
                )}
                {haveReferral && (
                  <div>
                    <label
                      htmlFor="referral"
                      className="text-labelText text-[14px] font-medium"
                    >
                      Referral
                    </label>
                    <input
                      id="referral"
                      value={referral}
                      onChange={(e) => {
                        setReferral(e.target.value);
                      }}
                      type="text"
                      className={`w-full bg-white px-[16px] py-[10px] border-[1px] outline-none rounded-regular text-inputText my-[8px] text-[16px] border-inputBorder`}
                    />
                  </div>
                )}

                <div className="flex justify-between">
                  <div className="mt-[16px] flex items-center">
                    <input
                      checked={isRemembered}
                      onChange={() =>
                        setIsRemembered((prevState) => !prevState)
                      }
                      type="checkbox"
                      id="remembercheckbox"
                      className="cursor-pointer"
                    />{" "}
                    <label
                      htmlFor="remembercheckbox"
                      className="ml-[11px] text-[14px] text-labelText font-medium cursor-pointer"
                    >
                      Remember me
                    </label>
                  </div>
                  {/* <div className="mt-[16px] flex items-center">
                    <input
                      checked={haveReferral}
                      onChange={() =>
                        setHaveReferral((prevState) => !prevState)
                      }
                      type="checkbox"
                      id="havereferralcheckbox"
                      className="cursor-pointer"
                    />{" "}
                    <label
                      htmlFor="havereferralcheckbox"
                      className="ml-[11px] text-[14px] text-labelText font-medium cursor-pointer"
                    >
                      Have a referral
                    </label>
                  </div> */}
                </div>
              </div>
              <p className="mt-[32px] text-labelText text-[14px] font-medium text-center">
                By continuing, you agree to{" "}
                <Link className="text-primaryColor" to="/terms&conditions">
                  Terms of Use
                </Link>{" "}
                and{" "}
                <Link className="text-primaryColor" to="/privacypolicy">
                  Privacy Policy
                </Link>{" "}
              </p>
              <button
                disabled={isLoginLoading}
                onClick={handleSendOTP}
                className="w-full mt-[36px] bg-primaryColor p-[12px] rounded-regular text-white font-medium text-[16px] flex items-center justify-center gap-[12px]"
              >
                <Oval
                  height={20}
                  width={20}
                  color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={isLoginLoading}
                  ariaLabel="oval-loading"
                  secondaryColor="#f8f8f8f"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
                Send OTP
              </button>
              <button
                className="w-full mt-[16px] bg-white p-[12px] rounded-regular text-labelText font-medium text-[16px] border-[1px] border-inputBorder flex justify-center items-center"
                onClick={() => loginUsingGoogle()}
              >
                <img src={googleLogo} alt="googlelogo" className="mr-[12px]" />{" "}
                Sign in with Google
              </button>
            </>
          ) : (
            <OTPVerification
              isVerifyLoading={isVerifyLoading}
              OTP={OTP}
              setOTP={setOTP}
              isOTPError={isOTPError}
              setIsOTPError={setIsOTPError}
              username={username}
              setStep={setStep}
              remainingSeconds={remainingSeconds}
              handleVerifyOTP={handleVerifyOTP}
              handleSendOTP={handleSendOTP}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
