import { Icon } from "@iconify/react";
import { toast } from "react-toastify";

export const showToast = (text, type, actionsButtons) => {
  toast(
    <>
      <div className="flex gap-[18px]">
        {type === "error" ? (
          <Icon icon="gridicons:cross-circle" color="#C62F39" width={32} />
        ) : (
          <Icon
            icon="material-symbols:check-circle"
            color="#58B455"
            width={32}
          />
        )}
        <h2 className="text-[16px] text-white font-semibold">{text}</h2>{" "}
      </div>
      {actionsButtons}
    </>,
    {
      toastId: Math.random(),
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      // closeOnClick: true,
      closeButton: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
    }
  );
};
