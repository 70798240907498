export function numDifferentiation(value) {
  var val = Math.abs(value);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(0) + " Crore";
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(0) + " Lakh";
  }
  return val;
}

export function fuzzyMatch(pattern, str) {
  pattern = ".*" + pattern.split("").join(".*") + ".*";
  const re = new RegExp(pattern);
  return re.test(str);
}
