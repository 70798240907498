import React from "react";
import HomeHeader from "../components/Header/HomeHeader";
import Footer from "../components/Footer";

const TermsConditions = () => {
  return (
    <>
      <div className="bg-white mx-auto max-w-screen-xl px-[24px]">
        <HomeHeader isHome={false} />
        <div className="my-[24px]">
          <h1>Terms and Conditions for Rempo</h1>
          <h4>
            These Terms and Conditions ("Terms") govern your access to and use
            of Rempo.in Please read these Terms carefully before using the
            Website. By accessing or using the Website, you agree to be bound by
            these Terms. If you do not agree to these Terms, you may not use the
            Website.
          </h4>
        </div>
        <ol type="1" className="list-decimal">
          <li className="text-[20px] font-medium mt-[24px]">
            Acceptance of Terms
          </li>
          <ol className="list-type-abc">
            <li className="my-[8px]">
              By using the Website, you acknowledge that you have read,
              understood, and agree to be bound by these Terms. If you do not
              agree with any of these Terms, please refrain from using the
              Website.
            </li>
            <li className="my-[8px]">
              The User undertakes to be bound by the Agreement each time the
              User accesses the Site, completes the registration process
            </li>
            <li className="my-[8px]">
              The User represents and warrants to Rempo that the User is 18
              (eighteen) years of age or above, and is capable of entering into,
              performing and adhering to the Agreement. Individuals under the
              age of 18 (eighteen) may access the Site or avail a Service only
              with the involvement, guidance, supervision and/or prior consent
              of their parents and/or legal guardians, through the Account of or
              under the guidance of such parent/ legal guardian. Rempo shall not
              be responsible for verifying if the User is atleast 18 (eighteen)
              years of age.
            </li>
          </ol>
          <li className="text-[20px] font-medium mt-[24px]">User Conduct</li>
          <ol className="list-type-abc">
            <li className="my-[8px]">
              You are solely responsible for your use of the Website and agree
              to use it in accordance with all applicable laws and regulations.
            </li>
            <li className="my-[8px]">You agree not to</li>
            <ul>
              <li>Use the Website for any unlawful or unauthorized purpose</li>
              <li>
                mpersonate any person or entity, or falsely state or otherwise
                misrepresent yourself, your affiliation with any person or
                entity, or your qualifications;
              </li>

              <li>
                Interfere or disrupt the operation of the Website or any servers
                or networks connected to the Website
              </li>

              <li>
                Engage in any conduct that could damage, disable, or overburden
                the Website or its servers, or any network(s) connected to the
                Website;
              </li>
              <li>
                Upload, post, or transmit any malicious code, viruses, worms, or
                any other software intended to damage or alter the functionality
                of the Website
              </li>
              <li>
                Collect or store personal information of other users without
                their consent
              </li>
              <li>
                Use the Website to transmit any unauthorized advertising,
                promotional materials, or any other form of solicitation
              </li>
              <li>Violate any applicable laws or regulations.</li>
            </ul>
            <li className="my-[8px]">SUBSCRIPTION PLANS</li>
            <ul>
              <li>
                Rempo offers a combination of Services which the User may
                subscribe to, subject to the need of the User, upon payment of
                applicable fees.
              </li>
              <li>
                The subscription fee paid by the User is non-refundable except
                when the User selects the Moneyback Plan as detailed on the
                Site.
              </li>

              <li>
                Rempo reserves the right to revise the fee of any subscription
                plan without notice to the User at any time prior to, at the
                time of, during the tenor or post subscription of the plan by
                the User. Rempo shall intimate the User of such revised fees and
                the User undertakes to pay to Rempo the difference in the amount
                after such revision.
              </li>
            </ul>
          </ol>
          <li className="text-[20px] font-medium mt-[24px]">
            Intellectual Property
          </li>
          <ol className="list-type-abc">
            <li className="my-[8px]">
              The content on the Website, including but not limited to text,
              images, graphics, logos, videos, and software, is protected by
              copyright and other intellectual property rights.
            </li>
            <li className="my-[8px]">
              You may not modify, reproduce, distribute, or create derivative
              works based on any content from the Website without obtaining
              prior written consent from the respective owners.
            </li>
          </ol>
          <li className="text-[20px] font-medium mt-[24px]">
            Third-Party Links
          </li>
          <p>
            The Website may contain links to third-party websites or resources.
            These links are provided for your convenience and do not signify
            endorsement or responsibility for the content or practices of these
            third-party websites. You acknowledge and agree that we are not
            liable for any damages or loss arising from your use of these
            third-party websites.
          </p>
          <li className="text-[20px] font-medium mt-[24px]">
            Disclaimer of Warranties
          </li>
          <ol className="list-type-abc">
            <li className="my-[8px]">
              The Website is provided on an "as is" and "as available" basis. We
              make no warranties, express or implied, regarding the accuracy,
              completeness, reliability, or availability of the information
              provided on the Website.
            </li>
            <li className="my-[8px]">
              We do not warrant that the Website will be uninterrupted,
              error-free, or free of viruses or other harmful components.
            </li>
          </ol>

          <li className="text-[20px] font-medium mt-[24px]">
            Limitation of Liability
          </li>
          <ol className="list-type-abc">
            <li className="my-[8px]">
              To the fullest extent permitted by applicable law, we shall not be
              liable for any direct, indirect, incidental, special,
              consequential, or exemplary damages, including but not limited to,
              damages for loss of profits, goodwill, use, data, or other
              intangible losses arising out of or in connection with your use of
              the Website.
            </li>
            <li className="my-[8px]">
              We shall not be liable for any errors or omissions in the content
              on the Website.
            </li>
          </ol>
          <li className="text-[20px] font-medium mt-[24px]">Indemnification</li>
          <p>
            You agree to indemnify and hold us harmless from any claims,
            damages, liabilities, costs, or expenses (including reasonable
            attorneys' fees) arising out of or in connection with your use of
            the Website or any violation of these Terms.
          </p>
          <li className="text-[20px] font-medium mt-[24px]">
            Modifications to the Terms
          </li>
          <p>
            We reserve the right to modify or update these Terms at any time
            without prior notice. Any changes will be effective immediately upon
            posting on the Website. Your continued use of the Website after any
            modifications or updates will constitute your acceptance of the
            revised Terms.
          </p>

          <li className="text-[20px] font-medium mt-[24px]">
            REAL ESTATE REGULATORY AUTHORITY
          </li>
          <ol className="list-type-abc">
            <li className="my-[8px]">
              For the purposes of this Agreement and Services related thereto,
              it shall be deemed that the Users are aware about the rules and
              regulations in relation to the Real Estate Regulatory Authority
              (RERA) of the concerned state in India. Rempo recommends that the
              Users periodically visit the concerned RERA website for updates
              and information in relation to any property listed on the Site,
              prior to finalizing any deal or transaction.
            </li>
            <li className="my-[8px]">
              Rempo does not endorse or advertise any property listed on the
              Site and it shall not be held liable for listing any property on
              its Site where such property is in violation of the rules made
              under RERA of the concerned state. Further, it shall not be deemed
              by the Users that Rempo has any connection or relation with the
              parties listing their properties on the Site.
            </li>
          </ol>

          <li className="text-[20px] font-medium mt-[24px]">Arbitration</li>
          <p>
            If any dispute arises between a user/users and Rempo arising out of
            use of the website or thereafter, in connection with the validity,
            interpretation, implementation or alleged breach of any provision of
            these terms and conditions, the dispute shall be referred to a sole
            Arbitrator who shall be an independent and neutral third party.
            Decision of the Arbitrator shall be final and binding on both the
            parties to the dispute. The place of arbitration shall be Delhi. The
            Arbitration & Conciliation Act, 1996, shall govern the arbitration
            proceedings.
          </p>
        </ol>

        <h3 className="mt-[48px] mb-[12px]">GOVERNING LAW AND JURISDICTION</h3>
        <p>
          Any action, claim, dispute or difference arising out of or in
          connection with this Agreement, including any question regarding its
          existence, validity or termination (Dispute) shall be governed by and
          construed in accordance with the laws of India and the courts in Pune
          shall have exclusive jurisdiction over Disputes arising out of this
          Agreement. Notwithstanding anything contained herein, Rempo shall not
          be restricted or withheld from instituting proceedings in courts/
          tribunals of any jurisdiction other than Pune that it may in its sole
          discretion deem appropriate and convenient.
        </p>

        <h3 className="mt-[48px] mb-[12px]">Severability</h3>
        <p>
          If any provision of these Terms is found to be invalid or
          unenforceable, the remaining provisions shall remain in full force and
          effect.
          <br />
          By using the Website, you acknowledge that you have read, understood,
          and agree to be bound by these Terms.
        </p>

        <h3 className="mt-[48px] mb-[12px]">DISCLAIMER</h3>
        <ul>
          <li>
            The information and opinions provided on the Site regarding the
            Services are intended as general guidelines and do not constitute an
            offer or recommendation from Rempo.{" "}
          </li>{" "}
          <li>
            The Content on the Site should not be considered as legal,
            financial, or real estate advice. Users are advised to conduct
            independent research and seek professional advice before making any
            legal, financial, or real estate decisions. Rempo is not responsible
            for any errors, omissions, or misunderstandings in the measurements,
            prices, or locations provided on the Site.
          </li>{" "}
          <li>
            The Services are used at the User's own risk and discretion. Rempo
            makes no express or implied representations or warranties to the
            User regarding the Services or this Agreement, including implied
            warranties of merchantability and fitness for a particular purpose.
          </li>{" "}
          <li>
            Users understand that they assume certain risks when interacting
            with other Users, individuals, or Third-Party Service Providers on
            the Site, and they are solely responsible for assessing their
            specific requirements for such interactions.
          </li>{" "}
          <li>
            Rempo disclaims any liability or responsibility for any Content or
            listings posted on the Site by Users or third parties, or for any
            deficiency in service caused by a Third-Party Service Provider.
          </li>{" "}
          <li>
            Rempo does not guarantee the Services or the results obtained from
            using them, nor does it guarantee that the Site will be
            uninterrupted or free from technical errors.
          </li>{" "}
          <li>
            Rempo is not responsible for the accuracy or reliability of any
            third-party reports, market information, studies, analysis, or
            external web links mentioned on the Site.
          </li>{" "}
          <li>
            Rempo is not obligated to physically meet, conduct background
            checks, or police verifications of Users. The terms "genuine
            verified owner" and "genuine verified tenants/buyers" on the Site
            refer to Rempo's algorithm-based process, which prohibits Brokers
            from accessing the Site and using the Services. This process is
            performed to the best of Rempo's ability and has limitations. Rempo
            accepts User Information in good faith and is not responsible for
            verifying the authenticity of Users.
          </li>{" "}
          <li>
            Rempo shall not be liable for any damages, expenses, costs, or
            losses arising from a User's engagement in Prohibited Conduct or
            from availing services of Third-Party Service Providers.
          </li>{" "}
          <li>
            Rempo shall not be held responsible for indemnifying, refunding, or
            reimbursing any User for any loss, damages, or expenses resulting
            from the suspension or deactivation of the User's Account.
          </li>{" "}
          <li>
            Rempo shall not be liable for any third-party fees, costs, charges,
            or expenses incurred by the User for accessing the Site or using the
            Services. Rempo shall not be liable for any disputes or legal
            proceedings between the User and third parties, and such disputes or
            proceedings should be resolved outside the Site without involving
            Rempo.
          </li>{" "}
          <li>
            Rempo does not have access to or control over the use of cookies or
            tracking technologies placed by Third-Party Service Providers on the
            Site. While these parties may provide options to opt out of tailored
            advertisements, Rempo assumes no responsibility or liability for the
            User's receipt or use of such tailored advertisements, even if the
            User opts out of tailored advertising.
          </li>{" "}
          <li>
            Rempo shall not be liable for the non-performance, defective
            performance, or late performance of any Services or its obligations
            under this Agreement if such issues arise due to acts of God, war,
            civil unrest, actions of Governmental Authorities, riots,
            revolutions, fire, floods, strikes, lock-outs, or industrial action.
          </li>{" "}
        </ul>
      </div>

      <Footer />
    </>
  );
};

export default TermsConditions;
