/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllBids } from "../apis/apiClient";
import ExpandedRows from "../components/ExpandedRows";
import Header from "../components/Header/AdminHeader";
import { fetchedClip, interestedClip } from "../components/shared/Clips";
import tableCustomStyles from "../components/shared/tableStyle";
import { Oval } from "react-loader-spinner";
import { fuzzyMatch } from "../utils/sharedFunc";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="px-[12px] py-[10px] border-[1px] border-[#D0D5DD] bg-white rounded-regular flex items-center w-full max-w-[350px] sm:mr-[16px]">
      <Icon
        icon="material-symbols:search"
        color="#858585"
        width="28"
        height="28"
      />
      <input
        id="search"
        type="text"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        className="outline-none text-[16px] font-medium text-[#858585] ml-[12px] w-full"
      />
    </div>
    <button
      className="rounded-regular bg-[#0259DB] py-[10px] px-[24px] text-[16px] text-white font-medium mt-[8px] md:mt-0"
      type="button"
      onClick={onClear}
    >
      Search
    </button>
  </>
);

// const logsData = [
//   {
//     id: 9876543210,
//     buyer: "Balaji Sankaran",
//     seller: "Kalyani Hayre",
//     bid: 3250,
//     isInterested: true,
//     isFetchedInfo: false,
//     expsell: 3000,
//   },
//   {
//     id: 9876543211,
//     buyer: "Narmada Chokshi",
//     seller: "Raju Chahal",
//     bid: 3250,
//     isInterested: false,
//     isFetchedInfo: true,
//     expsell: 3000,
//   },
//   {
//     id: 9876543212,
//     buyer: "Narendra Singh",
//     seller: "K. L.  Mishra",
//     bid: 3250,
//     isInterested: true,
//     isFetchedInfo: true,
//     expsell: 3000,
//   },
//   {
//     id: 9876543213,
//     buyer: "Devika Singhal",
//     seller: "Prabhat Hari",
//     bid: 3250,
//     isInterested: true,
//     isFetchedInfo: false,
//     expsell: 3000,
//   },
//   {
//     id: 9876543214,
//     buyer: "Kirti Datta",
//     seller: "Owais Sastry",
//     bid: "-",
//     isInterested: true,
//     isFetchedInfo: true,
//     expsell: 3000,
//   },
//   {
//     id: 9876543215,
//     buyer: "Balaji Sankaran",
//     seller: "Kalyani Hayre",
//     bid: 3250,
//     isInterested: true,
//     isFetchedInfo: false,
//     expsell: 3000,
//   },
//   {
//     id: 9876543216,
//     buyer: "Narmada Chokshi",
//     seller: "Raju Chahal",
//     bid: 3250,
//     isInterested: false,
//     isFetchedInfo: true,
//     expsell: 3000,
//   },
//   {
//     id: 9876543217,
//     buyer: "Narendra Singh",
//     seller: "K. L.  Mishra",
//     bid: 3250,
//     isInterested: true,
//     isFetchedInfo: true,
//     expsell: 3000,
//   },
//   {
//     id: 9876543218,
//     buyer: "Devika Singhal",
//     seller: "Prabhat Hari",
//     bid: 3250,
//     isInterested: true,
//     isFetchedInfo: false,
//     expsell: 3000,
//   },
//   {
//     id: 9876543219,
//     buyer: "Kirti Datta",
//     seller: "Owais Sastry",
//     bid: "-",
//     isInterested: true,
//     isFetchedInfo: true,
//     expsell: 3000,
//   },
// ];

const Logs = () => {
  const [logsData, setLogsData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isColumnsOpen, setIsColumnsOpen] = useState(false);

  const [isLogsDataLoading, setIsLogsDataLoading] = useState(true);

  const [currentColumns, setCurrentColumns] = useState([
    {
      show: true,
      name: "PUID",
      selector: (row) => row?.plot?.puid,
      width: "200px",
    },
    {
      show: true,
      name: "Buyer’s Info",
      selector: (row) => row.buyer,
      cell: (row) => (
        <span className="flex gap-[8px]">
          {row.user?.name} {row.isInterested && interestedClip}
        </span>
      ),
      width: "250px",
    },
    {
      show: true,
      name: "Seller info",
      selector: (row) => row.plot?.listed_by?.name,
      cell: (row) => (
        <span className="flex gap-[8px]">
          {row.plot?.listed_by?.name} {row.isFetchedInfo && fetchedClip}
        </span>
      ),
      width: "250px",
    },
    {
      show: true,
      name: "Offer Submitted",
      selector: (row) => row.amaount,
      cell: (row) => (
        <span>{row.amount !== "-" ? `₹${row.amount}` : row.amount}</span>
      ),
    },
    {
      show: true,
      name: "Expected Selling",
      selector: (row) => row.plot,
      cell: (row) => <span>{`₹${row.plot?.rate?.rate}`}</span>,
    },
    {
      title: "",
      dataIndex: "",
      key: "expand",
      width: 1,
      show: true,
      cell: (row) => (
        <button
          id={`expandButton-${row.id}`}
          className={`transition ease-in-out delay-10`}
          onClick={() => {
            document
              .querySelectorAll(`[data-testid="expander-button-${row.id}"]`)[0]
              .click();
          }}
        >
          <Icon width="24px" icon="material-symbols:keyboard-arrow-down" />
        </button>
      ),
    },
  ]);

  const profile = useSelector((state) => state.profile);

  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.isLoggedIn) {
      navigate("/login", { state: { navigateTo: "/logs" } });
    }

    document.getElementsByTagName("body")[0].addEventListener("click", (e) => {
      if (e.target.id === "opencolumns") {
        return false;
      }
      setIsColumnsOpen(false);
    });
    return () => {
      document
        .getElementsByTagName("body")[0]
        .removeEventListener("click", () => {});
    };
  }, []);

  useEffect(() => {
    const fetchLogsData = async () => {
      try {
        setIsLogsDataLoading(true);
        const response = await getAllBids();
        setIsLogsDataLoading(false);
        if (response.status === "success") {
          setLogsData(response.bids);
          setFilteredItems(response.bids);
        } else {
          console.log(response);
        }
      } catch (e) {
        console.log(e);
        setIsLogsDataLoading(false);
      }
    };

    fetchLogsData();
  }, []);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText !== "") {
        setFilteredItems((prevState) => {
          return logsData.filter(
            (item) =>
              (item.puid &&
                fuzzyMatch(
                  filterText.toLowerCase(),
                  item.puid.toString().toLowerCase()
                )) ||
              (item.user?.name &&
                fuzzyMatch(
                  filterText.toLowerCase(),
                  item.user?.name.toLowerCase()
                )) ||
              (item.plot?.listed_by?.name &&
                fuzzyMatch(
                  filterText.toLowerCase(),
                  item.plot?.listed_by?.name.toLowerCase()
                ))
          );
        });
      } else {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        setFilteredItems(logsData);
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => {
          setFilterText(e.target.value);
          if (e.target.value === "") {
            setFilteredItems(logsData);
          }
        }}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = currentColumns.filter((column) => column.show);
  const Export = ({ onExport }) => (
    <div className="flex gap-[24px] my-[28px]">
      <button
        onClick={(e) => onExport(e.target.value)}
        className="w-[140px] text-center justify-center flex gap-[10px] py-[10px] px-[26px] rounded-regular border-[1px] border-[#0259DB] text-[16px] text-[#0259DB] items-center"
      >
        Export
      </button>
      <div className="relative z-50">
        <button
          id="opencolumns"
          onClick={() => {
            setIsColumnsOpen((prevState) => !prevState);
          }}
          className={`w-[140px] text-center justify-center flex gap-[10px] py-[10px] px-[26px] rounded-regular border-[1px] bg-white border-[#D0D5DD] text-[16px] text-[#667085] items-center ${
            isColumnsOpen && " shadow-[0px_0px_0px_2px_#D9E8FF]"
          }`}
        >
          <Icon
            width="32px"
            height="28px"
            color="#667085"
            icon="material-symbols:view-column-outline-rounded"
          />
          Column
        </button>
        <ul
          className={`list-none z-50  bg-[#fff] w-full min-w-[240px] rounded-regular  gap-[8px] p-0 overflow-hidden shadow-xl absolute transition duration-100 ease-in-out origin-top-left ${
            isColumnsOpen ? "scale-100" : "scale-0"
          }`}
        >
          {currentColumns.map((column, index) => {
            return (
              column.name && (
                <li
                  onClick={() => {
                    setCurrentColumns((prevState) => {
                      prevState[index] = {
                        ...prevState[index],
                        show: !prevState[index].show,
                      };
                      return prevState;
                    });
                  }}
                  className="py-[10px] px-[16px] text-[#667085] text-[16px] hover:bg-[#F6F6F6] w-full flex justify-between items-center cursor-pointer"
                >
                  {column.name}
                  {column.show && (
                    <Icon
                      icon="material-symbols:done"
                      color="#0259DB"
                      width={20}
                    />
                  )}
                </li>
              )
            );
          })}
        </ul>
      </div>
    </div>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(logsData)} />,
    [isColumnsOpen]
  );

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(logsData[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <div className="bg-[#F9FCFF] min-h-screen">
      <Header />
      <div className="mx-auto max-w-screen-xl pt-[40px] pb-[64px] px-[24px]">
        <h1 className="text-[32px] font-semibold text-[#000E14]">Logs</h1>

        <section className="relative">
          {/* <div className='my-[28px]'></div> */}
          {isLogsDataLoading ? (
            <div className="h-[320px] flex items-center justify-center">
              <Oval
                height={52}
                width={52}
                color="#0259DB"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#0259DB"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <DataTable
              actions={actionsMemo}
              columns={columns}
              data={filteredItems}
              responsive
              striped
              highlightOnHover
              noDataComponent={
                <span className="px-[16px] py-[20px]">{`No data found`}</span>
              }
              expandableRows={{
                expandedRowRender: (record) => <p style={{ margin: 0 }}>H</p>,
              }}
              expandableRowsComponent={ExpandedRows}
              expandableRowsComponentProps={{ currentColumns }}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              customStyles={tableCustomStyles}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              onRowExpandToggled={(isExpanded, data) => {
                if (isExpanded) {
                  document
                    .getElementById(`expandButton-${data.id}`)
                    .classList.add("rotate-180");
                } else {
                  document
                    .getElementById(`expandButton-${data.id}`)
                    .classList.remove("rotate-180");
                }
              }}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default Logs;
