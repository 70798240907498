export const talPincode = [
  {
    srno: 1,
    district: "Kolhapur",
    taluka: "Karvir",
    pin_code: 416010,
  },
  {
    srno: 2,
    district: "Kolhapur",
    taluka: "Panhala",
    pin_code: 416201,
  },
  {
    srno: 3,
    district: "Kolhapur",
    taluka: "Shahuwadi",
    pin_code: 416215,
  },
  {
    srno: 4,
    district: "Kolhapur",
    taluka: "Kagal",
    pin_code: 416216,
  },
  {
    srno: 5,
    district: "Kolhapur",
    taluka: "Hatkanangale",
    pin_code: 416109,
  },
  {
    srno: 6,
    district: "Kolhapur",
    taluka: "Shirol",
    pin_code: 416103,
  },
  {
    srno: 7,
    district: "Kolhapur",
    taluka: "Radhanagari",
    pin_code: 416212,
  },
  {
    srno: 8,
    district: "Kolhapur",
    taluka: "Gaganbawada",
    pin_code: 416206,
  },
  {
    srno: 9,
    district: "Kolhapur",
    taluka: "Bhudargad",
    pin_code: 416209,
  },
  {
    srno: 10,
    district: "Kolhapur",
    taluka: "Gadhinglaj",
    pin_code: 416502,
  },
  {
    srno: 11,
    district: "Kolhapur",
    taluka: "Chandgad",
    pin_code: 416509,
  },
  {
    srno: 12,
    district: "Kolhapur",
    taluka: "Ajra",
    pin_code: 416505,
  },
  {
    srno: 13,
    district: "Sangli",
    taluka: "Miraj",
    pin_code: 416416,
  },
  {
    srno: 14,
    district: "Sangli",
    taluka: "Kavathe-Mahankal",
    pin_code: 413002,
  },
  {
    srno: 15,
    district: "Sangli",
    taluka: "Tasgaon",
    pin_code: 416312,
  },
  {
    srno: 16,
    district: "Sangli",
    taluka: "Jat",
    pin_code: 416404,
  },
  {
    srno: 17,
    district: "Sangli",
    taluka: "Walwa (Islampur)",
    pin_code: 416313,
  },
  {
    srno: 18,
    district: "Sangli",
    taluka: "Shirala",
    pin_code: 415408,
  },
  {
    srno: 19,
    district: "Sangli",
    taluka: "Khanapur-Vita",
    pin_code: 415307,
  },
  {
    srno: 20,
    district: "Sangli",
    taluka: "Atpadi",
    pin_code: 415306,
  },
  {
    srno: 21,
    district: "Sangli",
    taluka: "Palus",
    pin_code: 416310,
  },
  {
    srno: 22,
    district: "Sangli",
    taluka: "Kadegaon",
    pin_code: 415013,
  },
  {
    srno: 23,
    district: "Satara",
    taluka: "Satara",
    pin_code: 415001,
  },
  {
    srno: 24,
    district: "Satara",
    taluka: "Jaoli",
    pin_code: 413608,
  },
  {
    srno: 25,
    district: "Satara",
    taluka: "Koregaon",
    pin_code: 415501,
  },
  {
    srno: 26,
    district: "Satara",
    taluka: "Wai",
    pin_code: 412803,
  },
  {
    srno: 27,
    district: "Satara",
    taluka: "Mahabaleshwar",
    pin_code: 412806,
  },
  {
    srno: 28,
    district: "Satara",
    taluka: "Khandala",
    pin_code: 410301,
  },
  {
    srno: 29,
    district: "Satara",
    taluka: "Phaltan",
    pin_code: 415523,
  },
  {
    srno: 30,
    district: "Satara",
    taluka: "Maan (Dahiwadi)",
    pin_code: 411057,
  },
  {
    srno: 31,
    district: "Satara",
    taluka: "Khatav (Vaduj)",
    pin_code: 415505,
  },
  {
    srno: 32,
    district: "Satara",
    taluka: "Patan",
    pin_code: 415206,
  },
  {
    srno: 33,
    district: "Satara",
    taluka: "Karad",
    pin_code: 415110,
  },
  {
    srno: 34,
    district: "Pune",
    taluka: "Pune City",
    pin_code: 411001,
  },
  {
    srno: 35,
    district: "Pune",
    taluka: "Haveli",
    pin_code: 411023,
  },
  {
    srno: 36,
    district: "Pune",
    taluka: "Khed (Rajgurunagar)",
    pin_code: 415718,
  },
  {
    srno: 37,
    district: "Pune",
    taluka: "Junnar",
    pin_code: 410502,
  },
  {
    srno: 38,
    district: "Pune",
    taluka: "Ambegaon (Ghodegaon)",
    pin_code: 411046,
  },
  {
    srno: 39,
    district: "Pune",
    taluka: "Maval (Vadgaon)",
    pin_code: 410401,
  },
  {
    srno: 40,
    district: "Pune",
    taluka: "Mulshi (Paud)",
    pin_code: 412108,
  },
  {
    srno: 41,
    district: "Pune",
    taluka: "Shirur",
    pin_code: 412210,
  },
  {
    srno: 42,
    district: "Pune",
    taluka: "Purandhar (Saswad)",
    pin_code: 412301,
  },
  {
    srno: 43,
    district: "Pune",
    taluka: "Velhe",
    pin_code: 412212,
  },
  {
    srno: 44,
    district: "Pune",
    taluka: "Bhor",
    pin_code: 412206,
  },
  {
    srno: 45,
    district: "Pune",
    taluka: "Baramati",
    pin_code: 413102,
  },
  {
    srno: 46,
    district: "Pune",
    taluka: "Indapur",
    pin_code: 413106,
  },
  {
    srno: 47,
    district: "Pune",
    taluka: "Daund",
    pin_code: 413801,
  },
  {
    srno: 48,
    district: "Ahmednagar",
    taluka: "Nagar",
    pin_code: 414001,
  },
  {
    srno: 49,
    district: "Ahmednagar",
    taluka: "Shevgaon",
    pin_code: 414502,
  },
  {
    srno: 50,
    district: "Ahmednagar",
    taluka: "Pathardi",
    pin_code: 414102,
  },
  {
    srno: 51,
    district: "Ahmednagar",
    taluka: "Parner",
    pin_code: 414302,
  },
  {
    srno: 52,
    district: "Ahmednagar",
    taluka: "Sangamner",
    pin_code: 422605,
  },
  {
    srno: 53,
    district: "Ahmednagar",
    taluka: "Kopargaon",
    pin_code: 423601,
  },
  {
    srno: 54,
    district: "Ahmednagar",
    taluka: "Akole",
    pin_code: 422601,
  },
  {
    srno: 55,
    district: "Ahmednagar",
    taluka: "Shrirampur",
    pin_code: 413709,
  },
  {
    srno: 56,
    district: "Ahmednagar",
    taluka: "Nevasa",
    pin_code: 414603,
  },
  {
    srno: 57,
    district: "Ahmednagar",
    taluka: "Rahata",
    pin_code: 423107,
  },
  {
    srno: 58,
    district: "Ahmednagar",
    taluka: "Rahuri",
    pin_code: 413705,
  },
  {
    srno: 59,
    district: "Ahmednagar",
    taluka: "Shrigonda",
    pin_code: 413701,
  },
  {
    srno: 60,
    district: "Ahmednagar",
    taluka: "Karjat",
    pin_code: 410201,
  },
  {
    srno: 61,
    district: "Ahmednagar",
    taluka: "Jamkhed",
    pin_code: 413201,
  },
  {
    srno: 62,
    district: "Solapur",
    taluka: "Solapur North",
    pin_code: 413001,
  },
  {
    srno: 63,
    district: "Solapur",
    taluka: "Barshi",
    pin_code: 413401,
  },
  {
    srno: 64,
    district: "Solapur",
    taluka: "Solapur South",
    pin_code: 413002,
  },
  {
    srno: 65,
    district: "Solapur",
    taluka: "Akkalkot",
    pin_code: 413216,
  },
  {
    srno: 66,
    district: "Solapur",
    taluka: "Madha",
    pin_code: 413209,
  },
  {
    srno: 67,
    district: "Solapur",
    taluka: "Karmala",
    pin_code: 413203,
  },
  {
    srno: 68,
    district: "Solapur",
    taluka: "Pandharpur",
    pin_code: 413304,
  },
  {
    srno: 69,
    district: "Solapur",
    taluka: "Mohol",
    pin_code: 413213,
  },
  {
    srno: 70,
    district: "Solapur",
    taluka: "Malshiras",
    pin_code: 413107,
  },
  {
    srno: 71,
    district: "Solapur",
    taluka: "Sangole",
    pin_code: 413307,
  },
  {
    srno: 72,
    district: "Solapur",
    taluka: "Mangalvedhe",
    pin_code: 413305,
  },
  {
    srno: 73,
    district: "Osmanabad",
    taluka: "Osmanabad",
    pin_code: 413501,
  },
  {
    srno: 74,
    district: "Osmanabad",
    taluka: "Tuljapur",
    pin_code: 413601,
  },
  {
    srno: 75,
    district: "Osmanabad",
    taluka: "Bhum",
    pin_code: 413504,
  },
  {
    srno: 76,
    district: "Osmanabad",
    taluka: "Paranda",
    pin_code: 413502,
  },
  {
    srno: 77,
    district: "Osmanabad",
    taluka: "Washi",
    pin_code: 413503,
  },
  {
    srno: 78,
    district: "Osmanabad",
    taluka: "Kalamb",
    pin_code: 413507,
  },
  {
    srno: 79,
    district: "Osmanabad",
    taluka: "Lohara",
    pin_code: 413608,
  },
  {
    srno: 80,
    district: "Osmanabad",
    taluka: "Umarga",
    pin_code: 413606,
  },
  {
    srno: 81,
    district: "Latur",
    taluka: "Latur",
    pin_code: 413512,
  },
  {
    srno: 82,
    district: "Latur",
    taluka: "Renapur",
    pin_code: 413527,
  },
  {
    srno: 83,
    district: "Latur",
    taluka: "Ahmedpur",
    pin_code: 413515,
  },
  {
    srno: 84,
    district: "Latur",
    taluka: "Jalkot",
    pin_code: 413532,
  },
  {
    srno: 85,
    district: "Latur",
    taluka: "Chakur",
    pin_code: 413513,
  },
  {
    srno: 86,
    district: "Latur",
    taluka: "Shirur Anantpal",
    pin_code: 413544,
  },
  {
    srno: 87,
    district: "Latur",
    taluka: "Ausa",
    pin_code: 413520,
  },
  {
    srno: 88,
    district: "Latur",
    taluka: "Nilanga",
    pin_code: 413521,
  },
  {
    srno: 89,
    district: "Latur",
    taluka: "Deoni",
    pin_code: 413521,
  },
  {
    srno: 90,
    district: "Latur",
    taluka: "Udgir",
    pin_code: 413517,
  },
  {
    srno: 91,
    district: "Beed",
    taluka: "Beed",
    pin_code: 431122,
  },
  {
    srno: 92,
    district: "Beed",
    taluka: "Ashti",
    pin_code: 414202,
  },
  {
    srno: 93,
    district: "Beed",
    taluka: "Patoda",
    pin_code: 414204,
  },
  {
    srno: 94,
    district: "Beed",
    taluka: "Shirur-Kasar",
    pin_code: 412210,
  },
  {
    srno: 95,
    district: "Beed",
    taluka: "Georai",
    pin_code: 431127,
  },
  {
    srno: 96,
    district: "Beed",
    taluka: "Majalgaon",
    pin_code: 431131,
  },
  {
    srno: 97,
    district: "Beed",
    taluka: "Wadwani",
    pin_code: 431144,
  },
  {
    srno: 98,
    district: "Beed",
    taluka: "Kaij",
    pin_code: 431123,
  },
  {
    srno: 99,
    district: "Beed",
    taluka: "Dharur",
    pin_code: 431124,
  },
  {
    srno: 100,
    district: "Beed",
    taluka: "Parli",
    pin_code: 431515,
  },
  {
    srno: 101,
    district: "Beed",
    taluka: "Ambejogai",
    pin_code: 431517,
  },
  {
    srno: 102,
    district: "Aurangabad",
    taluka: "Aurangabad",
  },
  {
    srno: 103,
    district: "Aurangabad",
    taluka: "Kannad",
    pin_code: 431103,
  },
  {
    srno: 104,
    district: "Aurangabad",
    taluka: "Soegaon",
    pin_code: 431150,
  },
  {
    srno: 105,
    district: "Aurangabad",
    taluka: "Sillod",
    pin_code: 431112,
  },
  {
    srno: 106,
    district: "Aurangabad",
    taluka: "Phulambri",
    pin_code: 431111,
  },
  {
    srno: 107,
    district: "Aurangabad",
    taluka: "Khuldabad",
    pin_code: 431102,
  },
  {
    srno: 108,
    district: "Aurangabad",
    taluka: "Vaijapur",
    pin_code: 423701,
  },
  {
    srno: 109,
    district: "Aurangabad",
    taluka: "Gangapur",
    pin_code: 431109,
  },
  {
    srno: 110,
    district: "Aurangabad",
    taluka: "Paithan",
    pin_code: 431107,
  },
  {
    srno: 111,
    district: "Jalna",
    taluka: "Jalna",
    pin_code: 431203,
  },
  {
    srno: 112,
    district: "Jalna",
    taluka: "Bhokardan",
    pin_code: 431114,
  },
  {
    srno: 113,
    district: "Jalna",
    taluka: "Jafrabad",
    pin_code: 431206,
  },
  {
    srno: 114,
    district: "Jalna",
    taluka: "Badnapur",
    pin_code: 431202,
  },
  {
    srno: 115,
    district: "Jalna",
    taluka: "Ambad",
    pin_code: 431204,
  },
  {
    srno: 116,
    district: "Jalna",
    taluka: "Ghansawangi",
    pin_code: 431209,
  },
  {
    srno: 117,
    district: "Jalna",
    taluka: "Partur",
    pin_code: 431501,
  },
  {
    srno: 118,
    district: "Jalna",
    taluka: "Mantha",
    pin_code: 431504,
  },
  {
    srno: 119,
    district: "Parbhani",
    taluka: "Parbhani",
    pin_code: 431401,
  },
  {
    srno: 120,
    district: "Parbhani",
    taluka: "Sonpeth",
    pin_code: 431516,
  },
  {
    srno: 121,
    district: "Parbhani",
    taluka: "Gangakhed",
    pin_code: 431514,
  },
  {
    srno: 122,
    district: "Parbhani",
    taluka: "Palam",
    pin_code: 110045,
  },
  {
    srno: 123,
    district: "Parbhani",
    taluka: "Purna",
    pin_code: 431511,
  },
  {
    srno: 124,
    district: "Parbhani",
    taluka: "Sailu",
    pin_code: 431503,
  },
  {
    srno: 125,
    district: "Parbhani",
    taluka: "Jintur",
    pin_code: 431509,
  },
  {
    srno: 126,
    district: "Parbhani",
    taluka: "Manwath",
    pin_code: 431505,
  },
  {
    srno: 127,
    district: "Parbhani",
    taluka: "Pathri",
    pin_code: 431506,
  },
  {
    srno: 128,
    district: "Hingoli",
    taluka: "Hingoli",
    pin_code: 431513,
  },
  {
    srno: 129,
    district: "Hingoli",
    taluka: "Sengaon",
    pin_code: 431542,
  },
  {
    srno: 130,
    district: "Hingoli",
    taluka: "Kalamnuri",
    pin_code: 431702,
  },
  {
    srno: 131,
    district: "Hingoli",
    taluka: "Basmath",
    pin_code: 431512,
  },
  {
    srno: 132,
    district: "Hingoli",
    taluka: "Aundha Nagnath",
    pin_code: 411007,
  },
  {
    srno: 133,
    district: "Nanded",
    taluka: "Nanded",
    pin_code: 431601,
  },
  {
    srno: 134,
    district: "Nanded",
    taluka: "Ardhapur",
    pin_code: 431704,
  },
  {
    srno: 135,
    district: "Nanded",
    taluka: "Mudkhed",
    pin_code: 431806,
  },
  {
    srno: 136,
    district: "Nanded",
    taluka: "Bhokar",
    pin_code: 431801,
  },
  {
    srno: 137,
    district: "Nanded",
    taluka: "Umri",
    pin_code: 431807,
  },
  {
    srno: 138,
    district: "Nanded",
    taluka: "Loha",
    pin_code: 431708,
  },
  {
    srno: 139,
    district: "Nanded",
    taluka: "Kandhar",
    pin_code: 431714,
  },
  {
    srno: 140,
    district: "Nanded",
    taluka: "Kinwat",
    pin_code: 431804,
  },
  {
    srno: 141,
    district: "Nanded",
    taluka: "Himayatnagar",
    pin_code: 431802,
  },
  {
    srno: 142,
    district: "Nanded",
    taluka: "Hadgaon",
    pin_code: 431717,
  },
  {
    srno: 143,
    district: "Nanded",
    taluka: "Mahur",
    pin_code: 431721,
  },
  {
    srno: 144,
    district: "Nanded",
    taluka: "Deglur",
    pin_code: 431717,
  },
  {
    srno: 145,
    district: "Nanded",
    taluka: "Mukhed",
    pin_code: 431715,
  },
  {
    srno: 146,
    district: "Nanded",
    taluka: "Dharmabad",
    pin_code: 431809,
  },
  {
    srno: 147,
    district: "Nanded",
    taluka: "Biloli",
    pin_code: 431710,
  },
  {
    srno: 148,
    district: "Nanded",
    taluka: "Naigaon (Khairgaon)",
    pin_code: 441303,
  },
  {
    srno: 149,
    district: "Yavatmal",
    taluka: "Yavatmal",
    pin_code: 445500,
  },
  {
    srno: 150,
    district: "Yavatmal",
    taluka: "Arni",
    pin_code: 445103,
  },
  {
    srno: 151,
    district: "Yavatmal",
    taluka: "Babhulgaon",
    pin_code: 445101,
  },
  {
    srno: 152,
    district: "Yavatmal",
    taluka: "Kalamb",
    pin_code: 445401,
  },
  {
    srno: 153,
    district: "Yavatmal",
    taluka: "Darwha",
    pin_code: 445202,
  },
  {
    srno: 154,
    district: "Yavatmal",
    taluka: "Digras",
    pin_code: 445203,
  },
  {
    srno: 155,
    district: "Yavatmal",
    taluka: "Ner",
    pin_code: 445102,
  },
  {
    srno: 156,
    district: "Yavatmal",
    taluka: "Pusad",
    pin_code: 445204,
  },
  {
    srno: 157,
    district: "Yavatmal",
    taluka: "Umarkhed",
    pin_code: 445206,
  },
  {
    srno: 158,
    district: "Yavatmal",
    taluka: "Mahagaon",
    pin_code: 445205,
  },
  {
    srno: 159,
    district: "Yavatmal",
    taluka: "Kelapur (Pandharkawada)",
    pin_code: 445302,
  },
  {
    srno: 160,
    district: "Yavatmal",
    taluka: "Ralegaon",
    pin_code: 445402,
  },
  {
    srno: 161,
    district: "Yavatmal",
    taluka: "Ghatanji",
    pin_code: 445402,
  },
  {
    srno: 162,
    district: "Yavatmal",
    taluka: "Wani",
    pin_code: 445304,
  },
  {
    srno: 163,
    district: "Yavatmal",
    taluka: "Maregaon",
    pin_code: 445303,
  },
  {
    srno: 164,
    district: "Yavatmal",
    taluka: "Zari Jamani",
    pin_code: 445305,
  },
  {
    srno: 165,
    district: "Chandrapur",
    taluka: "Chandrapur",
    pin_code: 442401,
  },
  {
    srno: 166,
    district: "Chandrapur",
    taluka: "Saoli",
    pin_code: 441225,
  },
  {
    srno: 167,
    district: "Chandrapur",
    taluka: "Mul",
    pin_code: 441224,
  },
  {
    srno: 168,
    district: "Chandrapur",
    taluka: "Ballarpur",
    pin_code: 442701,
  },
  {
    srno: 169,
    district: "Chandrapur",
    taluka: "Pombhurna",
    pin_code: 441224,
  },
  {
    srno: 170,
    district: "Chandrapur",
    taluka: "Gondpimpri",
    pin_code: 442903,
  },
  {
    srno: 171,
    district: "Chandrapur",
    taluka: "Warora",
    pin_code: 442907,
  },
  {
    srno: 172,
    district: "Chandrapur",
    taluka: "Chimur",
    pin_code: 442903,
  },
  {
    srno: 173,
    district: "Chandrapur",
    taluka: "Bhadravati",
    pin_code: 442902,
  },
  {
    srno: 174,
    district: "Chandrapur",
    taluka: "Bramhapuri",
    pin_code: 441206,
  },
  {
    srno: 175,
    district: "Chandrapur",
    taluka: "Nagbhid",
    pin_code: 441205,
  },
  {
    srno: 176,
    district: "Chandrapur",
    taluka: "Sindewahi",
    pin_code: 441222,
  },
  {
    srno: 177,
    district: "Chandrapur",
    taluka: "Rajura",
    pin_code: 442905,
  },
  {
    srno: 178,
    district: "Chandrapur",
    taluka: "Korpana",
    pin_code: 442916,
  },
  {
    srno: 179,
    district: "Chandrapur",
    taluka: "Jivati",
    pin_code: 442908,
  },
  {
    srno: 180,
    district: "Gadchiroli",
    taluka: "Gadchiroli",
    pin_code: 442605,
  },
  {
    srno: 181,
    district: "Gadchiroli",
    taluka: "Dhanora",
    pin_code: 442606,
  },
  {
    srno: 182,
    district: "Gadchiroli",
    taluka: "Chamorshi",
    pin_code: 442603,
  },
  {
    srno: 183,
    district: "Gadchiroli",
    taluka: "Mulchera",
    pin_code: 442707,
  },
  {
    srno: 184,
    district: "Gadchiroli",
    taluka: "Desaiganj (Vadasa)",
    pin_code: 441207,
  },
  {
    srno: 185,
    district: "Gadchiroli",
    taluka: "Armori",
    pin_code: 441208,
  },
  {
    srno: 186,
    district: "Gadchiroli",
    taluka: "Kurkheda",
    pin_code: 442605,
  },
  {
    srno: 187,
    district: "Gadchiroli",
    taluka: "Korchi",
    pin_code: 441209,
  },
  {
    srno: 188,
    district: "Gadchiroli",
    taluka: "Aheri",
    pin_code: 442705,
  },
  {
    srno: 189,
    district: "Gadchiroli",
    taluka: "Bhamragad",
    pin_code: 442710,
  },
  {
    srno: 190,
    district: "Gadchiroli",
    taluka: "Sironcha",
    pin_code: 442504,
  },
  {
    srno: 191,
    district: "Gondia",
    taluka: "Gondia",
    pin_code: 441601,
  },
  {
    srno: 192,
    district: "Gondia",
    taluka: "Tirora",
    pin_code: 441911,
  },
  {
    srno: 193,
    district: "Gondia",
    taluka: "Goregaon",
    pin_code: 400063,
  },
  {
    srno: 194,
    district: "Gondia",
    taluka: "Arjuni Morgaon",
    pin_code: 441701,
  },
  {
    srno: 195,
    district: "Gondia",
    taluka: "Amgaon",
    pin_code: 441902,
  },
  {
    srno: 196,
    district: "Gondia",
    taluka: "Salekasa",
    pin_code: 441916,
  },
  {
    srno: 197,
    district: "Gondia",
    taluka: "Sadak Arjuni",
    pin_code: 441807,
  },
  {
    srno: 198,
    district: "Gondia",
    taluka: "Deori",
    pin_code: 441901,
  },
  {
    srno: 199,
    district: "Bhandara",
    taluka: "Bhandara",
    pin_code: 441201,
  },
  {
    srno: 200,
    district: "Bhandara",
    taluka: "Tumsar",
    pin_code: 441912,
  },
  {
    srno: 201,
    district: "Bhandara",
    taluka: "Pauni",
    pin_code: 441910,
  },
  {
    srno: 202,
    district: "Bhandara",
    taluka: "Mohadi",
    pin_code: 441909,
  },
  {
    srno: 203,
    district: "Bhandara",
    taluka: "Sakoli",
    pin_code: 441802,
  },
  {
    srno: 204,
    district: "Bhandara",
    taluka: "Lakhni",
    pin_code: 441804,
  },
  {
    srno: 205,
    district: "Bhandara",
    taluka: "Lakhandur",
    pin_code: 441803,
  },
  {
    srno: 206,
    district: "Nagpur",
    taluka: "Nagpur (Urban)",
    pin_code: 440002,
  },
  {
    srno: 207,
    district: "Nagpur",
    taluka: "Nagpur (Rural)",
    pin_code: 440002,
  },
  {
    srno: 208,
    district: "Nagpur",
    taluka: "Kamptee",
    pin_code: 440002,
  },
  {
    srno: 209,
    district: "Nagpur",
    taluka: "Hingna",
    pin_code: 440002,
  },
  {
    srno: 210,
    district: "Nagpur",
    taluka: "Katol",
    pin_code: 440002,
  },
  {
    srno: 211,
    district: "Nagpur",
    taluka: "Narkhed",
    pin_code: 440002,
  },
  {
    srno: 212,
    district: "Nagpur",
    taluka: "Savner",
    pin_code: 440002,
  },
  {
    srno: 213,
    district: "Nagpur",
    taluka: "Kalameshwar",
    pin_code: 440002,
  },
  {
    srno: 214,
    district: "Nagpur",
    taluka: "Ramtek",
    pin_code: 440002,
  },
  {
    srno: 215,
    district: "Nagpur",
    taluka: "Mouda",
    pin_code: 440002,
  },
  {
    srno: 216,
    district: "Nagpur",
    taluka: "Parseoni",
    pin_code: 440002,
  },
  {
    srno: 217,
    district: "Nagpur",
    taluka: "Umred",
    pin_code: 440002,
  },
  {
    srno: 218,
    district: "Nagpur",
    taluka: "Kuhi",
    pin_code: 440002,
  },
  {
    srno: 219,
    district: "Nagpur",
    taluka: "Bhiwapur",
    pin_code: 440002,
  },
  {
    srno: 220,
    district: "Wardha",
    taluka: "Wardha",
    pin_code: 442001,
  },
  {
    srno: 221,
    district: "Wardha",
    taluka: "Deoli",
    pin_code: 442101,
  },
  {
    srno: 222,
    district: "Wardha",
    taluka: "Seloo",
    pin_code: 442104,
  },
  {
    srno: 223,
    district: "Wardha",
    taluka: "Arvi",
    pin_code: 442201,
  },
  {
    srno: 224,
    district: "Wardha",
    taluka: "Ashti",
    pin_code: 414203,
  },
  {
    srno: 225,
    district: "Wardha",
    taluka: "Karanja",
    pin_code: 444105,
  },
  {
    srno: 226,
    district: "Wardha",
    taluka: "Hinganghat",
    pin_code: 442301,
  },
  {
    srno: 227,
    district: "Wardha",
    taluka: "Samudrapur",
    pin_code: 442305,
  },
  {
    srno: 228,
    district: "Amravati",
    taluka: "Amravati",
    pin_code: 444601,
  },
  {
    srno: 229,
    district: "Amravati",
    taluka: "Bhatukali",
    pin_code: 444602,
  },
  {
    srno: 230,
    district: "Amravati",
    taluka: "Nandgaon Khandeshwar",
    pin_code: 444708,
  },
  {
    srno: 231,
    district: "Amravati",
    taluka: "Dharni (Amravati)",
    pin_code: 444702,
  },
  {
    srno: 232,
    district: "Amravati",
    taluka: "Chikhaldara",
    pin_code: 444807,
  },
  {
    srno: 233,
    district: "Amravati",
    taluka: "Achalpur",
    pin_code: 444806,
  },
  {
    srno: 234,
    district: "Amravati",
    taluka: "Chandurbazar",
    pin_code: 444704,
  },
  {
    srno: 235,
    district: "Amravati",
    taluka: "Morshi",
    pin_code: 444905,
  },
  {
    srno: 236,
    district: "Amravati",
    taluka: "Warud",
    pin_code: 444906,
  },
  {
    srno: 237,
    district: "Amravati",
    taluka: "Daryapur",
    pin_code: 444803,
  },
  {
    srno: 238,
    district: "Amravati",
    taluka: "Anjangaon-Surji",
    pin_code: 444705,
  },
  {
    srno: 239,
    district: "Amravati",
    taluka: "Chandur",
    pin_code: 444904,
  },
  {
    srno: 240,
    district: "Amravati",
    taluka: "Dhamangaon",
    pin_code: 444707,
  },
  {
    srno: 241,
    district: "Amravati",
    taluka: "Tiosa",
    pin_code: 444903,
  },
  {
    srno: 242,
    district: "Washim",
    taluka: "Washim",
    pin_code: 444505,
  },
  {
    srno: 243,
    district: "Washim",
    taluka: "Malegaon",
    pin_code: 423203,
  },
  {
    srno: 244,
    district: "Washim",
    taluka: "Risod",
    pin_code: 444506,
  },
  {
    srno: 245,
    district: "Washim",
    taluka: "Mangrulpir",
    pin_code: 444403,
  },
  {
    srno: 246,
    district: "Washim",
    taluka: "Karanja",
    pin_code: 444105,
  },
  {
    srno: 247,
    district: "Washim",
    taluka: "Manora",
    pin_code: 444404,
  },
  {
    srno: 248,
    district: "Akola",
    taluka: "Akola",
    pin_code: 444001,
  },
  {
    srno: 249,
    district: "Akola",
    taluka: "Akot",
    pin_code: 444101,
  },
  {
    srno: 250,
    district: "Akola",
    taluka: "Telhara",
    pin_code: 444108,
  },
  {
    srno: 251,
    district: "Akola",
    taluka: "Balapur",
    pin_code: 444302,
  },
  {
    srno: 252,
    district: "Akola",
    taluka: "Patur",
    pin_code: 444501,
  },
  {
    srno: 253,
    district: "Akola",
    taluka: "Murtajapur",
    pin_code: 444107,
  },
  {
    srno: 254,
    district: "Akola",
    taluka: "Barshitakli",
    pin_code: 444401,
  },
  {
    srno: 255,
    district: "Buldhana",
    taluka: "Buldhana",
    pin_code: 443001,
  },
  {
    srno: 256,
    district: "Buldhana",
    taluka: "Chikhli",
    pin_code: 443201,
  },
  {
    srno: 257,
    district: "Buldhana",
    taluka: "Deulgaon Raja",
    pin_code: 443204,
  },
  {
    srno: 258,
    district: "Buldhana",
    taluka: "Jalgaon Jamod",
    pin_code: 443204,
  },
  {
    srno: 259,
    district: "Buldhana",
    taluka: "Sangrampur",
    pin_code: 444202,
  },
  {
    srno: 260,
    district: "Buldhana",
    taluka: "Malkapur",
    pin_code: 443101,
  },
  {
    srno: 261,
    district: "Buldhana",
    taluka: "Motala",
    pin_code: 443103,
  },
  {
    srno: 262,
    district: "Buldhana",
    taluka: "Nandura",
    pin_code: 443404,
  },
  {
    srno: 263,
    district: "Buldhana",
    taluka: "Khamgaon",
    pin_code: 444303,
  },
  {
    srno: 264,
    district: "Buldhana",
    taluka: "Shegaon",
    pin_code: 444203,
  },
  {
    srno: 265,
    district: "Buldhana",
    taluka: "Mehkar",
    pin_code: 443301,
  },
  {
    srno: 266,
    district: "Buldhana",
    taluka: "Sindkhed Raja",
    pin_code: 443203,
  },
  {
    srno: 267,
    district: "Buldhana",
    taluka: "Lonar",
    pin_code: 443302,
  },
  {
    srno: 268,
    district: "Jalgaon",
    taluka: "Jalgaon",
    pin_code: 425001,
  },
  {
    srno: 269,
    district: "Jalgaon",
    taluka: "Jamner",
    pin_code: 424206,
  },
  {
    srno: 270,
    district: "Jalgaon",
    taluka: "Erandol",
    pin_code: 425109,
  },
  {
    srno: 271,
    district: "Jalgaon",
    taluka: "Dharangaon",
    pin_code: 425105,
  },
  {
    srno: 272,
    district: "Jalgaon",
    taluka: "Bhusawal",
    pin_code: 425201,
  },
  {
    srno: 273,
    district: "Jalgaon",
    taluka: "Raver",
    pin_code: 425508,
  },
  {
    srno: 274,
    district: "Jalgaon",
    taluka: "Muktainagar (Edalabad)",
    pin_code: 425306,
  },
  {
    srno: 275,
    district: "Jalgaon",
    taluka: "Bodwad",
    pin_code: 435310,
  },
  {
    srno: 276,
    district: "Jalgaon",
    taluka: "Yawal",
    pin_code: 425301,
  },
  {
    srno: 277,
    district: "Jalgaon",
    taluka: "Amalner",
    pin_code: 425401,
  },
  {
    srno: 278,
    district: "Jalgaon",
    taluka: "Parola",
    pin_code: 425111,
  },
  {
    srno: 279,
    district: "Jalgaon",
    taluka: "Chopda",
    pin_code: 425107,
  },
  {
    srno: 280,
    district: "Jalgaon",
    taluka: "Pachora",
    pin_code: 424201,
  },
  {
    srno: 281,
    district: "Jalgaon",
    taluka: "Bhadgaon",
    pin_code: 424105,
  },
  {
    srno: 282,
    district: "Jalgaon",
    taluka: "Chalisgaon",
    pin_code: 424101,
  },
  {
    srno: 283,
    district: "Dhule",
    taluka: "Dhule",
    pin_code: 424001,
  },
  {
    srno: 284,
    district: "Dhule",
    taluka: "Sakri",
    pin_code: 424304,
  },
  {
    srno: 285,
    district: "Dhule",
    taluka: "Sindkheda",
    pin_code: 425406,
  },
  {
    srno: 286,
    district: "Dhule",
    taluka: "Shirpur",
    pin_code: 425405,
  },
  {
    srno: 287,
    district: "Nandurbar",
    taluka: "Nandurbar",
    pin_code: 425412,
  },
  {
    srno: 288,
    district: "Nandurbar",
    taluka: "Navapur",
    pin_code: 425418,
  },
  {
    srno: 289,
    district: "Nandurbar",
    taluka: "Shahada",
    pin_code: 425409,
  },
  {
    srno: 290,
    district: "Nandurbar",
    taluka: "Taloda",
    pin_code: 425413,
  },
  {
    srno: 291,
    district: "Nandurbar",
    taluka: "Akkalkuwa",
    pin_code: 425415,
  },
  {
    srno: 292,
    district: "Nandurbar",
    taluka: "Akrani (Dhadgaon)",
    pin_code: 425414,
  },
  {
    srno: 293,
    district: "Nashik",
    taluka: "Nashik",
    pin_code: 422003,
  },
  {
    srno: 294,
    district: "Nashik",
    taluka: "Igatpuri",
    pin_code: 422403,
  },
  {
    srno: 295,
    district: "Nashik",
    taluka: "Dindori",
    pin_code: 422202,
  },
  {
    srno: 296,
    district: "Nashik",
    taluka: "Peth",
    pin_code: 415407,
  },
  {
    srno: 297,
    district: "Nashik",
    taluka: "Trimbakeshwar",
    pin_code: 422212,
  },
  {
    srno: 298,
    district: "Nashik",
    taluka: "Kalwan",
    pin_code: 423501,
  },
  {
    srno: 299,
    district: "Nashik",
    taluka: "Deola",
    pin_code: 423102,
  },
  {
    srno: 300,
    district: "Nashik",
    taluka: "Surgana",
    pin_code: 422211,
  },
  {
    srno: 301,
    district: "Nashik",
    taluka: "Baglan (Satana)",
    pin_code: 423301,
  },
  {
    srno: 302,
    district: "Nashik",
    taluka: "Malegaon",
    pin_code: 423203,
  },
  {
    srno: 303,
    district: "Nashik",
    taluka: "Nandgaon",
    pin_code: 423106,
  },
  {
    srno: 304,
    district: "Nashik",
    taluka: "Chandwad",
    pin_code: 423101,
  },
  {
    srno: 305,
    district: "Nashik",
    taluka: "Niphad",
    pin_code: 422303,
  },
  {
    srno: 306,
    district: "Nashik",
    taluka: "Sinnar",
    pin_code: 422103,
  },
  {
    srno: 307,
    district: "Nashik",
    taluka: "Yeola",
    pin_code: 423401,
  },
  {
    srno: 308,
    district: "Palghar",
    taluka: "Palghar",
    pin_code: 401404,
  },
  {
    srno: 309,
    district: "Palghar",
    taluka: "Vasai",
    pin_code: 401208,
  },
  {
    srno: 310,
    district: "Palghar",
    taluka: "Dahanu",
    pin_code: 401602,
  },
  {
    srno: 311,
    district: "Palghar",
    taluka: "Talasari",
    pin_code: 401606,
  },
  {
    srno: 312,
    district: "Palghar",
    taluka: "Jawhar",
    pin_code: 401603,
  },
  {
    srno: 313,
    district: "Palghar",
    taluka: "Mokhada",
    pin_code: 401604,
  },
  {
    srno: 314,
    district: "Palghar",
    taluka: "Vada",
    pin_code: 421303,
  },
  {
    srno: 315,
    district: "Palghar",
    taluka: "Vikramgad",
    pin_code: 401605,
  },
  {
    srno: 316,
    district: "Thane",
    taluka: "Thane",
    pin_code: 400601,
  },
  {
    srno: 317,
    district: "Thane",
    taluka: "Kalyan",
    pin_code: 421301,
  },
  {
    srno: 318,
    district: "Thane",
    taluka: "Murbad",
    pin_code: 421401,
  },
  {
    srno: 319,
    district: "Thane",
    taluka: "Bhiwandi",
    pin_code: 421308,
  },
  {
    srno: 320,
    district: "Thane",
    taluka: "Shahapur",
    pin_code: 421601,
  },
  {
    srno: 321,
    district: "Thane",
    taluka: "Ulhasnagar",
    pin_code: 421004,
  },
  {
    srno: 322,
    district: "Thane",
    taluka: "Ambarnath",
    pin_code: 421501,
  },
  {
    srno: 323,
    district: "Mumbai City",
    taluka: "Kurla",
    pin_code: 400070,
  },
  {
    srno: 324,
    district: "Mumbai City",
    taluka: "Andheri",
    pin_code: 400069,
  },
  {
    srno: 325,
    district: "Mumbai City",
    taluka: "Borivali",
    pin_code: 400066,
  },
  {
    srno: 326,
    district: "Raigad",
    taluka: "Pen",
    pin_code: 402107,
  },
  {
    srno: 327,
    district: "Raigad",
    taluka: "Alibaug",
    pin_code: 402201,
  },
  {
    srno: 328,
    district: "Raigad",
    taluka: "Murud",
    pin_code: 413510,
  },
  {
    srno: 329,
    district: "Raigad",
    taluka: "Panvel",
    pin_code: 410206,
  },
  {
    srno: 330,
    district: "Raigad",
    taluka: "Uran",
    pin_code: 400702,
  },
  {
    srno: 331,
    district: "Raigad",
    taluka: "Karjat (Matheran)",
    pin_code: 410201,
  },
  {
    srno: 332,
    district: "Raigad",
    taluka: "Khalapur",
    pin_code: 410202,
  },
  {
    srno: 333,
    district: "Raigad",
    taluka: "Mangaon",
    pin_code: 402104,
  },
  {
    srno: 334,
    district: "Raigad",
    taluka: "Tala",
    pin_code: 402111,
  },
  {
    srno: 335,
    district: "Raigad",
    taluka: "Roha",
    pin_code: 402109,
  },
  {
    srno: 336,
    district: "Raigad",
    taluka: "Sudhagad (Pali)",
    pin_code: 410205,
  },
  {
    srno: 337,
    district: "Raigad",
    taluka: "Mahad",
    pin_code: 402301,
  },
  {
    srno: 338,
    district: "Raigad",
    taluka: "Poladpur",
    pin_code: 402303,
  },
  {
    srno: 339,
    district: "Raigad",
    taluka: "Shrivardhan",
    pin_code: 402110,
  },
  {
    srno: 340,
    district: "Raigad",
    taluka: "Mhasla",
    pin_code: 402105,
  },
  {
    srno: 341,
    district: "Ratnagiri",
    taluka: "Ratnagiri",
    pin_code: 415612,
  },
  {
    srno: 342,
    district: "Ratnagiri",
    taluka: "Sangameshwar (Deorukh)",
    pin_code: 415611,
  },
  {
    srno: 343,
    district: "Ratnagiri",
    taluka: "Lanja",
    pin_code: 416701,
  },
  {
    srno: 344,
    district: "Ratnagiri",
    taluka: "Rajapur",
    pin_code: 416702,
  },
  {
    srno: 345,
    district: "Ratnagiri",
    taluka: "Chiplun",
    pin_code: 415605,
  },
  {
    srno: 346,
    district: "Ratnagiri",
    taluka: "Guhagar",
    pin_code: 415703,
  },
  {
    srno: 347,
    district: "Ratnagiri",
    taluka: "Dapoli",
    pin_code: 415712,
  },
  {
    srno: 348,
    district: "Ratnagiri",
    taluka: "Mandangad",
    pin_code: 415203,
  },
  {
    srno: 349,
    district: "Ratnagiri",
    taluka: "Khed",
    pin_code: 415718,
  },
  {
    srno: 350,
    district: "Sindhudurg",
    taluka: "Kankavli",
    pin_code: 416602,
  },
  {
    srno: 351,
    district: "Sindhudurg",
    taluka: "Vaibhavwadi",
    pin_code: 416810,
  },
  {
    srno: 352,
    district: "Sindhudurg",
    taluka: "Devgad",
    pin_code: 416613,
  },
  {
    srno: 353,
    district: "Sindhudurg",
    taluka: "Malwan",
    pin_code: 416606,
  },
  {
    srno: 354,
    district: "Sindhudurg",
    taluka: "Sawantwadi",
    pin_code: 416510,
  },
  {
    srno: 355,
    district: "Sindhudurg",
    taluka: "Kudal",
    pin_code: 416520,
  },
  {
    srno: 356,
    district: "Sindhudurg",
    taluka: "Vengurla",
    pin_code: 416512,
  },
  {
    srno: 357,
    district: "Sindhudurg",
    taluka: "Dodamarg (Kasal)",
    pin_code: 416512,
  },
  {
    srno: 358,
    district: "mumbai suburban",
    taluka: "Andheri",
    pin_code: 400069,
  },
  {
    srno: 359,
    district: "mumbai suburban",
    taluka: "Borivali",
    pin_code: 400092,
  },
  {
    srno: 360,
    district: "mumbai suburban",
    taluka: "Kurla",
    pin_code: 400070,
  },
];
