import React from "react";

const LegalInfo = ({ plotDetails, setPlotDetails }) => {
  return (
    <section className="mt-[32px]">
      <div className="flex items-center gap-[12px]">
        <h4 className="text-[20px] text-black font-medium">
          Legal Information
        </h4>
        {/* <Icon
                    icon="material-symbols:info-outline"
                    color="#838383"
                    width={20}
                  /> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-[16px] mt-[24px]">
        {/* <div className="flex flex-col w-full">
          <label
            htmlFor="isfirstowner"
            className="text-[#344054] text-[14px] font-medium"
          >
            Are you the first Owner
            <span className="text-[#C62F39]"> *</span>
          </label>
          <select
            value={plotDetails.legal?.isFirstOwner}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");
              setPlotDetails((prevState) => {
                let legal = { ...prevState.legal };
                legal["isFirstOwner"] = e.target.value;
                return { ...prevState, legal };
              });
            }}
            placeholder="Select the distance from access road"
            id="isfirstowner"
            type="text"
            className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] ${
              plotDetails.legal?.isFirstOwner === ""
                ? "text-[#C7C7C7]"
                : "text-[#000E14]"
            }`}
          >
            <option className="hidden" value="" selected>
              Yes/No
            </option>
            <option className="text-[#000E14]" value={true}>
              Yes
            </option>
            <option className="text-[#000E14]" value={false}>
              No
            </option>
          </select>
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="purchasedeed"
            className="text-[#344054] text-[14px] font-medium"
          >
            Do you have original purchase deed
            <span className="text-[#C62F39]"> *</span>
          </label>
          <select
            value={plotDetails.legal?.purchaseDeed}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");
              setPlotDetails((prevState) => {
                let legal = { ...prevState.legal };
                legal["purchaseDeed"] = e.target.value;
                return { ...prevState, legal };
              });
            }}
            placeholder="Select the direction"
            id="purchasedeed"
            type="text"
            className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] ${
              plotDetails.legal?.purchaseDeed === ""
                ? "text-[#C7C7C7]"
                : "text-[#000E14]"
            }`}
          >
            <option className="hidden" value="" selected>
              Yes/No
            </option>
            <option className="text-[#000E14]" value={true}>
              Yes
            </option>
            <option className="text-[#000E14]" value={false}>
              No
            </option>
          </select>
        </div> */}
        <div className="flex flex-col w-full">
          <label
            htmlFor="undermortage"
            className="text-[#344054] text-[14px] font-medium"
          >
            Is property under mortgage
            {/* <span className="text-[#C62F39]"> *</span> */}
          </label>
          <select
            value={plotDetails.legal?.underMortage}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");
              setPlotDetails((prevState) => {
                let legal = { ...prevState.legal };
                legal["underMortage"] = e.target.value;
                return { ...prevState, legal };
              });
            }}
            id="undermortage"
            type="text"
            className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] ${
              plotDetails.legal?.underMortage === ""
                ? "text-[#C7C7C7]"
                : "text-[#000E14]"
            }`}
          >
            <option className="hidden" value="" selected>
              Yes/No
            </option>
            <option className="text-[#000E14]" value={true}>
              Yes
            </option>
            <option className="text-[#000E14]" value={false}>
              No
            </option>
          </select>
        </div>
      </div>
      {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-[16px] mt-[24px]">
        <div></div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="previouspurchasedeed"
            className="text-[#344054] text-[14px] font-medium"
          >
            Do you have original purchase deed of previous owner?
            <span className="text-[#C62F39]"> *</span>
          </label>
          <select
            value={plotDetails.legal?.previousPurchaseDeed}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");
              setPlotDetails((prevState) => {
                let legal = { ...prevState.legal };
                legal["previousPurchaseDeed"] = e.target.value;
                return { ...prevState, legal };
              });
            }}
            disabled={
              plotDetails.legal?.isFirstOwner === true ||
              plotDetails.legal?.isFirstOwner === "true"
            }
            placeholder="Select the direction"
            id="previouspurchasedeed"
            type="text"
            className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] disabled:cursor-not-allowed ${
              plotDetails.legal?.previousPurchaseDeed === ""
                ? "text-[#C7C7C7]"
                : "text-[#000E14]"
            }`}
          >
            <option className="hidden" value="" selected>
              Yes/No
            </option>
            <option className="text-[#000E14]" value={true}>
              Yes
            </option>
            <option className="text-[#000E14]" value={false}>
              No
            </option>
          </select>
        </div>
      </div> */}
    </section>
  );
};

export default LegalInfo;
