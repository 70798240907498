import React from "react";
import DataTable from "react-data-table-component";
import { downloadCSV, Export } from "../shared/exportButton";
import tableCustomStyles from "../shared/tableStyle";
import Dropdown from "./Dropdown";
import reportTableCustomStyle from "./ReportStyleCustomStyle";

const tabledata = [
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543210,
    listed: 3,
    views: 234,
    bids: 4,
    location: "Pimpri",
    area: 243,
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543211,
    listed: 3,
    views: 2344,
    bids: 8,
    location: "Pimpri",
    area: 243,
    city: "Nashik",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543212,
    listed: 3,
    views: 65,
    bids: 22,
    location: "Pimpri",
    area: 243,
    city: "Kokan",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543213,
    listed: 3,
    views: 876,
    bids: 12,
    location: "Pimpri",
    area: 243,
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543214,
    listed: 3,
    views: 2345,
    bids: 2,
    location: "Pimpri",
    area: 243,
    city: "Vidharbha",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543215,
    listed: 3,
    views: 2344,
    bids: "-",
    location: "Pimpri",
    area: 243,
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543216,
    listed: 3,
    views: 555,
    bids: "-",
    location: "Pimpri",
    area: 243,
    city: "Kokan",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543217,
    listed: 3,
    views: 10,
    bids: 41,
    location: "Pimpri",
    area: 243,
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543218,
    listed: 3,
    views: 10,
    bids: 4,
    location: "Pimpri",
    area: 243,
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543219,
    listed: 3,
    views: 10,
    bids: 4,
    location: "Pimpri",
    area: 243,
    city: "Pune",
  },
  {
    sharedby: "Balaji Sankaran",
    sharedto: "Balaji Sankaran",
    sharedContact: "9876543210",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543220,
    listed: 3,
    views: 10,
    bids: 4,
    location: "Pimpri",
    area: 243,
    city: "Pune",
  },
];

const Referral = ({ reportType, setReportType }) => {
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(tabledata)} />,
    []
  );

  const columns = [
    {
      name: "PUID",
      selector: (row) => row.id,
      width: "200px",
    },
    {
      name: "Username",
      selector: (row) => row.sharedby,
      width: "200px",
    },
    {
      name: "Mobile number",
      selector: (row) => row.mobile,
      width: "200px",
    },
    {
      name: "Email Id",
      selector: (row) => row.email,
      width: "300px",
    },
    {
      name: "Referral Code",
      selector: (row) => row.listed,
      cell: (row) => "AK3672G",
    },
    {
      name: "Discount Amount",
      selector: (row) => row.listed,
      cell: (row) => "₹3250",
      width: "200px",
    },
    {
      name: "Referred By",
      selector: (row) => row.listed,
      cell: (row) => "Balaji Sankaran",
    },
    {
      name: "Referred To",
      selector: (row) => row.listed,
      cell: (row) => "Balaji Sankaran",
    },
  ];

  return (
    <DataTable
      actions={actionsMemo}
      columns={columns}
      data={tabledata}
      responsive
      striped
      highlightOnHover
      noDataComponent={
        <span className="px-[16px] py-[20px]">{`No data found`}</span>
      }
      pagination
      customStyles={{
        ...reportTableCustomStyle,
        headCells: {
          style: {
            padding: "20px 16px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#666666",
            borderRight: "none",
          },
        },
      }}
      subHeader
      subHeaderAlign="left"
      subHeaderComponent={
        <Dropdown reportType={reportType} setReportType={setReportType} />
      }
    />
  );
};

export default Referral;
