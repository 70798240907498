import React from "react";
import { useSelector } from "react-redux";
import { getUnitLabel } from "../../utils/units";

const PlotDetails = ({ selectedPlot, plotDetails }) => {
  const profile = useSelector((state) => state.profile);

  return (
    <div className="mt-[32px]">
      <div className="flex justify-between">
        <h3 className="text-[24px] text-[#000E14] font-semibold">
          Property Details {selectedPlot && selectedPlot.label}
        </h3>
        {/* {(plotDetails.listed_by === profile.userData?.id ||
          profile.userData?.role === "admin") && (
          <button className="text-[16px] text-primaryColor font-semibold">
            Edit Details
          </button>
        )} */}
      </div>
      <div className="shadow-md px-[24px] py-[12px] w-full rounded-regular mt-[12px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-[16px] bg-white">
        <div className="w-full py-[8px] px-[16px] border-[1px] border-[#D9D9D9] rounded-regular">
          <p className="text-[16px] font-medium text-[#838383]">Front</p>
          <h5 className="text-[20px] text-[#000E14] font-medium whitespace-nowrap overflow-hidden text-ellipsis mt-[8px]">
            {plotDetails.front}{" "}
            {plotDetails.front_unit && getUnitLabel(plotDetails.front_unit)}
          </h5>
        </div>
        <div className="w-full py-[8px] px-[16px] border-[1px] border-[#D9D9D9] rounded-regular">
          <p className="text-[16px] font-medium text-[#838383]">Depth</p>
          <h5 className="text-[20px] text-[#000E14] font-medium whitespace-nowrap overflow-hidden text-ellipsis mt-[8px]">
            {plotDetails.depth}{" "}
            {plotDetails.front_unit && getUnitLabel(plotDetails.front_unit)}
          </h5>
        </div>
        <div className="w-full py-[8px] px-[16px] border-[1px] border-[#D9D9D9] rounded-regular">
          <p className="text-[16px] font-medium text-[#838383]">
            Property facing
          </p>
          <h5 className="text-[20px] text-[#000E14] font-medium whitespace-nowrap overflow-hidden text-ellipsis mt-[8px]">
            {plotDetails.facing}
          </h5>
        </div>
        <div className="w-full py-[8px] px-[16px] border-[1px] border-[#D9D9D9] rounded-regular">
          <p className="text-[16px] font-medium text-[#838383]">
            Property access road
          </p>
          <h5 className="text-[20px] text-[#000E14] font-medium whitespace-nowrap overflow-hidden text-ellipsis mt-[8px]">
            {plotDetails.access_road}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default PlotDetails;
