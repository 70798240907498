import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { getLibrary, updateGallery, updateLibrary } from "../../apis/apiClient";
import AddNew from "./AddNew";
import ArticleDetails from "./ArticleDetails";
import { Oval } from "react-loader-spinner";
import { fuzzyMatch } from "../../utils/sharedFunc";
import { baseURL } from "../../constants";
import { Link, useLocation } from "react-router-dom";

const htmlDecode = (content) => {
  let e = document.createElement("div");
  e.innerHTML = content;
  return e.childNodes.length === 0
    ? ""
    : e.childNodes[0].nodeValue
    ? e.childNodes[0].nodeValue
    : e.outerHTML;
};

const Articles = ({
  setIsDetailsOpen,
  selectedTab,
  isDetailsOpen,
  isAddNewOpen,
  setIsAddNewOpen,
  searchQuery,
  setIsLightBoxOpen,
  setGalleryImages,
  profile,
  isResource
}) => {
  const [articlesData, setArticlesData] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  const location = useLocation();


  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    const fetchLibraryData = async () => {
      setIsDataLoading(true);
      const response = await getLibrary({ name: selectedTab });
      setIsDataLoading(false);
      setArticlesData(response);
      setFilteredData(response.data);
    };
    fetchLibraryData();
  }, [selectedTab]);

  useEffect(() => {
    setFilteredData(
      articlesData?.data?.filter((article) =>
        fuzzyMatch(searchQuery.toLowerCase(), article.name.toLowerCase())
      )
    );
  }, [articlesData, searchQuery]);


  const updateData = async (data) => {
    const oldData = [...articlesData?.data] || [];

    const dataIndex = oldData.findIndex((article) => article.id === data.id);

    oldData[dataIndex] = data;

    const resonse = await updateLibrary({
      name: articlesData.name,
      data: [...oldData],
    });
    console.log(resonse);
    setArticlesData((prevState) => ({
      name: prevState.name,
      data: [...oldData],
    }));
  };

  const deleteData = async (data) => {
    const oldData = [...articlesData.data];

    const dataIndex = oldData.findIndex((article) => article.id === data);
    oldData.splice(dataIndex, 1);
    const resonse = await updateLibrary({
      name: articlesData.name,
      data: [...oldData],
    });
    console.log(resonse);
    setArticlesData((prevState) => ({
      name: prevState.name,
      data: [...oldData],
    }));
  };

  return !isDetailsOpen ? (
    !isAddNewOpen ? (
      <>
        {isDataLoading ? (
          <div className="h-[320px] flex items-center justify-center">
            <Oval
              height={52}
              width={52}
              color="#0259DB"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#0259DB"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <>
            {selectedTab === "Gallery" ? (
              <div className="my-[32px] p-[24px] rounded-regular shadow-lg grid grid-cols-1 md:grid-cols-3 gap-[40px]">
                {filteredData?.map((article, index) => {
                  return (
                    <div className="shadow-lg cursor-pointer relative rounded-lg">
                      {profile.isLoggedIn &&
                      profile.userData.role === "admin" ? (
                        <div
                          className="absolute top-[12px] right-[12px] bg-[#ffffff8f] rounded-[8px] p-[4px]"
                          onClick={async () => {
                            const oldData = [...articlesData.data];

                            oldData.splice(index, 1);

                            const response = await updateGallery({
                              ...articlesData,
                              data: [...oldData],
                            });
                            console.log(response);
                            setArticlesData((prevState) => ({
                              ...prevState,
                              data: [...oldData],
                            }));
                            setIsAddNewOpen(false);

                            return;
                          }}
                        >
                          <Icon
                            icon="mdi:delete"
                            width={18}
                            color="#000"
                            className="min-w-[18px]"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      <img
                        onClick={() => {
                          setIsLightBoxOpen(true);
                          setGalleryImages(article.data);
                        }}
                        class="rounded-t-lg min-h-[200px] max-h-[200px] w-full object-cover"
                        src={`${baseURL}/${article.data[0]?.path}`}
                        alt=""
                      />
                      <div
                        class="p-[12px]"
                        onClick={() => {
                          setIsLightBoxOpen(true);
                          setGalleryImages(article.data);
                        }}
                      >
                        <h5 class="text-[#484848] text-[20px] font-semibold">
                          {article?.name}
                        </h5>
                        {/* <button class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                          Show more
                        </button> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="my-[32px] p-[24px] rounded-regular shadow-lg grid grid-cols-1 md:grid-cols-2 gap-[40px]">
                {filteredData?.map((article) => (
                  <div className="flex gap-[24px]">
                    <Icon
                      icon="mdi:file-document-outline"
                      width={32}
                      color="#484848"
                      className="min-w-[32px] my-[12px]"
                    />
                    <div>
                      <h5 className="text-[#484848] text-[20px] font-semibold">
                        {article?.name}
                      </h5>
                      <p className="mt-[8px] text-[#838383] text-[16px] line-clamp-5">
                        {htmlDecode(article?.data).replaceAll(
                          /<\/?[^>]+(>|$)/g,
                          ""
                        )}
                      </p>
                      <Link to={selectedTab === "FAQ" ? `/faqs/${article.slug}` : `/blog/${article.slug}`} state={{from : (selectedTab!=="FAQ" && location.pathname === '/resources') && 'resources'}}
                        className="py-[8px] px-[24px] mt-[8px] w-fit border-[1px] border-[#0259DB] rounded-regular text-[16px] font-medium text-[#0259DB] block"
                        // onClick={() => {
                        //   setArticleData(article);
                        //   setIsDetailsOpen(true);
                        // }}
                      >
                        Read more
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </>
    ) : (
      <AddNew
        selectedTab={selectedTab}
        setIsAddNewOpen={setIsAddNewOpen}
        articlesData={articlesData}
        setArticlesData={setArticlesData}
      />
    )
  ) : (
    <ArticleDetails
      deleteData={deleteData}
      articlesData={articlesData}
      updateData={updateData}
      setIsDetailsOpen={setIsDetailsOpen}
    isResource={isResource}
    />
  );
};

export default Articles;
