import { Icon } from "@iconify/react";
import React from "react";

const Highlights = ({ plotDetails, setPlotDetails }) => {
  return (
    <section className="mt-[32px]">
      <div className="flex items-center gap-[12px]">
        <h4 className="text-[20px] text-black font-medium">
          Property Highlights
          <span className="text-[14px]"> (Optional) </span>
        </h4>
        <div className="group inline-block relative">
          <Icon
            className="cursor-pointer"
            icon="material-symbols:info-outline"
            color="#838383"
            width={20}
          />
          <div className="bg-white border rounded-regular transform scale-0 group-hover:scale-100 absolute transition duration-100 ease-in-out origin-left py-[4px] px-[8px] w-[276px] overflow-scroll left-[30px] top-[-100%] z-50">
            <p className="text-center text-[12px] text-[#838383]">
              Highlights of the property i.e. If the plot consist of special
              trees or if its a corner plot This Section is optional
            </p>
          </div>
        </div>
      </div>
      {plotDetails.highlights.length === 0 && (
        <button
          onClick={() => {
            setPlotDetails((prevState) => {
              let highlights = prevState.highlights;
              let tempArray = [...highlights];
              tempArray.push("");
              return { ...prevState, highlights: [...tempArray] };
            });
            // setHighlights((prevState) => [...prevState, ""]);
          }}
          className="px-[16px] py-[12px] text-[16px] font-medium text-[#666666] border-[1px] border-[#666666] rounded-regular mt-[24px]"
        >
          Add New Highlight
        </button>
      )}

      {plotDetails.highlights.map((highlight, index) => (
        <div
          key={`${index}__highlight`}
          className="grid grid-cols-1 md:grid-cols-3 gap-[16px]"
        >
          <div className="flex flex-col w-full mt-[24px]">
            <label
              htmlFor={`highlight_${index}`}
              className="text-[#344054] text-[14px] font-medium"
            >
              Highlight
            </label>
            <input
              id={`highlight_${index}`}
              value={highlight}
              onChange={(e) => {
                e.target.classList.remove("!border-[#C62F39]");
                setPlotDetails((prevState) => {
                  let highlights = prevState.highlights;
                  let tempArray = [...highlights];
                  tempArray[index] = e.target.value;
                  return { ...prevState, highlights: [...tempArray] };
                });
                // setHighlights((prevState) => {
                //   return [...tempArray];
                // });
              }}
              placeholder="e.g, Corner plot, Well, Tree"
              type="text"
              className="w-full rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] mt-[8px]"
            />
          </div>
          <div className="flex gap-[16px] items-end">
            <div className="py-[12px]">
              <Icon
                icon="ic:baseline-remove-circle-outline"
                color="#B42318"
                width={24}
                className="cursor-pointer"
                onClick={() => {
                  setPlotDetails((prevState) => {
                    let highlights = prevState.highlights;
                    let tempArray = [...highlights];
                    tempArray.splice(index, 1);
                    return { ...prevState, highlights: [...tempArray] };
                  });
                  // setHighlights((prevState) => {
                  //   let tempArray = [...prevState];
                  //   tempArray.splice(index, 1);
                  //   return [...tempArray];
                  // });
                }}
              />
            </div>
            {index === plotDetails.highlights.length - 1 && (
              <button
                onClick={() => {
                  setPlotDetails((prevState) => {
                    let highlights = prevState.highlights;
                    let tempArray = [...highlights];
                    tempArray.push("");
                    return { ...prevState, highlights: [...tempArray] };
                  });
                  // setHighlights((prevState) => [...prevState, ""]);
                }}
                className="px-[16px] py-[12px] text-[16px] font-medium text-[#666666] border-[1px] border-[#666666] rounded-regular"
              >
                Add New Highlight
              </button>
            )}
          </div>
        </div>
      ))}
    </section>
  );
};

export default Highlights;
