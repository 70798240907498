import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const teamData = [
  {
    name: "Parag D Patil",
    designation: "Founder",
    description: `Parag D Patil is a seasoned professional with over two decades of diverse experience in the real estate domain. Starting as a chemical engineer in an edible oil refinery in 1997, he shifted to real estate in 2003, and since then, he has sold around 11 lakh sq. ft. of constructed residential dwellings. Apart from his professional expertise, he holds a distinguished record in the Kinva Book of Records and the Guinness Book of World Records for Longest Human Chain Underwater. With a passion for excellence and a commitment to delivering results, Parag is focused on creating seamless experiences for the buyers and the sellers of residential plots.`,
    photo: require("../../assets/images/directors/Parag Patil.webp"),
  },
  {
    name: "CA Varad Prashant Saoji",
    designation: "Founder",
    description: `As a second-generation entrepreneur in the real estate sector and a Chartered Accountant
by profession, Varad Prashant Saoji has extensive experience in the industry. With a
particular focus on fair market pricing, demand-supply co-relation, price determination and
trend analysis, and project finance, he has executed and closed over 500 deals in the plot
market in just two and a half years. His vision for the real estate market is to bring structure
to an otherwise unstructured market, providing a platform for growth and success.`,
    photo: require("../../assets/images/directors/Varad Saoji.webp"),
  },
//   {
//     name: "Tanishk Shantanu Pawar",
//     designation: "Founder",
//     description: `Tanishk Pawar is a skilled civil engineer and project management professional with a
// passion for real estate. He holds a Bachelor of Engineering in Civil and a post-graduation in
// Project Management from NICMAR.
// Tanishk excels at overseeing construction projects from start to finish, ensuring timely
// delivery within budget and high-quality standards. He has successfully delivered various
// residential projects and managed project budgets, schedules, and teams of engineers,
// contractors, and architects. Tanishk's attention to detail and effective communication skills
// have helped him build strong relationships with clients, contractors, and vendors.`,
//     photo: require("../../assets/images/directors/Tanishk pawar.JPG"),
//   },
  {
    name: "Sarthak Prashant Saoji",
    designation: "Founder",
    description: `Sarthak Prashant Saoji is a highly motivated civil engineer with a Master’s in Political
Leadership and Government. He has over 5 years of experience in the real estate industry,
specializing in NA-Plots. With strong leadership qualities, Sarthak effectively manages
projects while maintaining up-to-date knowledge of real estate regulations and policies.
Skilled in building and maintaining long-lasting relationships with clients and stakeholders,
Sarthak excels in site selection, feasibility analysis, and land acquisition, with a proven ability
to identify profitable opportunities.`,
    photo: require("../../assets/images/directors/Sarthak Saoji.webp"),
  },

  {
    name: "Trupti Parag Patil",
    designation: "Founder",
    description: `Trupti Parag Patil is an accomplished professional with extensive experience in the real
estate and energy industries. With a passion for excellence and a proven track record of
success, Trupti is a valuable asset to any team. After earning her BSc degree, she began
her career in the edible oil refinery industry, where she worked for over six years before
transitioning to the real estate sector in 2003. Since then, Trupti has become a prominent
figure in the industry, serving as a partner in both DNV Builder and Shriaunsh Group. With a
keen eye for finance, she has successfully managed the development of over one million
square feet in the residential market in Pune.In 2018, she also founded a company specializing in solar photovoltaic systems, delivering
exceptional results to her satisfied clients.`,
    photo: require("../../assets/images/directors/Trupti-patil.webp"),
  },
  {
    name: "Rasika Varad Saoji",
    designation: "Founder",
    description: `With a master's degree in commerce and certification as an investment advisor, Rasika
Varad Saoji brings a wealth of experience in finance and investment domain. She
specializes in portfolio management in the financial markets and believes that the real estate
market offers strong long-term performance and should be a core consideration for
investors. Rasika believes that real estate plotting is an area that needs more awareness as
an investment opportunity. Her work with REMPO reflects a commitment to bringing greater
transparency, trust, and liquidity to the unstructured real estate market.`,
    photo: require("../../assets/images/directors/Reshma Saoji.JPG"),
  },
];

const Team = () => {
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [teamMember, setTeamMember] = useState({});

  return (
    <>
      <section
        className="mt-[64px] items-center mx-auto max-w-screen-xl px-[24px]"
        id="ourteam"
      >
        <h1 className="text-[36px] md:text-[48px] font-bold text-headingSecondary">
          Our Team
        </h1>
        <div className=" flex flex-wrap justify-center gap-[28px] mt-[32px]">
          {teamData.map((member, index) => (
            <div
              key={`${index}`}
              className="p-[24px] rounded-regular bg-white shadow-md"
            >
              <div className="h-[236px]">
                <img
                  className="h-full w-full object-cover"
                  src={member.photo}
                  alt={member.name}
                ></img>
              </div>
              <h6 className="mt-[16px] text-headingSecondary text-[18px] font-semibold">
                {member.name}
              </h6>
              {/* <p className="my-[8px] text-cardSecondaryText font-semibold">
                {member.designation}
              </p> */}
              <button
                className="text-primaryColor text-[14px]"
                onClick={() => {
                  setTeamMember(member);
                  setIsTeamModalOpen(true);
                }}
              >
                READ MORE
              </button>
            </div>
          ))}
        </div>
      </section>
      <div
        className={`fixed z-50 overflow-y-auto top-0 w-full left-0 ${
          !isTeamModalOpen && "hidden"
        }`}
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => {
              setIsTeamModalOpen(false);
            }}
          >
            <div className="absolute inset-0 bg-[#1C1C1C] opacity-90" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[900px] sm:w-full p-[32px]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex justify-between items-center">
              <div className="flex gap-[12px] items-center">
                <h3 className="text-[#000E14] text-[24px] font-semibold">
                  {teamMember.name}
                </h3>
              </div>
              <button
                onClick={() => {
                  setIsTeamModalOpen(false);
                }}
              >
                <Icon
                  icon="material-symbols:close"
                  width={24}
                  height={24}
                  color="#000"
                />
              </button>
            </div>
            <div className="mt-[12px] flex flex-col gap-[12px] h-full max-h-[70vh] overflow-y-scroll text-justify">
              <p>{teamMember.description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
