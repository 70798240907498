import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { updateToken } from "../apis/apiClient";

const cookies = new Cookies();

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    isLoggedIn: cookies.get("token") ? true : false,
    userData: {},
    subscriptionData: null,
  },
  reducers: {
    loginUser: (state, action) => {
      cookies.set("token", `Bearer ${action.payload.tokens.access.token}`, {
        path: "/",
      });
      updateToken(action.payload.tokens.access.token);
      localStorage.setItem(
        "token",
        `Bearer ${action.payload.tokens.access.token}`
      );
      state.isLoggedIn = true;
      state.userData = action.payload;
    },
    updateUser: (state, action) => {
      state.userData = action.payload;
    },
    logoutUser: (state, action) => {
      cookies.remove("token");
      state.isLoggedIn = false;
      state.userData = {};
      state.subscriptionData = null;
    },
    setSubscriptionData: (state, action) => {
      state.subscriptionData = action.payload;
    },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // },
  },
});

// Action creators are generated for each case reducer function
export const { loginUser, logoutUser, updateUser, setSubscriptionData } = profileSlice.actions;

export default profileSlice.reducer;
