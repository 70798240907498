import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getNotifications } from "../apis/apiClient";
import BuyerHeader from "../components/Header/BuyerHeader";
import { getFormattedDate } from "../utils/date";
import HomeHeader from "../components/Header/HomeHeader";
import { Oval } from "react-loader-spinner";
import { FiAlertCircle } from "react-icons/fi";
import { FcCancel } from "react-icons/fc";
import { FaUserCog } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { MdOutlineLocalOffer } from "react-icons/md";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const profile = useSelector((state) => state.profile);
  const notificationsValue = useSelector(
    (state) => state.notificationList.list
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.isLoggedIn) {
      navigate("/login", { state: { navigateTo: "/notifications" } });
    }

    const fetchNotifications = async () => {
      setIsLoading(true);
      try {
        const response = await getNotifications(profile.userData?.id);
        console.log(response);
        setIsLoading(false);

        if (response.status === "success") {
          setNotifications(response.notifications);
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    };
    if (profile.userData.id) {
      fetchNotifications();
    }
  }, [profile]);

  return (
    <div className="bg-white mx-auto max-w-screen-xl px-[24px]">
      <HomeHeader isHome={false} />
      <h1 className="text-[24px] font-semibold text-[#013583] my-[24px]">
        Notifications
      </h1>
      <div className="bg-white w-full py-[12px] px-[20px] drop-shadow-md rounded-[12px]">
        {isLoading ? (
          <div className="w-full min-h-[400px] h-full flex justify-center items-center">
            <Oval
              height={52}
              width={52}
              color="#0259DB"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#0259DB"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <>
            {notifications?.map(
              (notification) =>
                notification.type !== "saved" &&
                notification.type !== "unsaved" && (
                  <div className="flex gap-[16px] items-start mb-[40px]">
                    {notification.type === "listings" && (
                      <div className="min-w-[48px] min-h-[48px] bg-[#EFF8EE] flex justify-center items-center rounded-full">
                        <Icon
                          icon="material-symbols:post-add"
                          color="#027A48"
                          width={20}
                        />
                      </div>
                    )}
                    {notification.type === "interested" && (
                      <div className="min-w-[48px] min-h-[48px] bg-[#FAEBEC] flex justify-center items-center rounded-full">
                        <Icon
                          icon="mdi:favourite-border"
                          color="#C62F39"
                          width={20}
                        />
                      </div>
                    )}
                    {notification.type === "bid" && (
                      <div className="min-w-[48px] min-h-[48px] bg-[#CCDEF8] flex justify-center items-center rounded-full">
                        <Icon
                          icon="material-symbols:trending-up"
                          color="#0259DB"
                          width={20}
                        />
                      </div>
                    )}
                    <div>
                      {notification.type === "listings" && (
                        <h6 className="text-[#000E14] text-[14px] text-left">
                          Your Plot in{" "}
                          {notification?.plot && (
                            <span className="font-bold text-[#212B36]">
                              {notification?.plot?.location?.village} -{" "}
                              {notification?.plot?.puid}{" "}
                            </span>
                          )}
                          {notification?.scheme && (
                            <span className="font-bold text-[#212B36]">
                              {notification?.scheme?.location?.village} -{" "}
                              {notification?.scheme?.puid}{" "}
                            </span>
                          )}
                          has been posted.
                        </h6>
                      )}
                      {notification.type === "interested" && (
                        <h6 className="text-[#000E14] text-[14px] text-left">
                          1 people Interested your Listing for Plot in{" "}
                          <span className="font-bold text-[#212B36]">
                            {notification.plot?.location?.village} -{" "}
                            {notification.plot?.puid}
                          </span>
                          .
                        </h6>
                      )}
                      {notification.type === "bid" && (
                        <h6 className="text-[#000E14] text-[14px] text-left">
                          <span className="font-bold text-[#212B36]">
                            You have new Offer{" "}
                          </span>
                          on your plot in{" "}
                          <span className="font-bold text-[#212B36]">
                            {notification.plot?.location?.village} -{" "}
                            {notification.plot?.puid}
                          </span>
                          .
                        </h6>
                      )}
                      <div className="flex mt-[4px] gap-[4px] items-center">
                        <Icon
                          icon="mdi:clock-time-three-outline"
                          color="#919EAB"
                          width={16}
                        />
                        <p className="text-[#919EAB] text-[12px]">
                          {getFormattedDate(notification?.createdAt, {
                            withMonthName: true,
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                )
            )}
            {notificationsValue?.map((notification) => (
              <>
                {notification.type === "kycUpdate" && (
                  <div
                    key={notification.id}
                    className="flex gap-4 items-start mb-6 bg-green-100 p-4 rounded-lg" // Use a blue background for admin update notifications
                  >
                    <div className="flex-shrink-0">
                      <GoVerified className="text-green-500 w-6 h-6" />{" "}
                    </div>
                    <div>
                      <p className="text-[#000E14] text-sm">
                        {notification.message}
                      </p>
                    </div>
                  </div>
                )}

                {notification.type === "offerPlaced" && (
                  <div
                    key={notification.id}
                    className="flex gap-4 items-start mb-6 p-4 rounded-lg" // Use a blue background for admin update notifications
                  >
                    <div className="flex-shrink-0">
                      <MdOutlineLocalOffer className="text-green-500 w-6 h-6" />{" "}
                    </div>
                    <div>
                      <p className="text-[#000E14] text-sm">
                        {notification.message}
                      </p>
                    </div>
                  </div>
                )}
                {notification.type === "adminUpdate" && (
                  <div
                    key={notification.id}
                    className="flex gap-4 items-start mb-6 bg-blue-100 p-4 rounded-lg" // Use a blue background for admin update notifications
                  >
                    <div className="flex-shrink-0">
                      <FaUserCog className="text-blue-500 w-6 h-6" />{" "}
                      {/* Use the admin icon */}
                    </div>
                    <div>
                      <p className="text-[#000E14] text-sm">
                        {notification.message}
                      </p>
                    </div>
                  </div>
                )}
                {notification.type === "deleted" && (
                  <div
                    key={notification.id}
                    className="flex gap-4 items-start mb-6 bg-red-100 p-4 rounded-lg"
                  >
                    <div className="flex-shrink-0">
                      <FcCancel className="text-red-400 w-6 h-6" />
                    </div>
                    <div>
                      <p className="text-[#000E14] text-sm">
                        Your listing{" "}
                        <span className="font-bold">
                          {notification?.plot?.plot?.title} -{" "}
                          {notification?.plot?.plot?.puid}
                        </span>{" "}
                        has been removed from REMPO.
                      </p>
                    </div>
                  </div>
                )}
              </>
            ))}

            {!profile.userData.isRegistered && (
              <div>
                {
                  <li className="flex gap-4 items-start mb-6 bg-yellow-100 p-4 rounded-lg">
                    <div className="flex-shrink-0">
                      <FiAlertCircle className="text-yellow-500 w-6 h-6" />
                    </div>
                    <Link to="/registration" className="text-[#000E14] text-sm">
                      Some information is missing. Please fill in the mandatory
                      fields to complete your sign-up.
                    </Link>
                  </li>
                }
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Notifications;
