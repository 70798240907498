import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Icon } from "@iconify/react";
import React from "react";

const DocsModal = ({
  isDocViewerOpen,
  setIsDocViewerOpen,
  selectedDocument,
}) => {
  return (
    <div
      className={`fixed z-50 overflow-y-auto top-0 w-full left-0 ${
        isDocViewerOpen ? "" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => {
            setIsDocViewerOpen(false);
          }}
        >
          <div className="absolute inset-0 bg-[#1C1C1C] opacity-90" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-xl h-[90vh] overflow-y-scroll sm:w-full p-[24px]"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-between items-center">
            <div className="flex gap-[12px] items-center">
              <h3 className="text-[#000E14] text-[24px] font-semibold">
                Documents from Seller
              </h3>
            </div>
            <button
              onClick={() => {
                setIsDocViewerOpen(false);
              }}
            >
              <Icon
                icon="material-symbols:close"
                width={24}
                height={24}
                color="#000"
              />
            </button>
          </div>
          <DocViewer
            documents={selectedDocument}
            pluginRenderers={DocViewerRenderers}
          />
        </div>
      </div>
    </div>
  );
};

export default DocsModal;
