import React from "react";

const Insights = ({ plotDetails, selectedPlot, setIsInterestedModalOpen }) => {
  return (
    <>
      {plotDetails.type === "bulk" && plotDetails.area && (
        <div className="mt-[32px]">
          <h3 className="text-[24px] text-[#000E14] font-semibold">
            {selectedPlot.label} Insights
          </h3>
          <div className="mt-[12px] grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-[24px]">
            <div className="p-[12px] bg-[#EFF8EE] shadow-md rounded-regular border-b-[2px] border-[#027A48]">
              <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
                {selectedPlot.value?.views?.length}
              </h3>
              <p className="text-[14px] font-medium text-[#5E6A72] text-center">
                Total Views
              </p>
            </div>
            <div className="p-[12px] bg-[#EFF8EE] shadow-md rounded-regular border-b-[2px] border-[#027A48]">
              <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
                {selectedPlot.value?.bids?.length}
              </h3>
              <p className="text-[14px] font-medium text-[#5E6A72] text-center">
                Total Offers
              </p>
            </div>
            <div className="p-[12px] bg-[#EFF8EE] shadow-md rounded-regular border-b-[2px] border-[#027A48]">
              <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
                {selectedPlot.value?.interested?.length}
              </h3>
              <p className="text-[14px] font-medium text-[#5E6A72] text-center">
                Interested
              </p>
            </div>
            <div className="p-[12px] bg-[#EFF8EE] shadow-md rounded-regular border-b-[2px] border-[#027A48]">
              <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
                {selectedPlot.value?.contacts_fetched?.length}
              </h3>
              <p className="text-[14px] font-medium text-[#5E6A72] text-center">
                Fetched Contact
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="mt-[32px]">
        <h3 className="text-[24px] text-[#000E14] font-semibold">
          {plotDetails.type === "bulk"
            ? "Scheme Insights"
            : "Property Insights"}
        </h3>
        <div className="mt-[12px] grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-[24px]">
          <div className="p-[12px] bg-[#ECF3FF66] shadow-md rounded-regular border-b-[2px] border-primaryColor">
            <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
              {plotDetails.views?.length}
            </h3>
            <p className="text-[14px] font-medium text-[#5E6A72] text-center">
              Total Views
            </p>
          </div>
          <div className="p-[12px] bg-[#ECF3FF66] shadow-md rounded-regular border-b-[2px] border-primaryColor">
            <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
              {plotDetails.bids?.length}
            </h3>
            <p className="text-[14px] font-medium text-[#5E6A72] text-center">
              Total Offers
            </p>
          </div>
          <div
            onClick={() => {
              setIsInterestedModalOpen(true);
            }}
            className="cursor-pointer p-[12px] bg-[#ECF3FF66] shadow-md rounded-regular border-b-[2px] border-primaryColor"
          >
            <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
              {plotDetails.interested?.length}
            </h3>
            <p className="text-[14px] font-medium text-[#5E6A72] text-center">
              Interested
            </p>
          </div>
          <div className="p-[12px] bg-[#ECF3FF66] shadow-md rounded-regular border-b-[2px] border-primaryColor">
            <h3 className="text-[24px] font-semibold text-[#000E14] text-center">
              {plotDetails.contacts_fetched?.length}
            </h3>
            <p className="text-[14px] font-medium text-[#5E6A72] text-center">
              Fetched Contact
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Insights;
