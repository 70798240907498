import React from "react";
import { getFormattedDate } from "../utils/date";

const ExpandedRows = ({ data, currentColumns }) => {
  return (
    <div className="px-[32px] py-[16px] flex border-b-[1px] border-[#ECECEC]">
      {(currentColumns ? currentColumns[0].show : true) && (
        <div className="w-[200px] flex flex-col gap-[10px]">
          <div>
            <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
              Date
            </p>
            <p className="text-[12px] font-medium text-[#484848]">
              {getFormattedDate(data.updatedAt)}
            </p>
          </div>
          <div>
            <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
              Location
            </p>
            <p className="text-[12px] font-medium text-[#484848]">
              {data.plot?.type === "single"
                ? data.plot?.location?.village
                : data.plot?.scheme?.location?.village}
            </p>
          </div>
        </div>
      )}
      {(currentColumns ? currentColumns[1].show : true) && (
        <div className="w-[250px] flex flex-col gap-[10px]">
          <div>
            <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
              Contact info
            </p>
            <p className="text-[12px] font-medium text-[#484848]">
              {data.user?.mobile_number}
            </p>
          </div>
          <div>
            <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
              Email
            </p>
            <p className="text-[12px] font-medium text-[#484848]">
              {data.user?.email}
            </p>
          </div>
        </div>
      )}
      {(currentColumns ? currentColumns[2].show : true) && (
        <div className="w-[250px] flex flex-col gap-[10px]">
          <div>
            <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
              Contact info
            </p>
            <p className="text-[12px] font-medium text-[#484848]">
              {data.plot?.listed_by?.mobile_number}
            </p>
          </div>
          <div>
            <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
              Email
            </p>
            <p className="text-[12px] font-medium text-[#484848]">
              {data.plot?.listed_by?.email}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpandedRows;
