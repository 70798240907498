import { Icon } from "@iconify/react";
import React from "react";
import { useSelector } from "react-redux";

const LegalDetails = ({ plotDetails }) => {
  const profile = useSelector((state) => state.profile);

  return (
    <div className="mt-[32px]">
      <div className="flex justify-between">
        <h3 className="text-[24px] text-[#000E14] font-semibold">
          Legal Status
        </h3>
        {/* {(plotDetails.listed_by === profile.userData?.id ||
          profile.userData?.role === "admin") && (
          <button className="text-[16px] text-primaryColor font-semibold">
            Edit Details
          </button>
        )} */}
      </div>
      <div className="shadow-md px-[24px] py-[12px] w-full rounded-regular mt-[12px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-[16px]">
        {/* <div className="w-full py-[8px] px-[16px] border-[1px] border-[#D9D9D9] rounded-regular flex flex-col justify-between">
          <h6 className="text-[16px] text-[#838383] font-medium">
            Are you the first owner?
          </h6>
          <div
            className={`px-[12px] py-[4px] rounded-full text-[12px] md:text-[14px] font-smibold flex gap-[8px] items-center w-fit mt-[8px] ${
              plotDetails.legal?.isFirstOwner === "true"
                ? "bg-[#ECFDF3] text-[#027A48]"
                : "bg-[#FEF3F2] text-[#B42318]"
            }`}
          >
            {plotDetails.legal?.isFirstOwner === "true" ? (
              <>
                <Icon
                  icon="material-symbols:check-circle"
                  width={24}
                  height={24}
                />
                Yes
              </>
            ) : (
              <>
                <Icon icon="material-symbols:cancel" width={24} height={24} />
                No
              </>
            )}
          </div>
        </div>
        <div className="w-full py-[8px] px-[16px] border-[1px] border-[#D9D9D9] rounded-regular flex flex-col justify-between">
          <h6 className="text-[16px] text-[#838383] font-medium">
            Do you have the original purchase deed?
          </h6>
          <div
            className={`px-[12px] py-[4px] rounded-full text-[12px] md:text-[14px] font-smibold flex gap-[8px] items-center w-fit mt-[8px] ${
              plotDetails.legal?.purchaseDeed === "true"
                ? "bg-[#ECFDF3] text-[#027A48]"
                : "bg-[#FEF3F2] text-[#B42318]"
            }`}
          >
            {plotDetails.legal?.purchaseDeed === "true" ? (
              <>
                <Icon
                  icon="material-symbols:check-circle"
                  width={24}
                  height={24}
                />
                Yes
              </>
            ) : (
              <>
                <Icon icon="material-symbols:cancel" width={24} height={24} />
                No
              </>
            )}
          </div>
        </div>
        {plotDetails.legal?.isFirstOwner === "false" && (
          <div className="w-full py-[8px] px-[16px] border-[1px] border-[#D9D9D9] rounded-regular flex flex-col justify-between">
            <h6 className="text-[16px] text-[#838383] font-medium">
              Do you have the original purchase deed of the previous owner?
            </h6>
            <div
              className={`px-[12px] py-[4px] rounded-full text-[12px] md:text-[14px] font-smibold flex gap-[8px] items-center w-fit mt-[8px] ${
                plotDetails.legal?.previousPurchaseDeed === "true"
                  ? "bg-[#ECFDF3] text-[#027A48]"
                  : "bg-[#FEF3F2] text-[#B42318]"
              }`}
            >
              {plotDetails.legal?.previousPurchaseDeed === "true" ? (
                <>
                  <Icon
                    icon="material-symbols:check-circle"
                    width={24}
                    height={24}
                  />
                  Yes
                </>
              ) : (
                <>
                  <Icon icon="material-symbols:cancel" width={24} height={24} />
                  No
                </>
              )}
            </div>
          </div>
        )} */}
        <div className="w-full py-[8px] px-[16px] border-[1px] border-[#D9D9D9] rounded-regular flex flex-col justify-between">
          <h6 className="text-[16px] text-[#838383] font-medium">
            Is property under mortgage?
          </h6>
          <div
            className={`px-[12px] py-[4px] rounded-full text-[12px] md:text-[14px] font-smibold flex gap-[8px] items-center w-fit mt-[8px] ${
              plotDetails.legal?.underMortage === "true"
                ? "bg-[#FEF3F2] text-[#B42318]"
                : "bg-[#ECFDF3] text-[#027A48]"
            }`}
          >
            {plotDetails.legal?.underMortage === "true" ? (
              <>
                <Icon icon="material-symbols:cancel" width={24} height={24} />
                Yes
              </>
            ) : (
              <>
                <Icon
                  icon="material-symbols:check-circle"
                  width={24}
                  height={24}
                />
                No
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalDetails;
