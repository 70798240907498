import { Icon } from "@iconify/react";
import React from "react";
import { useSelector } from "react-redux";
import { baseURL } from "../../constants";

const DocumentDetails = ({
  plotDetails,
  setSelectedDocument,
  setIsDocViewerOpen,
}) => {
  const profile = useSelector((state) => state.profile);

  return (
    <div className="mt-[32px]">
      <div className="flex justify-between">
        <h3 className="text-[24px] text-[#000E14] font-semibold">Documents</h3>
        {/* {(plotDetails.listed_by === profile.userData?.id ||
          profile.userData?.role === "admin") && (
          <button className="text-[16px] text-primaryColor font-semibold">
            Edit Details
          </button>
        )} */}
      </div>
      <div className="shadow-md px-[24px] py-[12px] w-full rounded-regular mt-[12px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-[16px]">
        {plotDetails.documents?.map((document) => (
          <div className="w-full py-[8px] px-[16px] border-[1px] border-[#D9D9D9] rounded-regular flex items-center justify-between">
            <div className="flex gap-[24px]">
              <div className="p-[2px] bg-[#367DE7] w-fit rounded-[4px]">
                <Icon
                  icon="material-symbols:description-outline"
                  color="#fff"
                  width={20}
                  height={20}
                />
              </div>
              <h6 className="text-[16px] text-[#838383] font-medium">
                {document.name}
              </h6>
            </div>
            <button
              onClick={() => {
                setSelectedDocument([
                  {
                    uri: `${baseURL}/${document.file.path}`,
                  },
                ]);
                setIsDocViewerOpen(true);
              }}
              className="px-[12px] py-[4px] bg-[#EAF2FF] rounded-regular text-[#0259DB] text-[12px] md:text-[14px] font-medium flex gap-[8px] items-center w-fit mt-[8px]"
            >
              View
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentDetails;
