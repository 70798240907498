import React, { useState } from "react";
import { lengthunits, units } from "../../utils/units";

const Details = ({ plotDetails, setPlotDetails }) => {
  const [isCustomAccess, setIsCustomAccess] = useState(false);
  return (
    <section>
      <h4 className="text-[20px] text-black font-medium">Property Details</h4>
      <div className="flex flex-col w-full mt-[24px]">
        <label
          htmlFor="title"
          className="text-[#344054] text-[14px] font-medium"
        >
          Property Name
          <span className="text-[#C62F39]"> *</span>
        </label>
        <input
          value={plotDetails.title}
          onChange={(e) => {
            e.target.classList.remove("!border-[#C62F39]");
            setPlotDetails((prevState) => ({
              ...prevState,
              title: e.target.value,
            }));
          }}
          placeholder="Please Enter name of your Property"
          id="title"
          type="text"
          className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:bg-[#F7F7F7]"
        />
      </div>
      <div className="flex flex-col w-full mt-[24px]">
        <label
          htmlFor="description"
          className="text-[#344054] text-[14px] font-medium"
        >
          Property Description
        </label>
        <textarea
          value={plotDetails.description}
          onChange={(e) => {
            e.target.classList.remove("!border-[#C62F39]");
            setPlotDetails((prevState) => ({
              ...prevState,
              description: e.target.value,
            }));
          }}
          placeholder="Please enter description of your property"
          id="description"
          type="text"
          className="min-w-full max-w-full min-h-[150px] h-[150px] mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-[16px] mt-[24px]">
        <div className="flex flex-col w-full">
          <label
            htmlFor="category"
            className="text-[#344054] text-[14px] font-medium"
          >
            Property Category
            <span className="text-[#C62F39]"> *</span>
          </label>
          <select
            value={plotDetails.category}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");
              setPlotDetails((prevState) => ({
                ...prevState,
                category: e.target.value,
              }));
            }}
            placeholder="Agricultural/commercial/Residential"
            id="category"
            type="text"
            className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] ${
              plotDetails.category === "" ? "text-[#C7C7C7]" : "text-[#000E14]"
            }`}
          >
            <option className="hidden" value="" selected>
              {[
                "NA",
                "Guntha",
                "Farmhouse",
                "Society",
                "Cidco",
                "MHADA",
                "Other",
              ].join(" Plot / ")}
            </option>

            {[
              "NA",
              "Guntha",
              "Farmhouse",
              "Society",
              "Cidco",
              "MHADA",
              "Other",
            ].map((opt) => (
              <option className="text-[#000E14]" value={opt}>
                {opt} Plot
              </option>
            ))}
            {/* <option className="hidden" value="" selected>
              Agricultural/commercial/Residential
            </option>
            <option className="text-[#000E14]" value="agricultural">
              Agricultural
            </option>
            <option className="text-[#000E14]" value="commercial">
              Commercial
            </option>
            <option className="text-[#000E14]" value="residential">
              Residential
            </option> */}
          </select>
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="sub_category"
            className="text-[#344054] text-[14px] font-medium"
          >
            Property Sub Category
            <span className="text-[#C62F39]"> *</span>
          </label>
          <select
            value={plotDetails.sub_category}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");
              setPlotDetails((prevState) => ({
                ...prevState,
                sub_category: e.target.value,
              }));
            }}
            placeholder="Agricultural/Commercial/Residential"
            id="sub_category"
            type="text"
            className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] ${
              plotDetails.sub_category === ""
                ? "text-[#C7C7C7]"
                : "text-[#000E14]"
            }`}
          >
            <option className="hidden" value="" selected>
              {[
                "Residential",
                "Commercial",
                "Industrial",
                "Residential cum Commercial",
                "Other",
              ].join(" Plot / ")}
            </option>

            {[
              "Residential",
              "Commercial",
              "Industrial",
              "Residential cum Commercial",
              "Other",
            ].map((opt) => (
              <option className="text-[#000E14]" value={opt}>
                {opt} Plot
              </option>
            ))}
            {/* <option className="hidden" value="" selected>
              Agricultural/Commercial/Residential
            </option>
            <option className="text-[#000E14]" value="agricultural">
              Agricultural
            </option>
            <option className="text-[#000E14]" value="commercial">
              Commercial
            </option>
            <option className="text-[#000E14]" value="residential">
              Residential
            </option> */}
          </select>
        </div>
        {plotDetails.type === "single" && (
          <div className="flex flex-col w-full">
            <label
              htmlFor="availability"
              className="text-[#344054] text-[14px] font-medium"
            >
              Availability
              <span className="text-[#C62F39]"> *</span>
            </label>
            <select
              value={plotDetails.availability}
              onChange={(e) => {
                e.target.classList.remove("!border-[#C62F39]");
                setPlotDetails((prevState) => ({
                  ...prevState,
                  availability: e.target.value,
                }));
              }}
              placeholder="Available/Sold"
              id="availability"
              type="text"
              className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] ${
                plotDetails.availability === ""
                  ? "text-[#C7C7C7]"
                  : "text-[#000E14]"
              }`}
            >
              <option className="hidden" value="" selected>
                Available/Sold
              </option>
              <option className="text-[#000E14]" value="available">
                Available
              </option>
              <option className="text-[#000E14]" value="sold">
                Sold
              </option>
            </select>
          </div>
        )}
      </div>
      {plotDetails.type === "single" && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-[16px] mt-[24px]">
            <div className="flex flex-col w-full">
              <label
                htmlFor="area"
                className="text-[#344054] text-[14px] font-medium"
              >
                Property Area
                <span className="text-[#C62F39]"> *</span>
              </label>
              <input
                value={plotDetails.area}
                onChange={(e) => {
                  e.target.classList.remove("!border-[#C62F39]");
                  setPlotDetails((prevState) => ({
                    ...prevState,
                    area: e.target.value,
                  }));
                }}
                onKeyDown={(e) => {
                  // Allow backspace, delete, and arrow keys
                  if (
                    e.key === 'Backspace' ||
                    e.key === 'Delete' ||
                    e.key === 'ArrowLeft' ||
                    e.key === 'ArrowRight'
                  ) {
                    return;
                  }
              
                  // Prevent non-numeric input and exclude 'e' and 'E'
                  if (!/[\d+-.]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                placeholder="Please enter property area e.g. 27000"
                id="area"
                type="number"
                className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
              />
            </div>
            <div className="flex flex-col w-full">
              <label
                htmlFor="areaunit"
                className="text-[#344054] text-[14px] font-medium"
              >
                Area Unit
                <span className="text-[#C62F39]"> *</span>
              </label>
              <select
                value={plotDetails.area_unit}
                onChange={(e) => {
                  e.target.classList.remove("!border-[#C62F39]");
                  setPlotDetails((prevState) => ({
                    ...prevState,
                    area_unit: e.target.value,
                  }));
                }}
                placeholder="Agricultural/commercial/Residential"
                id="areaunit"
                type="text"
                className={`max-w-[180px] mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]`}
              >
                {units.map((unit) => (
                  <option value={unit.value}>{unit.label}</option>
                ))}
                {/* <option value="m2" selected>
                  Square Meter
                </option>
                <option value="ft2">Square Meters</option>
                <option value="g">Guntha</option>
                <option value="ac">Acre</option>
                <option value="ha">Hectare</option> */}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-[16px] mt-[24px]">
            <div className="flex flex-col w-full">
              <label
                htmlFor="front"
                className="text-[#344054] text-[14px] font-medium"
              >
                Front
                <span className="text-[#C62F39]"> *</span>
              </label>
              <input
                value={plotDetails.front}
                onChange={(e) => {
                  e.target.classList.remove("!border-[#C62F39]");
                  setPlotDetails((prevState) => ({
                    ...prevState,
                    front: e.target.value,
                  }));
                }}
                placeholder="e.g. 10"
                id="front"
                type="number"
                className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
              />
            </div>
            <div className="flex flex-col w-full">
              <label
                htmlFor="depth"
                className="text-[#344054] text-[14px] font-medium"
              >
                Depth
                <span className="text-[#C62F39]"> *</span>
              </label>
              <input
                value={plotDetails.depth}
                onChange={(e) => {
                  e.target.classList.remove("!border-[#C62F39]");
                  setPlotDetails((prevState) => ({
                    ...prevState,
                    depth: e.target.value,
                  }));
                }}
                placeholder="e.g. 10"
                id="depth"
                type="number"
                className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
              />
            </div>
            <div className="flex flex-col w-full">
              <label
                htmlFor="areaunit"
                className="text-[#344054] text-[14px] font-medium"
              >
                Front & Depth Unit
                <span className="text-[#C62F39]"> *</span>
              </label>
              <select
                value={plotDetails.front_unit}
                onChange={(e) => {
                  e.target.classList.remove("!border-[#C62F39]");
                  setPlotDetails((prevState) => ({
                    ...prevState,
                    front_unit: e.target.value,
                  }));
                }}
                placeholder="Front Depth Unit"
                id="areaunit"
                type="text"
                className={`mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]`}
              >
                {lengthunits.map((unit) => (
                  <option value={unit.value}>{unit.label}</option>
                ))}
                {/* <option value="m2" selected>
                  Square Meter
                </option>
                <option value="ft2">Square Meters</option>
                <option value="g">Guntha</option>
                <option value="ac">Acre</option>
                <option value="ha">Hectare</option> */}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-[16px] mt-[24px]">
            <div className="flex flex-col w-full">
              <label
                htmlFor="facing"
                className="text-[#344054] text-[14px] font-medium"
              >
                Property Facing Direction
                <span className="text-[#C62F39]"> *</span>
              </label>
              <select
                value={plotDetails.facing}
                onChange={(e) => {
                  e.target.classList.remove("!border-[#C62F39]");
                  setPlotDetails((prevState) => ({
                    ...prevState,
                    facing: e.target.value,
                  }));
                }}
                placeholder="Select the direction"
                id="facing"
                type="text"
                className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] ${
                  plotDetails.facing === ""
                    ? "text-[#C7C7C7]"
                    : "text-[#000E14]"
                }`}
              >
                <option className="hidden" value="" selected>
                  Select the direction
                </option>
                <option className="text-[#000E14]" value="north">
                  North
                </option>
                <option className="text-[#000E14]" value="south">
                  South
                </option>
                <option className="text-[#000E14]" value="east">
                  East
                </option>
                <option className="text-[#000E14]" value="west">
                  West
                </option>
                <option className="text-[#000E14]" value="north-east">
                  North-East
                </option>
                <option className="text-[#000E14]" value="north-west">
                  North–West
                </option>
                <option className="text-[#000E14]" value="south-east">
                  South-East
                </option>
                <option className="text-[#000E14]" value="south-west">
                  South-West
                </option>
              </select>
            </div>
            <div className="flex flex-col w-full">
              <label
                htmlFor="access_road"
                className="text-[#344054] text-[14px] font-medium"
              >
                Access Road
                <span className="text-[#C62F39]"> *</span>
              </label>
              <select
                // value={plotDetails.access_road}
                onChange={(e) => {
                  e.target.classList.remove("!border-[#C62F39]");
                  if (e.target.value === "Other") {
                    setIsCustomAccess(true);
                    setPlotDetails((prevState) => ({
                      ...prevState,
                      access_road: "",
                    }));
                  } else {
                    setIsCustomAccess(false);
                    setPlotDetails((prevState) => ({
                      ...prevState,
                      access_road: e.target.value,
                    }));
                  }
                }}
                placeholder="Width of access Road"
                id="access_road"
                type="text"
                className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] ${
                  plotDetails.access_road === ""
                    ? "text-[#C7C7C7]"
                    : "text-[#000E14]"
                }`}
              >
                <option className="hidden" value="" selected>
                Size of Access road
                </option>
                <option className="text-[#000E14]" value="6 Meters">
                  6 Meters
                </option>
                <option className="text-[#000E14]" value="9 Meters">
                  9 Meters
                </option>
                <option className="text-[#000E14]" value="12 Meters">
                  12 Meters
                </option>
                <option className="text-[#000E14]" value="18 Meters">
                  18 Meters
                </option>
                <option className="text-[#000E14]" value="24 Meters">
                  24 Meters
                </option>
                <option className="text-[#000E14]" value="36 Meters">
                  36 Meters
                </option>
                <option className="text-[#000E14]" value="Other">
                  Other
                </option>
              </select>
            </div>
            {isCustomAccess && (
              <div className="flex flex-col w-full">
                <label
                  htmlFor="customaccess"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Type Access Road distance
                  <span className="text-[#C62F39]"> *</span>
                </label>
                <input
                  value={plotDetails.access_road}
                  onChange={(e) => {
                    e.target.classList.remove("!border-[#C62F39]");
                    setPlotDetails((prevState) => ({
                      ...prevState,
                      access_road: e.target.value,
                    }));
                  }}
                  onKeyDown={(e) => {
                    // Allow backspace, delete, and arrow keys
                    if (
                      e.key === 'Backspace' ||
                      e.key === 'Delete' ||
                      e.key === 'ArrowLeft' ||
                      e.key === 'ArrowRight'
                    ) {
                      return;
                    }
                
                    // Prevent non-numeric input and exclude 'e' and 'E'
                    if (!/[\d+-.]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  placeholder="Width of access Road"
                  id="customaccess"
                  type="text"
                  className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
                />
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default Details;
