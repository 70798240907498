import { Icon } from "@iconify/react";
import React from "react";
import { showToast } from "../../utils/showToast";

const Documents = ({ plotDetails, setPlotDetails }) => {
  return (
    <section className="mt-[32px]">
      <div className="flex items-center gap-[12px]">
        <h4 className="text-[20px] text-black font-medium">Document Upload</h4>
      </div>

      {plotDetails.documents?.length === 0 &&  (
        <button
          onClick={() => {
            setPlotDetails((prevState) => {
              let documents = prevState.documents;
              let tempArray = [...documents];
              tempArray.push({ name: "", file: "" });
              return { ...prevState, documents: [...tempArray] };
            });
            // setDocuments((prevState) => [
            //   ...prevState,
            //   { name: "", file: "" },
            // ]);
          }}
          className="mt-[24px] px-[16px] py-[12px] text-[16px] font-medium text-[#666666] border-[1px] border-[#666666] rounded-regular"
        >
          Add New Document
        </button>
      )}

      {plotDetails.documents?.map((document, index) => {
        var isOtherSelected = false;
        return (
          <div
            className="mt-[24px] grid grid-cols-1 md:grid-cols-3 gap-[16px]"
            key={`${index}__document`}
          >
            <div className="flex justify-evenly gap-[8px]">
              <div className="flex flex-col w-full">
                <label
                  htmlFor={`documentname_${index}`}
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Document Type <span className="text-[#C62F39]"> *</span>
                </label>
                <select
                  id={`documentname_${index}`}
                  value={
                    ["Map", "NA Order"].includes(document.name)
                      ? document.name
                      : "Other"
                  }
                  onChange={(e) => {
                    e.target.classList.remove("!border-[#C62F39]");

                    if (e.target.value === "Other") {
                      window.document
                        .getElementById(`showtypename_${index}`)
                        .classList.remove("hidden");
                    } else {
                      window.document
                        .getElementById(`showtypename_${index}`)
                        .classList.add("hidden");

                      isOtherSelected = false;
                      setPlotDetails((prevState) => {
                        let documents = prevState.documents;
                        let tempArray = [...documents];
                        tempArray[index]["name"] = e.target.value;
                        return { ...prevState, documents: [...tempArray] };
                      });
                    }
                    // setDocuments((prevState) => {
                    //   let tempArray = [...prevState];
                    //   tempArray[index]["name"] = e.target.value;
                    //   return [...tempArray];
                    // });
                  }}
                  placeholder="Select the distance from access road"
                  type="text"
                  className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] ${
                    document.name === "" ? "text-[#C7C7C7]" : "text-[#000E14]"
                  }`}
                >
                  <option className="hidden" value="" selected>
                    Select document name
                  </option>
                  <option className="text-[#000E14]" value="NA Order">
                    NA Order
                  </option>
                  <option className="text-[#000E14]" value="Map">
                    Map
                  </option>
                  <option className="text-[#000E14]" value="Other">
                    Other
                  </option>
                </select>
              </div>
              <div
                className={`flex flex-col w-full ${
                  ["Map", "NA Order"].includes(document.name) ? "hidden" : ""
                }`}
                id={`showtypename_${index}`}
              >
                <label
                  htmlFor={`typename__${index}`}
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Type Name
                  <span className="text-[#C62F39]"> *</span>
                </label>
                <input
                  value={document.name}
                  onChange={(e) => {
                    e.target.classList.remove("!border-[#C62F39]");
                    e.target.classList.remove("!border-[#C62F39]");

                    setPlotDetails((prevState) => {
                      let documents = prevState.documents;
                      let tempArray = [...documents];
                      tempArray[index]["name"] = e.target.value;
                      return { ...prevState, documents: [...tempArray] };
                    });
                  }}
                  placeholder="Document Name"
                  id={`typename__${index}`}
                  type="text"
                  className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <label className="text-[#344054] text-[14px] font-medium">
                Upload Document <span className="text-[#C62F39]"> *</span>
              </label>
              <div className="flex items-center mt-[8px] gap-[16px]">
                <label
                  htmlFor={`documentfile_${index}`}
                  id={`documentfilelabel_${index}`}
                  className="w-full bg-[#F6F6F6] flex items-center px-[16px] py-[12px] rounded-regular border-dashed border-[1px] border-[#367DE7] cursor-pointer text-[#666666] gap-[8px] text-[16px]"
                >
                  <Icon icon="ic:outline-cloud-upload" width={24} />
                  {document.file?.name ? document.file?.name : "Upload"}
                  <input
                    id={`documentfile_${index}`}
                    type="file"
                    className="hidden"
                    onChange={(e) => {
                      window.document
                        .getElementById(`documentfilelabel_${index}`)
                        ?.classList?.remove("!border-[#C62F39]");
                      if (e.target.files[0].size > 5242880) {
                        showToast(
                          "Document size should be less than 5MB",
                          "error"
                        );
                        e.value = "";
                        return;
                      }
                      setPlotDetails((prevState) => {
                        let documents = prevState.documents;
                        let tempArray = [...documents];
                        tempArray[index]["file"] = e.target.files[0];
                        return { ...prevState, documents: [...tempArray] };
                      });
                      // setDocuments((prevState) => {
                      //   let tempArray = [...prevState];
                      //   tempArray[index]["file"] = e.target.files[0];
                      //   return [...tempArray];
                      // });
                    }}
                    accept="application/pdf"
                  />
                </label>
              </div>
            </div>

            <div className="flex gap-[16px] items-end">
              <div className="py-[12px]">
                <Icon
                  icon="ic:baseline-remove-circle-outline"
                  color="#B42318"
                  width={24}
                  className="cursor-pointer"
                  onClick={() => {
                    setPlotDetails((prevState) => {
                      let documents = prevState.documents;
                      let tempArray = [...documents];
                      tempArray.splice(index, 1);
                      return { ...prevState, documents: [...tempArray] };
                    });
                    // setDocuments((prevState) => {
                    //   let tempArray = [...prevState];
                    //   tempArray.splice(index, 1);
                    //   return [...tempArray];
                    // });
                  }}
                />
              </div>
              {index === plotDetails.documents?.length - 1 && (
                <button
                  onClick={() => {
                    setPlotDetails((prevState) => {
                      let documents = prevState.documents;
                      let tempArray = [...documents];
                      tempArray.push({ name: "", file: "" });
                      return { ...prevState, documents: [...tempArray] };
                    });
                    // setDocuments((prevState) => [
                    //   ...prevState,
                    //   { name: "", file: "" },
                    // ]);
                  }}
                  className="px-[16px] py-[12px] text-[16px] font-medium text-[#666666] border-[1px] border-[#666666] rounded-regular"
                >
                  Add New Document
                </button>
              )}
            </div>
          </div>
        );
      })}
      {plotDetails.type === "single" && (
        <>
          <div className="flex flex-col w-full mt-[24px]">
            <label className="text-[#344054] text-[14px] font-medium">
              Upload Photos
            </label>
            <div className="flex flex-wrap items-center mt-[8px] gap-[16px]">
              <label className="w-[140px] h-[140px] bg-[#F6F6F6] flex flex-col justify-center items-center px-[16px] py-[12px] rounded-regular border-dashed border-[1px] border-[#367DE7] cursor-pointer text-[#838383] gap-[8px] text-[16px]">
                <Icon
                  icon="material-symbols:photo-camera-outline"
                  width={24}
                  color="#838383"
                />
                Upload Photo
                <input
                  multiple
                  accept="image/*"
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    let containsLargeFile = false;
                    [...e.target.files]?.map((image) => {
                      if (image.size > 5242880) {
                        showToast(
                          "Image size should be less than 5MB",
                          "error"
                        );
                        e.value = "";
                        containsLargeFile = true;
                      }
                      return false;
                    });
                    if (!containsLargeFile) {
                      setPlotDetails((prevState) => {
                        let images = prevState.images;
                        let tempArray = [...images];
                        tempArray.push(...e.target.files);
                        return { ...prevState, images: [...tempArray] };
                      });
                    }
                    // setImages((prevState) => [...prevState, ...e.target.files]);
                  }}
                />
              </label>
              {plotDetails.images.map((image, index) => (
                <div className="w-[140px] h-[140px] rounded-regular relative">
                  <button
                    className="absolute right-[8px] top-[8px]"
                    onClick={() => {
                      setPlotDetails((prevState) => {
                        let images = prevState.images;
                        let tempArray = [...images];
                        tempArray.splice(index, 1);
                        return { ...prevState, images: [...tempArray] };
                      });
                    }}
                  >
                    <Icon icon="mdi:close-circle" width={20} color="#fff" />
                  </button>
                  <img
                    src={URL.createObjectURL(image)}
                    alt=""
                    className="w-full h-full cover rounded-regular"
                  />
                </div>
              ))}
            </div>
          </div>

          {plotDetails.videos.length === 0 && (
            <button
              onClick={() => {
                setPlotDetails((prevState) => {
                  let videos = prevState.videos;
                  let tempArray = [...videos];
                  tempArray.push("");
                  return { ...prevState, videos: [...tempArray] };
                });
                // setDocuments((prevState) => [
                //   ...prevState,
                //   { name: "", file: "" },
                // ]);
              }}
              className="mt-[24px] px-[16px] py-[12px] text-[16px] font-medium text-[#666666] border-[1px] border-[#666666] rounded-regular"
            >
              Add New Video Link
            </button>
          )}

          {plotDetails.videos.map((video, index) => {
            var isOtherSelected = false;
            return (
              <div
                className="mt-[24px] grid grid-cols-1 md:grid-cols-3 gap-[16px]"
                key={`${index}__document`}
              >
                <div className="flex justify-evenly gap-[8px] col-span-2">
                  <div className="flex flex-col w-full">
                    <input
                      id={`videos_${index}`}
                      value={video}
                      onChange={(e) => {
                        e.target.classList.remove("!border-[#C62F39]");
                        setPlotDetails((prevState) => {
                          let videos = prevState.videos;
                          let tempArray = [...videos];
                          tempArray[index] = e.target.value;
                          return { ...prevState, videos: [...tempArray] };
                        });
                        // setHighlights((prevState) => {
                        //   return [...tempArray];
                        // });
                      }}
                      placeholder="Paste Youtube video link here"
                      type="text"
                      className="w-full rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] mt-[8px]"
                    />
                  </div>
                </div>

                <div className="flex gap-[16px] items-end">
                  <div className="py-[12px]">
                    <Icon
                      icon="ic:baseline-remove-circle-outline"
                      color="#B42318"
                      width={24}
                      className="cursor-pointer"
                      onClick={() => {
                        setPlotDetails((prevState) => {
                          let videos = prevState.videos;
                          let tempArray = [...videos];
                          tempArray.splice(index, 1);
                          return { ...prevState, videos: [...tempArray] };
                        });
                        // setDocuments((prevState) => {
                        //   let tempArray = [...prevState];
                        //   tempArray.splice(index, 1);
                        //   return [...tempArray];
                        // });
                      }}
                    />
                  </div>
                  {index === plotDetails.videos.length - 1 && (
                    <button
                      onClick={() => {
                        setPlotDetails((prevState) => {
                          let videos = prevState.videos;
                          let tempArray = [...videos];
                          tempArray.push("");
                          return { ...prevState, videos: [...tempArray] };
                        });
                        // setDocuments((prevState) => [
                        //   ...prevState,
                        //   { name: "", file: "" },
                        // ]);
                      }}
                      className="px-[16px] py-[12px] text-[16px] font-medium text-[#666666] border-[1px] border-[#666666] rounded-regular"
                    >
                      Add New Video Link
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </section>
  );
};

export default Documents;
