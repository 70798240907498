/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { blockProfile, getUsers } from "../apis/apiClient";
import ExpandedRows from "../components/ExpandedRows";
import Header from "../components/Header/AdminHeader";
import { fetchedClip, interestedClip } from "../components/shared/Clips";
import tableCustomStyles from "../components/shared/tableStyle";
import { downloadCSV } from "../utils/downloadCSV";
import { showToast } from "../utils/showToast";
import { fuzzyMatch } from "../utils/sharedFunc";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="px-[12px] py-[10px] border-[1px] border-[#D0D5DD] bg-white rounded-regular flex items-center w-full max-w-[350px] mr-[16px]">
      <Icon
        icon="material-symbols:search"
        color="#858585"
        width="28"
        height="28"
      />
      <input
        id="search"
        type="text"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        className="outline-none text-[16px] font-medium text-[#858585] ml-[12px] w-full"
      />
    </div>
    <button
      className="rounded-regular bg-[#0259DB] py-[10px] px-[24px] text-[16px] text-white font-medium mt-[8px] md:mt-0"
      type="button"
      onClick={onClear}
    >
      Search
    </button>
  </>
);

// const tableData = [
//   {
//     id: 9876543210,
//     buyer: "Balaji Sankaran",
//     seller: "Kalyani Hayre",
//     bid: 3250,
//     isInterested: true,
//     isFetchedInfo: false,
//     expsell: 3000,
//   },
//   {
//     id: 9876543211,
//     buyer: "Narmada Chokshi",
//     seller: "Raju Chahal",
//     bid: 3250,
//     isInterested: false,
//     isFetchedInfo: true,
//     expsell: 3000,
//   },
//   {
//     id: 9876543212,
//     buyer: "Narendra Singh",
//     seller: "K. L.  Mishra",
//     bid: 3250,
//     isInterested: true,
//     isFetchedInfo: true,
//     expsell: 3000,
//   },
//   {
//     id: 9876543213,
//     buyer: "Devika Singhal",
//     seller: "Prabhat Hari",
//     bid: 3250,
//     isInterested: true,
//     isFetchedInfo: false,
//     expsell: 3000,
//   },
//   {
//     id: 9876543214,
//     buyer: "Kirti Datta",
//     seller: "Owais Sastry",
//     bid: "-",
//     isInterested: true,
//     isFetchedInfo: true,
//     expsell: 3000,
//   },
//   {
//     id: 9876543215,
//     buyer: "Balaji Sankaran",
//     seller: "Kalyani Hayre",
//     bid: 3250,
//     isInterested: true,
//     isFetchedInfo: false,
//     expsell: 3000,
//   },
//   {
//     id: 9876543216,
//     buyer: "Narmada Chokshi",
//     seller: "Raju Chahal",
//     bid: 3250,
//     isInterested: false,
//     isFetchedInfo: true,
//     expsell: 3000,
//   },
//   {
//     id: 9876543217,
//     buyer: "Narendra Singh",
//     seller: "K. L.  Mishra",
//     bid: 3250,
//     isInterested: true,
//     isFetchedInfo: true,
//     expsell: 3000,
//   },
//   {
//     id: 9876543218,
//     buyer: "Devika Singhal",
//     seller: "Prabhat Hari",
//     bid: 3250,
//     isInterested: true,
//     isFetchedInfo: false,
//     expsell: 3000,
//   },
//   {
//     id: 9876543219,
//     buyer: "Kirti Datta",
//     seller: "Owais Sastry",
//     bid: "-",
//     isInterested: true,
//     isFetchedInfo: true,
//     expsell: 3000,
//   },
// ];

const Users = () => {
  const [tableData, setTableData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);

  const profile = useSelector((state) => state.profile);

  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.isLoggedIn) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const getUsersData = async () => {
      try {
        setIsUserDataLoading(true);
        const response = await getUsers();
        setIsUserDataLoading(false);
        setTableData(response.users);
        setFilteredItems(response.users);
      } catch (e) {
        console.log(e);
        setIsUserDataLoading(false);
      }
    };
    getUsersData();
  }, []);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText !== "") {
        setFilteredItems((prevState) => {
          return tableData.filter(
            (item) =>
              (item.mobile_number &&
                fuzzyMatch(
                  filterText.toLowerCase(),
                  item.mobile_number.toString().toLowerCase()
                )) ||
              (item.name &&
                fuzzyMatch(
                  filterText.toLowerCase(),
                  item.name.toLowerCase()
                )) ||
              (item.email &&
                fuzzyMatch(filterText.toLowerCase(), item.email.toLowerCase()))
          );
        });
      } else {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        setFilteredItems(tableData);
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => {
          setFilterText(e.target.value);
          if (e.target.value === "") {
            setFilteredItems(tableData);
          }
        }}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row, index, data) => (
        <h6 className="text-[14px] font-semibold text-primaryColor">
          {row.name}
        </h6>
      ),
      style: {
        borderRight: "1px solid #ECECEC",
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "250px",
    },
    {
      name: "Mobile number",
      selector: (row) => row.mobile_number,
    },
    {
      name: "Listed Properties",
      selector: (row) => row.listings,
      cell: (row) => row.listings?.length,
    },
    {
      name: "Offered Properties",
      selector: (row) => row.bids,
      cell: (row) => row.bids?.length,
    },
    {
      name: "Interested",
      selector: (row) => row.interested,
      cell: (row) => row.interested?.length,
    },
    {
      name: "Fetched Contact Info.",
      selector: (row) => row.contacts_fetched,
      cell: (row) => row.contacts_fetched?.length,
    },
    {
      title: "",
      dataIndex: "",
      width: "70px",
      cell: (row, index) => (
        <div className="group inline-block relative">
          <button>
            <Icon width="24px" icon="material-symbols:more-vert" />
            <ul className="list-none bg-white border rounded-regular transform scale-0 group-hover:scale-100 absolute transition duration-100 ease-in-out origin-top-right p-[8px] w-[300px] max-h-[260px] overflow-scroll right-0 z-50">
              <li className="">
                <Link
                  className="rounded-t py-[12px] px-[32px] block whitespace-no-wrap text-[16px] font-medium text-[#333333] hover:bg-[#f6f6f6] text-left"
                  to={`/users/${row.id}`}
                >
                  View Full Profile
                </Link>
              </li>
              <li className="">
                <button
                  className="w-full py-[12px] px-[32px] block whitespace-no-wrap text-[16px] font-medium text-[#333333] hover:bg-[#f6f6f6] text-left"
                  onClick={async (e) => {
                    try {
                      const response = await blockProfile(row.id);
                      if (response.status === "success") {
                        e.target.innerText === "Unblock"
                          ? showToast(`${row.name} unblocked`, "success")
                          : showToast(`${row.name} blocked`, "success");
                        e.target.innerText === "Unblock"
                          ? (e.target.innerText = "Block")
                          : (e.target.innerText = "Unblock");
                      } else {
                        showToast(
                          "Some error occurred, plase try again later.",
                          "error"
                        );
                      }
                    } catch (e) {
                      showToast(
                        "Some error occurred, plase try again later.",
                        "error"
                      );
                      console.log(e);
                    }
                  }}
                >
                  {row.isBlocked ? "Unblock" : "Block"}
                </button>
              </li>
              <li className="">
                <Link
                  className="rounded-b py-[12px] px-[32px] block whitespace-no-wrap text-[16px] font-medium text-[#333333] hover:bg-[#f6f6f6] text-left"
                  to={`/users/${row.id}#edituser`}
                >
                  Change Mobile Number
                </Link>
              </li>
              <li className="">
                <Link
                  className="rounded-t py-[12px] px-[32px] block whitespace-no-wrap text-[16px] font-medium text-[#333333] hover:bg-[#f6f6f6] text-left"
                  to={`/users/${row.id}#edituser`}
                >
                  Change Email ID
                </Link>
              </li>
              <li className="">
                <Link
                  className="py-[12px] px-[32px] block whitespace-no-wrap text-[16px] font-medium text-[#333333] hover:bg-[#f6f6f6] text-left"
                  to={`/users/${row.id}#verifyuser`}
                >
                  Approve Blue Tick
                </Link>
              </li>
            </ul>
          </button>
        </div>
      ),
    },
  ];

  const Export = ({ onExport }) => (
    <div className="flex gap-[24px] my-[28px]">
      <button
        onClick={(e) => onExport(e.target.value)}
        className="w-[140px] text-center justify-center flex gap-[10px] py-[10px] px-[26px] rounded-regular border-[1px] border-[#0259DB] text-[16px] text-[#0259DB] items-center"
      >
        Export
      </button>
    </div>
  );

  const actionsMemo = React.useMemo(
    () => (
      <Export
        onExport={() => downloadCSV(tableData, `Rempo - Users Data.csv`)}
      />
    ),
    [tableData]
  );

  return (
    <div className="bg-[#F9FCFF] min-h-screen">
      <Header />
      <div className="mx-auto max-w-screen-xl pt-[40px] pb-[64px] px-[24px] xl:px-0">
        <h1 className="text-[32px] font-semibold text-[#000E14]">
          User Management
        </h1>
        <section className="relative datatableWithDropdown">
          {/* <div className='my-[28px]'></div> */}
          {isUserDataLoading ? (
            <div className="h-[320px] flex items-center justify-center">
              <Oval
                height={52}
                width={52}
                color="#0259DB"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#0259DB"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <DataTable
              actions={actionsMemo}
              columns={columns}
              data={filteredItems}
              responsive
              striped
              highlightOnHover
              noDataComponent={
                <span className="px-[16px] py-[20px]">{`No data found`}</span>
              }
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              customStyles={{
                ...tableCustomStyles,
                headRow: {
                  style: {
                    padding: 0,
                  },
                },
                headCells: {
                  style: {
                    padding: "20px 16px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#666666",
                    "&:first-of-type": {
                      borderRight: "1px solid #ECECEC",
                    },
                  },
                },
                rows: {
                  style: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#484848",
                    backgroundColor: "#fff",
                    padding: 0,
                    "&:not(:last-of-type)": {
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1px",
                      borderBottomColor: "#ECECEC",
                    },
                  },
                  stripedStyle: {
                    backgroundColor: "#FAFAFA",
                    color: "#484848",
                  },
                },
                cells: {
                  style: {
                    padding: "16px",
                    wordBreak: "break-word",
                  },
                },
                header: {
                  style: {
                    paddingRight: 0,
                    paddingLeft: 0,
                    zIndex: 1,
                    background: "transparent",
                  },
                },
                subHeader: {
                  style: {
                    width: "60%",
                    position: "absolute",
                    top: 0,
                    padding: "28px 0",
                    minHeight: "56px",
                    zIndex: 2,
                    background: "transparent",
                    justifyContent: "flex-start",
                    "@media screen and (max-width: 768px)": {
                      width: "100%",
                      position: "relative",
                    },
                  },
                },
              }}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default Users;
