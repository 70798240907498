import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HomeHeader from "../components/Header/HomeHeader";
import AdminHeader from "../components/Header/AdminHeader";
import { Icon } from "@iconify/react";
import { fuzzyMatch } from "../utils/sharedFunc"
import TabSelection from "../components/Library/TabSelection";
import ResourceSelector from "../components/Library/ResourceSelector";
import Blog from "./Blog";

const Resources = () => {
    const [listData, setListData] = useState([]);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [libraryData, setLibraryData] = useState({});
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    setListData(
      libraryData?.data?.locations.filter((article) =>
        fuzzyMatch(searchQuery.toLowerCase(), article.name.toLowerCase())
      )
    );
  }, [searchQuery, libraryData]);

  useEffect(() => {
    setListData(libraryData?.data?.locations);
  }, [libraryData]);

  return (
    <>
      <>
        {profile.isLoggedIn && profile.userData.role === "admin" ? (
          <AdminHeader />
        ) : (
          <HomeHeader isHome={false} />
        )}
      </>

      <div className="bg-white mx-auto max-w-screen-xl px-[24px]">
        <div className="mt-[24px]">
         <ResourceSelector selectedTab={'Articles'}/>

         <Blog isResource={true}/>
        </div>
      </div>
    </>
  );
};

export default Resources;
