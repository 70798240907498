import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import HomeHeader from "../components/Header/HomeHeader";
import "leaflet/dist/leaflet.css";
import Highlights from "../components/SellPlot/Highlights";
import Details from "../components/SellPlot/Details";
import Features from "../components/SellPlot/Features";
import LegalInfo from "../components/SellPlot/LegalInfo";
import Rate from "../components/SellPlot/Rate";
import Location from "../components/SellPlot/Location";
import Documents from "../components/SellPlot/Documents";
import PlotCard from "../components/SellPlot/Bulk/PlotCard";

import {
  draftPlot,
  getPlotByID,
  listNewPlot,
  updatePlot,
  deletePlotByID,
} from "../apis/apiClient";
import { showToast } from "../utils/showToast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getFileFromURL } from "../utils/getFileFromURL";
import convert from "convert-units";
import { convertArea } from "../utils/units";
import CSVReader from "react-csv-reader";
import { RTONumbers } from "../utils/RTO";
import { Oval } from "react-loader-spinner";
import { units } from "../utils/units";
import { lengthunits } from "../utils/units";
import axios from "axios";
import SampleBulkDownload from "../components/SampleBulkDownload";

// const defaultPlotDetails = ;

// const defaultBulkPlotDetails = ;

const sortFormData = (a, b) => {
  if (b === "documents") {
    return -1;
  } else if (b === "images") {
    return -1;
  }
  return 1;
};

function generateRandomString() {
  var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var randomString = "";

  for (var i = 0; i < 2; i++) {
    var randomIndex = Math.floor(Math.random() * chars.length);
    randomString += chars.charAt(randomIndex);
  }

  return randomString;
}

const SellPlot = () => {
  const [plotDetails, setPlotDetails] = useState({
    puid: Math.floor(100000000 + Math.random() * 900000000),
    owner_name: "",
    marketed_by: "",
    type: "",
    title: "",
    description: "",
    category: "",
    availability: "",
    sub_category: "",
    area: "",
    area_unit: "ft2",
    front_unit: "ft",
    front: "",
    depth: "",
    facing: "",
    access_road: "",
    highlights: [],
    features: [],
    rera_number: "",
    legal: {
      isFirstOwner: "",
      purchaseDeed: "",
      underMortage: "",
      previousPurchaseDeed: "",
    },
    rate: {
      rate: "",
      unit: "ft2",
      lumpsum: "",
      from: "",
      to: "",
      noquote: false,
    },
    location: { country: "India" },
    documents: [],
    images: [],
    videos: [],
  });
  const [bulkPlotDetails, setBulkPlotDetails] = useState([
    {
      scheme_puid: plotDetails.puid,
      puid: Math.floor(100000000 + Math.random() * 900000000),
      highlights: [],
      plot_name: "Plot No 1",
      access_road: "",
      facing: "",
      front: "",
      depth: "",
      availability: "available",
      images: [],
      videos: [],
      area: "",
      area_unit: "ft2",
      front_unit: "ft",
      rate: {
        rate: "",
        unit: "ft2",
        lumpsum: "",
        from: "",
        to: "",
        noquote: false,
      },
    },
    {
      scheme_puid: plotDetails.puid,
      puid: Math.floor(100000000 + Math.random() * 900000000),
      highlights: [],
      plot_name: "Plot No 2",
      access_road: "",
      facing: "",
      front: "",
      depth: "",
      availability: "available",
      images: [],
      videos: [],
      area: "",
      area_unit: "ft2",
      front_unit: "ft",
      rate: {
        rate: "",
        unit: "ft2",
        lumpsum: "",
        from: "",
        to: "",
        noquote: false,
      },
    },
  ]);

  const [plotCount, setPlotCount] = useState(bulkPlotDetails.length);

  const [selectedCSV, setSelectedCSV] = useState();
  const [isValid, setIsValid] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const { plotId } = useParams();

  const profile = useSelector((state) => state.profile);

  const [isLoading, setIsLoading] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    const handlePopstate = (event) => {
      // Your logic to handle back navigation
      // For example, show a confirmation dialog or perform necessary actions
      console.log("Navigating back");
      const confirmLeave = window.confirm(
        "Continue Without Saving Property As Draft?"
      );
      console.log(confirmLeave);
      if (!confirmLeave) {
        event.preventDefault();
        // Redirect only if the current URL is different from the previous on
      } else {
        navigate("/");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.history.pushState(null, ""); // Ensure we have at least one entry in the history
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    if (!profile.isLoggedIn) {
      navigate("/login");
    }

    if (location.pathname?.includes("editplot")) {
      fetchPlotDetails();
    } else {
      setPlotDetails({
        puid: Math.floor(100000000 + Math.random() * 900000000),
        owner_name: "",
        marketed_by: "" || profile?.userData?.name,
        type: "",
        title: "",
        description: "",
        category: "",
        sub_category: "",
        availability: "",
        area: "",
        area_unit: "ft2",
        front_unit: "ft",
        front: "",
        depth: "",
        facing: "",
        access_road: "",
        highlights: [],
        features: [],
        legal: {
          isFirstOwner: "",
          purchaseDeed: "",
          underMortage: "",
          previousPurchaseDeed: "",
        },
        rate: {
          rate: "",
          unit: "ft2",
          lumpsum: "",
          from: "",
          to: "",
          noquote: false,
        },
        location: { country: "India" },
        documents: [],
        images: [],
        videos: [],
      });
      setBulkPlotDetails([
        {
          scheme_puid: plotDetails.puid,
          puid: Math.floor(100000000 + Math.random() * 900000000),
          highlights: [],

          plot_name: "Plot No 1",
          access_road: "",
          facing: "",
          front: "",
          depth: "",
          availability: "available",
          images: [],
          videos: [],
          area: "",
          area_unit: "ft2",
          front_unit: "ft",
          rate: {
            rate: "",
            unit: "ft2",
            lumpsum: "",
            from: "",
            to: "",
            noquote: false,
          },
        },
        {
          scheme_puid: plotDetails.puid,
          puid: Math.floor(100000000 + Math.random() * 900000000),
          highlights: [],
          access_road: "",

          plot_name: "Plot No 2",
          facing: "",
          front: "",
          depth: "",
          availability: "available",
          images: [],
          videos: [],
          area: "",
          area_unit: "ft2",
          front_unit: "ft",
          rate: {
            rate: "",
            unit: "ft2",
            lumpsum: "",
            from: "",
            to: "",
            noquote: false,
          },
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (profile.userData?.name) {
      setPlotDetails((prevPlotDetails) => ({
        ...prevPlotDetails,
        marketed_by: profile.userData.name,
      }));
    }
  }, [profile.userData?.name]);

  useEffect(() => {
    if (
      plotDetails?.location.state &&
      plotDetails?.location.state !== "" &&
      plotDetails?.location.district &&
      plotDetails?.location.district !== "" &&
      !location.pathname.includes("editplot")
    ) {
      const stateDict = RTONumbers.filter(
        (obj) => obj.state === plotDetails?.location.state
      )[0];
      if (stateDict) {
        if (plotDetails.type === "single") {
          const puid = generatePUID(
            plotDetails?.location.state,
            plotDetails?.location.district
          );
          setPlotDetails((prevState) => ({ ...prevState, puid }));
        } else {
          const schemepuid = generatePUID(
            plotDetails?.location.state,
            plotDetails?.location.district
          );
          setPlotDetails((prevState) => ({ ...prevState, puid: schemepuid }));

          setBulkPlotDetails((prevState) => {
            let tempArray = [...prevState];
            tempArray.map((tmp, index) => {
              const puid = generatePUID(
                plotDetails?.location.state,
                plotDetails?.location.district
              );
              tempArray[index]["puid"] = puid;
              tempArray[index]["scheme_puid"] = schemepuid;
              return "";
            });

            return [...tempArray];
          });
        }
      }
    }
  }, [
    plotDetails?.location.state,
    plotDetails?.location.district,
    plotDetails.type,
    location.pathname,
  ]);

  function generatePUID(state, district) {
    const stateDict = RTONumbers.filter((obj) => obj.state === state)[0];
    if (stateDict) {
      return `${stateDict?.code}${
        stateDict?.codes[district]
          ? stateDict?.codes[district]
          : generateRandomString()
      }${Math.floor(1000000 + Math.random() * 9000000)}`;
    }
    return Math.floor(100000000 + Math.random() * 900000000);
  }

  const fetchPlotDetails = async () => {
    const response = await getPlotByID({
      plotId,
    });

    console.log("response", response);
    const documents = await Promise.all(
      response.documents?.map(async (document) => {
        const file = await getFileFromURL(document.file);
        return { name: document.name, file };
      })
    );

    const images =
      response.images &&
      (await Promise.all(
        response.images?.map(async (image) => await getFileFromURL(image))
      ));

    var area;

    if (response.area) {
      area = convertArea(response.area, "ft2", response.area_unit);
    }

    setPlotDetails({ ...response, documents, images, area });

    if (response.scheme) {
      response.scheme.scheme_puid = response.scheme.puid;
      delete response.scheme.id;
      delete response.scheme.puid;

      const documents = await Promise.all(
        response.scheme?.documents?.map(async (document) => {
          const file = await getFileFromURL(document.file);
          return { name: document.name, file };
        })
      );

      setPlotDetails({ ...response, ...response.scheme, documents });
    }

    if (response.type === "bulk") {
      if (response.plots) {
        setBulkPlotDetails(
          await Promise.all(
            response.plots?.map(async (plot) => {
              const images = await Promise.all(
                plot.images.map(async (image) => await getFileFromURL(image))
              );
              const area = convertArea(plot.area, "ft2", plot.area_unit);
              plot.area = area;
              plot.images = images;
              return plot;
            })
          )
        );
      } else {
        setBulkPlotDetails(
          await Promise.all(
            response.scheme?.plots?.map(async (plot) => {
              const images = await Promise.all(
                plot.images.map(async (image) => await getFileFromURL(image))
              );
              const area = convertArea(plot.area, "ft2", plot.area_unit);
              plot.area = area;
              plot.images = images;
              return plot;
            })
          )
        );
      }
    }
  };

  const handleProceed = async () => {
    setIsLoading(true);
    const isValid = isValidDetails();
    console.log("plotDetails", plotDetails);
    if (isValid) {
      const formData = new FormData();
      if (plotDetails.type === "single") {
        Object.keys(plotDetails)
          ?.sort(sortFormData)
          .forEach((key) => {
            if (key === "images") {
              plotDetails.images.forEach((image) => {
                formData.append("images", image);
              });
            } else if (key === "documents") {
              plotDetails.documents.forEach((document, i) => {
                const documentData = Object.entries(document);
                let key = "",
                  value = "";
                for (let previewKey in documentData) {
                  if (documentData[previewKey][0] === "file") {
                    value = documentData[previewKey][1];
                  } else {
                    key = documentData[previewKey][1];
                  }
                }
                formData.append(`documents[${key}]`, value);
              });
            } else if (key === "rate") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                // if (
                //   previewKey === "area_unit" &&
                //   plotDetails[key][previewKey] !== "ft2"
                // ) {
                //   let convertedArea = plotDetails[key][previewKey];
                //   if (plotDetails.area_unit === "g") {
                //     convertedArea = Number(plotDetails[key][previewKey]) * 1089;
                //   } else {
                //     convertedArea = convert(plotDetails[key][previewKey])
                //       .from(plotDetails.area_unit)
                //       .to("ft2");
                //   }
                //   formData.append(`${key}[${previewKey}]`, convertedArea);
                // } else {
                formData.append(
                  `rate[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
                // }
              }
            } else if (key === "legal") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                formData.append(
                  `legal[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
              }
            } else if (key === "location") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                if (previewKey === "latlng") {
                  formData.append(
                    "location[latlng][0]",
                    plotDetails[key][previewKey][0] || 0
                  );
                  formData.append(
                    "location[latlng][1]",
                    plotDetails[key][previewKey][1] || 0
                  );
                } else {
                  formData.append(
                    `location[${previewKey}]`,
                    plotDetails[key][previewKey]
                  );
                }
              }
            } else if (key === "features") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                for (let nestedObjKey in plotDetails[key][previewKey]) {
                  formData.append(
                    `features[${previewKey}][${nestedObjKey}]`,
                    plotDetails[key][previewKey][nestedObjKey]
                  );
                }
              }
            } else if (key === "highlights") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                formData.append(
                  `highlights[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
              }
            } else if (key === "videos") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                formData.append(
                  `videos[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
              }
            } else if (key === "views") {
              // append nested object
              if (plotDetails[key].length > 0) {
                for (let previewKey in plotDetails[key]) {
                  formData.append(
                    `views[${previewKey}]`,
                    plotDetails[key][previewKey]
                  );
                }
              } else {
                // formData.append(`views][]`, "");
              }
            } else if (key === "contacts_fetched") {
              // append nested object
              if (plotDetails[key].length > 0) {
                for (let previewKey in plotDetails[key]) {
                  formData.append(
                    `contacts_fetched[${previewKey}]`,
                    plotDetails[key][previewKey]
                  );
                }
              } else {
                // formData.append(`contacts_fetched][]`, "");
              }
            } else if (key === "interested") {
              // append nested object
              if (plotDetails[key].length > 0) {
                for (let previewKey in plotDetails[key]) {
                  formData.append(
                    `interested[${previewKey}]`,
                    plotDetails[key][previewKey]
                  );
                }
              } else {
                // formData.append(`interested][]`, "");
              }
            } else if (key === "bids") {
              // append nested object
              if (plotDetails[key].length > 0) {
                for (let previewKey in plotDetails[key]) {
                  formData.append(
                    `bids[${previewKey}]`,
                    plotDetails[key][previewKey]
                  );
                }
              } else {
                // formData.append(`plots[${index}][bids][]`, "");
              }
            } else if (key === "area") {
              if (plotDetails.area_unit !== "ft2") {
                // let convertedArea = plotDetails.area;
                const convertedArea = convertArea(
                  plotDetails.area,
                  plotDetails.area_unit,
                  "ft2"
                );
                // if (plotDetails.area_unit === "g") {
                //   convertedArea = Number(plotDetails.area) * 1089;
                // } else {
                //   convertedArea = convert(plotDetails.area)
                //     .from(plotDetails.area_unit)
                //     .to("ft2");
                // }
                formData.append("area", convertedArea);
              } else {
                formData.append("area", plotDetails[key]);
              }
            } else if (key === "listed_by") {
              if (typeof plotDetails.listed_by === "object") {
                formData.append("listed_by", plotDetails.listed_by.id);
              } else {
                formData.append("listed_by", plotDetails.listed_by);
              }
            } else {
              formData.append(key, plotDetails[key]);
            }
          });
      } else {
        delete plotDetails.listed_by;
        delete plotDetails.plots;
        bulkPlotDetails.forEach((plotData, index) => {
          if (location.pathname?.includes("editplot")) {
            formData.append(
              `plots[${index}][scheme]`,
              plotData.scheme
                ? typeof plotData.scheme === "object"
                  ? plotData.scheme.id
                  : plotData.scheme
                : plotDetails.id
            );
          }
          Object.keys(plotData)
            ?.sort(sortFormData)
            .forEach((key) => {
              if (key !== "puid" && key !== "id" && key !== "location") {
                delete plotDetails[key];
              }
              if (key === "listed_by") {
                return;
              } else if (key === "images") {
                plotData.images.map((image) => {
                  formData.append(`plots[${index}][images]`, image);
                  return image;
                });
              } else if (key === "scheme") {
              } else if (key === "rate") {
                // append nested object
                for (let previewKey in plotData[key]) {
                  console.log("previewKey", previewKey);
                  // if (previewKey !== "ft2") {
                  //   let convertedArea = plotData[key][previewKey];
                  //   if (plotData.area_unit === "g") {
                  //     convertedArea = Number(plotData[key][previewKey]) * 1089;
                  //   } else {
                  //     convertedArea = convert(plotData[key][previewKey])
                  //       .from(plotData.area_unit)
                  //       .to("ft2");
                  //   }
                  //   formData.append(
                  //     `plots[${index}][${key}][${previewKey}]`,
                  //     convertedArea
                  //   );
                  // } else {
                  formData.append(
                    `plots[${index}][rate][${previewKey}]`,
                    plotData[key][previewKey]
                  );
                  // }
                }
              } else if (key === "highlights") {
                // append nested object
                for (let previewKey in plotData[key]) {
                  formData.append(
                    `plots[${index}][highlights][${previewKey}]`,
                    plotData[key][previewKey]
                  );
                }
              } else if (key === "videos") {
                // append nested object
                for (let previewKey in plotData[key]) {
                  formData.append(
                    `plots[${index}][videos][${previewKey}]`,
                    plotData[key][previewKey]
                  );
                }
              } else if (key === "views") {
                // append nested object
                if (plotData[key].length > 0) {
                  for (let previewKey in plotData[key]) {
                    formData.append(
                      `plots[${index}][views][${previewKey}]`,
                      plotData[key][previewKey]
                    );
                  }
                } else {
                  // formData.append(`plots[${index}][views][]`, "");
                }
              } else if (key === "contacts_fetched") {
                // append nested object
                if (plotData[key].length > 0) {
                  for (let previewKey in plotData[key]) {
                    formData.append(
                      `plots[${index}][contacts_fetched][${previewKey}]`,
                      plotData[key][previewKey]
                    );
                  }
                } else {
                  // formData.append(`plots[${index}][contacts_fetched][]`, "");
                }
              } else if (key === "interested") {
                // append nested object
                if (plotData[key].length > 0) {
                  for (let previewKey in plotData[key]) {
                    formData.append(
                      `plots[${index}][interested][${previewKey}]`,
                      plotData[key][previewKey]
                    );
                  }
                } else {
                  // formData.append(`plots[${index}][interested][]`, "");
                }
              } else if (key === "bids") {
                // append nested object
                if (plotData[key].length > 0) {
                  for (let previewKey in plotData[key]) {
                    formData.append(
                      `plots[${index}][bids][${previewKey}]`,
                      plotData[key][previewKey]
                    );
                  }
                } else {
                  // formData.append(`plots[${index}][bids][]`, "");
                }
              } else if (key === "area") {
                if (plotData.area_unit !== "ft2") {
                  // let convertedArea = plotData.area;
                  const convertedArea = convertArea(
                    plotData.area,
                    plotData.area_unit,
                    "ft2"
                  );
                  // if (plotData.area_unit === "g") {
                  //   convertedArea = Number(plotData.area) * 1089;
                  // } else {
                  //   convertedArea = convert(plotData.area)
                  //     .from(plotData.area_unit)
                  //     .to("ft2");
                  // }
                  formData.append(`plots[${index}][${key}]`, convertedArea);
                } else {
                  formData.append(`plots[${index}][${key}]`, plotData[key]);
                }
              } else {
                console.log(
                  `plots[${index}][${key}]`,
                  plotData[key],
                  "fgdbhunijufbg"
                );

                formData.append(`plots[${index}][${key}]`, plotData[key]);
              }
            });
        });

        Object.keys(plotDetails)
          ?.sort(sortFormData)
          .forEach((key) => {
            if (key === "documents") {
              plotDetails.documents.map((document, i) => {
                console.log("documentdocument", document);
                const documentData = Object.entries(document);
                let key = "",
                  value = "";
                for (let previewKey in documentData) {
                  if (documentData[previewKey][0] === "file") {
                    value = documentData[previewKey][1];
                  } else {
                    key = documentData[previewKey][1];
                  }
                }
                formData.append(`documents[${key}]`, value);
                // formData.append("documents", document.file);
                return document;
              });
            } else if (key === "legal") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                formData.append(
                  `legal[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
              }
            } else if (key === "location") {
              // append nested object
              delete plotDetails?.location.coordinates;
              for (let previewKey in plotDetails[key]) {
                console.log(plotDetails[key][previewKey][0]);
                if (previewKey === "latlng") {
                  formData.append(
                    "location[latlng][0]",
                    plotDetails[key][previewKey][0] !== undefined
                      ? plotDetails[key][previewKey][0]
                      : 0
                  );
                  formData.append(
                    "location[latlng][1]",
                    plotDetails[key][previewKey][1] !== undefined
                      ? plotDetails[key][previewKey][1]
                      : 0
                  );
                } else {
                  formData.append(
                    `location[${previewKey}]`,
                    plotDetails[key][previewKey]
                  );
                }
              }
            } else if (key === "highlights") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                formData.append(
                  `highlights[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
              }
            } else if (key === "videos") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                formData.append(
                  `videos[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
              }
            } else if (key === "features") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                for (let nestedObjKey in plotDetails[key][previewKey]) {
                  formData.append(
                    `features[${previewKey}][${nestedObjKey}]`,
                    plotDetails[key][previewKey][nestedObjKey]
                  );
                }
              }
            } else {
              formData.append(key, plotDetails[key]);
            }
          });
      }
      // navigate("/subscription");
      try {
        let response;
        console.log(Object.keys(formData), formData, "FORMDATA");
        if (location.pathname?.includes("editplot")) {
          // for (var pair of formData.entries()) {
          //   console.log(pair[0] + ", " + pair[1]);
          // }
          response = await updatePlot(formData);
        } else {
          response = await listNewPlot(formData);
        }

        console.log(response);
        if (response.status === "success") {
          if (location.pathname?.includes("editplot")) {
            showToast(
              `Your Property id ${plotDetails.puid} updated Successfully!`,
              "success"
            );
          } else {
            showToast(
              `Your Property id ${plotDetails.puid} listed Successfully!`,
              "success"
            );
          }

          navigate("/profile", {
            state: {
              selectedTab: "listing",
            },
          });
        } else {
          showToast(`Some error occured, Please try again later`, "error");
          console.log(response);
        }
      } catch (e) {
        if (e.response?.status === 400) {
        } else {
          showToast(`Some error occured, Please try again later`, "error");
        }
        console.log("error", e);
      }
    } else {
      setIsLoading(false);
      showToast("Please fill all required fields", "error");
    }
  };

  const handleDraft = async () => {
    // setIsSavingDraft(true);
    if (plotDetails.title?.trim() === "") {
      showToast("Please fill title to save property as draft", "error");
    } else {
      const formData = new FormData();
      if (plotDetails.type === "single") {
        Object.keys(plotDetails)
          ?.sort(sortFormData)
          .forEach((key) => {
            if (key === "images") {
              plotDetails.images.forEach((image) => {
                formData.append("images", image);
              });
            } else if (key === "documents") {
              plotDetails.documents.forEach((document, i) => {
                const documentData = Object.entries(document);
                let key = "",
                  value = "";
                for (let previewKey in documentData) {
                  if (documentData[previewKey][0] === "file") {
                    value = documentData[previewKey][1];
                  } else {
                    key = documentData[previewKey][1];
                  }
                }
                formData.append(`documents[${key}]`, value);
              });
            } else if (key === "rate") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                // if (
                //   previewKey === "area_unit" &&
                //   plotDetails[key][previewKey] !== "ft2"
                // ) {
                //   let convertedArea = plotDetails[key][previewKey];
                //   if (plotDetails.area_unit === "g") {
                //     convertedArea = Number(plotDetails[key][previewKey]) * 1089;
                //   } else {
                //     convertedArea = convert(plotDetails[key][previewKey])
                //       .from(plotDetails.area_unit)
                //       .to("ft2");
                //   }
                //   formData.append(`${key}[${previewKey}]`, convertedArea);
                // } else {
                formData.append(
                  `rate[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
                // }
              }
            } else if (key === "legal") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                formData.append(
                  `legal[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
              }
            } else if (key === "location") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                if (previewKey === "latlng") {
                  formData.append(
                    "location[latlng][0]",
                    plotDetails[key][previewKey][0]
                  );
                  formData.append(
                    "location[latlng][1]",
                    plotDetails[key][previewKey][1]
                  );
                } else {
                  formData.append(
                    `location[${previewKey}]`,
                    plotDetails[key][previewKey]
                  );
                }
              }
            } else if (key === "features") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                for (let nestedObjKey in plotDetails[key][previewKey]) {
                  formData.append(
                    `features[${previewKey}][${nestedObjKey}]`,
                    plotDetails[key][previewKey][nestedObjKey]
                  );
                }
              }
            } else if (key === "highlights") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                formData.append(
                  `highlights[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
              }
            } else if (key === "videos") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                formData.append(
                  `videos[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
              }
            } else if (key === "views") {
              // append nested object
              if (plotDetails[key].length > 0) {
                for (let previewKey in plotDetails[key]) {
                  formData.append(
                    `views[${previewKey}]`,
                    plotDetails[key][previewKey]
                  );
                }
              } else {
                // formData.append(`views][]`, "");
              }
            } else if (key === "contacts_fetched") {
              // append nested object
              if (plotDetails[key].length > 0) {
                for (let previewKey in plotDetails[key]) {
                  formData.append(
                    `contacts_fetched[${previewKey}]`,
                    plotDetails[key][previewKey]
                  );
                }
              } else {
                // formData.append(`contacts_fetched][]`, "");
              }
            } else if (key === "interested") {
              // append nested object
              if (plotDetails[key].length > 0) {
                for (let previewKey in plotDetails[key]) {
                  formData.append(
                    `interested[${previewKey}]`,
                    plotDetails[key][previewKey]
                  );
                }
              } else {
                // formData.append(`interested][]`, "");
              }
            } else if (key === "bids") {
              // append nested object
              if (plotDetails[key].length > 0) {
                for (let previewKey in plotDetails[key]) {
                  formData.append(
                    `bids[${previewKey}]`,
                    plotDetails[key][previewKey]
                  );
                }
              } else {
                // formData.append(`plots[${index}][bids][]`, "");
              }
            } else if (key === "area") {
              if (plotDetails.area_unit !== "ft2") {
                // let convertedArea = plotDetails.area;
                const convertedArea = convertArea(
                  plotDetails.area,
                  plotDetails.area_unit,
                  "ft2"
                );
                // if (plotDetails.area_unit === "g") {
                //   convertedArea = Number(plotDetails.area) * 1089;
                // } else {
                //   convertedArea = convert(plotDetails.area)
                //     .from(plotDetails.area_unit)
                //     .to("ft2");
                // }
                formData.append("area", convertedArea);
              } else {
                formData.append("area", plotDetails[key]);
              }
            } else if (key === "listed_by") {
              if (typeof plotDetails.listed_by === "object") {
                formData.append("listed_by", plotDetails.listed_by.id);
              } else {
                formData.append("listed_by", plotDetails.listed_by);
              }
            } else {
              formData.append(key, plotDetails[key]);
            }
          });
      } else {
        delete plotDetails.listed_by;
        delete plotDetails.plots;
        bulkPlotDetails.forEach((plotData, index) => {
          if (location.pathname?.includes("editplot")) {
            formData.append(
              `plots[${index}][scheme]`,
              plotData.scheme
                ? typeof plotData.scheme === "object"
                  ? plotData.scheme.id
                  : plotData.scheme
                : plotDetails.id
            );
          }
          Object.keys(plotData)
            ?.sort(sortFormData)
            .forEach((key) => {
              if (key !== "puid" && key !== "id" && key !== "location") {
                delete plotDetails[key];
              }
              if (key === "listed_by") {
                return;
              } else if (key === "images") {
                plotData.images.map((image) => {
                  formData.append(`plots[${index}][images]`, image);
                  return image;
                });
              } else if (key === "scheme") {
              } else if (key === "rate") {
                // append nested object
                for (let previewKey in plotData[key]) {
                  // if (previewKey !== "ft2") {
                  //   let convertedArea = plotData[key][previewKey];
                  //   if (plotData.area_unit === "g") {
                  //     convertedArea = Number(plotData[key][previewKey]) * 1089;
                  //   } else {
                  //     convertedArea = convert(plotData[key][previewKey])
                  //       .from(plotData.area_unit)
                  //       .to("ft2");
                  //   }
                  //   formData.append(
                  //     `plots[${index}][${key}][${previewKey}]`,
                  //     convertedArea
                  //   );
                  // } else {
                  formData.append(
                    `plots[${index}][rate][${previewKey}]`,
                    plotData[key][previewKey]
                  );
                  // }
                }
              } else if (key === "highlights") {
                // append nested object
                for (let previewKey in plotData[key]) {
                  formData.append(
                    `plots[${index}][highlights][${previewKey}]`,
                    plotData[key][previewKey]
                  );
                }
              } else if (key === "videos") {
                // append nested object
                for (let previewKey in plotData[key]) {
                  formData.append(
                    `plots[${index}][videos][${previewKey}]`,
                    plotData[key][previewKey]
                  );
                }
              } else if (key === "views") {
                // append nested object
                if (plotData[key].length > 0) {
                  for (let previewKey in plotData[key]) {
                    formData.append(
                      `plots[${index}][views][${previewKey}]`,
                      plotData[key][previewKey]
                    );
                  }
                } else {
                  // formData.append(`plots[${index}][views][]`, "");
                }
              } else if (key === "contacts_fetched") {
                // append nested object
                if (plotData[key].length > 0) {
                  for (let previewKey in plotData[key]) {
                    formData.append(
                      `plots[${index}][contacts_fetched][${previewKey}]`,
                      plotData[key][previewKey]
                    );
                  }
                } else {
                  // formData.append(`plots[${index}][contacts_fetched][]`, "");
                }
              } else if (key === "interested") {
                // append nested object
                if (plotData[key].length > 0) {
                  for (let previewKey in plotData[key]) {
                    formData.append(
                      `plots[${index}][interested][${previewKey}]`,
                      plotData[key][previewKey]
                    );
                  }
                } else {
                  // formData.append(`plots[${index}][interested][]`, "");
                }
              } else if (key === "bids") {
                // append nested object
                if (plotData[key].length > 0) {
                  for (let previewKey in plotData[key]) {
                    formData.append(
                      `plots[${index}][bids][${previewKey}]`,
                      plotData[key][previewKey]
                    );
                  }
                } else {
                  // formData.append(`plots[${index}][bids][]`, "");
                }
              } else if (key === "area") {
                if (plotData.area_unit !== "ft2") {
                  // let convertedArea = plotData.area;
                  const convertedArea = convertArea(
                    plotData.area,
                    plotData.area_unit,
                    "ft2"
                  );
                  // if (plotData.area_unit === "g") {
                  //   convertedArea = Number(plotData.area) * 1089;
                  // } else {
                  //   convertedArea = convert(plotData.area)
                  //     .from(plotData.area_unit)
                  //     .to("ft2");
                  // }
                  formData.append(`plots[${index}][${key}]`, convertedArea);
                } else {
                  formData.append(`plots[${index}][${key}]`, plotData[key]);
                }
              } else {
                formData.append(`plots[${index}][${key}]`, plotData[key]);
              }
            });
        });

        Object.keys(plotDetails)
          ?.sort(sortFormData)
          .forEach((key) => {
            if (key === "documents") {
              plotDetails.documents.map((document, i) => {
                const documentData = Object.entries(document);
                let key = "",
                  value = "";
                for (let previewKey in documentData) {
                  if (documentData[previewKey][0] === "file") {
                    value = documentData[previewKey][1];
                  } else {
                    key = documentData[previewKey][1];
                  }
                }
                formData.append(`documents[${key}]`, value);
                // formData.append("documents", document.file);
                return document;
              });
            } else if (key === "legal") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                formData.append(
                  `legal[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
              }
            } else if (key === "location") {
              // append nested object
              delete plotDetails?.location.coordinates;
              for (let previewKey in plotDetails[key]) {
                if (previewKey === "latlng") {
                  formData.append(
                    "location[latlng][0]",
                    plotDetails[key][previewKey][0]
                  );
                  formData.append(
                    "location[latlng][1]",
                    plotDetails[key][previewKey][1]
                  );
                } else {
                  formData.append(
                    `location[${previewKey}]`,
                    plotDetails[key][previewKey]
                  );
                }
              }
            } else if (key === "highlights") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                formData.append(
                  `highlights[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
              }
            } else if (key === "videos") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                formData.append(
                  `videos[${previewKey}]`,
                  plotDetails[key][previewKey]
                );
              }
            } else if (key === "features") {
              // append nested object
              for (let previewKey in plotDetails[key]) {
                for (let nestedObjKey in plotDetails[key][previewKey]) {
                  formData.append(
                    `features[${previewKey}][${nestedObjKey}]`,
                    plotDetails[key][previewKey][nestedObjKey]
                  );
                }
              }
            } else {
              formData.append(key, plotDetails[key]);
            }
          });
      }
      // navigate("/subscription");
      try {
        setIsSavingDraft(true);
        //         let idObject = {};
        // console.log(...formData)
        //         for (const pair of formData) {
        //           const [key, value] = pair;
        //           if (key === "id") {
        //             idObject.plotId = value;
        //             idObject.listed_by = value;
        //             break;
        //           }
        //         }

        //         if (idObject.plotId && idObject.plotId.length > 0) {
        //           console.log(plotDetails)
        //           await deletePlotByID();
        //         }

        console.log("plotDetails", plotDetails);
        if (plotDetails.id) {
          await deletePlotByID({ plotId: plotDetails?.puid });
        }

        const disallowedFields = [
          "id",
          "updatedAt",
          "createdAt",
          "listed_by",
          "is_drafted",
        ];

        const cleanedFormData = new FormData();

        // Iterate over formData entries
        for (const pair of formData) {
          const [key, value] = pair;

          if (!disallowedFields.includes(key) && !key.startsWith("views[")) {
            if (key === "location[coordinates]") {
              try {
                const coordinates = JSON.parse(value);
                if (Array.isArray(coordinates) && coordinates.length === 2) {
                  const [lat, lon] = coordinates.map((coord) =>
                    coord !== null ? parseFloat(coord) : null
                  );
                  cleanedFormData.set("location[coordinates][0]", lat);
                  cleanedFormData.set("location[coordinates][1]", lon);
                }
              } catch (error) {
                console.error(error);
              }
            } else if (key === "area" || key === "location[pincode]") {
              const numericValue = parseFloat(value);
              if (!isNaN(numericValue)) {
                cleanedFormData.append(key, numericValue);
              }
            } else {
              cleanedFormData.append(key, value);
            }
          }
        }

        // Omitting the latlng entries if both values are undefined or not valid floating-point numbers
        const latValue = cleanedFormData.get("location[latlng][0]");
        const lonValue = cleanedFormData.get("location[latlng][1]");

        if (
          (latValue === undefined || isNaN(latValue)) &&
          (lonValue === undefined || isNaN(lonValue))
        ) {
          cleanedFormData.delete("location[latlng][0]");
          cleanedFormData.delete("location[latlng][1]");
        }

        const response = await draftPlot(cleanedFormData);

        setIsSavingDraft(false);

        if (response.status === "success") {
          showToast(
            `Your Property id ${plotDetails.puid} drafted Successfully!`,
            "success"
          );

          navigate("/profile", {
            state: {
              selectedTab: "listing",
            },
          });
        } else {
          showToast(`Some error occured, Please try again later`, "error");
          console.log(response);
        }
      } catch (e) {
        if (e.response?.status === 400) {
        } else {
          showToast(`Some error occured, Please try again later`, "error");
        }
        console.log("error", e);
        setIsSavingDraft(false);
      }
    }
  };

  const isValidDetails = () => {
    if (plotDetails.owner_name?.trim() === "") {
      document.getElementById("owner")?.classList?.add("!border-[#C62F39]");
    }
    if (plotDetails.title?.trim() === "") {
      document.getElementById("title")?.classList?.add("!border-[#C62F39]");
    }
    if (plotDetails.category?.trim() === "") {
      document.getElementById("category")?.classList?.add("!border-[#C62F39]");
    }
    if (plotDetails.sub_category?.trim() === "") {
      document
        .getElementById("sub_category")
        ?.classList?.add("!border-[#C62F39]");
    }

    if (plotDetails.legal.isFirstOwner?.trim() === "") {
      document
        .getElementById("isfirstowner")
        ?.classList?.add("!border-[#C62F39]");
    }
    if (plotDetails.legal.purchaseDeed?.trim() === "") {
      document
        .getElementById("purchasedeed")
        ?.classList?.add("!border-[#C62F39]");
    }
    // if (plotDetails.legal.underMortage?.trim() === "") {
    //   document
    //     .getElementById("undermortage")
    //     ?.classList?.add("!border-[#C62F39]");
    // }

    if (
      plotDetails.legal.isFirstOwner?.trim() === "false" &&
      plotDetails.legal.previousPurchaseDeed?.trim() === ""
    ) {
      document
        .getElementById("previouspurchasedeed")
        ?.classList?.add("!border-[#C62F39]");
    }
    if (
      plotDetails?.location.survey_number === undefined ||
      plotDetails?.location.survey_number?.trim() === ""
    ) {
      document
        .getElementById("survey_number")
        ?.classList?.add("!border-[#C62F39]");
    }
    if (
      plotDetails?.location.plot_number === undefined ||
      plotDetails?.location.plot_number?.trim() === ""
    ) {
      document
        .getElementById("plot_number")
        ?.classList?.add("!border-[#C62F39]");
    }

    if (
      plotDetails?.location.landmark === undefined ||
      plotDetails?.location.landmark?.trim() === ""
    ) {
      document.getElementById("landmark")?.classList?.add("!border-[#C62F39]");
    }
    if (
      plotDetails?.location.village === undefined ||
      plotDetails?.location.village?.trim() === ""
    ) {
      document.getElementById("village")?.classList?.add("!border-[#C62F39]");
    }
    if (
      plotDetails?.location.taluka === undefined ||
      plotDetails?.location.taluka?.trim() === ""
    ) {
      document.getElementById("taluka")?.classList?.add("!border-[#C62F39]");
    }
    if (
      plotDetails?.location.district === undefined ||
      plotDetails?.location.district?.trim() === ""
    ) {
      document.getElementById("district")?.classList?.add("!border-[#C62F39]");
    }
    if (
      plotDetails?.location.state === undefined ||
      plotDetails?.location.state?.trim() === ""
    ) {
      document.getElementById("state")?.classList?.add("!border-[#C62F39]");
    }
    if (
      plotDetails?.location.country === undefined ||
      plotDetails?.location.country?.trim() === ""
    ) {
      document.getElementById("country")?.classList?.add("!border-[#C62F39]");
    }
    if (
      plotDetails?.location.pincode === undefined ||
      (typeof plotDetails?.location.pincode === "string" &&
        plotDetails?.location.pincode?.trim() === "")
    ) {
      document.getElementById("pincode")?.classList?.add("!border-[#C62F39]");
    }

    if (plotDetails.features.length > 0) {
      plotDetails.features.map((feature, index) => {
        if (feature.name?.trim() === "") {
          document
            .getElementById(`featurename_${index}`)
            ?.classList?.add("!border-[#C62F39]");
        }
        // if (feature.distance?.trim() === "") {
        //   document
        //     .getElementById(`featuredistance_${index}`)
        //     ?.classList?.add("!border-[#C62F39]");
        // }
        return 0;
      });
    }

    if (plotDetails.documents.length > 0) {
      plotDetails.documents.map((doc, index) => {
        if (doc.name?.trim() === "") {
          document
            .getElementById(`documentname_${index}`)
            ?.classList?.add("!border-[#C62F39]");
        }
        if (doc.file === "") {
          document
            .getElementById(`documentfilelabel_${index}`)
            ?.classList?.add("!border-[#C62F39]");
        }
        return 0;
      });
    }

    if (plotDetails.type === "single") {
      if (
        typeof plotDetails.area === "string" &&
        plotDetails.area?.trim() === ""
      ) {
        document.getElementById("area")?.classList?.add("!border-[#C62F39]");
      }
      if (plotDetails.area_unit?.trim() === "") {
        document
          .getElementById("area_unit")
          ?.classList?.add("!border-[#C62F39]");
      }
      if (plotDetails?.front === "") {
        document.getElementById("front")?.classList?.add("!border-[#C62F39]");
      }
      if (plotDetails.depth === "") {
        document.getElementById("depth")?.classList?.add("!border-[#C62F39]");
      }
      if (plotDetails.access_road === "") {
        document
          .getElementById("access_road")
          ?.classList?.add("!border-[#C62F39]");
      }
      if (plotDetails.facing === "") {
        document.getElementById("facing")?.classList?.add("!border-[#C62F39]");
      }
      if (plotDetails.availability?.trim() === "") {
        document
          .getElementById("availability")
          ?.classList?.add("!border-[#C62F39]");
      }
      if (
        !plotDetails.rate.noquote &&
        plotDetails.rate.from === "" &&
        plotDetails.rate.to === ""
      ) {
        if (
          typeof plotDetails.rate.rate === "string" &&
          plotDetails.rate.rate?.trim() === ""
        ) {
          document.getElementById("rate")?.classList?.add("!border-[#C62F39]");
        }
        if (plotDetails.rate.unit?.trim() === "") {
          document
            .getElementById("rateunit")
            ?.classList?.add("!border-[#C62F39]");
        }
        if (
          typeof plotDetails.rate.lumpsum === "string" &&
          plotDetails.rate.lumpsum?.trim() === ""
        ) {
          document
            .getElementById("lumpsum")
            ?.classList?.add("!border-[#C62F39]");
        }
      }
      if (plotDetails.highlights.length > 0) {
        plotDetails.highlights.map((highlight, index) => {
          if (highlight?.trim() === "") {
            document
              .getElementById(`highlight_${index}`)
              ?.classList?.add("!border-[#C62F39]");
          }
          return 0;
        });
      }
      if (plotDetails.videos.length > 0) {
        plotDetails.videos.map((video, index) => {
          if (
            video?.trim() === "" ||
            !(
              video?.includes("https://img.youtube.com/vi/") ||
              video.includes("https://youtu.be/")
            )
          ) {
            document
              .getElementById(`videos_${index}`)
              ?.classList?.add("!border-[#C62F39]");
          }
          return 0;
        });
      }
    } else {
      // bulkPlotDetails.map((currplot, index) => {
      //   if (typeof currplot.area === "string" && currplot.area.trim() === "") {
      //     document
      //       .getElementById(`plotarea_${index}`)
      //       ?.classList?.add("!border-[#C62F39]");
      //   }
      //   if (
      //     !currplot.rate.noquote &&
      //     currplot.rate.from === "" &&
      //     currplot.rate.to === ""
      //   ) {
      //     if (
      //       typeof currplot.rate.rate === "string" &&
      //       currplot.rate.rate.trim() === ""
      //     ) {
      //       document
      //         .getElementById(`plotrate_${index}`)
      //         ?.classList?.add("!border-[#C62F39]");
      //     }
      //     if (
      //       typeof currplot.rate.lumpsum === "string" &&
      //       currplot.rate.lumpsum.trim() === ""
      //     ) {
      //       document
      //         .getElementById(`plotlumpsum_${index}`)
      //         ?.classList?.add("!border-[#C62F39]");
      //     }
      //   }
      //   if (currplot.access_road.trim() === "") {
      //     document
      //       .getElementById(`plotaccessroad_${index}`)
      //       ?.classList?.add("!border-[#C62F39]");
      //   }
      //   if (currplot.facing.trim() === "") {
      //     document
      //       .getElementById(`plotfacing_${index}`)
      //       ?.classList?.add("!border-[#C62F39]");
      //   }
      //   if (currplot.availability.trim() === "") {
      //     document
      //       .getElementById(`plotavailability_${index}`)
      //       ?.classList?.add("!border-[#C62F39]");
      //   }
      //   if (currplot.front.trim() === "") {
      //     document
      //       .getElementById(`plotfront_${index}`)
      //       ?.classList?.add("!border-[#C62F39]");
      //   }
      //   if (currplot.depth.trim() === "") {
      //     document
      //       .getElementById(`plotdepth_${index}`)
      //       ?.classList?.add("!border-[#C62F39]");
      //   }
      //   if (currplot.videos?.length > 0) {
      //     currplot.videos?.map((video, i) => {
      //       if (
      //         video?.trim() === "" ||
      //         !(
      //           video?.includes("https://img.youtube.com/vi/") ||
      //           video.includes("https://youtu.be/")
      //         )
      //       ) {
      //         document
      //           .getElementById(`plotvideo_${index}`)
      //           ?.classList?.add("!border-[#C62F39]");
      //       }
      //       return 0;
      //     });
      //   }
      //   return 0;
      // });
    }
    return (
      document.getElementsByClassName("border-[#C62F39]").length === 0 &&
      document.getElementsByClassName("!border-[#C62F39]").length === 0
    );
  };

  const handleAddPlots = (count) => {
    const newPlots = Array.from({ length: count }, (_, index)  => ({
      scheme_puid: plotDetails.puid,
      puid: Math.floor(100000000 + Math.random() * 900000000),
      highlights: [],
      access_road: "",
      plot_name: `Plot No ${bulkPlotDetails.length + index + 1}`,
      facing: "",
      front: "",
      depth: "",
      availability: "available",
      images: [],
      videos: [],
      area: "",
      area_unit: "ft2",
      front_unit: "ft",
      rate: {
        rate: "",
        unit: "ft2",
        lumpsum: "",
        from: "",
        to: "",
        noquote: false,
      },
    }));

    setBulkPlotDetails((prevState) => [...prevState, ...newPlots]);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === '') {
      setPlotCount('');
    } else {
      const count = Math.max(0, parseInt(value) || 0);
      setPlotCount(count);
    }
  };
  
  const handleBlur = () => {
    const count = Math.max(2, plotCount || 2);
    const currentCount = bulkPlotDetails.length;
    setPlotCount(count);
    if (count > currentCount) {
      handleAddPlots(count - currentCount);
    } else if (count < currentCount) {
      setBulkPlotDetails((prevState) => prevState.slice(0, count));
    }
  };

  const handleSingleFileLoaded = (data, file) => {
    setSelectedCSV(file);
    console.log("Data is", data, file);
    const locationData = data[0]?.location
      ? JSON.parse(data[0].location)
      : plotDetails.location;
    const locationWithoutId = { ...locationData };
    delete locationWithoutId?._id;

    console.log(locationWithoutId);

    const dataOfPlot = {
      owner_name: data[0]?.owner_name || "",
      marketed_by: data[0]?.marketed_by || "",
      area: data[0]?.area || "",
      availability: data[0]?.availability || "",
      category: data[0]?.category || "",
      sub_category: data[0]?.sub_category || "",
      access_road: data[0]?.access_road || "",
      title: data[0]?.title || "",
      depth: data[0]?.depth || "",
      front: data[0]?.front || "",
      facing: data[0]?.facing || "",
      highlights: data[0].highlights ? JSON.parse(data[0].highlights) : [],
      videos: data[0].videos ? JSON.parse(data[0].videos) : [],
      legal: data[0]?.legal
        ? JSON.parse(data[0]?.legal)
        : {
            isFirstOwner: "",
            purchaseDeed: "",
            underMortage: "",
            previousPurchaseDeed: "",
          },
      location: locationWithoutId,
      description: data[0]?.description || "",
    };

    console.log(JSON.parse(data[0]?.location));
    setPlotDetails({ ...plotDetails, ...dataOfPlot });
  };

  const handleFileLoaded = (data, file) => {
    setSelectedCSV(file);
    console.log("refref0000", data);

    const formattedData = data.map((plot) => {
      plot.images = [];
      plot.selectedOption = plot?.plot_option ? plot?.plot_option : "";
      plot.scheme_puid = plotDetails.puid;
      plot.puid = generatePUID(plotDetails.state, plotDetails.district);
      plot.highlights = plot.highlights?.split(",");
      plot.access_road = plot.access_road ? String(plot.access_road) : "";
      plot.facing = plot.facing ? String(plot.facing) : "";
      plot.front = plot.front ? String(plot.front) : "";
      plot.depth = plot.depth ? String(plot.depth) : "";
      plot.availability = plot.availability
        ? String(plot.availability)
        : "available";
      plot.videos = plot.videos?.split(",");
      // plot.area = "";
      // plot.area_unit = "ft2";
      // plot.front_unit = "f";
      var temprate = plot.rate ? plot.rate : plot.rate;
      plot.rate = {};
      plot.rate.lumpsum = plot.lumpsum ? plot.lumpsum : "";
      plot.rate.rate = temprate;
      plot.rate.unit = plot.rate_unit ? plot.rate_unit : "";
      plot.rate.from = plot.from_rate ? plot.from_rate : "";
      plot.rate.to = plot.to_rate ? plot.to_rate : "";
      plot.rate.noquote = plot.no_quote ? plot.no_quote === "1" : "false";

      // plot.plot_name = plot.plot_name;
      delete plot.lumpsum;
      delete plot.rate_unit;
      delete plot.from_rate;
      delete plot.to_rate;
      delete plot.no_quote;

      return plot;
    });
    console.log("Formatted Data:", formattedData);

    const emptyValues = [null, undefined, "", "false", "0"];

    const filteredData = formattedData.filter((plot) => {
      // Check if plot_name is valid
      const hasValidPlotName =
        plot.plot_name && !emptyValues.includes(plot.plot_name);
      const hasNonEmptyValues = Object.values(plot).some((value) => {
        if (emptyValues.includes(value)) {
          return false;
        }
        if (Array.isArray(value)) {
          return value.length > 0; // Non-empty array
        }
        if (typeof value === "object") {
          return Object.keys(value).length > 0; // Non-empty object
        }
        return true;
      });

      return hasValidPlotName && hasNonEmptyValues;
    });

    console.log("Filtered Data:", filteredData);
    if (filteredData.length > 1) {
      setBulkPlotDetails(filteredData);
    }
  };

  useEffect(()=>{
    if(bulkPlotDetails.length > 0){
      setPlotCount(bulkPlotDetails.length)
    }

  },[bulkPlotDetails])
  return (
    <>
      <div className="bg-white mx-auto max-w-screen-xl px-[24px]">
        <HomeHeader isHome={false} />
        <div className="mt-[24px]">
          <div className="flex items-center gap-[20px]">
            {/* <Icon icon="bx:arrow-back" color="#2B2B2B" width={24} /> */}
            <h3 className="text-[#000000] text-[24px] font-semibold">
              {location.pathname?.includes("editplot")
                ? "Edit Property"
                : "Add New Property"}
            </h3>
          </div>
          <div className="mt-[32px] p-[24px] border-[1px] border-[#D9D9D9] rounded-[16px] bg-white">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-[16px]">
              <div className="flex flex-col w-full mb-[24px]">
                <label
                  htmlFor="owner"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Property Owner Name
                  <span className="text-[#C62F39]"> *</span>
                </label>
                <input
                  value={plotDetails.owner_name}
                  onChange={(e) => {
                    e.target.classList.remove("!border-[#C62F39]");
                    setPlotDetails((prevState) => ({
                      ...prevState,
                      owner_name: e.target.value,
                    }));
                  }}
                  placeholder="Please enter the owner name"
                  id="owner"
                  type="text"
                  className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
                />
              </div>
              <div className="flex flex-col w-full mb-[24px]">
                <label
                  htmlFor="marketed"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Marketed By
                </label>
                <input
                  value={plotDetails.marketed_by}
                  onChange={(e) => {
                    setPlotDetails((prevState) => ({
                      ...prevState,
                      marketed_by: e.target.value,
                    }));
                  }}
                  placeholder="Please Enter the Marketeer's name"
                  id="marketed"
                  type="text"
                  className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
                />
              </div>
              <div className="flex flex-col w-full mb-[24px]">
                <label
                  htmlFor="rera_number"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  RERA Registration Number
                </label>
                <input
                  value={plotDetails.rera_number}
                  onChange={(e) => {
                    setPlotDetails((prevState) => ({
                      ...prevState,
                      rera_number: e.target.value,
                    }));
                  }}
                  placeholder="xxxxxxxxxx"
                  id="rera_number"
                  type="text"
                  className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-[16px]">
              <div className="flex flex-col w-full mb-[24px]">
                <label
                  htmlFor="listing"
                  className="text-[#344054] text-[14px] font-medium"
                >
                  Listing Type
                  <span className="text-[#C62F39]"> *</span>
                </label>
                <select
                  value={plotDetails.type}
                  onChange={(e) => {
                    setPlotDetails((prevState) => ({
                      ...prevState,
                      type: e.target.value,
                    }));
                  }}
                  placeholder="Haveli"
                  id="listing"
                  type="text"
                  className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] bg-white ${
                    plotDetails.type === ""
                      ? "text-[#C7C7C7]"
                      : "text-[#000E14]"
                  }`}
                >
                  <option className="hidden" selected value="">
                    Single/Bulk
                  </option>
                  <option className="text-[#000E14]" value="single">
                    Single
                  </option>
                  <option className="text-[#000E14]" value="bulk">
                    Bulk
                  </option>
                </select>
              </div>

              {plotDetails.type === "bulk" && (
                <>
                <div className="flex flex-col md:flex-row items-center w-full mb-[24px]">
                  <div className="flex flex-col w-full mb-[24px]">
                    <label
                      htmlFor="listing"
                      className="text-[#344054] text-[14px] font-medium"
                    >
                      Total Number of Plots
                      <span className="text-[#C62F39]"> *</span>
                    </label>
                    <div className="mt-[8px] flex">
                      <button
                        className="bg-[#ECECEC] w-fit py-[12px] px-[16px] border-[1px] border-[#C7C7C7] rounded-l-regular cursor-pointer"
                        onClick={() => {
                          setBulkPlotDetails((prevState) => [
                            ...prevState,
                            {
                              scheme_puid: plotDetails.puid,
                              puid: Math.floor(
                                100000000 + Math.random() * 900000000
                              ),
                              highlights: [],
                              access_road: "",
                              plot_name: "Plot No ",
                              facing: "",
                              front: "",
                              depth: "",
                              availability: "available",
                              images: [],
                              videos: [],
                              area: "",
                              area_unit: "ft2",
                              front_unit: "ft",
                              rate: {
                                rate: "",
                                unit: "ft2",
                                lumpsum: "",
                                from: "",
                                to: "",
                                noquote: false,
                              },
                            },
                          ]);
                        }}
                      >
                        <Icon
                          icon="material-symbols:add"
                          color="#666666"
                          width={20}
                        />
                      </button>
                      <input
                        type="number"
                        className="bg-[#fff] py-[12px] px-[16px] border-t-[1px] border-b-[1px] border-[#C7C7C7] w-auto max-w-[80px] text-center focus:outline-none"
                        value={plotCount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <button
                        className="bg-[#ECECEC] w-fit py-[12px] px-[16px] border-[1px] border-[#C7C7C7] rounded-r-regular cursor-pointer"
                        onClick={() => {
                          if (bulkPlotDetails.length > 2) {
                            setBulkPlotDetails((prevState) => {
                              let tempArray = [...prevState];
                              tempArray.pop();
                              return [...tempArray];
                            });
                          }
                        }}
                      >
                        <Icon
                          icon="ic:baseline-minus"
                          color="#666666"
                          width={20}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="w-[90%]">

                  {/* <SampleBulkDownload /> */}
                  </div>
                  </div>
                  <CSVReader
                    parserOptions={{
                      header: true,
                      dynamicTyping: true,
                      skipEmptyLines: true,

                      transformHeader: (header) =>
                        header.toLowerCase().replace(/\W/g, "_"),
                    }}
                    onError={(e) => {
                      console.log(e);
                    }}
                    inputId="bulkcsvupload"
                    inputName="bulkcsvupload"
                    cssInputClass="hidden w-full"
                    onFileLoaded={handleFileLoaded}
                    cssClass="w-full flex items-center"
                    label={
                      <label
                        htmlFor="bulkcsvupload"
                        className="w-full bg-[#F6F6F6] flex items-center px-[16px] py-[12px] rounded-regular border-dashed border-[1px] border-[#367DE7] cursor-pointer text-[#666666] gap-[8px] text-[16px] truncate mt-[-1.2rem]"
                      >
                        <Icon icon="ic:outline-cloud-upload" width={24} />
                        {selectedCSV ? selectedCSV.name : "Upload CSV"}
                        {/* <input type="file" className="hidden" /> */}
                      </label>
                    }
                  />
                </>
              )}
            </div>
          </div>
          {plotDetails.type !== "" && (
            <div className="mt-[32px] p-[24px] border-[1px] border-[#D9D9D9] rounded-[16px] bg-white">
              <Details
                plotDetails={plotDetails}
                setPlotDetails={setPlotDetails}
              />
              {plotDetails.type === "single" && (
                <Highlights
                  plotDetails={plotDetails}
                  setPlotDetails={setPlotDetails}
                />
              )}

              <Features
                plotDetails={plotDetails}
                setPlotDetails={setPlotDetails}
              />

              <LegalInfo
                plotDetails={plotDetails}
                setPlotDetails={setPlotDetails}
              />

              {plotDetails.type === "single" && (
                <Rate
                  plotDetails={plotDetails}
                  setPlotDetails={setPlotDetails}
                />
              )}

              <Location
                plotDetails={plotDetails}
                setPlotDetails={setPlotDetails}
              />

              <Documents
                plotDetails={plotDetails}
                setPlotDetails={setPlotDetails}
              />

              {plotDetails.type === "bulk" && (
                <section className="mt-[32px] border-[1px] border-[#ECECEC] rounded-regular">
                  {bulkPlotDetails.map((plot, index) => (
                    <PlotCard
                      plotDeatils={bulkPlotDetails}
                      setBulkPlotDetails={setBulkPlotDetails}
                      index={index}
                    />
                  ))}
                </section>
              )}

              {location.pathname?.includes("editplot") ? (
                <div className="mt-[32px] grid grid-cols-2 md:grid-cols-4 gap-[16px]">
                  <button
                    // disabled={!isSavingDraft}
                    onClick={handleDraft}
                    className="p-[12px] border-[1px] border-[#0259DB] rounded-regular w-full text-[16px] font-medium text-[#0259DB]"
                  >
                    <p
                      style={{
                        display: "inline-flex",
                        flexDirection: "row",
                      }}
                    >
                      {isSavingDraft && (
                        <div>
                          <Oval
                            height={20}
                            width={20}
                            color="#0259DB"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#0259DB"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                          />
                        </div>
                      )}
                      <div style={{ marginLeft: 15 }}>Save as Draft</div>
                    </p>
                  </button>
                  <button
                    onClick={handleProceed}
                    className="p-[12px] border-[1px] border-[#0259DB] rounded-regular w-full text-[16px] font-medium text-white bg-[#0259DB]"
                  >
                    Update
                  </button>
                </div>
              ) : (
                <div className="mt-[32px] grid grid-cols-2 md:grid-cols-4 gap-[16px]">
                  {!location.pathname.includes("editplot") && (
                    <button
                      // disabled={!isSavingDraft}
                      onClick={handleDraft}
                      className="p-[12px] border-[1px] border-[#0259DB] rounded-regular w-full text-[16px] font-medium text-[#0259DB]"
                    >
                      <p
                        style={{
                          display: "inline-flex",
                          flexDirection: "row",
                        }}
                      >
                        {isSavingDraft && (
                          <div>
                            <Oval
                              height={20}
                              width={20}
                              color="#0259DB"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="#0259DB"
                              strokeWidth={2}
                              strokeWidthSecondary={2}
                            />
                          </div>
                        )}
                        <div style={{ marginLeft: 15 }}>Save as Draft</div>
                      </p>
                    </button>
                  )}
                  <button
                    disabled={!isValid && !isLoading}
                    onClick={handleProceed}
                    className="p-[12px] border-[1px] border-[#0259DB] rounded-regular w-full text-[16px] font-medium text-white bg-[#0259DB] disabled:bg-[#88B8FF] disabled:border-[#88B8FF] disabled:cursor-not-allowed"
                  >
                    <p
                      style={{
                        display: "inline-flex",
                        flexDirection: "row",
                      }}
                    >
                      {isLoading && (
                        <div>
                          <Oval
                            height={20}
                            width={20}
                            color="#ffffff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#ffffff"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                          />
                        </div>
                      )}
                      <div style={{ marginLeft: 15 }}>
                        {!location.pathname.includes("editplot")
                          ? "Proceed"
                          : "Update"}
                      </div>
                    </p>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SellPlot;

// {plotDetails.type === "single" && (
// <>
//   <CSVReader
//     parserOptions={{
//       header: true,
//       dynamicTyping: true,
//       skipEmptyLines: true,

//       transformHeader: (header) =>
//         header.toLowerCase().replace(/\W/g, "_"),
//     }}
//     onError={(e) => {
//       console.log(e);
//     }}
//     inputId="bulkcsvupload"
//     inputName="bulkcsvupload"
//     cssInputClass="hidden w-full"
//     onFileLoaded={handleSingleFileLoaded}
//     cssClass="w-full flex items-center"
//     label={
//       <label
//         htmlFor="bulkcsvupload"
//         className="w-full bg-[#F6F6F6] flex items-center px-[16px] py-[12px] rounded-regular border-dashed border-[1px] border-[#367DE7] cursor-pointer text-[#666666] gap-[8px] text-[16px] truncate"
//       >
//         <Icon icon="ic:outline-cloud-upload" width={24} />
//         {selectedCSV ? selectedCSV.name : "Upload CSV"}
//         {/* <input type="file" className="hidden" /> */}
//       </label>
//     }
//   />
// </>
// )}
