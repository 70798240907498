import React from "react";
import DataTable from "react-data-table-component";
import { downloadCSV, Export } from "../shared/exportButton";
import tableCustomStyles from "../shared/tableStyle";
import Dropdown from "./Dropdown";
import reportTableCustomStyle from "./ReportStyleCustomStyle";

const tabledata = [
  {
    userinfo: "Balaji Sankaran",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543210,
    listed: 3,
    fees: 600,
    discount: 50,
  },
  {
    userinfo: "Balaji Sankaran",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543211,
    listed: 3,
    fees: 600,
    discount: 50,
  },
  {
    userinfo: "Balaji Sankaran",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543212,
    listed: 3,
    fees: 600,
    discount: 50,
  },
  {
    userinfo: "Balaji Sankaran",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543213,
    listed: 3,
    fees: 600,
    discount: 50,
  },
  {
    userinfo: "Balaji Sankaran",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543214,
    listed: 3,
    fees: 600,
    discount: 50,
  },
  {
    userinfo: "Balaji Sankaran",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543215,
    listed: 3,
    fees: 600,
    discount: 50,
  },
  {
    userinfo: "Balaji Sankaran",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543216,
    listed: 3,
    fees: 600,
    discount: 50,
  },
  {
    userinfo: "Balaji Sankaran",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543217,
    listed: 3,
    fees: 600,
    discount: 50,
  },
  {
    userinfo: "Balaji Sankaran",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543218,
    listed: 3,
    fees: 600,
    discount: 50,
  },
  {
    userinfo: "Balaji Sankaran",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543219,
    listed: 3,
    fees: 600,
    discount: 50,
  },
  {
    userinfo: "Balaji Sankaran",
    email: "balajisankaran1998@gmail.com",
    mobile: "9876543210",
    id: 9876543220,
    listed: 3,
    fees: 600,
    discount: 50,
  },
];

const Fees = ({ reportType, setReportType }) => {
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(tabledata)} />,
    []
  );

  const columns = [
    {
      name: "User Info",
      selector: (row) => row.userinfo,

      style: {
        borderRight: "1px solid #ECECEC",
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "300px",
      // cell:(row) => <span className='flex gap-[8px]'>{row.buyer} {row.isInterested && interestedClip}</span>,
    },
    {
      name: "Mobile number",
      selector: (row) => row.mobile,
      // cell:(row) => <span className='flex gap-[8px]'>{row.seller} {row.isFetchedInfo && fetchedClip}</span>,
    },
    {
      name: "PUID",
      selector: (row) => row.id,
      // cell:(row) => <span>{row.bid !== "-" ? `₹${row.bid}`: row.bid}</span>
    },
    {
      name: "Properties Listed",
      selector: (row) => row.listed,
      // cell:(row) => <span>{row.expsell !== "-" ? `₹${row.expsell}`: row.expsell}</span>,
    },
    {
      name: "Fees Collected",
      selector: (row) => row.fees,
      cell: (row) => (
        <span>{row.fees !== "-" ? `₹${row.fees}` : row.fees}</span>
      ),
    },
    {
      name: "Coupon Discount",
      selector: (row) => row.discount,
      cell: (row) => (
        <span>{row.discount !== "-" ? `₹${row.discount}` : row.discount}</span>
      ),
    },
  ];

  return (
    <DataTable
      actions={actionsMemo}
      columns={columns}
      data={tabledata}
      responsive
      striped
      highlightOnHover
      noDataComponent={
        <span className="px-[16px] py-[20px]">{`No data found`}</span>
      }
      pagination
      customStyles={reportTableCustomStyle}
      subHeader
      subHeaderAlign="left"
      subHeaderComponent={
        <Dropdown reportType={reportType} setReportType={setReportType} />
      }
    />
  );
};

export default Fees;
