import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { downloadCSV, Export } from "../shared/exportButton";
import tableCustomStyles from "../shared/tableStyle";
import Dropdown from "./Dropdown";
import reportTableCustomStyle from "./ReportStyleCustomStyle";
import {
  getUserBids,
  getUserInterested,
  getUserListings,
  getUserSaved,
  getUsers,
} from "../../apis/apiClient";
import { fuzzyMatch } from "../../utils/sharedFunc";

// const tabledata = [
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     taluka: "Khed",
//     id: 9876543210,
//     listed: 3,
//     views: 234,
//     bids: 4,
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     taluka: "Mokhada",
//     id: 9876543211,
//     listed: 3,
//     views: 2344,
//     bids: 8,
//     location: "Pimpri",
//     area: 243,
//     city: "Nashik",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     taluka: "Dindori",
//     id: 9876543212,
//     listed: 3,
//     views: 65,
//     bids: 22,
//     location: "Pimpri",
//     area: 243,
//     city: "Kokan",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     taluka: "Achalpur",
//     id: 9876543213,
//     listed: 3,
//     views: 876,
//     bids: 12,
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     taluka: "Khed",
//     id: 9876543214,
//     listed: 3,
//     views: 2345,
//     bids: 2,
//     location: "Pimpri",
//     area: 243,
//     city: "Vidharbha",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     taluka: "Khed",
//     id: 9876543215,
//     listed: 3,
//     views: 2344,
//     bids: "-",
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     taluka: "Khed",
//     id: 9876543216,
//     listed: 3,
//     views: 555,
//     bids: "-",
//     location: "Pimpri",
//     area: 243,
//     city: "Kokan",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     taluka: "Khed",
//     id: 9876543217,
//     listed: 3,
//     views: 10,
//     bids: 41,
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     taluka: "Khed",
//     id: 9876543218,
//     listed: 3,
//     views: 10,
//     bids: 4,
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     taluka: "Khed",
//     id: 9876543219,
//     listed: 3,
//     views: 10,
//     bids: 4,
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
//   {
//     sharedby: "Balaji Sankaran",
//     sharedto: "Balaji Sankaran",
//     sharedContact: "9876543210",
//     email: "balajisankaran1998@gmail.com",
//     mobile: "9876543210",
//     taluka: "Khed",
//     id: 9876543220,
//     listed: 3,
//     views: 10,
//     bids: 4,
//     location: "Pimpri",
//     area: 243,
//     city: "Pune",
//   },
// ];

const EndUser = ({ reportType, setReportType, setIsOpen, setModalData }) => {
  const [tableData, setTableData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const [search, setSearch] = useState("");
  useEffect(() => {
    setFilteredItems((prevState) => {
      return tableData.filter((item) => {
        return Object.entries(item)
          ?.map((val) => {
            if (
              val[0] === "name" ||
              val[0] === "email" ||
              val[0] === "mobile_number" ||
              val[0] === "district" ||
              val[0] === "taluka"
            ) {
              return fuzzyMatch(
                search?.toLowerCase(),
                val[1]?.toString()?.toLowerCase()
              );
            } else {
              return false;
            }
          })
          .includes(true);
      });
    });
  }, [search]);

  const getListing = async (userId) => {
    const response = await getUserListings(userId);
    setModalData(response.listings.results);
  };
  const getBids = async (userId) => {
    const response = await getUserBids(userId);
    setModalData(response.bids.results);
  };
  const getSaved = async (userId) => {
    const response = await getUserSaved(userId);
    setModalData(response.saved.results);
  };
  const getInterested = async (userId) => {
    const response = await getUserInterested(userId);
    setModalData(response.interested.results);
  };

  useEffect(() => {
    const getUsersData = async () => {
      try {
        const response = await getUsers();
        setTableData(response.users);

        setFilteredItems(response.users);
      } catch (e) {
        console.log(e);
      }
    };
    getUsersData();
  }, []);

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(tableData)} />,
    [tableData]
  );

  const columns = [
    // {
    //     name: 'PUID',
    //     selector: row => row.id,
    // },
    {
      name: "Username",
      selector: (row) => row.name,
    },
    {
      name: "Mobile number",
      selector: (row) => row.mobile_number,
    },
    {
      name: "Email Id",
      selector: (row) => row.email,
      width: "300px",
    },
    {
      name: "Properties Listed",
      selector: (row) => row.listings,
      cell: (row) => (
        <span
          className="text-primaryColor cursor-pointer"
          onClick={() => {
            getListing(row.id);
            setIsOpen("propertieslisted");
          }}
        >
          {row.listings?.length}
        </span>
      ),
    },
    {
      name: "District",
      selector: (row) => row.district,
    },
    {
      name: "Taluka",
      selector: (row) => row.taluka,
    },
    {
      name: "Interested",
      selector: (row) => row.interested,
      cell: (row) => (
        <span
          className="text-primaryColor cursor-pointer"
          onClick={() => {
            getInterested(row.id);
            setIsOpen("interested");
          }}
        >
          {row.interested?.length}
        </span>
      ),
    },
    {
      name: "Offered",
      selector: (row) => row.bids,
      cell: (row) => (
        <span
          className="text-primaryColor cursor-pointer"
          onClick={() => {
            getBids(row.id);
            setIsOpen("bidded");
          }}
        >
          {row.bids?.length}
        </span>
      ),
    },
  ];

  return (
    <DataTable
      actions={actionsMemo}
      columns={columns}
      data={filteredItems}
      responsive
      striped
      highlightOnHover
      noDataComponent={
        <span className="px-[16px] py-[20px]">{`No data found`}</span>
      }
      pagination
      customStyles={{
        ...reportTableCustomStyle,
        headCells: {
          style: {
            padding: "20px 16px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#666666",
            borderRight: "none",
          },
        },
      }}
      subHeader
      subHeaderAlign="left"
      subHeaderComponent={
        <Dropdown
          reportType={reportType}
          setReportType={setReportType}
          search={search}
          setSearch={setSearch}
        />
      }
    />
  );
};

export default EndUser;
