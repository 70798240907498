import { Icon } from "@iconify/react";

import React, { useEffect, useState } from "react";
import CustomEditor from "./Editor";
import { useSelector } from "react-redux";

const htmlDecode = (content) => {
  let e = document.createElement("div");
  e.innerHTML = content;
  return e.childNodes.length === 0
    ? ""
    : e.childNodes[0].nodeValue
    ? e.childNodes[0].nodeValue
    : e.outerHTML;
};

const ArticleDetails = ({
  articlesData,
  updateData,
  deleteData,
  setIsDetailsOpen,
}) => {
  const [isEditting, setIsEditting] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [title, setTitle] = useState(articlesData.name);

  const [articleContent, setArticleContent] = useState(``);
  const [articleContentMain, setArticleContentMain] = useState(articleContent);
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    setEditorLoaded(true);
    if (articlesData.data) {
      setArticleContent(htmlDecode(articlesData.data));
      setArticleContentMain(htmlDecode(articlesData.data));
    }
  }, [articlesData]);

  return (
    <div className="mt-[32px]">
      
      <div className="flex justify-between items-center">
        {!isEditting && (
          <div className="flex gap-[12px] items-center">
            <Icon
              onClick={() => {
                setIsDetailsOpen(false);
              }}
              icon="bx:arrow-back"
              width={32}
              color="#000"
              className="min-w-[32px] my-[12px] cursor-pointer"
            />
            <Icon
              icon="mdi:file-document-outline"
              width={32}
              color="#0259DB"
              className="min-w-[32px] my-[12px]"
            />
            <h1 className="text-[32px] text-[#000E14] font-semibold">
              {articlesData.name}
            </h1>
          </div>
        )}
        {profile.isLoggedIn &&
          profile.userData.role === "admin" &&
          !isEditting && (
            <div className="flex gap-[16px]">
              <button
                onClick={() => {
                  setIsEditting(true);
                }}
                className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] bg-[#0259DB] rounded-regular text-[16px] font-medium text-[#fff] flex items-center gap-[12px]"
                // onClick={() => {
                //   setIsBanksModalOpen(false);
                // }}
              >
                <Icon icon="mdi:pencil-outline" width={20} />
                Edit
              </button>
              <button
                onClick={() => {
                  deleteData(articlesData.id);
                  setIsDetailsOpen(false);
                }}
                className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] rounded-regular text-[16px] font-medium text-[#0259DB] flex items-center gap-[12px]"
              >
                Delete
              </button>
            </div>
          )}
      </div>
      {isEditting ? (
        <input
          type="text"
          className="text-[16px] text-[#000E14] font-medium py-[12px] px-[16px] w-full rounded-regular border-[1px] border-[#D0D5DD] outline-none"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        ></input>
      ) : (
        <h5 className="text-[24px] text-[#000E14] font-medium mt-[20px]">
          {title}
        </h5>
      )}

      <CustomEditor
        isVisible={isEditting}
        name="article"
        onChange={(data) => {
          setArticleContent(data);
        }}
        editorLoaded={editorLoaded}
        value={articleContent}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: articleContentMain.replaceAll("<a ", "<a target='_blank'"),
        }}
        className={`mt-[32px] ${isEditting && "hidden"}`}
      ></div>

      {isEditting && (
        <div className="flex gap-[16px] my-[24px] justify-end">
          <button
            onClick={() => {
              setArticleContent(articleContentMain);
              setIsEditting(false);
            }}
            className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] rounded-regular text-[16px] font-medium text-[#0259DB] flex items-center gap-[12px]"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              updateData({ ...articlesData, data: articleContent });
              setArticleContentMain(articleContent);
              setIsEditting(false);
            }}
            className="py-[8px] px-[16px] w-fit border-[1px] border-[#0259DB] bg-[#0259DB] rounded-regular text-[16px] font-medium text-[#fff] flex items-center gap-[12px]"
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default ArticleDetails;
