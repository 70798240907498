const monthList = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getFormattedDate = (ISODate, options = {}) => {
  if (ISODate) {
    const date = ISODate?.substring(0, 10);
    const dateArr = date.split("-");
    if (options.withMonthName) {
      return `${dateArr[2]} ${monthList[Number(dateArr[1]) - 1]} ${dateArr[0]}`;
    }
    return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
  }
  return "-";
};
