import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { baseURL } from "../../constants";
import { saved } from "../../apis/apiClient";
import { showToast } from "../../utils/showToast";
import { updateUser } from "../../reducers/profile";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const PlotCard = ({ data, keyIndex, showGmapPlotCard,setShowGmapPlotCard }) => {
  const [image, setImage] = useState(
    require(`../../assets/images/plots/placeholder.jpg`)
  );
  const [isShareOpen, setIsShareOpen] = useState(false);
  const profile = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      if (data?.type === "single" || data.images) {
        if (data?.images && data?.images[0]?.path) {
          setImage(`${baseURL}/${data?.images[0]?.path}`);
        }
      } else {
        data.plots?.some((plot) => {
          if (plot.images && plot.images[0]?.path) {
            setImage(`${baseURL}/${plot.images[0]?.path}`);
            return true;
          }
          return false;
        });
      }
      //   : data?.plots[0]?.images && data?.plots[0]?.images[0]?.path
      //   ?
      //   : require(`../../assets/images/plots/placeholder.jpg`);
    }
    document.getElementsByTagName("body")[0].addEventListener("click", (e) => {
      if (e.target.tagName === "path" || e.target.tagName === "svg") {
        return false;
      }
      setIsShareOpen(false);
    });
    return () => {
      document
        .getElementsByTagName("body")[0]
        .removeEventListener("click", () => {});
    };
  }, [data]);

  const handleSave = async () => {
    try {
      const response = await saved({
        plotId: data._id,
      });
      if (response.status === "success") {
        if (profile.userData?.saved.includes(data._id)) {
          showToast(`You have Unsaved ${data.puid} plot`, "success");
          const preUserData = profile.userData;
          const savedArr = [...preUserData.saved];

          const index = savedArr?.indexOf(data._id);
          if (index > -1) {
            savedArr.splice(index, 1); // 2nd parameter means remove one item only
          }

          dispatch(
            updateUser({
              ...preUserData,
              saved: [...savedArr],
            })
          );
        } else {
          showToast(`You have Saved ${data.puid} plot`, "success");
          const preUserData = profile.userData;
          dispatch(
            updateUser({
              ...preUserData,
              saved: [...preUserData.saved, data._id],
            })
          );
        }
      } else {
        showToast(`Some error occured, Please try again later`, "error");
        console.log(response.status);
      }
    } catch (e) {
      // if (e.response?.status === 400) {
      //   showToast(`Please fill all details to list property`, "error");
      // } else {
      showToast(`Some error occured, Please try again later`, "error");
      // }
      console.log("error", e);
    }
  };



  
return(
  showGmapPlotCard ? (
<>
<div onMouseLeave={() => setShowGmapPlotCard(false)} className="block w-[14rem] h-[15rem] rounded-md overflow-hidden bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 plot-card-container mx-auto -ml-4 lg:ml-0">
<div className="relative overflow-hidden bg-cover bg-no-repeat w-full h-[10rem]">
    <img className="object-cover w-full h-full rounded-t-md" src={image} alt="" />
</div>


    <div className="absolute top-0 left-0 right-0 p-2">
        <div className="flex justify-between items-end">
            <div className="flex gap-[12px]">
                {data.area && !profile.userData?.listings?.includes(data?._id) && (
                    <div onClick={handleSave} className="w-[32px] h-[32px] bg-[#00000099] cursor-pointer rounded-[4px] flex justify-center items-center">
                        {profile.userData?.saved?.includes(data?._id) ? (
                            <Icon icon="material-symbols:bookmark" color="#fff" width={18} />
                        ) : (
                            <Icon icon="material-symbols:bookmark-outline" color="#fff" width={18} />
                        )}
                    </div>
                )}
                <div className="relative" onClick={() => setIsShareOpen((prevState) => !prevState)}>
                    <div className="w-[32px] h-[32px] bg-[#00000099] cursor-pointer rounded-[4px] flex justify-center items-center">
                        <Icon icon="material-symbols:share" color="#fff" width={18} />
                    </div>
                    <div id="dropdown" className={`z-10 bg-[#efefef] rounded-regular divide-y gap-[8px] divide-gray-100 px-[12px] py-[8px] shadow-xl absolute flex transition duration-100 ease-in-out origin-top-left ${isShareOpen ? "scale-100" : "scale-0"}`}>
   
                        <FacebookShareButton
                            url={`${window.location.host}/plot/${data?.puid}`}
                            quote={data.title}
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>

                        <TwitterShareButton
                            url={`${window.location.host}/plot/${data?.puid}`}
                            title={data.title}
                        >
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>

                        <TelegramShareButton
                            url={`${window.location.host}/plot/${data?.puid}`}
                            title={data.title}
                        >
                            <TelegramIcon size={32} round />
                        </TelegramShareButton>
                        <WhatsappShareButton
                            url={`${window.location.host}/plot/${data?.puid}`}
                            title={data.title}
                            separator=": "
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>

                        <EmailShareButton
                            url={`${window.location.host}/plot/${data?.puid}`}
                            subject={data.title}
                            body="body"
                            className="Demo__some-network__share-button"
                        >
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                    
                    </div>
                </div>
                <Link to={`/plot/${data?.puid}#map`} className="px-[10px] py-[4px] bg-[#00000099] cursor-pointer rounded-[4px] flex justify-center items-center">
                    <p className="font-medium text-[14px] text-white">View on map</p>
                </Link>
            </div>
        
        </div>
    </div>

    <div className="absolute bottom-0 left-0 right-0 p-2 -ml-4 lg:ml-0">
    <Link to={`/plot/${data?.puid}`} className="font-bold text-[14px] md:text-[16px] text-[#333333] cursor-pointer hover:text-primaryColor">
                {data?.title}
            </Link>
        <p className="text-[12px] text-[#8A8A8A] mt-[4px] flex">
          
            <span className="text-[#838383]">
                <Icon icon="material-symbols:location-on" width={16} height={16} color="#838383" />
            </span>
            {`${data?.location?.taluka}, ${data?.location?.district}, ${data?.location?.state}`}
        </p>

        <div className="flex flex-wrap mt-[8px] gap-[4px]">
            {data?.type !== "single" &&
                data?.plots &&
                [
                    { name: "Total Plots", count: data?.plots?.length },
                    {
                        name: "Sold",
                        count: data?.plots?.filter((plot) => plot?.availability === "sold")?.length,
                    },
                ].map((tag, i) => (
                    <div
                        key={i}
                        className="px-[8px] py-[3px] bg-[#EAF2FF] rounded-regular text-[#0259DB] text-[10px] font-medium flex gap-[4px] items-center"
                    >
                        {tag.name}{" "}
                        <span className="bg-[#CCDEF8] px-[4px] rounded-[2px] text-[#0247AF] font-medium text-[12px]">
                            {tag.count}
                        </span>
                    </div>
                ))}

            {data?.is_drafted && (
                <div className="italic px-[8px] py-[3px] bg-[#F6F6F6] rounded-regular text-[#666666] text-[10px] font-medium flex gap-[4px] items-center">
                    Saved as draft
                </div>
            )}
            {data?.type === "single" &&
                data?.highlights?.map((tag, i) => (
                    <div
                        key={i}
                        className="px-[8px] py-[3px] bg-[#EAF2FF] rounded-regular text-[#0259DB] text-[10px] font-medium flex gap-[4px] items-center"
                    >
                        {tag}{" "}
                        {tag.count && (
                            <span className="bg-[#CCDEF8] px-[4px] rounded-[2px] text-[#0247AF] font-medium text-[12px]">
                                {tag.count}
                            </span>
                        )}
                    </div>
                ))}
            {profile?.userData?.bids?.includes(data._id) && (
                <div className="px-[8px] py-[3px] bg-[#EAF2FF] rounded-regular text-[#0259DB] text-[10px] font-medium flex gap-[4px] items-center">
                    My Offer - ₹
                    {data?.bids?.filter((bid) => bid.user === profile.userData?.id)[0]?.amount}
                </div>
            )}
        </div>
    </div>
</div>



</>

  ) : (

      <>
 <div className={`shadow-lg rounded-[16px] mb-[12px] p-[16px] flex flex-col sm:flex-row gap-x-[12px]`}>
      <div
        className={`w-full bg-cover bg-no-repeat bg-center sm:w-[240px] h-[200px] rounded-regular relative`}
        style={{
          backgroundImage: `url('${image}')`,
        }}
      >
        <div className="absolute bottom-[12px] left-[12px] flex gap-[12px]">
          {data.area && !profile.userData?.listings?.includes(data?._id) && (
            <div
              onClick={handleSave}
              className="w-[32px] h-[32px] bg-[#00000099] cursor-pointer rounded-[4px] flex justify-center items-center"
            >
              {profile.userData?.saved?.includes(data?._id) ? (
                <Icon
                  icon="material-symbols:bookmark"
                  color="#fff"
                  width={18}
                />
              ) : (
                <Icon
                  icon="material-symbols:bookmark-outline"
                  color="#fff"
                  width={18}
                />
              )}
            </div>
          )}
          <div
            className="relative"
            onClick={() => {
              setIsShareOpen((prevState) => !prevState);
            }}
          >
            <div className="w-[32px] h-[32px] bg-[#00000099] cursor-pointer rounded-[4px] flex justify-center items-center">
              <Icon icon="material-symbols:share" color="#fff" width={18} />
            </div>
            <div
              id="dropdown"
              className={`z-10  bg-[#efefef] rounded-regular divide-y gap-[8px] divide-gray-100 px-[12px] py-[8px] shadow-xl absolute flex transition duration-100 ease-in-out origin-top-left ${
                isShareOpen ? "scale-100" : "scale-0"
              }`}
            >
              <FacebookShareButton
                url={`${window.location.host}/plot/${data?.puid}`}
                quote={data.title}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              <TwitterShareButton
                url={`${window.location.host}/plot/${data?.puid}`}
                title={data.title}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>

              <TelegramShareButton
                url={`${window.location.host}/plot/${data?.puid}`}
                title={data.title}
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              <WhatsappShareButton
                url={`${window.location.host}/plot/${data?.puid}`}
                title={data.title}
                separator=": "
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>

              <EmailShareButton
                url={`${window.location.host}/plot/${data?.puid}`}
                subject={data.title}
                body="body"
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
          </div>
          <Link
            to={`/plot/${data?.puid}#map`}
            className="px-[10px] py-[4px] bg-[#00000099] cursor-pointer rounded-[4px] flex justify-center items-center"
          >
            <p className="font-medium text-[14px] text-white">View on map</p>
          </Link>
        </div>
      </div>
      <div className="w-full sm:w-[calc(100%-252px)] h-full">
        <Link
          to={`/plot/${data?.puid}`}
          className="font-bold text-[16px] md:text-[20px] text-[#333333] cursor-pointer hover:text-primaryColor"
        >
          {data?.title}
        </Link>
        {/* <p className="font-medium text-[14px] md:text-[16px] text-[#747C7F] mt-[8px] flex items-center gap-[8px]">
          By {data?.owner_name}{" "}
          {data?.listed_by[0]?.isVerified && (
            <Icon
              icon="material-symbols:verified"
              width={22}
              height={22}
              color="#58B455"
            />
          )}{" "}
        </p> */}
        <p className="font-semibold text-[14px] md:text-[16px] text-[#8A8A8A] mt-[8px] flex items-center gap-[8px]">
          {" "}
          <Icon
            icon="material-symbols:location-on"
            width={22}
            height={22}
            color="#838383"
          />{" "}
          {`${data?.location?.taluka}, ${data?.location?.district}, ${data?.location?.state}`}
        </p>
        <div className="flex flex-wrap mt-[18px] gap-[8px]">
          {data?.type !== "single" &&
            data?.plots &&
            [
              { name: "Total Plots", count: data?.plots?.length },
              {
                name: "Sold",
                count: data?.plots?.filter(
                  (plot) => plot?.availability === "sold"
                )?.length,
              },
            ].map((tag, i) => (
              <div
                key={i}
                className="px-[12px] py-[5px] bg-[#EAF2FF] rounded-regular text-[#0259DB] text-[12px] md:text-[14px] font-medium flex gap-[8px] items-center"
              >
                {tag.name}{" "}
                <span className="bg-[#CCDEF8] px-[6px] rounded-[4px] text-[#0247AF] font-medium text-[14px]">
                  {tag.count}
                </span>
              </div>
            ))}

          {data?.is_drafted && (
            <div className="italic px-[12px] py-[5px] bg-[#F6F6F6] rounded-regular text-[#666666] text-[12px] md:text-[14px] font-medium flex gap-[8px] items-center">
              Saved as draft
            </div>
          )}
          {data?.type === "single" &&
            data?.highlights?.map((tag, i) => (
              <div
                key={i}
                className="px-[12px] py-[5px] bg-[#EAF2FF] rounded-regular text-[#0259DB] text-[12px] md:text-[14px] font-medium flex gap-[8px] items-center"
              >
                {tag}{" "}
                {tag.count && (
                  <span className="bg-[#CCDEF8] px-[6px] rounded-[4px] text-[#0247AF] font-medium text-[14px]">
                    {tag.count}
                  </span>
                )}
              </div>
            ))}
          {profile?.userData?.bids?.includes(data._id) && (
            <div className="px-[12px] py-[5px] bg-[#EAF2FF] rounded-regular text-[#0259DB] text-[12px] md:text-[14px] font-medium flex gap-[8px] items-center">
              My Offer - ₹
              {
                data?.bids?.filter(
                  (bid) => bid.user === profile.userData?.id
                )[0]?.amount
              }
            </div>
          )}
        </div>
       </div>
       </div>
     
       </>
       )
    )
    
};
export default PlotCard;
