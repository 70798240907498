import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/svg/logo.svg";
import { logoutUser } from "../../reducers/profile";
import HeaderDropdown from "./HeaderDropdown";
import { getNotifications } from "../../apis/apiClient";

const HomeHeader = ({ isHome, showResourceSelector }) => {
  const [opacity, setOpacity] = useState(
    isHome ? "bg-opacity-0" : "bg-opacity-100"
  );
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleLogout = () => {
    localStorage.removeItem("userData");
    dispatch(logoutUser());
    navigate("/login");
  };

  const checkScroll = () => {
    if (window.scrollY > 500 || !isHome) {
      setOpacity("bg-opacity-100 transition duration-250");
    } else {
      setOpacity("bg-opacity-0 transition duration-250");
    }
  };

  window.addEventListener("scroll", checkScroll);

  return (
    <header className="h-[92px]">
      <nav
        className={`bg-white p-[32px] fixed top-0 left-0 w-full ${opacity} z-40`}
      >
        <div className="flex justify-between items-center mx-auto max-w-screen-xl px-[24px]">
          <div className="flex">
            <Link to="/" className="flex items-center">
              <img src={logo} className="mr-3 h-6 sm:h-9" alt="Logo" />
            </Link>
            <div
              className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1 ml-[40px]"
              id="mobile-menu-2"
            >
              <ul className="list-none flex flex-row font-medium gap-[20px] mt-[10px]">
                <li>
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive
                        ? "block px-[10px] font-bold text-primaryColor border-b-[2px] border-primaryColor pb-[10px]"
                        : "block px-[10px] text-menuLinkColor border-b-[2px] border-[transparent] pb-[10px]"
                    }
                  >
                    Home
                  </NavLink>
                </li>

                {profile.isLoggedIn ? (
                  <>
                    <li>
                      <NavLink
                        to="/buyplot"
                        className={({ isActive }) =>
                          isActive
                            ? "block px-[10px] font-bold text-primaryColor border-b-[2px] border-primaryColor pb-[10px]"
                            : "block px-[10px] text-menuLinkColor border-b-[2px] border-[transparent] pb-[10px]"
                        }
                      >
                        Buy a Property
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/sellplot"
                        className={({ isActive }) =>
                          isActive
                            ? "block px-[10px] font-bold text-primaryColor border-b-[2px] border-primaryColor pb-[10px]"
                            : "block px-[10px] text-menuLinkColor border-b-[2px] border-[transparent] pb-[10px]"
                        }
                      >
                        Sell a Property
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <a
                        href="/#aboutus"
                        className="block px-[10px] text-menuLinkColor border-b-[2px] border-[transparent] pb-[10px]"
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="/#ourteam"
                        className="block px-[10px] text-menuLinkColor border-b-[2px] border-[transparent] pb-[10px]"
                      >
                        Our Team
                      </a>
                    </li>
                  </>
                )}
                <li>
                  <NavLink
                    to="/resources"
                    className={({ isActive }) =>
                      (isActive || showResourceSelector)
                        ? "block px-[10px] font-bold text-primaryColor border-b-[2px] border-primaryColor pb-[10px]"
                        : "block px-[10px] text-menuLinkColor border-b-[2px] border-[transparent] pb-[10px]"
                    }
                  >
                    Resources
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/blog"
                    className={({ isActive }) =>
                      (isActive && !showResourceSelector)
                        ? "block px-[10px] font-bold text-primaryColor border-b-[2px] border-primaryColor pb-[10px]"
                        : "block px-[10px] text-menuLinkColor border-b-[2px] border-[transparent] pb-[10px]"
                    }
                  >
                    Blog
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex items-center lg:order-2">
            {profile.isLoggedIn ? (
              <HeaderDropdown handleLogout={handleLogout} />
            ) : (
              <button
                className="bg-btnBgColor border-[2px] border-primaryColor px-[16px] py-[12px] text-primaryColor text-[16px] rounded-regular"
                onClick={handleLoginClick}
              >
                Login/Signup
              </button>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default HomeHeader;
