import { Icon } from "@iconify/react";
import React from "react";

const Features = ({ plotDetails, setPlotDetails }) => {
  return (
    <section className="mt-[32px]">
      <div className="flex items-center gap-[12px]">
        <h4 className="text-[20px] text-black font-medium">
          Property Landamarks/Nearby
          <span className="text-[14px]"> (Optional) </span>
        </h4>
        <div className="group inline-block relative">
          <Icon
            className="cursor-pointer"
            icon="material-symbols:info-outline"
            color="#838383"
            width={20}
          />
          <div className="bg-white border rounded-regular transform scale-0 group-hover:scale-100 absolute transition duration-100 ease-in-out origin-left py-[4px] px-[8px] w-[276px] overflow-scroll left-[30px] top-[-100%] z-50">
            <p className="text-center text-[12px] text-[#838383]">
              Landamarks/Nearby of the property consist of any nearby developed
              location/building i.e. nearby hotel, highway which add up to
              property’s value
            </p>
          </div>
        </div>
      </div>

      {plotDetails.features?.length === 0 && (
        <button
          onClick={() => {
            setPlotDetails((prevState) => {
              let features = prevState.features;
              let tempArray = [...features];
              tempArray.push({ name: "", distance: "" });
              return { ...prevState, features: [...tempArray] };
            });
            // setFeatures((prevState) => [
            //   ...prevState,
            //   { feature: "", distance: "" },
            // ]);
          }}
          className="px-[16px] py-[12px] text-[16px] font-medium text-[#666666] border-[1px] border-[#666666] rounded-regular mt-[24px]"
        >
          Add New Landamarks/Nearby
        </button>
      )}

      {plotDetails.features?.map((feature, index) => (
        <div
          className="mt-[24px] grid grid-cols-1 md:grid-cols-3 gap-[16px]"
          key={`${index}__feature`}
        >
          <div className="flex flex-col w-full">
            <label
              htmlFor={`featurename_${index}`}
              className="text-[#344054] text-[14px] font-medium"
            >
              Landamarks/Nearby
            </label>
            <div className="flex items-center mt-[8px]">
              <input
                id={`featurename_${index}`}
                value={feature.name}
                onChange={(e) => {
                  e.target.classList.remove("!border-[#C62F39]");
                  setPlotDetails((prevState) => {
                    let features = prevState.features;
                    let tempArray = [...features];
                    tempArray[index]["name"] = e.target.value;
                    return { ...prevState, features: [...tempArray] };
                  });
                  // setFeatures((prevState) => {
                  //   let tempArray = [...prevState];
                  //   tempArray[index]["feature"] = e.target.value;
                  //   return [...tempArray];
                  // });
                }}
                placeholder="e.g, Corner plot, Well, Tree"
                type="text"
                className="w-full rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
              />
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label
              htmlFor={`featuredistance_${index}`}
              className="text-[#344054] text-[14px] font-medium"
            >
              Distance
            </label>
            <div className="flex items-center mt-[8px] gap-[16px]">
              <input
                id={`featuredistance_${index}`}
                value={feature.distance}
                onChange={(e) => {
                  e.target.classList.remove("!border-[#C62F39]");
                  setPlotDetails((prevState) => {
                    let features = prevState.features;
                    let tempArray = [...features];
                    tempArray[index]["distance"] = e.target.value;
                    return { ...prevState, features: [...tempArray] };
                  });
                  // setFeatures((prevState) => {
                  //   let tempArray = [...prevState];
                  //   tempArray[index]["distance"] = e.target.value;
                  //   return [...tempArray];
                  // });
                }}
                placeholder="e.g, 100 meters"
                type="text"
                className="w-full rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
              />
            </div>
          </div>

          <div className="flex gap-[16px] items-end">
            <div className="py-[12px]">
              <Icon
                icon="ic:baseline-remove-circle-outline"
                color="#B42318"
                width={24}
                className="cursor-pointer"
                onClick={() => {
                  setPlotDetails((prevState) => {
                    let features = prevState.features;
                    let tempArray = [...features];
                    tempArray.splice(index, 1);
                    return { ...prevState, features: [...tempArray] };
                  });
                  // setFeatures((prevState) => {
                  //   let tempArray = [...prevState];
                  //   tempArray.splice(index, 1);
                  //   return [...tempArray];
                  // });
                }}
              />
            </div>
            {index === plotDetails.features?.length - 1 && (
              <button
                onClick={() => {
                  setPlotDetails((prevState) => {
                    let features = prevState.features;
                    let tempArray = [...features];
                    tempArray.push({ name: "", distance: "" });
                    return { ...prevState, features: [...tempArray] };
                  });
                  // setFeatures((prevState) => [
                  //   ...prevState,
                  //   { feature: "", distance: "" },
                  // ]);
                }}
                className="px-[16px] py-[12px] text-[16px] font-medium text-[#666666] border-[1px] border-[#666666] rounded-regular"
              >
                Add New Landamarks/Nearby
              </button>
            )}
          </div>
        </div>
      ))}
    </section>
  );
};

export default Features;
