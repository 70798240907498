import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header/AdminHeader";
import googleLogo from "../assets/svg/google.svg";
import yahooo from "../assets/svg/yahooo.svg";

import OTPVerification from "../components/OTPVerification";
import { useNavigate } from "react-router-dom";
import { registerProfile, sendOTP, updateProfile } from "../apis/apiClient";
import { updateUser } from "../reducers/profile";
import { Oval } from "react-loader-spinner";
import { showToast } from "../utils/showToast";

const Registration = () => {
  const [step, setStep] = useState(0);
  const [isOTPError, setIsOTPError] = useState(false);
  const [OTP, setOTP] = useState("");
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);

  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const [userData, setuserData] = useState({
    name: "",
    dob: "",
    district: "",
    taluka: "",
    mobile_number: profile.userData.mobile_number,
    email: profile.userData.email,
    aadhaar_number: "",
    isRegistered: true,
    username: profile.userData.mobile_number
      ? profile.userData.mobile_number
      : profile.userData.email,
  });

  useEffect(() => {
    setuserData((prevState) => ({
      ...prevState,
      mobile_number: profile.userData.mobile_number,
      email: profile.userData.email,
      username: profile.userData.mobile_number
        ? profile.userData.mobile_number
        : profile.userData.email,
    }));
  }, [profile]);

  useEffect(() => {
    const secondsInterval = setInterval(() => {
      if (remainingSeconds > 0) {
        setRemainingSeconds((prevState) => prevState - 1);
      }
    }, 1000);
    return () => {
      clearInterval(secondsInterval);
    };
  }, [remainingSeconds]);

  const handleVerifyOTP = async () => {
    try {
      const username = profile.userData.email
        ? profile.userData.email
        : profile.userData.mobile_number;

      setIsVerifyLoading(true);
      const response = await registerProfile({
        ...userData,
        username,
        otp: OTP !== "" ? Number(OTP) : 0,
      });
      setIsVerifyLoading(false);

      if (response.status === "success") {
        dispatch(updateUser(response.user));
        setStep(2);
      } else {
        setIsOTPError(true);
      }
    } catch (e) {
      setIsVerifyLoading(false);

      setIsOTPError(true);
      console.log(e);
    }
  };

  const handleNext = async () => {
    if (userData.name !== "" && userData.dob !== "") {
      if (
        (profile.userData?.mobile_number
          ? userData?.email
          : userData?.mobile_number) !== undefined
      ) {
        var data = {};
        if (profile.userData.email) {
          data = {
            mobile_number: userData.mobile_number,
            username: profile.userData.email,
          };
        } else {
          data = {
            email: userData.email,
            username: profile.userData.mobile_number,
          };
        }
        try {
          setIsRegisterLoading(true);
          const response = await sendOTP(data);
          setIsRegisterLoading(false);

          if (response.status === "success") {
            setRemainingSeconds(30);
            setStep(1);
          }
        } catch (e) {
          setIsRegisterLoading(false);

          console.log(e);
        }
      } else {
        handleVerifyOTP();
      }
    } else {
      showToast("Please Fill required fields", "error");
    }
  };

  const handleExplore = () => {
    navigate("/");
  };

  return (
    <div className="bg-[#F9FCFF] min-h-screen">
      <Header isRegistration />
      <div
        className={`w-full flex justify-center h-[calc(100vh-80px)] overflow-y-scroll ${
          step !== 0 && "items-center"
        }`}
      >
        <div className="max-w-[700px] h-fit my-[80px] mx-[24px] md:mx-0">
          {step === 0 && (
            <>
              <h1 className="text-[24px] md:text-[40px] font-bold text-center">
                Profile Details
              </h1>
              <h5 className="text-[16px] md:text-[18px] text-secondaryText mt-[16px] text-center font-medium">
                Please enter your personal details to proceed
              </h5>
              <div className="mt-[40px]">
                <div>
                  <label
                    htmlFor="fullname"
                    className="text-labelText text-[14px] font-medium"
                  >
                    Enter your Full Name*
                  </label>
                  <input
                    onChange={(e) => {
                      setuserData((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }));
                    }}
                    value={userData.name}
                    id="fullname"
                    type="text"
                    className={`w-full bg-white px-[16px] py-[10px] border-[1px] outline-none rounded-regular text-inputText my-[8px] text-[16px] border-inputBorder`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="dob"
                    className="text-labelText text-[14px] font-medium"
                  >
                    Date of Birth*
                  </label>
                  <input
                    onChange={(e) => {
                      setuserData((prevState) => ({
                        ...prevState,
                        dob: e.target.value,
                      }));
                    }}
                    value={userData.dob}
                    id="dob"
                    type="date"
                    className={`w-full bg-white px-[16px] py-[10px] border-[1px] outline-none rounded-regular text-inputText my-[8px] text-[16px] border-inputBorder`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="district"
                    className="text-labelText text-[14px] font-medium"
                  >
                    Enter District Name
                  </label>
                  <input
                    onChange={(e) => {
                      setuserData((prevState) => ({
                        ...prevState,
                        district: e.target.value,
                      }));
                    }}
                    value={userData.district}
                    id="district"
                    type="text"
                    className={`w-full bg-white px-[16px] py-[10px] border-[1px] outline-none rounded-regular text-inputText my-[8px] text-[16px] border-inputBorder`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="taluka"
                    className="text-labelText text-[14px] font-medium"
                  >
                    Enter Taluka Name
                  </label>
                  <input
                    onChange={(e) => {
                      setuserData((prevState) => ({
                        ...prevState,
                        taluka: e.target.value,
                      }));
                    }}
                    value={userData.taluka}
                    id="taluka"
                    type="text"
                    className={`w-full bg-white px-[16px] py-[10px] border-[1px] outline-none rounded-regular text-inputText my-[8px] text-[16px] border-inputBorder`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="mobile_number"
                    className="text-labelText text-[14px] font-medium"
                  >
                    Phone Number
                  </label>
                  <input
                    maxLength="10"
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                    defaultValue={userData.mobile_number}
                    onChange={(e) => {
                      setuserData((prevState) => ({
                        ...prevState,
                        mobile_number: e.target.value,
                      }));
                    }}
                    disabled={profile.userData.mobile_number ? true : false}
                    id="mobile_number"
                    type="number"
                    className={`w-full bg-white px-[16px] py-[10px] border-[1px] outline-none rounded-regular text-inputText my-[8px] text-[16px] border-inputBorder disabled:bg-disabledColor`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="text-labelText text-[14px] font-medium"
                  >
                    Email ID
                  </label>
                  <input
                    defaultValue={userData.email}
                    onChange={(e) => {
                      setuserData((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }));
                    }}
                    disabled={profile.userData.email ? true : false}
                    id="email"
                    type="text"
                    className={`w-full bg-white px-[16px] py-[10px] border-[1px] outline-none rounded-regular text-inputText my-[8px] text-[16px] border-inputBorder disabled:bg-disabledColor`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="aadhaar_number"
                    className="text-labelText text-[14px] font-medium"
                  >
                    Aadhaar number
                  </label>
                  <input
                    onKeyUp={(e) => {
                      var value = e.target.value;
                      value = value
                        .replace(/\D/g, "")
                        .split(/(?:([\d]{4}))/g)
                        .filter((s) => s.length > 0)
                        .join("-");
                      e.target.value = value;
                    }}
                    onChange={(e) => {
                      setuserData((prevState) => ({
                        ...prevState,
                        aadhaar_number: e.target.value,
                      }));
                    }}
                    value={userData.value}
                    maxLength={19}
                    id="aadhaar_number"
                    type="text"
                    className={`w-full bg-white px-[16px] py-[10px] border-[1px] outline-none rounded-regular text-inputText my-[8px] text-[16px] border-inputBorder`}
                  />
                </div>
              </div>

              <button
                onClick={handleNext}
                disabled={isRegisterLoading}
                className="w-full mt-[36px] bg-primaryColor p-[12px] rounded-regular text-white font-medium text-[16px] flex items-center justify-center gap-[12px]"
              >
                <Oval
                  height={20}
                  width={20}
                  color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={isRegisterLoading}
                  ariaLabel="oval-loading"
                  secondaryColor="#f8f8f8f"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
                Next
              </button>
              {/* <button className="w-full mt-[16px] bg-white p-[12px] rounded-regular text-labelText font-medium text-[16px] border-[1px] border-inputBorder flex justify-center items-center">
                <img src={googleLogo} alt="googlelogo" className="mr-[12px]" />{" "}
                Sign in with Google
              </button> */}
            </>
          )}
          {step === 1 && (
            <OTPVerification
              isVerifyLoading={isVerifyLoading}
              OTP={OTP}
              setOTP={setOTP}
              isOTPError={isOTPError}
              setIsOTPError={setIsOTPError}
              email={profile.userData.email}
              username={
                profile.userData?.mobile_number
                  ? userData?.email
                  : userData?.mobile_number
              }
              setStep={setStep}
              remainingSeconds={remainingSeconds}
              handleVerifyOTP={handleVerifyOTP}
              handleSendOTP={handleNext}
            />
          )}

          {step === 2 && (
            <>
              <div className="flex justify-center">
                <img alt="yahooo" src={yahooo} />
              </div>

              <h1 className="text-[40px] font-semibold text-center">Yahooo!</h1>
              <h5 className="text-[20px] text-secondaryText mt-[16px] text-center font-medium">
                Your profile has been created successfully
              </h5>
              <h6 className="text-[16px] text-secondaryText mt-[16px] text-center font-medium">
                Start exploring new properties
              </h6>

              <button
                onClick={handleExplore}
                className="w-full mt-[52px] bg-primaryColor p-[12px] rounded-regular text-white font-medium text-[16px]"
              >
                Start Exploring
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Registration;
