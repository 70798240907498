import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};

const MetricCard = ({ data }) => {
  const [gradient1, setGradient] = useState("");
  useEffect(() => {
    var ctx = document.getElementById("temp-chart").getContext("2d");
    var gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, data.percentage < 0 ? "#FFEEEF" : "#D1FFEC");
    gradient.addColorStop(0.17, "#fff");
    setGradient(gradient);
  }, [data]);

  return (
    <div className="w-full bg-white p-[20px] border-[1px] border-[#EAECF0] shadow-sm rounded-regular">
      <canvas id="temp-chart" className="hidden" />
      <h6 className="text-menuLinkColor font-medium text-[14px]">
        {data.title}
      </h6>
      <h2 className="text-[#2B2B2B] font-semibold text-[24px] mt-[16px]">
        {data.count}
      </h2>
      <div className="mt-[16px] flex justify-between items-end">
        <div className="flex gap-[8px] items-center">
          <div
            className={`py-[4px] px-[8px] text-[12px] font-semibold flex items-center gap-[4px] rounded-regular ${
              data.percentage < 0
                ? "bg-[#FFEEEF] text-[#C62F39]"
                : "bg-[#D1FFEC] text-[#027A48]"
            }`}
          >
            {data.percentage < 0 ? (
              <Icon icon="mdi:arrow-down" />
            ) : (
              <Icon icon="mdi:arrow-up" />
            )}{" "}
            {Math.round(data.percentage)} %
          </div>
          <span className="text-[#838383] font-medium text-[12px]">
            vs last month
          </span>
        </div>
        <div className="w-[128px] h-[64px]">
          <Line
            data={{
              labels: data.chart?.map((data) => data),
              datasets: [
                {
                  fill: true,
                  backgroundColor: gradient1,
                  borderColor: data.percentage < 0 ? "#C62F39" : "#027A48",
                  borderWidth: 2,
                  lineTension: 0.4,
                  data: data.chart.map((data) => data),
                },
              ],
            }}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default MetricCard;
