import { configureStore } from '@reduxjs/toolkit'
import profileReducer from '../reducers/profile'
import notificationReducer from '../reducers/notification'

export default configureStore({
  reducer: {
    profile: profileReducer,
    notificationList : notificationReducer,
  },
},
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)