import React from "react";
import HomeHeader from "../components/Header/HomeHeader";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="bg-white mx-auto max-w-screen-xl px-[24px]">
        <HomeHeader isHome={false} />
        <div className="my-[24px]">
          <h1 className="font-extrabold">Privacy Policy</h1>
          <div className="my-[36px]">
            <p className="text-justify ml-0 md:ml-8 py-4">
              Our Privacy Policy is designed with an intent to customize the
              needs and to cater personalized services to the users while
              ensuring that ultimately, you have the control of your personal
              information disclosed by you while creating an account to login
              onto the Platform and availing of the Service through it. You are
              advised to please read this Privacy Policy carefully to avoid any
              confusion. By accessing to and availing of the Service on the
              Platform, you agree to the collection and use of your Personal
              Information by us in the manner provided in this Privacy Policy
              and have given your consent thereto.
            </p>
            <p className="text-justify ml-0 md:ml-8 py-4">
              This document describes our policies and procedures on the
              collection, use and disclosure of your Personal Information when
              you use the Service and apprise you about your privacy rights and
              how the law protects you. We use your Personal Information to
              provide and improve the Service. By using the Service, you agree
              to the collection and use of Personal Information in accordance
              with this Privacy Policy.
            </p>

            <div>
              <h3 className="font-bold text-[20px] my-8 ml-2">DEFINITIONS</h3>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">a.</span>"
                <span className="font-semibold text-md text-black whitespace-nowrap">
                  Account
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means a unique account created by and for any User to access
                  the online Platform and avail of our Service.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">b.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap">
                  Affiliate
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means an entity that controls, is controlled by or is under
                  common control with a party, where "control" means ownership
                  of 50% or more of the shares, equity interest or other
                  securities entitled to vote for election of directors or other
                  managing authority.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">c.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Applicable Law
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means all applicable laws, by-laws, rules, regulations,
                  orders, ordinances, protocols, codes, guidelines, policies and
                  judgments which are precedents or other statutory directions
                  given by statutory Authority in any relevant jurisdiction.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">d.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Application
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means the software program developed for the Service by the
                  Company which is downloadable by any User on any electronic
                  Device.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">e.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Company
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means <strong>Rempo Ventures Pvt. Ltd. </strong>, Sr. No.7 &
                  8, Office No.304, Elite Premio, Balewadi, Pune 411045 and
                  includes "we", "us" or "our" as referred to in this Policy,
                  and includes its Affiliate, if any, directors, employees,
                  agents, and authorized representatives.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">f.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Consent
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means voluntary consent given by You to the Company to receive
                  and process your Personal Information in accordance with this
                  Policy. Consent may be explicit or inferred as defined in
                  these regulations.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">g.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Device
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means any electronic device that can access the Service such
                  as a computer, a cell phone or a digital tablet.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">h.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Force Majeure Event
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means and includes but not limited to fire, flood, explosion,
                  earthquake, natural disaster, pandemic, epidemic, drought or
                  bad weather, lightning, or any act of God, acts of state,
                  strikes, acts of war (whether declared or not), hostilities,
                  terrorism, riots, civil disorders or commotion, lockouts,
                  industrial disputes, rebellions or revolutions, blockages;
                  quarantines, embargoes and other similar governmental action
                  and any other reason whatsoever beyond our control.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">i.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Inferred Consent
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means any consent and/or permission that can be reasonably
                  inferred from your conduct or action and/or inaction
                  pertaining to any specific Transaction or issue in course of
                  availing of the Service.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">j.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Personal data
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means any data about an individual who is identifiable by or
                  in relation to such data.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">k.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Personal Information
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means any information related to an individual User disclosed
                  and/or automatically generated on the Platform in course of
                  availing of the Service and includes all information shared by
                  and between the Users on the Platform pertaining to any real
                  estate Transaction by and between them, including Personal
                  Data, agreement, contract, gift deed, title deed or any other
                  deed on title, title certificates, revenue records, any other
                  government records and/or any other documents related to right
                  title or interest in any piece of land and/or any other real
                  estate property of any User.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">l.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Platform
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means the Application, including Mobile App and website,
                  developed and owned by the Company which is accessible by
                  downloading it and creating a user account for availing the
                  Service related to all and any real estate Transactions.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">m.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Processing
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  in relation to personal data means an automated operation or
                  set of operations performed on digital personal data, and may
                  include operations such as collection, recording,
                  organization, structuring, storage, adaptation, alteration,
                  retrieval, use, alignment or combination, indexing, sharing,
                  disclosure by transmission, dissemination or otherwise making
                  available, restriction, erasure or destruction.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">n.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Service
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means all activities doable and services available on the
                  Platform related to any real estate Transaction and include
                  all support services provided by us as may be required by any
                  User.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">o.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  Transaction
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means any and all transactions related to real estate,
                  including land, building or any other structure, unit, shop,
                  godown, showroom, warehouse, factory, office unit, residential
                  unit, TDR, FSI, and/or any other immovable property.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black">p.</span> "
                <span className="font-semibold text-md text-black whitespace-nowrap ">
                  User(s)
                </span>
                "{" "}
                <p className="text-justify ml-1 md:ml-16">
                  means any individual using the Service and includes “you” and
                  “your” as referred to in this Policy.
                </p>
              </div>
            </div>

            <div>
              <h3 className="font-bold text-[20px] my-8 ml-2">
                ABOUT THIS PRIVACY POLICY
              </h3>

              <p className="text-justify mb-6 ml-0 md:ml-8 text-gray-900">
                This Privacy Policy explains how we collect, use, disclose,
                process, and protect any Personal Information that are disclosed
                by any User and/or generated on the Platform while accessing and
                using the Service.
              </p>
              <p className="text-justify mb-6 ml-0 md:ml-8 text-gray-900">
                Disclosure, use and processing of all and any Personal
                Information of the User on the Platform shall be in accordance
                with this Privacy Policy and/or our Terms of Use and in
                compliance with Applicable Law.
              </p>
            </div>

            <div>
              <h3 className="font-bold text-[20px] my-8 ml-2">
                WHAT PERSONAL INFORMATION IS COLLECTED FROM USERS
              </h3>

              <div class="definition mb-6 text-gray-700">
                <span className="font-semibold text-md text-black ml-1 md:ml-6 mb-2">
                  Personal Information that you provide
                </span>{" "}
                <p className="text-justify ml-1 md:ml-16 mb-6">
                  When you seek to access to the Platform to use our Service,
                  you may be asked to provide certain Personal Data that can be
                  used to contact or identify you. Such Personal Data may
                  include, but is not limited to: email address, phone number,
                  first name, middle name, last name and usage data. For the
                  purpose of this policy, usage data refers to any data as to
                  how the Service is used and availed by Users and the
                  activities of the Users while availing the Service on the
                  Platform and may include information such as your device's
                  internet protocol address (e.g. IP address), browser type,
                  browser version, the pages of our Service that you visit, the
                  time and date of your visit, the time spent on those pages,
                  unique device identifiers and other diagnostic data. When you
                  access our Service on the Platform, you may be asked to
                  disclose certain information automatically, including, but not
                  limited to, the type of Device you use, your Device unique ID,
                  the IP address of your Device, your Device operating system,
                  the type of Device internet browser you use, unique device
                  identifiers and other diagnostic data. It may also collect
                  information that your browser sends whenever you visit our
                  Platform or when you access the Service by or through a
                  Device.
                </p>
                <p className="text-justify ml-1 md:ml-16">
                  We may record and process video and/or audio recordings from
                  the Device, subject to your instruction, configuration and
                  settings. This may include capturing and emailing to you
                  portions of this Data as part of a notification or analyzing
                  the Data to identify faces or other events. If you instruct us
                  by setting up your camera and choosing to receive mobile
                  notifications, we may process information from your device to
                  send you alerts when something happens.
                </p>
              </div>
            </div>

            <div>
              <h3 className="font-bold text-[20px] my-8 ml-1 md:ml-2">
                HOW THE PERSONAL INFORMATION CAN BE USED
              </h3>

              <div class="definition mb-6 ml-1 md:ml-8 text-gray-700">
                <span class="font-semibold text-black text-[1.115rem]">I.</span>
                {"  "}
                <span className="text-[1.115rem] ml-0 mb-2 text-black">
                  <i>
                    {" "}
                    We may use personal information for the following purposes:
                  </i>
                </span>{" "}
                <p className="text-justify ml-0 md:ml-16 mt-2">
                  <ol>
                    <div class="definition mb-6 text-gray-700">
                      <li className="font-semibold text-md text-black list-disc">
                        Providing the Service:
                      </li>{" "}
                      <p className="text-justify ml-1 md:ml-16">
                        To operate and ensure operation of the Platform 24/7/365
                        for being used by the Users, including coordination with
                        and providing liaisoning and other services as may be
                        required by the Users.
                      </p>
                    </div>

                    <div class="definition mb-6 text-gray-700">
                      <li className="font-semibold text-md text-black list-disc">
                        Maintain and improve our Service:
                      </li>{" "}
                      <p className="text-justify ml-1 md:ml-16">
                        To maintain, improve, and further develop the Service,
                        including monitoring the usage of the Service by the
                        Users and other services provided by Us and/or through
                        the Platform, by analyzing and developing statistics on
                        use of the Service to power our Service.
                      </p>
                    </div>

                    <div class="definition mb-6 text-gray-700">
                      <li className="font-semibold text-md text-black list-disc">
                        Managing and maintaining accounts:
                      </li>{" "}
                      <p className="text-justify ml-1 md:ml-16">
                        To manage registration of Users of the Service and
                        provide maintenance services for uninterrupted operation
                        of accounts by their respective Users, except
                        interruption on account of any Force Majeure Event.
                      </p>
                    </div>

                    <div class="definition mb-6 text-gray-700">
                      <li className="font-semibold text-md text-black list-disc">
                        Connecting with Users:
                      </li>{" "}
                      <p className="text-justify ml-1 md:ml-16">
                        To contact with Users by email, telephone calls, SMS, or
                        other equivalent forms of electronic communication, such
                        as a mobile application's push notifications regarding
                        updates or informative communications related to the
                        transactions of the Users thereby facilitating
                        conclusion of such transactions, and/or providing
                        related services, including the security updates of
                        device and app, customer support services as and when
                        required by Us.
                      </p>
                    </div>

                    <div class="definition mb-6 text-gray-700">
                      <li className="font-semibold text-md text-black list-disc">
                        Providing personalized information:
                      </li>{" "}
                      <p className="text-justify ml-1 md:ml-16">
                        To provide Users with relevant news, special offers and
                        general information about any special services and
                        events, including promotional materials and updates,
                        which we may offer from time to time; provided that the
                        Users have the option to opt out to receive such
                        information.
                      </p>
                    </div>

                    <div class="definition mb-6 text-gray-700">
                      <li className="font-semibold text-md text-black list-disc">
                        Facilitating closure of transaction:
                      </li>{" "}
                      <p className="text-justify ml-1 md:ml-16">
                        To facilitate closure of any Transaction as may be
                        required by the Users and for that purpose, to provide
                        assistance to the concerned Users online or offline as
                        may be required.
                      </p>
                    </div>

                    <div class="definition mb-6 text-gray-700">
                      <li className="font-semibold text-md text-black list-disc">
                        Manage requests:
                      </li>{" "}
                      <p className="text-justify ml-1 md:ml-16">
                        To attend, manage and answer the requests and queries of
                        the Users.
                      </p>
                    </div>

                    <div class="definition mb-6 text-gray-700">
                      <li className="font-semibold text-md text-black list-disc">
                        For other purposes:
                      </li>{" "}
                      <p className="text-justify ml-1 md:ml-16">
                        We may use the Personal Information for other purposes,
                        such as data analysis, digitization of data, identifying
                        usage trends, determining the effectiveness of our
                        promotional campaigns and to evaluate and improve our
                        Service, products, related services, marketing, and your
                        experience and provide any other services reasonably
                        required by the Users related to any real estate
                        transaction.
                      </p>
                    </div>
                  </ol>
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black text-[1.115rem]">
                  II.
                </span>
                {"  "}
                <span className="text-[1.115rem] ml-0 mb-2 text-black">
                  <i>
                    We may also use the Personal Information for legitimate
                    purpose including but not limited to the following:
                  </i>
                </span>{" "}
                <p className="text-justify ml-0 md:ml-16 mt-2">
                  <ol>
                    <li className="text-justify list-disc mb-2">
                      To provide, operate, and maintain our Platform 24/7/365.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To understand and analyze how you use our Platform.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To communicate with you for customer services.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To provide Users with other relevant information.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To carry on marketing and promotional activities.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To understand Users' needs and interests to improve
                      engagement and loyalty.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To improve our understanding and improvise our Service,
                      products and related service.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To facilitate your use and personalizing your experience
                      on the Website.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To respond to Users' inquiries or responding to Users'
                      requests for any help or Service.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To resolve any technical glitch ensuring uninterrupted use
                      of the Platform by Users.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To detect and prevent any identity theft, and other
                      potentially illegal acts.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To provide information to law enforcement agencies or in
                      connection with an investigation on matters related to
                      public safety.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To comply with Applicable Law.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To resolve disputes and troubleshoot problems.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To assist in any investigations or complaints.
                    </li>
                    <li className="text-justify list-disc mb-2">
                      To provide Users with relevant information and other
                      services that may be requested from us or which we feel
                      may interest Users.
                    </li>
                  </ol>
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span class="font-semibold text-black text-[1.115rem]">
                  III.
                </span>
                {"  "}
                <span className="text-[1.115rem] ml-0 mb-2 text-black text-justify">
                  <i className="text-justify">
                    We may also use the Personal Information on a good faith
                    belief that the disclosure is necessary to prevent fraud,
                    defend the Platform against cyber-attacks, or to protect the
                    information and interest of the Company, its customers and
                    Users, or the public, as the case may be
                  </i>
                </span>
              </div>
            </div>

            <div>
              <h3 className="font-bold text-[20px] my-8 ml-2">
                TRANSFER OF YOUR PERSONAL INFORMATION
              </h3>
              <p className="text-justify ml-0 md:ml-8">
                Personal Information, including Personal Data, is processed at
                the Company's operating offices and at any other places where
                the parties involved in the processing are located. Thus, the
                Personal Information may be transferred to and maintained on
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction. Your consent to
                this Privacy Policy followed by Your submission of such Personal
                Information represents Your agreement to that transfer. The
                Company will take all steps reasonably necessary to ensure that
                your Personal Information is treated securely and in accordance
                with this Privacy Policy and no transfer of your Personal
                Information will take place to an organization or a country
                unless there are adequate controls in place including the
                security of Your Personal Information.
              </p>
            </div>

            <div>
              <h3 className="font-bold text-[20px] my-8 ml-2">
                DISCLOSURE OF YOUR PERSONAL DATA
              </h3>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span className="font-semibold text-md text-black">
                  Business transactions:
                </span>
                <p className="text-justify ml-1 md:ml-16">
                  If the company is involved in a merger, acquisition or asset
                  sale, your Personal Information may be transferred to such
                  merged or new entity. We will provide notice before your
                  Personal Information is transferred and becomes subject to a
                  different Privacy Policy.
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span className="font-semibold text-md text-black">
                  Law enforcement:
                </span>
                <p className="text-justify ml-1 md:ml-16">
                  Under certain circumstances, the Company may be required to
                  disclose your Personal Information if required to do so by law
                  or in response to valid requests by public authorities (e.g. a
                  court or a government agency).
                </p>
              </div>

              <div class="definition mb-6 ml-0 md:ml-8 text-gray-700">
                <span className="font-semibold text-md text-black">
                  Other legal requirements:
                </span>
                <p className="text-justify ml-1 md:ml-16">
                  The Company may disclose your Personal Information in good
                  faith belief that such action is necessary to: 
                  <br />Comply with a legal obligation.
                  <br />Protect and defend the rights or property of
                  the Company. 
                  <br />Prevent or investigate possible wrongdoing in
                  connection with the Service.
                  <br /> Protect the personal safety of
                  Users of the Service or the public. 
                  <br />Provide protection against
                  legal liability.
                </p>
              </div>
            </div>

            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
                TRANSFER OF YOUR PERSONAL INFORMATION
              </h3>
              <p className="text-justify ml-1 md:ml-8">Users may choose to log in to the Platform via various online services, including social networking services. Our Service may also enable Users to access social networking services such as Facebook, Twitter, LinkedIn, Instagram or Google. When linking to a social network account to our Platform or log into our Service using social network account, we may collect relevant information necessary to enable our Service to access that social network and user data contained therein and may share the user information with the operator of that social network to facilitate or enhance delivery of services to Users. However, the manner in which such a social network collects, uses, stores, and discloses user information is governed by the policies of such social network operator and we shall have no liability or responsibility for the privacy practices or other actions of any such social network.</p>
            </div>


            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
            CORRECTION / ERASING OF INFORMATION
              </h3>
              <p className="text-justify ml-1 md:ml-8">You are entitled to modify, rectify or erase or restrict the processing of your Personal Information available on the Platform or you may request the Company to do so on your behalf. However, in some cases, the Company may retain certain Personal Information as required by law or in the interest of its business to comply with any legal obligation and/or enforce any obligation under any agreement. In any event, the Company will not retain any Personal Information which is no longer required.</p>
            </div>


            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
            SECURITY
              </h3>
              <p className="text-justify ml-1 md:ml-8">We provide reasonable administrative, technical, and physical security controls to protect the Information. However, despite our efforts, no security controls are 100% effective as the transmission of information via the internet is not completely secure and is exposed to online fraud and hacking. Although we will do our best to protect your personal Information, we cannot guarantee the security of your Information transmitted to our site; any transmission is at your own risk. Once we have received your Personal Information, we will use strict procedures and security features to try to prevent unauthorized access.</p>
            </div>


            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
            YOUR CONSENT AND WITHDRAWAL OF CONSENT
              </h3>
              <p className="text-justify ml-1 md:ml-8">You acknowledge that use of the Platform and availing of the Service, the Company is required to receive and process the Personal Information of the Users and do hereby freely and unambiguously give Your consent to use and processing of Your Personal Information in the manner as may be required by the Company in accordance with this Policy and in accordance with the provisions of Applicable Law.</p>
            </div>



            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
            COOKIES
              </h3>
              <p className="text-justify ml-1 md:ml-8">Like many companies, we use "cookies" for record keeping purposes. Cookies do not contain any information that can personally identify You. They help us to recognize You when you visit the Platform and make use of the Service so that the Service can be tailored to your needs. Cookies may enhance Your online experience by saving your preferences. We use cookies to measure the activity on the Platform. You acknowledge and agree that without cookies, you may not be able to take full advantage of all our Platform and use the Service.</p>
            </div>


            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
            ACCESS AND CORRECTION
              </h3>
              <p className="text-justify ml-1 md:ml-8">Subject to Applicable Law, You have the option to access to your Personal Information we have retained about you and the ability to review, correct, update, block or delete any of your Personal Information where it is inaccurate. Subject to Applicable Law, we may limit or deny access to Personal Information where providing such access is unreasonably burdensome or expensive under the circumstances.</p>
            </div>


            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
            ACCOUNT DELETION  
              </h3>
              <p className="text-justify ml-1 md:ml-8">You may, at your discretion, delete or ask us for deletion of your account created for availing our Service.</p>
            </div>


            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
            RETENTION OF YOUR PERSONAL DATA  
              </h3>
              <p className="text-justify ml-1 md:ml-8">The Company will retain your Personal Information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Information to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. The Company will also retain usage data for internal analysis purposes for such period as may be required for our legal obligation.</p>
            </div>


            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
            ACCESS OF SERVICES BY CHILDREN 
              </h3>
              <p className="text-justify ml-1 md:ml-8">Our Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from anyone under the age of 18. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If we become aware that we have collected Personal Data from anyone under the age of 18 without verification of parental consent, We take steps to remove that data from Our servers.</p>
            </div>


            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
            DISCLAIMER 
              </h3>
              <p className="text-justify ml-1 md:ml-8">You acknowledge and agree that any Transaction done by use of the Platform and the Service provided by the Company is solely and exclusively by and between the Users and the Users take the full responsibility and liability in respect of such Transaction. Company does not have any right or obligation to make any advice or suggestion or to involve therein in any manner whatsoever and as such, we disclaim any responsibility or liability pertaining to or arising out of any such Transaction done on the Platform by the Users.</p>
            </div>

            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
            UPDATES TO OUR PRIVACY POLICY
              </h3>
              <p className="text-justify ml-1 md:ml-8">This Privacy Policy may be updated periodically and without prior notice to you to reflect changes in our personal information practices or Applicable Laws. We will post a prominent notice on the Platform including website of the Company to notify you of any significant changes to our Privacy Policy. We will also let you know via email, prior to the change becoming effective. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
            </div>


            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
            ANY QUESTION OR QUERY
              </h3>
              <p className="text-justify ml-1 md:ml-8 mb-6">If you have any specific questions/doubts or need any clarification pertaining to our Privacy Policy, do not hesitate to contact us at <a href="mailto:admin@rempo.in" target="_blank" rel="noreferrer" referrerPolicy="no-referrer" className="text-[#529fe4]">admin@rempo.in</a>.</p>
              <p className="text-justify ml-1 md:ml-8 ">This Privacy Policy applies only to our online activities and is valid for Users to our Platform with regard to the Personal Information shared on the Platform or collected by us in terms of this Policy. This policy is not applicable to any information collected offline or via channels other than those stated above</p>
            </div>


            <div>
            <h3 className="font-bold text-[20px] my-8 ml-2">
            CONTACT US
              </h3>
              <p className="text-justify ml-1 md:ml-8">If you have any comments or questions about this Privacy Policy or any questions relating to the Platform and/or the Service, please contact us at the address below. We have a professional team to resolve your questions.</p>

              <p className="ml-1 md:ml-8 my-6">
                <span className="text-base font-semibold">Address</span>: <span className="text-[1.105rem] font-bold">Rempo Ventures Pvt. Ltd.</span>
                <br />Sr No. 7 & 8, Office No. 304,
                <br />3rd Floor, Elite Premio,
                <br />Balewadi, Pune, 411045
              </p>

            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
