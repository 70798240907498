import { Icon } from "@iconify/react";
import React from "react";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../../shared/tableStyle";

const BidHistory = ({ setIsOpen }) => {
  return (
    <>
      <div className="flex justify-between w-full items-center">
        <h5 className="text-[20px] font-medium">9876543210</h5>
        <button
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <Icon width="24px" icon="material-symbols:close" />
        </button>
      </div>
      <h6 className="text-[16px] text-[#667085] mt-[8px] mb-[24px]">
        Offer History
      </h6>
      <DataTable
        columns={[
          {
            name: "Bid Date",
            selector: (row) => row.id,
            cell: (row) => <span>23/04/2023</span>,
          },
          {
            name: "Bid Amount",
            selector: (row) => row.buyer,
            cell: (row) => <span>₹1255</span>,
          },
        ]}
        data={[{ id: 123 }, { id: 876 }, { id: 876 }]}
        noDataComponent={
          <span className="px-[16px] py-[20px]">{`No data found`}</span>
        }
        customStyles={{
          ...tableCustomStyles,
          headRow: {
            style: {
              padding: "0",
            },
          },
          rows: {
            style: {
              fontSize: "14px",
              fontWeight: "500",
              color: "#484848",
              backgroundColor: "#FAFAFA",
              // padding: "16px",
              "&:not(:last-of-type)": {
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                borderBottomColor: "#ECECEC",
              },
            },
          },
        }}
      />
    </>
  );
};

export default BidHistory;
