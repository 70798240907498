import React, { useEffect, useState } from "react";
import { getPlotByID } from "../../apis/apiClient";
import { numDifferentiation } from "../../utils/sharedFunc";

const ExpandedRows = ({ data, someTitleProp }) => {
  const [plotDetails, setPlotDetails] = useState(data);
  useEffect(() => {
    setPlotDetails(data);
    if (data.type === "bulk") {
      setPlotDetails({ ...data, ...data.scheme });
    }
  }, [data]);
  return (
    <>
      <div className="ml-[149px] pl-[16px] pr-[32px] py-[16px] flex gap-[16px] border-l-[1px] border-[#ECECEC]">
        <div className="min-w-[80px]">
          <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
            Plot number
          </p>
          <p className="text-[12px] font-medium text-[#484848]">
            {plotDetails.location?.plot_number}
          </p>
        </div>
        <div className="min-w-[80px]">
          <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
            Area
          </p>
          <p className="text-[12px] font-medium text-[#484848]">
            {plotDetails.area} {plotDetails.area_unit}
          </p>
        </div>
        <div className="min-w-[80px]">
          <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
            Front
          </p>
          <p className="text-[12px] font-medium text-[#484848]">
            {" "}
            {plotDetails.front}
          </p>
        </div>
        <div className="min-w-[80px]">
          <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
            Depth
          </p>
          <p className="text-[12px] font-medium text-[#484848]">
            {" "}
            {plotDetails.depth}
          </p>
        </div>
        <div className="min-w-[80px]">
          <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
            Road access
          </p>
          <p className="text-[12px] font-medium text-[#484848]">
            {" "}
            {plotDetails.access_road}
          </p>
        </div>
        <div className="min-w-[80px]">
          <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
            Lumpsum
          </p>
          <p className="text-[12px] font-medium text-[#484848]">
            {numDifferentiation(plotDetails.rate?.lumpsum)}
          </p>
        </div>
        <div className="min-w-[80px]">
          <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
            First owner
          </p>
          <p className="text-[12px] font-medium text-[#484848]">
            {plotDetails.legal?.isFirstOwner === "true" ? "Yes" : "No"}
          </p>
        </div>

        <div className="min-w-[80px]">
          <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
            Deed Available
          </p>
          <p className="text-[12px] font-medium text-[#484848]">
            {" "}
            {plotDetails.legal?.purchaseDeed === "true" ? "Yes" : "No"}
          </p>
        </div>
        <div className="min-w-[80px]">
          <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
            Deed of previous owner
          </p>
          <p className="text-[12px] font-medium text-[#484848]">
            {plotDetails.legal?.previousPurchaseDeed === "true" ? "Yes" : "No"}
          </p>
        </div>
      </div>
      <div className="ml-[149px] pl-[16px] pr-[32px] py-[16px] flex border-b-[1px] border-[#ECECEC] gap-[16px] border-l-[1px]">
        <div className="min-w-[80px]">
          <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
            Mortgage
          </p>
          <p className="text-[12px] font-medium text-[#484848]">
            {plotDetails.legal?.underMortage === "true" ? "Yes" : "No"}
          </p>
        </div>
        <div className="min-w-[80px]">
          <p className="text-[10px] font-medium text-[#838383] mb-[4px]">
            Deed of previouos owner
          </p>
          <p className="text-[12px] font-medium text-[#484848]">Yes?</p>
        </div>
      </div>
    </>
  );
};

export default ExpandedRows;
