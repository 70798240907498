import React from "react";
import about from "../../assets/images/about.png";

const About = () => {
  return (
    <section
      className="mt-[64px] gap-[120px] items-center mx-auto max-w-screen-xl px-[24px] flex flex-col md:flex-row justify-evenly"
      id="aboutus"
    >
      <div className="w-full h-full">
        <h1 className="text-[36px] md:text-[48px] font-bold text-headingSecondary">
          About Rempo
        </h1>
        <p className="text-[16px] mt-[24px] text-headingSecondary text-justify">
          <span className="font-bold">REMPO </span> to bring transparency and
          ease of dealing in land investments in Maharashtra, REMPO has created
          a one of its kind web portal and app, that will allow property owners
          and investors to connect seamlessly while leveraging the digital
          platform.
        </p>
      </div>
      <div className="w-full h-full">
        <img alt="about" src={about} />
      </div>
    </section>
  );
};

export default About;
