import React from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import HomeHeader from "../components/Header/HomeHeader";
import { FaArrowRight } from "react-icons/fa6";

const PaymentInvalid = () => {
  const data = new URLSearchParams(window.location.search).get("message");
  const navigate = useNavigate();

  if (!data) {
    navigate("/subscription");
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: require("../assets/animations/warning.json"),
  };
  return (
    <>
      <HomeHeader isHome={false} />
      <div className="bg-white mx-auto max-w-screen-xl px-[24px] min-h-screen">
        <div className="mt-[24px]">
          <div className="flex items-center gap-[20px]">
            {/* <Icon icon="bx:arrow-back" color="#2B2B2B" width={24} /> */}
            <h3 className="text-[#000000] text-[24px] font-semibold">
              Payment Status
            </h3>
          </div>
          <div className="flex flex-col items-center p-4">
            <div className="flex justify-center items-center">
              <Lottie options={defaultOptions} height={150} width={300} />
            </div>
            <div className="text-center text-3xl font-bold mt-4">
              Error Processing Payment
            </div>
            <div className="text-center text-lg text-red-500 font-bold mt-4">
              {data}
            </div>
            <div className="text-center text-md md:text-lg mt-2">
              For payment-related issues, contact{" "}
              <a
                href="mailto:admin@rempo.in"
                className="text-blue-600 mt-2 no-underline hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                admin@rempo.in
              </a>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center gap-4">
          <button
            className="mt-6 flex items-center gap-2 bg-btnBgColor border-[2px] border-primaryColor px-[16px] py-[12px] text-primaryColor text-[16px] rounded-regular"
            onClick={() => navigate("/subscription")}
          >
            <img
              src={"../retry.svg"}
              width={18}
              height={18}
              alt=""
              className="text-primaryColor"
            />
            Try Again
          </button>

          <button
            className="mt-6 flex items-center gap-2 bg-btnBgColor border-[2px] border-primaryColor px-[16px] py-[12px] text-primaryColor text-[16px] rounded-regular"
            onClick={() => navigate("/")}
          >
            <FaArrowRight />
            Back to Home
          </button>
        </div>
      </div>
    </>
  );
};

export default PaymentInvalid;
